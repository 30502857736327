import { Fragment, useEffect, useState } from 'react';
import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Pagination,
  TooltipDefinition,
} from 'carbon-components-react';
import {
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  TableSelectRow,
  TableSelectAll,
  TableContainer,
} from 'carbon-components-react/lib/components/DataTable';
import {
  DataSortOrder,
  DataTableInterface,
  DownloadDataPages,
  GetPractitionerSummary,
  HeadersInterface,
  PractitionerSortFields,
  SelectablePractitioner,
  SingleFieldUpdateType,
  sortData,
  DEFAULT_ROWS_PER_PAGE,
  actionSort,
  ROWS_PER_PAGE_SELECTIONS,
  toFixedValue,
} from '@cic-boardlite/common';
import { headers } from './headers-data';
import { useTranslation } from 'react-i18next';
import {
  useFilteredPractitioners,
  usePractitioners,
  usePractitionerAssignments,
  usePractitionerCv,
  useFilterTabs,
  useUser,
} from '../../../custom-hooks';
import PractitionersTableExpandedRow from '../practitioner-table-expanded-row/table-expanded-row';
import { DataTableSortState } from 'carbon-components-react/lib/components/DataTable/state/sorting';
import { Edit, Renew, Warning } from '@carbon/icons-react/next';
import DataDownloadToolbar from '../../data-download-toolbar/data-download-toolbar';
import UpdateSingleFieldComponent from '../../single-field-update-practitioners-table/single-field-update-component';
import UpdateAvailabilityDateModal from '../../update-availability-date-modal/update-availability-date-modal';
import './table-expandable.scss';

const PractitionersTableExpandable = () => {
  const { filteredPractitionersData, isLoadingFilteredPractitioners } = useFilteredPractitioners();
  const { isLoadingFilterTabs } = useFilterTabs();
  const { t } = useTranslation('common');
  const [isPractitioner, setPractitioner] = useState<GetPractitionerSummary>();
  const [isRowExpanded, setIsRowExpanded] = useState([false]);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_ROWS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredPractitionerRows, setFilteredPractitionerRows] = useState<SelectablePractitioner[]>([]);
  const [selectablePractitioners, setSelectablePractitioners] = useState<SelectablePractitioner[]>([]);
  const [selectedRowsNumber, setRowSelected] = useState(0);
  const [sortKey, setSortKey] = useState('');
  const [sortKeyDirection, setSortKeyDirection] = useState<DataTableSortState>(DataSortOrder.NONE);
  const [updatingPractitionerFieldId, setUpdatingPractitionerFieldId] = useState('');

  const { triggerCalculatePractitionerAvailabilityDate } = usePractitionerAssignments();

  useEffect(() => {
    setCurrentPage(1);
    setIsRowExpanded([]);
    setSortKey('');
    setCurrentPageSize(DEFAULT_ROWS_PER_PAGE);

    const foundSelectable = findSelectable(filteredPractitionersData.filteredPractitioners);

    setSelectablePractitioners(foundSelectable);
    setFilteredPractitionerRows(foundSelectable.slice(0, currentPageSize));
  }, [filteredPractitionersData]);

  useEffect(() => {
    let sortedPractitioners;

    if (sortKeyDirection === DataSortOrder.NONE) {
      sortedPractitioners = filteredPractitionersData.filteredPractitioners;
    } else {
      sortedPractitioners = [...filteredPractitionersData.filteredPractitioners].sort(dynamicSort(sortKey));
    }

    setIsRowExpanded([]);
    const foundSelectable = findSelectable(sortedPractitioners);

    setSelectablePractitioners(foundSelectable);
    setFilteredPractitionerRows(foundSelectable.slice(0, currentPageSize));
  }, [sortKey, sortKeyDirection]);

  const { userInfo, roles } = useUser();
  const { pushPractitionerToTheList, removePractitionerFromTheList, selectSelectedPractitioners } = usePractitionerCv();
  const { toggleSingleFieldComponent, currentSingleFieldComponentType } = usePractitioners();
  const getPractitionerByRowId = (id: string) => {
    const practitioner = filteredPractitionerRows.find((object: { id: string }) => object.id === id);
    setPractitioner(practitioner);
  };
  const checkPractitionerByRowId = (id: string): SelectablePractitioner => {
    return selectablePractitioners.find((object: { id: string }) => object.id === id)!;
  };
  const getPractitionerHiringDate = (id: string) => {
    const practitioner = filteredPractitionerRows.find((object: { id: string }) => object.id === id);
    return practitioner?.hiringDate ? practitioner.hiringDate : '';
  };

  const findSelectable = (practitioners: SelectablePractitioner[]): SelectablePractitioner[] => {
    return practitioners.map((practitioner) => {
      practitioner = {
        ...practitioner,
        isSelectable: checkRoles(
          practitioner.peopleManagerPractitionerId === userInfo.id || practitioner.id === userInfo.uid
        ),
      };
      return practitioner;
    });
  };

  const checkRoles = (isPem?: boolean) => {
    if (roles.includes('wmp') || roles.includes('admin')) return true;
    return !!isPem;
  };

  const handleRowExpansion = (
    rows: SelectablePractitioner[],
    expandedRowId: string,
    nodeName?: string,
    className?: string,
    index?: number
  ) => {
    currentSingleFieldComponentType.length && toggleSingleFieldComponent('');
    if (
      className === 'bx--table-column-checkbox' ||
      className === 'bx--table-expand' ||
      className === 'bx--table-column-checkbox selectTableRow' ||
      nodeName === 'INPUT'
    ) {
      return;
    }
    setPractitioner(undefined);
    getPractitionerByRowId(expandedRowId);
    if (index === undefined && nodeName !== 'svg' && nodeName !== 'path' && nodeName !== 'BUTTON') return;

    const rowExpansionStatus = [...isRowExpanded];

    rows.map((row, index) => (rowExpansionStatus[index] = row.id === expandedRowId && !rowExpansionStatus[index]));

    setIsRowExpanded(rowExpansionStatus);
  };

  const checkIfEveryRowIsSelected = () => {
    return (
      !!filteredPractitionerRows.length &&
      !!selectSelectedPractitioners.length &&
      filteredPractitionerRows.every((row) =>
        selectSelectedPractitioners.find((practitioner) => practitioner.id === row.id)
      )
    );
  };

  const selectOrRemoveAllPractitioners = (rows: SelectablePractitioner[]) => {
    if (checkIfEveryRowIsSelected()) {
      for (const row of rows) {
        const alreadySelected = selectSelectedPractitioners.find((practitioner) => practitioner.id === row.id);
        if (alreadySelected) removePractitionerFromTheList(row.id);
      }
    } else {
      for (const row of rows) {
        const alreadySelected = selectSelectedPractitioners.find((practitioner) => practitioner.id === row.id);
        if (!alreadySelected) {
          const practitioner = filteredPractitionersData.filteredPractitioners.find(
            (practitioner) => practitioner.id === row.id
          );
          practitioner && pushPractitionerToTheList(practitioner);
        }
      }
    }
  };

  const handlePagination = ({ page, pageSize }: { page: number; pageSize: number }) => {
    setCurrentPage(page);
    setIsRowExpanded([]);
    setRowSelected(0);
    setCurrentPageSize(pageSize);
    const firstRowId = pageSize * page - pageSize;
    setFilteredPractitionerRows(selectablePractitioners.slice(firstRowId, pageSize * page));
  };

  const singleRowSelection = (row: SelectablePractitioner) => {
    if (row.isSelected) {
      if (selectedRowsNumber > 0) setRowSelected(selectedRowsNumber - 1);
      return removePractitionerFromTheList(row.id);
    }
    setRowSelected(selectedRowsNumber + 1);
    removePractitionerFromTheList(row.id);
    return pushPractitionerToTheList(checkPractitionerByRowId(row.id)!);
  };

  function handleRowSort(header: HeadersInterface) {
    setCurrentPage(1);
    if (header.key === sortKey) {
      handleKeyDirection();
    } else {
      setSortKey(header.key);
      setSortKeyDirection(DataSortOrder.DESC);
    }
  }

  function handleKeyDirection() {
    if (sortKeyDirection === DataSortOrder.NONE) {
      setSortKeyDirection(DataSortOrder.DESC);
    } else if (sortKeyDirection === DataSortOrder.DESC) {
      setSortKeyDirection(DataSortOrder.ASC);
    } else {
      setSortKeyDirection(DataSortOrder.NONE);
    }
  }

  function dynamicSort(property: string) {
    let sortOrder = 0;
    if (sortKeyDirection === DataSortOrder.ASC) {
      sortOrder = -1;
    } else if (sortKeyDirection === DataSortOrder.DESC) {
      sortOrder = 1;
    }
    return function (practitionerA: PractitionerSortFields, practitionerB: PractitionerSortFields) {
      const key = property as keyof typeof practitionerA;
      if (practitionerA[key] === null) {
        return -1 * sortOrder;
      }
      if (practitionerB[key] === null) {
        return 1 * sortOrder;
      }

      const valueA = (practitionerA[key] || '').toLowerCase();
      const valueB = (practitionerB[key] || '').toLowerCase();

      let result;

      if (property === 'actionRequiredStatus') {
        result = actionSort(valueA, valueB);
      } else if (property === 'band') {
        return sortData(valueA, valueB, property, sortKeyDirection);
      } else if (property === 'costRate') {
        result = Number(valueA) > Number(valueB) ? 1 : -1;
      } else {
        result = valueA.localeCompare(valueB);
      }
      return result * sortOrder;
    };
  }

  const handleRenewAvailabilityDate = (practitionerId: string) => {
    triggerCalculatePractitionerAvailabilityDate({
      practitionerId: Number(practitionerId),
      recalculateOnAssignmentUpdate: false,
    });
  };

  const checkIfAvailabilityDateEditedManually = (practitionerId: string) => {
    const practitioner = filteredPractitionersData.filteredPractitioners.find(
      (practitioner) => practitioner.id === practitionerId
    );
    return !!(practitioner && practitioner.availabilityDateManuallyEdited);
  };

  const handleOpenSingleFieldUpdateComp = (fieldType: string, practitionerId: string) => {
    toggleSingleFieldComponent(fieldType);
    setUpdatingPractitionerFieldId(practitionerId);
  };

  const checkIfPractitionerTerminatedOrDeletion = (id: string) => {
    const practitioner = filteredPractitionersData.filteredPractitioners.find((practitioner) => practitioner.id === id);

    return !!(practitioner && (practitioner.deletion || practitioner.terminated));
  };

  return isLoadingFilterTabs || isLoadingFilteredPractitioners ? (
    <DataTableSkeleton
      columnCount={headers.length + 2}
      headers={headers}
      rowCount={20}
      showHeader={false}
      showToolbar={false}
    />
  ) : (
    <>
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <DataTable headers={headers} size="md" rows={filteredPractitionerRows}>
          {({ rows, headers, getTableProps, getSelectionProps, getHeaderProps, getRowProps }: DataTableInterface) => {
            return (
              <>
                <UpdateAvailabilityDateModal editingFromProfilePage={false} editingFromPractitionersTable={true} />
                <TableContainer>
                  <DataDownloadToolbar page={DownloadDataPages.Practitioners} />
                  <Table {...getTableProps()}>
                    <TableHead className="practitionerTableHeaders">
                      <TableRow>
                        <TableExpandHeader />
                        <TableSelectAll
                          {...getSelectionProps()}
                          onSelect={(e) => {
                            selectOrRemoveAllPractitioners(rows);
                            getSelectionProps().onSelect(e);
                          }}
                          indeterminate={!!selectSelectedPractitioners.length && !checkIfEveryRowIsSelected()}
                          checked={checkIfEveryRowIsSelected()}
                        />
                        {headers.map((header, index) => (
                          <TableHeader
                            key={index}
                            {...getHeaderProps({ header })}
                            isSortable={true}
                            sortDirection={sortKeyDirection}
                            isSortHeader={header.key === sortKey}
                            onClick={() => {
                              handleRowSort(header);
                            }}
                            className="practitioners-list-padding-left"
                          >
                            {t(`tableHeader.${header.key}`)}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => {
                        return (
                          <Fragment key={index}>
                            {(row.isSelectable = checkPractitionerByRowId(row.id)?.isSelectable)}
                            {
                              (row.isSelected =
                                row.isSelectable &&
                                selectSelectedPractitioners.some((practitioner) => practitioner.id === row.id))
                            }
                            <TableExpandRow
                              onClick={(e) => {
                                handleRowExpansion(
                                  rows,
                                  row.id,
                                  (e.target as HTMLElement).nodeName,
                                  (e.target as HTMLElement).className,
                                  undefined
                                );
                              }}
                              {...getRowProps({ row })}
                              isExpanded={isRowExpanded[index] === undefined ? false : isRowExpanded[index]}
                            >
                              <Fragment>
                                {
                                  (row.isSelected = selectSelectedPractitioners.some(
                                    (practitioner) => practitioner.id === row.id
                                  ))
                                }
                                {checkIfPractitionerTerminatedOrDeletion(row.id) ? (
                                  <TableCell>
                                    <div className="practitioners-table-terminated-deletion">
                                      <Warning />
                                    </div>
                                  </TableCell>
                                ) : (
                                  <TableSelectRow
                                    disabled={!row.isSelectable}
                                    className={row.isSelectable ? '' : 'selectTableRow'}
                                    {...getSelectionProps({ row })}
                                    onChange={() => singleRowSelection(row)}
                                  />
                                )}
                                {row.cells.map((cell, index) => {
                                  return (
                                    <TableCell
                                      onClick={(e) =>
                                        cell.info.header !== 'availabilityDate' &&
                                        cell.info.header !== 'availabilityInfo'
                                          ? handleRowExpansion(rows, row.id, undefined, undefined, index)
                                          : e.stopPropagation()
                                      }
                                      onDoubleClick={() => {
                                        cell.info.header === 'availabilityDate' &&
                                          handleOpenSingleFieldUpdateComp(
                                            SingleFieldUpdateType.AvailabilityDate,
                                            row.id
                                          );

                                        cell.info.header === 'availabilityInfo' &&
                                          handleOpenSingleFieldUpdateComp(
                                            SingleFieldUpdateType.AvailabilityInfo,
                                            row.id
                                          );
                                      }}
                                      className={`practitioner-table-max-cell-width ${
                                        cell.info.header === 'actionRequiredStatus'
                                          ? `${cell.value}-practitionerActionTableCell`
                                          : ''
                                      } ${
                                        cell.info.header === 'availabilityInfo' && !cell.value
                                          ? 'practitioner-table-empty-availability'
                                          : ''
                                      }`}
                                      key={index}
                                    >
                                      {cell.info.header === 'availabilityDate' ? (
                                        updatingPractitionerFieldId === row.id &&
                                        currentSingleFieldComponentType === SingleFieldUpdateType.AvailabilityDate ? (
                                          <div className="practitioner-availability-date-edit-wrapper">
                                            <UpdateSingleFieldComponent
                                              type={SingleFieldUpdateType.AvailabilityDate}
                                              defaultValue={cell.value}
                                              practitionerId={row.id}
                                              oldAvailabilityDateManuallyEdited={checkIfAvailabilityDateEditedManually(
                                                row.id
                                              )}
                                              practitionerHiringDate={new Date(getPractitionerHiringDate(row.id))}
                                            />
                                          </div>
                                        ) : (
                                          <div className="practitioner-availability-date-edit-wrapper">
                                            {checkIfAvailabilityDateEditedManually(row.id) && (
                                              <TooltipDefinition
                                                onFocus={(e) => e.currentTarget.blur()}
                                                className="practitioner-availability-date-tooltip"
                                                direction="top"
                                                align="start"
                                                tooltipText={t('availabilityDateUpdate.manuallyEnteredDate')}
                                              >
                                                <p className="practitioner-availability-date-manually-edited">
                                                  {cell.value}
                                                </p>
                                              </TooltipDefinition>
                                            )}
                                            {!checkIfAvailabilityDateEditedManually(row.id) && cell.value}
                                            <div>
                                              <Edit
                                                className="edit-button"
                                                onClick={() => {
                                                  getPractitionerByRowId(row.id);
                                                  handleOpenSingleFieldUpdateComp(
                                                    SingleFieldUpdateType.AvailabilityDate,
                                                    row.id
                                                  );
                                                }}
                                              />
                                            </div>
                                            {checkIfAvailabilityDateEditedManually(row.id) && (
                                              <TooltipDefinition
                                                onFocus={(e) => e.currentTarget.blur()}
                                                className="practitioner-availability-date-tooltip"
                                                direction="top"
                                                tooltipText={t('availabilityDateUpdate.tooltipRecalculate')}
                                                onClick={(event) => {
                                                  getPractitionerByRowId(row.id);
                                                  handleRenewAvailabilityDate(row.id);
                                                  event.stopPropagation();
                                                }}
                                              >
                                                <div>
                                                  <Renew />
                                                </div>
                                              </TooltipDefinition>
                                            )}
                                          </div>
                                        )
                                      ) : cell.info.header === 'costRate' ? (
                                        toFixedValue(cell.value, '-')
                                      ) : (
                                        cell.value
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </Fragment>
                            </TableExpandRow>
                            {isRowExpanded[index] && (
                              <TableExpandedRow colSpan={headers?.length + 2}>
                                {isPractitioner && (
                                  <PractitionersTableExpandedRow
                                    practitionersInfo={isPractitioner}
                                    isRowExpanded={isRowExpanded}
                                  />
                                )}
                              </TableExpandedRow>
                            )}
                          </Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            );
          }}
        </DataTable>
      }
      <Pagination
        className="practitionerTablePagination"
        backwardText={t('pagination.previousPage')}
        forwardText={t('pagination.nextPage')}
        itemRangeText={(min, max, total) => t('pagination.itemRangeText', { min, max, total })}
        pageRangeText={(current, total) => t('pagination.pageRangeText', { current, total })}
        page={currentPage}
        pageSize={currentPageSize}
        pageSizes={ROWS_PER_PAGE_SELECTIONS}
        itemsPerPageText={t('pagination.itemsPerPage')}
        totalItems={filteredPractitionersData.filteredPractitioners.length}
        onChange={handlePagination}
      />
    </>
  );
};
export default PractitionersTableExpandable;
