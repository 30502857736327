import { environment } from '../environments/environment';

export const domain = '/api';

export const blueServicesDomain = environment.BLUE_SERVICES_DOMAIN;

export const bluePagesDBDomain = environment.BLUE_PAGES_SERVICE;

export const bluePagesUnifiedProfileAPI = environment.BLUE_PAGES_UNIFIED_PROFILE_API;

export const authorizationConfig = {
  assertLogin: `${domain}/assert/login`,
  assertLogout: `${domain}/assert/logout`,
  assertUser: `${domain}/assert/user`,
  tokenName: `token`,
  assertRefreshToken: `${domain}/assert/refreshtoken`,
  hiringPipelinePractitioners: `${domain}/practitioners/hiringPipeline`,
};
export const sagaConfig = {
  allPractitionerAPI: `${domain}/practitioners/all`,
  staticDataAPI: `${domain}/staticValues`,
};
export const practitionerConfig = {
  singlePractitionerDetails: `${domain}/practitioners/practitioner/?id=`,
  actionRequiredPractitionersNumber: `${domain}/practitioners/action-required-practitioners-count`,
  practitionersNames: `${domain}/practitioners/names-list`,
  geoPmNamesTalentIds: `${domain}/projects/geo-pm-names-talents-ids-list`,
  createPractitioner: `${domain}/practitioner-service/create`,
  updatePractitioner: `${domain}/practitioner-service/update`,
  deletePractitioner: `${domain}/practitioner-service/delete`,
  getPractitionerAssignments: `${domain}/assignments/practitionerAssignments?id=`,
  getPractitionerAssignmentsReduced: `${domain}/assignments/practitionerAssignmentsReduced?id=`,
  createPractitionerAssignment: `${domain}/assignments-domain/create`,
  updatePractitionerAssignment: `${domain}/assignments-domain/update`,
  deletePractitionerAssignment: `${domain}/assignments-domain/delete`,
  hiringPipelinePractitioners: `${domain}/practitioners/hiringPipeline`,
  practitionersEmails: `${domain}/practitioners/practitioners-emails`,
  projectAssignmentEmailsDates: `${domain}/assignments`,
  getPractitionerAssignmentsCount: `${domain}/assignments/practitionerAssignmentsCount?id=`,
  getMaintainerEmails: `${domain}/practitioners/maintainer-emails`,
};
export const getFilteredProjectsConfig = {
  projectsService: `${domain}/projects`,
};
export const blueServicesConfig = {
  cvService: `${blueServicesDomain}/cv-service`,
  sendEmail: `${blueServicesDomain}/email-service/sendEmail`,
  sendFeedbackEmail: `${blueServicesDomain}/email-service/sendFeedbackEmail`,
  sendProjectAssignmentEmail: `${blueServicesDomain}/email-service/sendCustomEmail`,
  healthPing: `${blueServicesDomain}/health/ping`,
  cvBuilderPing: `${blueServicesDomain}/health/pingCvBuilder`,
};
export const projectConfig = {
  getProjectAssignments: `${domain}/assignments/projectAssignments?id=`,
  getProject: `${domain}/projects/single-project`,
  singleFieldUpdate: `${domain}/project-service/singleFieldUpdate`,
  lastAssignmentEndDate: `${domain}/assignments/lastAssignmentEndDate?id=`
};
export const practitionersFilterConfig = {
  getPractitionersFilter: `${domain}/practitioners/filter?isCic=true&terminated=false&`,
};
export const exportFileConfig = {
  exportPractitioners: `${domain}/file-export/practitioners`,
  exportPractitionersAssignments: `${domain}/file-export/practitioners-assignments`,
  exportProjects: `${domain}/file-export/projects`,
  exportProjectsAssignments: `${domain}/file-export/projects-assignments`,
};
export const multiChangeConfig = {
  multiChange: `${domain}/multi-change-service`,
};
export const dataModifyLogsConfig = {
  setLog: `${domain}/data-modify-logs/set`,
  getLogs: `${domain}/data-modify-logs/get`,
};
