import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'carbon-components-react';
import { useCommonState, useProject, useProjectAssignments } from '../../../custom-hooks';
import ProjectAssignmentPageComponent from './assignment-page-comp';
import AssignmentCreateEdit from '../../assignment-create-edit/create-edit-assignment-action';
import MultiChangesDialog from '../../multi-changes-dialog/multi-changes-dialog';
import { DeleteModalType, MultiChangeAction, ProjectReduceType } from '@cic-boardlite/common';
import { useTranslation } from 'react-i18next';
import { DeleteAction } from '../../../ui/common/delete-action/deleteAction';
import { Add16 } from '@carbon/icons-react';
import SendEmailDialog from '../../send-email-modal-dialog/send-email-dialog';

const ProjectAssignmentPage = () => {
  const { id } = useParams<{ id: string }>();
  const { getProjectData, projectData } = useProject();
  const { isLoadingProjectAssignments } = useProjectAssignments();

  useEffect(() => {
    if (id) {
      getProjectData(id, ProjectReduceType.assignment);
    }
  }, []);

  const { t } = useTranslation('common');
  const assignmentMultiChangeKeys = [
    {
      key: 'endDate',
      title: t('multiChange.assignmentFields.endDate'),
    },
    // TODO add backend logic for this before uncommenting
    // {
    //   key: 'isMainAssignment',
    //   title: t('multiChange.assignmentFields.isMainAssignment'),
    // },
    {
      key: 'assignmentStatus',
      title: t('multiChange.assignmentFields.assignmentStatus'),
    },
    {
      key: 'allocation',
      title: t('multiChange.assignmentFields.allocation'),
    },
  ];

  const assignmentDropdowns = {
    assignmentStatus: [
      { value: 1, title: t('createEditAssignment.status.options.onboarded') },
      { value: 2, title: t('createEditAssignment.status.options.offboarded') },
    ],
    isMainAssignment: [
      { value: true, title: 'true' },
      { value: false, title: 'false' },
    ],
  };

  const { toggleAssignmentCreateEditModal } = useCommonState();

  function toggleAssignmentCreateEdit() {
    toggleAssignmentCreateEditModal({
      isOpen: true,
      projectDetails: projectData,
      projectPage: true,
    });
  }

  return (
    <>
      <MultiChangesDialog
        modalType={MultiChangeAction.assignment}
        multiChangeKeys={assignmentMultiChangeKeys}
        overrideDropdown={assignmentDropdowns}
      />
      <DeleteAction type={DeleteModalType.assignment} />
      <ProjectAssignmentPageComponent />
      {!isLoadingProjectAssignments && <AssignmentCreateEdit />}
      <SendEmailDialog projectsPage={true} />
      <Button
        className="create-assignment-button"
        hasIconOnly
        renderIcon={Add16}
        id="createAssignmentButton"
        onClick={toggleAssignmentCreateEdit}
        iconDescription={t('createEditAssignment.iconDescription')}
      />
    </>
  );
};

export default ProjectAssignmentPage;
