import { authorizationConfig } from '../../../config/config';
import { Button, Link, Loading } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  authorizationPopup,
  useAuthorization,
} from '../../custom-hooks/useAuthorization';
import './login.scss';
import { useEffect, useState } from 'react';

type Status = {
  status: string;
  previousLocation: string;
};
export function Login() {
  const { checkAuthorization } = useAuthorization();
  let { previousLocation } = useParams<Status>();
  if (previousLocation) previousLocation = '../' + previousLocation;
  const { t } = useTranslation('common');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);

  useEffect(() => {

    const interval = setInterval(() => {
      checkAuthorization();
      const token = localStorage.getItem(authorizationConfig.tokenName);
      if (token) {
        clearInterval(interval);
        setIsLoading(false);
        window.location.href = '/';
      }
    }, 2000);
    return () => {
      setIsLoading(false);
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="login">
      <h1 className="page-title">{t('appNameFull')}</h1>
      
      <Button
        className="login-button"
        kind="secondary"
        disabled={isLoading}
        onClick={() => {
          authorizationPopup('/');
          setIsLoading(true);
        }}
      >
        {isLoading ? (
          <div>
            <span className="loading-indicator">
              <Loading
                description="Active loading indicator"
                withOverlay={false}
                small
              />
            </span>
            <span>{t('loginPage.loginInProgress')}</span>
          </div>
        ) : (
          <>{t('loginPage.loginW3')}</>
        )}
      </Button>
      <div className="privacy-notice">
        {t('loginPage.privacyPolicyStatement')}{' '}
        <Link className="privacy-link" href="privacyPolicy" target="policy" rel="noopener noreferrer">
          {t('loginPage.ibmPrivacyStatement')}
        </Link>
      </div>
    </div>
  );
}

export default Login;
