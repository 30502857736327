import { HeadersInterface } from '@cic-boardlite/common';

export const headers: HeadersInterface[] = [
  {
    key: 'projectId',
    header: '',
  },
  {
    key: 'clientName',
    header: '',
  },
  {
    key: 'pmo',
    header: '',
  },
  {
    key: 'projectAccountIds',
    header: '',
  },
  {
    key: 'projectType',
    header: '',
  },
  {
    key: 'startDate',
    header: '',
  },
  {
    key: 'endDate',
    header: '',
  },
  {
    key: 'projectStatus',
    header: '',
  },
  {
    key: 'actionRequired',
    header: '',
  },
  {
    key: 'serviceLine',
    header: '',
  }
];