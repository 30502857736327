import { FilterTabsState, FilterTabData, PageType } from '@cic-boardlite/common';
import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import {
  getFilterTabsData,
  setIsCreatingFilterTabData,
  setCurrentFilterTabData,
  setFilterTabData,
  setDeleteFilterTabData,
  setTabUnsavedContextData,
} from '../data-access/filter-tabs/slice';
import { Action } from '@reduxjs/toolkit';
import { practitionerDefaultTab, projectDefaultTab } from '../data-access/filter-tabs/default-tab-data';

interface UseFilterResponse extends FilterTabsState {
  setCurrentFilterTab: (currentFilterData: FilterTabData) => Action;
  setIsCreatingFilterTab: (data: { practitionerTab: boolean; projectTab: boolean }) => Action;
  getFilterTabs: (pageType: number) => void;
  setNewFilterTab: (filtersData: FilterTabData, isEdit: boolean, isSilentUpdate?: boolean) => Action;
  setDeleteFilterTab: (data: { id: FilterTabData['id']; pageType: number }) => Action;
  setTabUnsavedContext: (
    id: FilterTabData['id'],
    unsavedContext: FilterTabData['unsavedContext'],
    pageType: number
  ) => Action;
  createTabForNewUser: (page: number, dataLength: number) => void;
}

const useFilterTabs = (): UseFilterResponse => {
  const dispatch = useAppDispatch();

  const isCreatingFilterTab = useAppSelector((state) => state.filterTabs.isCreatingFilterTab);
  const practitionerFilterTabsData = useAppSelector((state) => state.filterTabs.practitionerFilterTabsData);
  const projectFilterTabsData = useAppSelector((state) => state.filterTabs.projectFilterTabsData);
  const isLoadingFilterTabs: boolean = useAppSelector((state) => state.filterTabs.isLoadingFilterTabs);
  const isSavingFilterTab: boolean = useAppSelector((state) => state.filterTabs.isSavingFilterTab);
  const isDeletingFilterTab: boolean = useAppSelector((state) => state.filterTabs.isDeletingFilterTab);
  const activePractitionerFilterTab = useAppSelector((state) => state.filterTabs.activePractitionerFilterTab);
  const activeProjectFilterTab = useAppSelector((state) => state.filterTabs.activeProjectFilterTab);
  const createdPractitionerDefaultTab = useAppSelector((state) => state.filterTabs.isDefaultPractitionerTabCreated);
  const createdProjectDefaultTab = useAppSelector((state) => state.filterTabs.isDefaultProjectTabCreated);

  const getFilterTabs = (pageType: number) => {
    dispatch(getFilterTabsData(pageType));
  };
  const setCurrentFilterTab = (currentFilterData: FilterTabData) => {
    return dispatch(setCurrentFilterTabData(currentFilterData));
  };
  const setIsCreatingFilterTab = (data: { practitionerTab: boolean; projectTab: boolean }) => {
    return dispatch(setIsCreatingFilterTabData(data));
  };
  const setNewFilterTab = (filtersData: FilterTabData, isEdit: boolean, isSilentUpdate?: boolean) => {
    return dispatch(setFilterTabData({ ...filtersData, isEdit, isSilentUpdate }));
  };
  const setDeleteFilterTab = (data: { id: FilterTabData['id']; pageType: number }) => {
    return dispatch(setDeleteFilterTabData(data));
  };
  const setTabUnsavedContext = (
    id: FilterTabData['id'],
    unsavedContext: FilterTabData['unsavedContext'],
    pageType: number
  ) => {
    return dispatch(setTabUnsavedContextData({ id, unsavedContext, pageType }));
  };

  const createTabForNewUser = (page: number, dataLength: number) => {
    if(dataLength !== 1) return;

    if (page === PageType.Project && !createdProjectDefaultTab) {
      return setNewFilterTab(
        {
          ...projectDefaultTab,
        },
        false
      );
    }

    if(createdPractitionerDefaultTab) return;

    return setNewFilterTab(
      {
        ...practitionerDefaultTab,
      },
      false
    );
  };

  return {
    isLoadingFilterTabs,
    isSavingFilterTab,
    isDeletingFilterTab,
    practitionerFilterTabsData,
    projectFilterTabsData,
    activePractitionerFilterTab,
    activeProjectFilterTab,
    getFilterTabs,
    setCurrentFilterTab,
    isCreatingFilterTab,
    setIsCreatingFilterTab,
    setNewFilterTab,
    setDeleteFilterTab,
    setTabUnsavedContext,
    createTabForNewUser,
  };
};

export default useFilterTabs;
