import {
  calculatePractitionerAvailabilityDate,
  calculatePractitionerAvailabilityDateFailed,
  calculatePractitionerAvailabilityDateSuccess,
  countPractitionerAssignments,
  countPractitionerAssignmentsFailed,
  countPractitionerAssignmentsSuccess,
  createPractitioner,
  createPractitionerAssignment,
  createPractitionerFailed,
  createPractitionerSuccess,
  createUpdatePractitionerAssignmentFailed,
  createUpdatePractitionerAssignmentSuccess,
  deletePractitioner,
  deletePractitionerAssignment,
  deletePractitionerAssignmentFailed,
  deletePractitionerAssignmentFromProjectPage,
  deletePractitionerAssignmentSuccess,
  deletePractitionerFailed,
  deletePractitionerSuccess,
  getActionRequiredPractitionersNumber,
  getActionRequiredPractitionersNumberSuccess,
  getAuditLogsData,
  getAuditLogsFailed,
  getAuditLogsSuccess,
  getFilteredPractitioners,
  getFilteredPractitionersFailed,
  getFilteredPractitionersSuccess,
  getHiringPipelinePractitioners,
  getHiringPipelinePractitionersFailed,
  getHiringPipelinePractitionersSuccess,
  getPractitionerAssignments,
  getPractitionerAssignmentsFailed,
  getPractitionerAssignmentsSuccess,
  getPractitionerDetails,
  getPractitionerDetailsSuccess,
  getPractitionerDetailsFailed,
  getPractitionerNames,
  getPractitionerNamesFailed,
  getPractitionerNamesSuccess,
  getPractitioners,
  getPractitionersEmails,
  getPractitionersEmailsFailed,
  getPractitionersEmailsSuccess,
  getPractitionersFailed,
  getPractitionersSuccess,
  removeSelectedPractitioner,
  sendPractitionerAssignmentEmail,
  sendPractitionerAssignmentEmailFailed,
  sendPractitionerAssignmentEmailSuccess,
  singleFieldUpdateSuccess,
  updatePractitioner,
  updatePractitionerAssignment,
  updatePractitionerFailed,
  updatePractitionersCv,
  updatePractitionersCvFailed,
  updatePractitionersCvSuccess,
} from './slice';
import {
  call,
  CallEffect,
  delay,
  put as putEffect,
  PutEffect,
  select,
  SelectEffect,
  takeLatest,
} from 'redux-saga/effects';
import { deleteMethod, get, post, put } from '../state-configurations/base-api';
import {
  blueServicesConfig,
  dataModifyLogsConfig,
  domain,
  practitionerConfig,
  practitionersFilterConfig,
  sagaConfig,
} from '../../../config/config';
import {
  AssignmentReduced,
  AssignmentTargetField,
  AuditActionType,
  AuditEntityType,
  ChangedFieldsIndexes,
  compareOldAndUpdatedObjects,
  CreateEditAssignmentInterface,
  CreateUpdateAssignmentResponse,
  CvDownload,
  DeleteAssignmentInterface,
  DeleteProjectAssignmentInterface,
  EmailContentResponse,
  GetPractitionerAssignments,
  GetPractitionerDetails,
  GetPractitionerSummary,
  HookPractitionerObject,
  MultiChangeAction,
  PractitionerAvailabilityCalculateResponseInterface,
  PractitionerName,
  PractitionersEmailsResponseInterface,
  SendEmailContentInterface,
  UpdatedPractitionerSummary,
  PractitionerStaticDataReloadOn,
} from '@cic-boardlite/common';
import i18next from 'i18next';
import common_en from '../../../assets/locales/en/translation.json';
import { AnyAction } from '@reduxjs/toolkit';
import { addNotification } from '../notifications/slice';
import { getProjectAssignments } from '../project/slice';
import { omit } from 'lodash';
import { LogsAndLogsCountInterface } from '../../interfaces/audit-interfaces';
import { triggerMultiChange } from '../multi-change/slice';
import { PractitionerInterface } from '../../interfaces/practitionerInterfaces';
import { setAuditLogData } from '../audit-logs/slice';
import { getStaticData } from '../static-data/slice';
import { getPractitionersMaintainerEmails } from '../feedback/slice';

export function* handleGetPractitioners(): Generator {
  const { allPractitionerAPI } = sagaConfig;

  try {
    const path = allPractitionerAPI;
    const responseAllPractitioners: any = yield call(get, path);
    const responseAllPractitionersKeyList = Object.keys(responseAllPractitioners) as Array<
      keyof typeof responseAllPractitioners
    >;
    responseAllPractitionersKeyList.map((key) => {
      return responseAllPractitioners[key].map((keyPractitioners: PractitionerInterface) => {
        return (keyPractitioners.id = keyPractitioners.id.toString());
      });
    });
    yield putEffect(getPractitionersSuccess(responseAllPractitioners));
  } catch (error) {
    console.log(error);
    yield putEffect(getPractitionersFailed());
  }
}

interface ActionInterface {
  type: string;
  payload: string;
}

export function* handleGetFilteredPractitioners(
  action: ActionInterface
): Generator<CallEffect<GetPractitionerSummary[]> | PutEffect<AnyAction>, void, GetPractitionerSummary[]> {
  try {
    const responseFilteredPractitioners = yield call(() => get<GetPractitionerSummary[]>(action.payload));
    yield putEffect(getFilteredPractitionersSuccess(responseFilteredPractitioners));
  } catch (error) {
    console.log('Practitioner saga error: ', error);
    yield putEffect(getFilteredPractitionersFailed());
  }
}

export function* handleGetActionRequiredPractitionersNumber(): Generator<
  CallEffect<number> | PutEffect<AnyAction>,
  void,
  number
> {
  const { actionRequiredPractitionersNumber } = practitionerConfig;
  try {
    const path = actionRequiredPractitionersNumber;
    const responsePractitionersCount = yield call(() => get<number>(path));
    yield putEffect(getActionRequiredPractitionersNumberSuccess(responsePractitionersCount));
  } catch (error) {
    console.log(error);
    yield putEffect(getPractitionersFailed());
  }
}

export function* handleUpdatePractitionersCvStatus(action: {
  type: string;
  payload: CvDownload;
}): Generator<CallEffect<UpdatedPractitionerSummary[]> | PutEffect<AnyAction>, void, UpdatedPractitionerSummary[]> {
  const { cvService } = blueServicesConfig;

  try {
    const path = cvService;

    if (action.payload.emailFrom.length === 0) {
      yield putEffect(
        addNotification({
          kind: 'error',
          title: i18next.t(common_en.cvDownloadModal.noEmailFrom),
        })
      );
      return;
    }

    const responseUpdatedPractitioners = yield call(() => post<UpdatedPractitionerSummary[]>(path, action.payload));

    yield putEffect(updatePractitionersCvSuccess(responseUpdatedPractitioners));
    yield putEffect(
      addNotification({
        kind: 'success',
        title: i18next.t(common_en.cvDownloadModal.cvEmailSent),
      })
    );
  } catch (error) {
    console.log(error);
    yield putEffect(
      addNotification({
        kind: 'error',
        title: i18next.t(common_en.cvDownloadModal.downloadError),
      })
    );
    yield putEffect(updatePractitionersCvFailed());
  }
}

export function* handleGetPractitionerDetails(action: {
  type: string;
  payload: string;
}): Generator<CallEffect<GetPractitionerDetails> | PutEffect<AnyAction>, void, GetPractitionerDetails> {
  try {
    const { singlePractitionerDetails } = practitionerConfig;
    const path = `${singlePractitionerDetails}${action.payload}`;

    const responsePractitionerDetails = yield call(() => get<GetPractitionerDetails>(path));

    if (responsePractitionerDetails) {
      yield putEffect(getPractitionerDetailsSuccess(responsePractitionerDetails));
    }
  } catch (error) {
    console.log(error);
    yield putEffect(getPractitionerDetailsFailed());
  }
}

export function* handleSendPractitionerAssignmentEmail(action: {
  type: string;
  payload: SendEmailContentInterface;
}): Generator<CallEffect<boolean> | PutEffect<AnyAction>, void, boolean> {
  try {
    const { sendProjectAssignmentEmail } = blueServicesConfig;
    const path = sendProjectAssignmentEmail;
    const emailData = action?.payload;
    const responseSendEmail = yield call(() => post<boolean>(path, emailData));

    if (!action.payload.extensionRequestEmailSent && responseSendEmail) {
      const oldAssignment = {
        id: Number(action.payload.assignmentId),
        [String(action.payload.contentType)]: action.payload.lastEmailSent?.length
          ? action.payload.lastEmailSent
          : null,
      };

      const updatedAssignmentData = {
        id: Number(action.payload.assignmentId),
        [String(action.payload.contentType)]: new Date(),
        projectsPage: action.payload.projectsPage,
      };

      Object.assign(
        updatedAssignmentData,
        action.payload.projectsPage
          ? { project: action.payload.projectId }
          : { practitioner: action.payload.practitionerId }
      );

      yield putEffect({
        type: updatePractitionerAssignment.type,
        payload: {
          oldData: oldAssignment,
          newData: updatedAssignmentData,
          notShowSuccessNotification: true,
        },
      });
    } else if (action.payload.extensionRequestEmailSent && responseSendEmail) {
      const assignmentIds = action.payload.extensionRequestEmailSent.map((assignmentInfo) => assignmentInfo.id);

      const oldAndNewDataArray = action.payload.extensionRequestEmailSent.map((assignmentInfo) => ({
        oldData: {
          id: Number(assignmentInfo.id),
          [AssignmentTargetField.extensionRequestEmailSent]: assignmentInfo.extensionRequestEmailSent,
        },
        newData: {
          id: Number(assignmentInfo.id),
          [AssignmentTargetField.extensionRequestEmailSent]: new Date(),
        },
      }));

      yield putEffect({
        type: triggerMultiChange.type,
        payload: {
          data: {
            ids: assignmentIds,
            multiChangeAction: MultiChangeAction.assignment,
            targetField: AssignmentTargetField.extensionRequestEmailSent,
            targetFieldValue: new Date(),
          },
          modificationLogData: oldAndNewDataArray,
          notShowSuccessNotification: true,
        },
      });
    }

    yield putEffect(
      addNotification({
        kind: 'success',
        title: i18next.t(common_en.sendPractitionerAssignmentEmail.sendSuccess),
      })
    );

    const contentType =
      action.payload?.contentType &&
      (
        action.payload?.contentType.charAt(0).toUpperCase() +
        action.payload?.contentType.slice(1).replace('EmailSent', '')
      )
        .match(/[A-Z][a-z]+/g)
        ?.join(' ');

    yield putEffect(
      setAuditLogData({
        actionType: AuditActionType.Email,
        entityType: emailData.projectsPage ? AuditEntityType.Project : AuditEntityType.Practitioner,
        practitionerId: emailData?.practitionerId,
        projectId: emailData?.projectId,
        afterReadable: {
          emailTo: emailData.emailTo,
          emailCc: emailData.emailCc,
          emailType: contentType || i18next.t(common_en.sendEmailModal.templates.extensionRequest),
        },
      })
    );

    yield putEffect(sendPractitionerAssignmentEmailSuccess(responseSendEmail));
  } catch (error: any) {
    console.error(error);
    yield putEffect(sendPractitionerAssignmentEmailFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.sendPractitionerAssignmentEmail.sendError),
      })
    );
  }
}

export function* handlePractitionerAssignmentDelete(action: {
  type: string;
  payload: DeleteAssignmentInterface;
}): Generator<CallEffect<boolean> | PutEffect<AnyAction>, void, boolean> {
  try {
    const { deletePractitionerAssignment } = practitionerConfig;
    const path = deletePractitionerAssignment;
    const responseDelete = yield call(() => deleteMethod<boolean>(path, { id: Number(action?.payload.id) }));

    if (responseDelete) {
      yield putEffect(deletePractitionerAssignmentSuccess(responseDelete));
      yield delay(500);

      yield putEffect(
        addNotification({
          kind: 'success',
          title: i18next.t(common_en.deleteAssignment.deleteSuccess),
        })
      );

      yield putEffect({
        type: getPractitionerAssignments.type,
        payload: { id: action.payload.practitionerId },
      });

      yield putEffect({
        type: calculatePractitionerAvailabilityDate.type,
        payload: {
          recalculateOnAssignmentUpdate: true,
          practitionerId: Number(action.payload.practitionerId),
        },
      });
    }
  } catch (error) {
    console.log(error);
    yield putEffect(
      addNotification({
        kind: 'error',
        title: i18next.t(common_en.deleteAssignment.deleteError),
      })
    );
    yield putEffect(deletePractitionerAssignmentFailed());
  }
}

export function* handlePractitionerAssignmentDeleteFromProjectPage(action: {
  type: string;
  payload: DeleteProjectAssignmentInterface;
}): Generator<CallEffect<boolean> | PutEffect<AnyAction>, void, string> {
  try {
    const { deletePractitionerAssignment } = practitionerConfig;
    const path = deletePractitionerAssignment;
    const responseDelete = yield call(() => deleteMethod<boolean>(path, { id: action?.payload.id }));

    if (responseDelete) {
      yield putEffect(deletePractitionerAssignmentSuccess(responseDelete));
      yield delay(500);

      yield putEffect(
        addNotification({
          kind: 'success',
          title: i18next.t(common_en.deleteAssignment.deleteSuccess),
        })
      );

      yield putEffect({
        type: getProjectAssignments.type,
        payload: Number(action.payload.projectId),
      });

      if (action.payload.practitionerId) {
        yield putEffect({
          type: calculatePractitionerAvailabilityDate.type,
          payload: {
            recalculateOnAssignmentUpdate: true,
            practitionerId: action.payload.practitionerId,
          },
        });
      }
    }
  } catch (error) {
    console.log(error);
    yield putEffect(
      addNotification({
        kind: 'error',
        title: i18next.t(common_en.deleteAssignment.deleteError),
      })
    );
    yield putEffect(deletePractitionerAssignmentFailed());
  }
}

export function* handleGetPractitionerAssignments(action: {
  type: string;
  payload: { id: string; reducedAssignments?: boolean };
}): Generator<
  CallEffect<GetPractitionerAssignments[] | AssignmentReduced[]> | PutEffect<AnyAction> | SelectEffect,
  void,
  GetPractitionerAssignments[] | AssignmentReduced[]
> {
  const showOnboardedOnly = yield select((state) => state.commonStates.showOnlyOnboardedAssignments);
  try {
    const { getPractitionerAssignments, getPractitionerAssignmentsReduced } = practitionerConfig;
    let path = `${action.payload.reducedAssignments ? getPractitionerAssignmentsReduced : getPractitionerAssignments}${
      action.payload.id
    }`;

    if (showOnboardedOnly) {
      path += `&onboardedOnly=true`;
    }

    const responsePractitionerAssignments = yield call(() =>
      get<GetPractitionerAssignments[] | AssignmentReduced[]>(path)
    );

    yield putEffect(getPractitionerAssignmentsSuccess(responsePractitionerAssignments));
  } catch (error) {
    console.log(error);
    yield putEffect(getPractitionerAssignmentsFailed());
  }
}

export function* handleGetAuditLogs(action: {
  type: string;
  payload: {
    id: string;
    page: number;
    itemsPerPage: number;
    filterString?: string;
  };
}): Generator<CallEffect<LogsAndLogsCountInterface> | PutEffect<AnyAction>, void, LogsAndLogsCountInterface> {
  try {
    const { getLogs } = dataModifyLogsConfig;
    let path = `${getLogs}?practitionerId=${action.payload.id}&page=${action.payload.page}&itemsPerPage=${action.payload.itemsPerPage}`;
    if (action.payload?.filterString?.length) path += `&filterString=${action.payload?.filterString}`;

    yield delay(300);

    const responseLogs = yield call(() => get<LogsAndLogsCountInterface>(path));

    yield putEffect(getAuditLogsSuccess(responseLogs));
  } catch (error) {
    console.log(error);
    yield putEffect(getAuditLogsFailed());
  }
}

export function* handleCreatePractitioner(action: {
  type: string;
  payload: HookPractitionerObject;
}): Generator<CallEffect<HookPractitionerObject> | PutEffect, void, boolean | EmailContentResponse> {
  const { createPractitioner } = practitionerConfig;
  const path = createPractitioner;
  const { sendEmail } = blueServicesConfig;
  const decodedUrl = decodeURIComponent(window.location.href);

  try {
    const responseCreatePractitioner = yield call(() => post<HookPractitionerObject>(path, action?.payload));

    if (responseCreatePractitioner) {
      const practitionersFilter = decodedUrl.split('practitioners?')[1];

      yield putEffect({
        type: getFilteredPractitioners.type,
        payload: !practitionersFilter
          ? practitionersFilterConfig.getPractitionersFilter
          : `${domain}/practitioners/filter?${practitionersFilter}`,
      });

      yield putEffect(
        addNotification({
          kind: 'success',
          title: i18next.t(common_en.createPractitioner.practitionerCreationSuccess),
        })
      );
      yield putEffect({ type: getPractitionersEmails.type });
      yield putEffect({ type: getPractitionerNames.type });

      yield putEffect(createPractitionerSuccess());

      if (typeof responseCreatePractitioner !== 'boolean') {
        yield call(() => post<EmailContentResponse>(sendEmail, responseCreatePractitioner));

        yield putEffect(
          setAuditLogData({
            actionType: AuditActionType.Email,
            entityType: AuditEntityType.Practitioner,
            practitionerId: responseCreatePractitioner.id,
            afterReadable: {
              emailTo: responseCreatePractitioner.emailContent.emailTo,
              emailCc: responseCreatePractitioner.emailContent.emailCc,
              emailType: i18next.t(common_en.extraEmailTemplates.practitionerCreation),
            },
          })
        );
      }

      yield putEffect({ type: getStaticData.type });
    }
  } catch (error: any) {
    console.log('Practitioner saga error: ', error);
    yield putEffect(createPractitionerFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.createPractitioner.practitionerCreationError),
      })
    );
  }
}

export function* handleUpdatePractitioner(action: {
  type: string;
  payload: {
    oldData: HookPractitionerObject;
    newData: HookPractitionerObject;
    updatingFromProfile: boolean;
    updatingFromPractitionersTable: boolean;
  };
}): Generator {
  const { updatePractitioner } = practitionerConfig;
  const path = updatePractitioner;

  try {
    const keysToIncludeInReturnObject: (keyof HookPractitionerObject)[] = ['id', 'serial'];

    if (
      action.payload.oldData.serviceLine !== action.payload.newData.serviceLine &&
      action.payload.oldData.isServiceLineLeader &&
      action.payload.newData.isServiceLineLeader
    )
      keysToIncludeInReturnObject.push('isServiceLineLeader');

    const changedFields = compareOldAndUpdatedObjects<HookPractitionerObject>(
      action.payload.oldData,
      action.payload.newData,
      keysToIncludeInReturnObject
    );

    const practitionerEditResponse = yield call(() =>
      put(path, {
        oldData: changedFields[ChangedFieldsIndexes.OldData],
        newData: changedFields[ChangedFieldsIndexes.NewData],
      })
    );

    if (practitionerEditResponse) {
      if (action.payload.updatingFromProfile) {
        yield putEffect({
          type: getPractitionerDetails.type,
          payload: action.payload.newData['id'],
        });
      } else if (action.payload.updatingFromPractitionersTable) {
        const decodedUrl = decodeURIComponent(window.location.href);
        const practitionersFilter = decodedUrl.split('practitioners?')[1];

        yield putEffect({
          type: getFilteredPractitioners.type,
          payload: `${domain}/practitioners/filter?${practitionersFilter}`,
        });
        yield putEffect(singleFieldUpdateSuccess(action.payload.newData));
      }

      yield putEffect(
        addNotification({
          kind: 'success',
          title: i18next.t(common_en.createPractitioner.practitionersUpdated),
        })
      );

      const checkIfStaticDataShouldReload = Object.values(PractitionerStaticDataReloadOn).some((value) => {
        return Object.keys(changedFields[ChangedFieldsIndexes.NewData]).includes(value);
      });

      if (checkIfStaticDataShouldReload) yield putEffect({ type: getStaticData.type });
      yield putEffect({ type: getPractitionersEmails.type });
      yield putEffect({ type: getPractitionerNames.type });
      yield putEffect({ type: getPractitionersMaintainerEmails.type });
    }
  } catch (error: any) {
    console.log('Practitioner saga error: ', error);
    yield putEffect(updatePractitionerFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.createPractitioner.practitionersUpdateError),
      })
    );
  }
}

export function* handleGetPractitionerNames(action: {
  type: string;
}): Generator<CallEffect<PractitionerName[]> | PutEffect<AnyAction>, void, PractitionerName[]> {
  try {
    const { practitionersNames } = practitionerConfig;

    const responsePractitionersNames = yield call(() => get<PractitionerName[]>(practitionersNames));

    if (responsePractitionersNames) {
      yield putEffect(getPractitionerNamesSuccess(responsePractitionersNames));
    }
  } catch (error) {
    console.log('Practitioner saga error: ', error);
    yield putEffect(getPractitionerNamesFailed());
  }
}

export function* handleCreatePractitionerAssignment(action: {
  type: string;
  payload: CreateEditAssignmentInterface;
}): Generator<CallEffect<CreateUpdateAssignmentResponse> | PutEffect<AnyAction>, void, CreateUpdateAssignmentResponse> {
  const { createPractitionerAssignment } = practitionerConfig;
  const { projectsPage, ...assignmentData } = action.payload;
  const path = createPractitionerAssignment;
  const { sendEmail } = blueServicesConfig;

  try {
    const createAssignmentResponse = yield call(() => post<CreateUpdateAssignmentResponse>(path, assignmentData));

    if (createAssignmentResponse) {
      if (projectsPage) {
        yield putEffect({
          type: getProjectAssignments.type,
          payload: assignmentData.project,
        });
      } else {
        yield putEffect({
          type: getPractitionerAssignments.type,
          payload: { id: assignmentData.practitioner },
        });
      }

      yield putEffect({
        type: calculatePractitionerAvailabilityDate.type,
        payload: {
          recalculateOnAssignmentUpdate: true,
          practitionerId: assignmentData.practitioner,
        },
      });

      yield putEffect(
        addNotification({
          kind: 'success',
          title: i18next.t(common_en.createEditAssignment.assignmentCreationSuccess),
        })
      );
      yield putEffect(createUpdatePractitionerAssignmentSuccess());

      if (createAssignmentResponse.assignmentId && assignmentData.notifyWmps) {
        try {
          yield call(() =>
            post<CreateUpdateAssignmentResponse>(sendEmail, omit(createAssignmentResponse, 'assignmentId'))
          );

          yield putEffect(
            addNotification({
              kind: 'success',
              title: i18next.t(common_en.createEditAssignment.wmpEmailSentSuccess),
            })
          );

          yield putEffect(
            setAuditLogData({
              actionType: AuditActionType.Email,
              entityType: AuditEntityType.Assignment,
              practitionerId: assignmentData.practitioner,
              projectId: assignmentData.project,
              assignmentId: createAssignmentResponse.assignmentId,
              afterReadable: {
                emailTo: createAssignmentResponse.emailContent?.emailTo,
                emailCc: createAssignmentResponse.emailContent?.emailCc,
                emailType: i18next.t(common_en.extraEmailTemplates.assignmentCreation),
              },
            })
          );
        } catch (e) {
          yield putEffect(
            addNotification({
              kind: 'error',
              title: i18next.t(common_en.createEditAssignment.wmpEmailSentFailed),
            })
          );
        }
      }
    }
  } catch (error: any) {
    console.log('Practitioner saga error: ', error);
    yield putEffect(createUpdatePractitionerAssignmentFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.createEditAssignment.assignmentCreationError),
      })
    );
  }
}

export function* handleUpdatePractitionerAssignment(action: {
  type: string;
  payload: {
    oldData: CreateEditAssignmentInterface;
    newData: CreateEditAssignmentInterface;
    notShowSuccessNotification?: boolean;
  };
}): Generator<CallEffect<CreateUpdateAssignmentResponse> | PutEffect<AnyAction>, void, CreateUpdateAssignmentResponse> {
  const { updatePractitionerAssignment } = practitionerConfig;
  const path = updatePractitionerAssignment;
  const { sendEmail } = blueServicesConfig;
  const { projectsPage, ...assignmentData } = action.payload.newData;

  const changedFields = compareOldAndUpdatedObjects<CreateEditAssignmentInterface>(
    action.payload.oldData,
    action.payload.newData,
    ['id', 'practitioner', 'project', 'notifyWmps', 'ccEmail', 'emailTo']
  );

  try {
    const updateAssignmentResponse = yield call(() =>
      put<CreateUpdateAssignmentResponse>(path, {
        oldData: changedFields[ChangedFieldsIndexes.OldData],
        newData: {
          ...changedFields[ChangedFieldsIndexes.NewData],
          projectsPage: undefined,
        },
      })
    );

    if (updateAssignmentResponse.assignmentId) {
      if (projectsPage) {
        yield putEffect({
          type: getProjectAssignments.type,
          payload: assignmentData.project,
        });
      } else {
        yield putEffect({
          type: getPractitionerAssignments.type,
          payload: { id: assignmentData.practitioner },
        });
      }

      if (
        !!changedFields[ChangedFieldsIndexes.NewData]['assignmentStatus'] ||
        !!changedFields[ChangedFieldsIndexes.NewData]['endDate'] ||
        changedFields[ChangedFieldsIndexes.NewData]['isMainAssignment'] !== undefined
      )
        yield putEffect({
          type: calculatePractitionerAvailabilityDate.type,
          payload: {
            recalculateOnAssignmentUpdate: true,
            practitionerId: assignmentData.practitioner,
          },
        });

      if (!action.payload?.notShowSuccessNotification)
        yield putEffect(
          addNotification({
            kind: 'success',
            title: i18next.t(common_en.createEditAssignment.assignmentUpdated),
          })
        );

      yield putEffect(createUpdatePractitionerAssignmentSuccess());
    }
    if (updateAssignmentResponse.assignmentId && assignmentData.notifyWmps) {
      try {
        yield call(() =>
          post<CreateUpdateAssignmentResponse>(sendEmail, omit(updateAssignmentResponse, 'assignmentId'))
        );

        yield putEffect(
          addNotification({
            kind: 'success',
            title: i18next.t(common_en.createEditAssignment.wmpEmailSentSuccess),
          })
        );

        yield putEffect(
          setAuditLogData({
            actionType: AuditActionType.Email,
            entityType: AuditEntityType.Assignment,
            practitionerId: assignmentData.practitioner,
            projectId: assignmentData.project,
            assignmentId: updateAssignmentResponse.assignmentId,
            afterReadable: {
              emailTo: updateAssignmentResponse.emailContent?.emailTo,
              emailCc: updateAssignmentResponse.emailContent?.emailCc,
              emailType: i18next.t(common_en.extraEmailTemplates.assignmentUpdate),
            },
          })
        );
      } catch (e) {
        yield putEffect(
          addNotification({
            kind: 'error',
            title: i18next.t(common_en.createEditAssignment.wmpEmailSentFailed),
          })
        );
      }
    }
  } catch (error: any) {
    console.log('Practitioner saga error: ', error);
    yield putEffect(createUpdatePractitionerAssignmentFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.createEditAssignment.assignmentUpdateError),
      })
    );
  }
}

export function* handleGetHiringPipelinePractitioners(): Generator {
  const { hiringPipelinePractitioners } = practitionerConfig;

  try {
    const responseHiringPipelinePractitioners = yield call(get, hiringPipelinePractitioners);
    yield putEffect(getHiringPipelinePractitionersSuccess(responseHiringPipelinePractitioners));
  } catch (error) {
    console.log(error);
    yield putEffect(getHiringPipelinePractitionersFailed());
  }
}

export function* handleGetPractitionersEmails(action: {
  type: string;
}): Generator<
  CallEffect<PractitionersEmailsResponseInterface> | PutEffect<AnyAction>,
  void,
  PractitionersEmailsResponseInterface
> {
  const { practitionersEmails } = practitionerConfig;

  try {
    const response = yield call(() => get<PractitionersEmailsResponseInterface>(practitionersEmails));

    if (response) yield putEffect(getPractitionersEmailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield putEffect(getPractitionersEmailsFailed());
  }
}

export function* handleDeletePractitioner(action: {
  type: string;
  payload: string;
}): Generator<CallEffect<string> | PutEffect<AnyAction>, void, string> {
  const { deletePractitioner } = practitionerConfig;
  const path = deletePractitioner;

  const decodedUrl = decodeURIComponent(window.location.href);
  const practitionersFilter = decodedUrl.split('practitioners?')[1];

  try {
    const responseDelete = yield call(() => deleteMethod<string>(`${path}/${action.payload}`));

    if (responseDelete) {
      yield putEffect({
        type: getFilteredPractitioners.type,
        payload: `${domain}/practitioners/filter?${practitionersFilter}`,
      });

      yield putEffect(
        addNotification({
          kind: 'success',
          title: i18next.t(common_en.deletePractitioner.practitionerDeleteSuccess),
        })
      );

      yield putEffect(removeSelectedPractitioner(action.payload.toString()));
      yield putEffect({ type: getPractitionerNames.type });
      yield putEffect({ type: getPractitionersEmails.type });
      yield putEffect(deletePractitionerSuccess());
    }
  } catch (error: any) {
    yield putEffect(deletePractitionerFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.deletePractitioner.practitionerDeleteError),
      })
    );
  }
}

export function* handleCalculatePractitionerAvailabilityDate(action: {
  type: string;
  payload: {
    recalculateOnAssignmentUpdate: boolean;
    practitionerId: number;
  };
}): Generator<
  CallEffect<PractitionerAvailabilityCalculateResponseInterface> | PutEffect<AnyAction>,
  void,
  PractitionerAvailabilityCalculateResponseInterface
> {
  const path = `${domain}/practitioner-service/recalculate-availability-date`;
  try {
    const responseCalculateAvailability = yield call(() =>
      post<PractitionerAvailabilityCalculateResponseInterface>(path, action?.payload)
    );

    if (responseCalculateAvailability) {
      if (!responseCalculateAvailability.availabilityUpdateNeeded) {
        yield putEffect(
          addNotification({
            kind: 'success',
            title: i18next.t(common_en.availabilityDateUpdate.upToDate),
          })
        );
      }
      yield putEffect(calculatePractitionerAvailabilityDateSuccess(responseCalculateAvailability));
    }
  } catch (error: any) {
    console.log('Practitioner calculate availability saga error: ', error);
    yield putEffect(calculatePractitionerAvailabilityDateFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: i18next.t(common_en.availabilityDateUpdate.failedToCalculate),
      })
    );
  }
}

export function* handleGetPractitionerAssignmentCount(action: {
  type: string;
  payload: number;
}): Generator<CallEffect<number> | PutEffect<AnyAction>, void, number> {
  const { getPractitionerAssignmentsCount } = practitionerConfig;
  const path = `${getPractitionerAssignmentsCount}${action.payload}`;
  try {
    const responseAssignmentCount = yield call(() => get<number>(path));

    if (typeof responseAssignmentCount === 'number') {
      yield putEffect(countPractitionerAssignmentsSuccess(responseAssignmentCount));
    }
  } catch (error: any) {
    console.log('Practitioner assignment count saga error: ', error);
    yield putEffect(countPractitionerAssignmentsFailed());
  }
}

export function* practitionerSaga() {
  yield takeLatest(getPractitioners.type, handleGetPractitioners);
  yield takeLatest(getFilteredPractitioners.type, handleGetFilteredPractitioners);
  yield takeLatest(getActionRequiredPractitionersNumber.type, handleGetActionRequiredPractitionersNumber);
  yield takeLatest(updatePractitionersCv.type, handleUpdatePractitionersCvStatus);
  yield takeLatest(getPractitionerDetails.type, handleGetPractitionerDetails);
  yield takeLatest(createPractitioner.type, handleCreatePractitioner);
  yield takeLatest(updatePractitioner.type, handleUpdatePractitioner);
  yield takeLatest(getPractitionerAssignments.type, handleGetPractitionerAssignments);
  yield takeLatest(createPractitionerAssignment.type, handleCreatePractitionerAssignment);
  yield takeLatest(updatePractitionerAssignment.type, handleUpdatePractitionerAssignment);
  yield takeLatest(deletePractitionerAssignment.type, handlePractitionerAssignmentDelete);
  yield takeLatest(sendPractitionerAssignmentEmail.type, handleSendPractitionerAssignmentEmail);
  yield takeLatest(getHiringPipelinePractitioners.type, handleGetHiringPipelinePractitioners);
  yield takeLatest(deletePractitionerAssignmentFromProjectPage.type, handlePractitionerAssignmentDeleteFromProjectPage);
  yield takeLatest(getPractitionerNames.type, handleGetPractitionerNames);
  yield takeLatest(getPractitionersEmails.type, handleGetPractitionersEmails);
  yield takeLatest(deletePractitioner.type, handleDeletePractitioner);
  yield takeLatest(calculatePractitionerAvailabilityDate.type, handleCalculatePractitionerAvailabilityDate);
  yield takeLatest(getAuditLogsData.type, handleGetAuditLogs);
  yield takeLatest(countPractitionerAssignments.type, handleGetPractitionerAssignmentCount);
}
