import React from 'react';

type Props = { children: React.ReactNode };

const ErrorBox: React.FC<Props> = ({ children }) => {
  return (
    <span className="delete-modal-error-message">
      <span>{children}</span>
    </span>
  );
};

export default ErrorBox;
