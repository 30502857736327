import { domain, exportFileConfig } from '../../../config/config';
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  getExportPractitionersSummary,
  getExportPractitionersSummarySuccess,
  getExportPractitionersSummaryFailed,
  getExportProjectsSummary,
  getExportProjectsSummarySuccess,
  getExportProjectsSummaryFailed,
  getExportPractitionersAssignmentsSummary,
  getExportPractitionersAssignmentsSummarySuccess,
  getExportPractitionersAssignmentsSummaryFailed,
  getExportProjectsAssignmentsSummary,
  getExportProjectsAssignmentsSummarySuccess,
  getExportProjectsAssignmentsSummaryFailed,
  getExportFile,
  getExportFileFailed,
  getExportFileSuccess,
  getImportFile,
  getImportFileFailed,
  getImportFileSuccess,
} from './slice';
import { get, post } from '../state-configurations/base-api';
import { AnyAction } from '@reduxjs/toolkit';
import { addNotification } from '../notifications/slice';
import i18next from 'i18next';
import common_en from '../../../assets/locales/en/translation.json';
import { DEFAULT_NOTIFICATION_DISPLAY_TIME } from '../../ui/constants/constants';
import { getStaticData } from '../static-data/slice';
import {
  ExportDataResponseInterface,
  ImportDataPayloadInterface,
  PractitionerAssignmentExportSummary,
  PractitionerExportSummary,
  ProjectAssignmentExportSummary,
  ProjectExportSummary,
} from '@cic-boardlite/common';

export function* handleExportFile(action: {
  type: string;
  payload: string;
}): Generator<CallEffect<ExportDataResponseInterface> | PutEffect<AnyAction>, void, ExportDataResponseInterface> {
  try {
    const path = `${domain}/${action.payload}/export`;
    const responseExportFile = yield call(() => get<ExportDataResponseInterface>(path));
    yield put(getExportFileSuccess(responseExportFile));
  } catch (error: any) {
    console.log('Export file saga error: ', error);
    yield put(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.fileImportExport.exportFailed),
        timeOut: DEFAULT_NOTIFICATION_DISPLAY_TIME,
      })
    );
    yield put(getExportFileFailed());
  }
}

export function* handleImportFile(action: { type: string; payload: ImportDataPayloadInterface }): Generator {
  try {
    const path = `${domain}/${action.payload.fileType}/import`;
    const importingArray = action.payload.fileObjects;
    const importingArrayObject = { importingArray };
    yield call(post, path, importingArrayObject);
    yield put(
      addNotification({
        kind: 'success',
        title: i18next.t(common_en.fileImportExport.importSuccess),
        timeOut: DEFAULT_NOTIFICATION_DISPLAY_TIME,
      })
    );
    yield put(getImportFileSuccess());
    yield put({
      type: getStaticData.type,
    });
  } catch (error: any) {
    console.log('Import file saga error: ', error);
    yield put(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.fileImportExport.importFailed),
        timeOut: DEFAULT_NOTIFICATION_DISPLAY_TIME,
      })
    );
    yield put(getImportFileFailed());
  }
}

export function* handleExportPractitionersSummary(action: {
  type: string;
  payload: string[];
}): Generator<CallEffect<PractitionerExportSummary[]> | PutEffect<AnyAction>, void, PractitionerExportSummary[]> {
  try {
    const path = exportFileConfig.exportPractitioners;

    const practitionersSummaryResponse = yield call(() => post<PractitionerExportSummary[]>(path, action.payload));

    yield put(getExportPractitionersSummarySuccess(practitionersSummaryResponse));
  } catch (error: any) {
    console.log('Export practitioners summary saga error: ', error);
    yield put(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.fileImportExport.practitionersExportFailed),
      })
    );
    yield put(getExportPractitionersSummaryFailed());
  }
}

export function* handleExportPractitionersAssignmentsSummary(action: {
  type: string;
  payload: string[];
}): Generator<
  CallEffect<PractitionerAssignmentExportSummary[]> | PutEffect<AnyAction>,
  void,
  PractitionerAssignmentExportSummary[]
> {
  try {
    const path = exportFileConfig.exportPractitionersAssignments;

    const responsePractitionersAssignmentsSummary = yield call(() =>
      post<PractitionerAssignmentExportSummary[]>(path, action.payload)
    );

    yield put(getExportPractitionersAssignmentsSummarySuccess(responsePractitionersAssignmentsSummary));
  } catch (error: any) {
    console.log('Export practitioners assignments saga error: ', error);
    addNotification({
      kind: 'error',
      title: error?.error ? error.error : i18next.t(common_en.fileImportExport.practitionersAssignmentsExportFailed),
    });
    yield put(getExportPractitionersAssignmentsSummaryFailed());
  }
}

export function* handleExportProjectsSummary(action: {
  type: string;
  payload: string[];
}): Generator<CallEffect<ProjectExportSummary[]> | PutEffect<AnyAction>, void, ProjectExportSummary[]> {
  try {
    const path = exportFileConfig.exportProjects;

    const projectSummaryResponse = yield call(() => post<ProjectExportSummary[]>(path, action.payload));

    yield put(getExportProjectsSummarySuccess(projectSummaryResponse));
  } catch (error: any) {
    console.log('Export projects saga error: ', error);
    addNotification({
      kind: 'error',
      title: error?.error ? error.error : i18next.t(common_en.fileImportExport.projectsExportFailed),
    });
    yield put(getExportProjectsSummaryFailed());
  }
}

export function* handleExportProjectsAssignmentsSummary(action: {
  type: string;
  payload: { projectIds: string[]; assignmentIds: string[] };
}): Generator<
  CallEffect<ProjectAssignmentExportSummary[]> | PutEffect<AnyAction>,
  void,
  ProjectAssignmentExportSummary[]
> {
  try {
    const path = exportFileConfig.exportProjectsAssignments;

    const responseProjectsAssignments = yield call(() => post<ProjectAssignmentExportSummary[]>(path, action.payload));

    yield put(getExportProjectsAssignmentsSummarySuccess(responseProjectsAssignments));
  } catch (error: any) {
    console.log('Export projects assignments saga error: ', error);
    addNotification({
      kind: 'error',
      title: error?.error ? error.error : i18next.t(common_en.fileImportExport.projectsAssignmentsExportFailed),
    });
    yield put(getExportProjectsAssignmentsSummaryFailed());
  }
}

export function* fileImportExportSaga() {
  yield takeLatest(getExportFile.type, handleExportFile);
  yield takeLatest(getImportFile.type, handleImportFile);
  yield takeLatest(getExportPractitionersSummary.type, handleExportPractitionersSummary);
  yield takeLatest(getExportProjectsSummary.type, handleExportProjectsSummary);
  yield takeLatest(getExportPractitionersAssignmentsSummary.type, handleExportPractitionersAssignmentsSummary);
  yield takeLatest(getExportProjectsAssignmentsSummary.type, handleExportProjectsAssignmentsSummary);
}
