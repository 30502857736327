import { Dispatch, FocusEventHandler, ReactElement, SetStateAction } from 'react';
import { FindOperator } from 'typeorm';
import { DenormalizedRow } from 'carbon-components-react';
import { CvServicePractitionersInterface, FormatType } from './practitioners';
import { EmailContent } from './email';
import { AssignmentSummaryInterface, ProjectAssignmentSummaryInterface } from './assignment';
import { ProjectSummaryInterfaceCreateEditAssignment } from './projects';
import { TalentID_LENGTH } from './assets/constants/constants';

export interface PractitionerSummary {
  id: number;
  nameFirstLast: string;
  band: string;
  availabilityDate: FindOperator<string> | string | Date;
  availabilityInfo: string;
  serviceLine: string;
}

export interface PractitionerSummaryBatch {
  futureBench: GetPractitionerPayload[];
  bench: GetPractitionerPayload[];
  hiringPipeline: GetPractitionerPayload[];
}

export interface GetPractitionerPayload {
  serviceLine: string | FindOperator<string>;
  nameFirstLast: string;
  availabilityDate: string | FindOperator<string>;
  jrs: string;
  id: number;
  band: string;
  availabilityInfo: string;
  isCic?: boolean;
  emfStatus?: string | FindOperator<string>;
  costRate?: number;
  actionRequiredStatus: 'Low' | 'Medium' | 'High';
}

export interface PractitionerName {
  id: number;
  name: string;
}

export interface GetPractitionerSummary {
  row?: string;
  id: string;
  serial: string;
  nameFirstLast?: string;
  band: string;
  availabilityDate: string;
  availabilityInfo: string;
  serviceLine: string;
  jrsId?: number;
  jrs?: string;
  hiringDate?: string;
  actionRequiredStatus: string;
  peopleManager: string;
  emfStatusId?: number;
  emfStatus: string;
  department: string;
  terminated: boolean;
  isCic: boolean;
  deletion?: boolean;
  terminationDate?: string;
  notes?: string;
  country?: string;
  countryCode?: number;
  psAdder?: string;
  contractType?: string;
  serviceArea?: string;
  costRate?: number;
  peopleManagerPractitionerId?: number;
  assignments?: {
    id: number;
    projectId: string;
    assignmentStatus: {
      id: number;
      title: string;
    };
    endDate: number;
    allocation: string;
    mainAssignment: boolean;
  }[];
  fileName?: string;
  availabilityDateManuallyEdited: boolean;
}

export interface SelectablePractitioner extends GetPractitionerSummary, DenormalizedRow {
  row: string;
  isSelectable?: boolean;
}

export interface UpdatedPractitionerSummary {
  serial: string;
  filename: string;
  foundCV: boolean;
}

export interface PractitionerInfoForCvDownlaod {
  serial: string;
  nameFirstLast?: string;
  band?: string;
  jrs?: string;
  availabilityDate?: string;
  costRate: string | number;
  psAdder: string;
}

export interface CvDownload {
  practitioners: PractitionerInfoForCvDownlaod[];
  idToken: string;
  format: string;
  emailFrom: string;
  emailTo: string;
  emailCc: string[];
}
export interface PropertiesToFindRates {
  countryId?: number;
  bandId?: number;
  psAdderId?: number;
}

export interface GetPractitionerBySerialWithRates {
  propertiesToFindRates: PropertiesToFindRates;
  practitionerByIdData: GetPractitionerDetails;
}

export interface GetPractitionerAssignments {
  dataRemovalEmailSent: string;
  extensionRequestEmailSent: string;
  assignmentExtensionEmailSent: string;
  assignmentDetailsEmailSent: string;
  offboardingEmailSent: string;
  onboardingEmailSent: string;
  id: number;
  project: {
    id: number;
    projectId: number;
    accountIds: string;
    name: string;
    clientName: string;
    geoPm: string;
    country: string;
    pmo: string;
    geoPmTalentId: string | null;
  };
  practitioner: {
    firstName: string;
    lastName: string;
    serial: string;
  };
  startDate: Date;
  endDate: Date;
  pmpSeat: string;
  allocation: string;
  uwh: boolean;
  uwhEndDate: Date;
  uwhStartDate: Date;
  mainAssignment: boolean;
  status: {
    id: number;
    title: string;
  };
}

export interface GetPractitionerDetails {
  id: string;
  serial: string;
  jrs?: string;
  jrsId?: number;
  nameFirstLast?: string;
  practitionerTag?: string;
  firstName: string;
  lastName: string;
  availabilityDate?: string;
  hiringDate?: string;
  assignmentEndDate?: string;
  availabilityInfo?: string;
  serviceLine?: string;
  serviceLineId?: number;
  band?: string;
  bandId?: number;
  peopleManager?: string;
  peopleManagerPractitionerId?: number /**/;
  emfStatus?: string;
  emfStatusId?: number;
  department?: string;
  departmentId?: number;
  terminated?: boolean;
  terminationDate?: string;
  isPMO?: boolean;
  isPeM?: boolean;
  isCic: boolean;
  country?: string;
  countryId?: number;
  psAdder?: string;
  contractType?: string;
  contractTypeId?: number;
  email?: string;
  serviceArea?: string;
  role?: { id: number; title: string; description: string }[];
  practitionerPlan?: {
    promotionBand: string;
    promotionDate: Date;
    comment: string;
  };
  countryCode?: number;
  costRate?: number;
  assignments?: ProjectInterface[];
  availabilityDateManuallyEdited?: boolean;
  isServiceLineLeader?: boolean;
}

export interface PractitionerServiceData {
  id: number;
  isCic: boolean;
  firstName: string;
  lastName: string;
  practitionerTag: string;
  country: number;
  serial: string;
  band?: number;
  department?: number;
  hiringDate?: Date;
  serviceLine?: number;
  peopleManagerPractitionerId?: number;
  emfStatus?: number;
  contractType?: number;
  jrs?: number;
  availabilityDate?: Date;
  availabilityInfo?: string;
  isPeM?: boolean;
  isPMO?: boolean;
  terminationDate?: Date;
  userRole?: PractitionerUserRoleInterface[];
  sendEmail: boolean;
  ccEmail?: string[];
  countryTitle?: string;
  bandTitle?: string;
  departmentTitle?: string;
  serviceLineTitle?: string;
  emfStatusTitle?: string;
  contractTypeTitle?: string;
  jrsTitle?: string;
}

export interface PractitionerUserRoleInterface {
  id: number;
  title?: string;
  description?: string;
}

export interface ProjectInterface {
  id: number;
  startDate: Date;
  endDate: Date;
  allocation: string;
  isMainAssignment: boolean;
  projectId: number;
  status?: number;
}

export interface GetProjectSummary {
  projectId: string;
  id: string;
  name: string;
  clientName: string;
  created?: string;
  startDate: string;
  endDate: string;
  country: string;
  projectAccountIds: string;
  projectType: string;
  projectStatus: string;
  serviceLine: string;
  createdBy?: string;
  comment: string;
  geoPm: string;
  pmo: string;
}

export interface PractitionerQueryByRole {
  serviceLine?: FindOperator<null | string> | string;
  peopleManager?: PractitionerId;
}
export interface PractitionerId {
  practitionerId: string;
}

export const DEFAULT_ROWS_PER_PAGE = 100;
export const ROWS_PER_PAGE_SELECTIONS = [5, 20, 50, 100, 150, 200];

export const profilePicturePath = '../assets/images/profile.jpg';

export function getPractitionerPictureLink(serial: string, countryCode?: number): string {
  const unifiedProfileImageSearchUrl = 'https://w3-unifiedprofile-api.dal1a.cirrus.ibm.com/v3/image/';
  if (serial.length === TalentID_LENGTH.SHORT && countryCode)
    return unifiedProfileImageSearchUrl + serial + countryCode + '?def=avatar';

  return unifiedProfileImageSearchUrl + serial + '?def=avatar';
}

export interface DeleteModalProps {
  deleteConfirmationField?: string;
  deleteObject: {
    id: string;
    practitionerId?: number;
    projectId?: string;
  };
  deletableObjectType: DeletableObjectType;
}

export enum DeleteModalType {
  project = 'project',
  practitioner = 'practitioner',
  assignment = 'assignment',
}

export interface assignmentCreateEditModalProps {
  assignmentInfo?: AssignmentSummaryInterface;
  projectDetails?: ProjectAssignmentSummaryInterface | ProjectSummaryInterfaceCreateEditAssignment;
  projectPage?: boolean;
  editingAssignment?: boolean;
  isOpen: boolean;
}

export enum DeletableObjectType {
  practitioner = 'practitioner',
  practitionerAssignment = 'practitionerAssignment',
  project = 'project',
  projectAssignment = 'projectAssignment',
  none = '',
}

export enum MultiChangeAction {
  practitioner = 'practitioner',
  assignment = 'assignment',
  project = 'project',
}

export enum PractitionerTargetField {
  jrs = 'jrs',
  availabilityDate = 'availabilityDate',
  emfStatus = 'emfStatus',
}

export enum ProjectTargetField {
  pmo = 'pmo',
  projectStatus = 'projectStatus',
  endDate = 'endDate',
}

export enum AssignmentTargetField {
  assignmentEndDate = 'endDate',
  isMainAssignment = 'isMainAssignment',
  assignmentStatus = 'assignmentStatus',
  allocation = 'allocation',
  assignmentExtensionEmailSent = 'assignmentExtensionEmailSent',
  extensionRequestEmailSent = 'extensionRequestEmailSent',
}

export interface MultiChangeInterface {
  data: MultiChangeData;
  modificationLogData: {
    oldData: { [p: string]: any };
    newData: { [p: string]: any };
  }[];
  notShowSuccessNotification?: boolean;
}

export interface MultiChangeData {
  ids: string[];
  targetField: string;
  targetFieldValue: number | Date | boolean | string;
  multiChangeAction: MultiChangeAction;
  emailTo?: string;
  emailFrom?: string;
  ccEmail?: string[];
  namesForMultiChange?: string[];
  sendEmail?: boolean;
  projectId?: string;
}

export interface MultiChangeResponse {
  availabilityManuallyEntered?: number;
  emailData: {
    subject: string;
    emailContent: EmailContent;
    emailTemplate: string;
  };
  handEnteredAvailability: number;
}

export interface DeleteModalInputInterface {
  delete: string;
}

export interface OpenCreatePractitionerModal {
  openCreatePractitionerModal: boolean;
  setOpenCreatePractitionerModal: Dispatch<SetStateAction<boolean>>;
}

export interface StaticDataInterface {
  staticData: StaticDataObjectInterface;
}

export interface StaticDataObjectInterface {
  bandStaticData: BandStaticDataInterface[];
  contractTypeStaticData: ContractTypeStaticDataInterface[];
  departmentStaticData: DepartmentStaticDataInterface[];
  emfStatusStaticData: EmfStatusStaticDataInterface[];
  jrsStaticData: JrsStaticDataInterface[];
  pemInfoStaticData: PeopleManagerStaticDataInterface[];
  serviceLineStaticData: ServiceLineStaticDataInterface[];
  roleStaticData: RoleStaticDataInterface[];
  countryStaticData: CountryStaticDataInterface[];
  rateStaticData: RateStaticDataInterface[];
  departmentServiceLineMappingStaticData: DepartmentServiceLineMappingStaticDataInterface[];
  [key: string]:
    | BandStaticDataInterface[]
    | CountryStaticDataInterface[]
    | RateStaticDataInterface[]
    | DepartmentStaticDataInterface[]
    | EmfStatusStaticDataInterface[]
    | JrsStaticDataInterface[]
    | PeopleManagerStaticDataInterface[]
    | ServiceLineStaticDataInterface[]
    | RoleStaticDataInterface[]
    | ContractTypeStaticDataInterface[]
    | DepartmentServiceLineMappingStaticDataInterface[];
}

export interface BandStaticDataInterface {
  title: string;
  id: number;
}
export interface ContractTypeStaticDataInterface {
  title: string;
  id: number;
}
export interface DepartmentStaticDataInterface {
  title: string;
  id: number;
}
export interface EmfStatusStaticDataInterface {
  title: string;
  id: number;
}
export interface JrsStaticDataInterface {
  title: string;
  serviceArea: string;
  id: number;
  psAdder: {
    id: number;
    title: string;
  };
}
export interface ServiceLineStaticDataInterface {
  title: string;
  id: number;
  leader: {
    id: number;
    firstName: string;
    lastName: string;
  } | null;
}
export interface RoleStaticDataInterface {
  title: string;
  id: number;
  description: string;
}
export interface PeopleManagerStaticDataInterface {
  firstName: string;
  lastName: string;
  id: number;
  serial: string;
}

export interface CountryStaticDataInterface {
  label: string;
  code: string;
  id: number;
}

export interface RateStaticDataInterface {
  rate: string;
  psAdder: { title: string };
  band: { title: string };
  country: { label: string };
  id?: number;
}

export interface DepartmentServiceLineMappingStaticDataInterface {
  id: number;
  title: string;
  department: DepartmentStaticDataInterface;
  serviceLine: ServiceLineStaticDataInterface;
}

export interface AttachmentsInterface {
  filename: string;
  content: Uint8Array;
  contentType: string;
}

export interface FeedbackAttachmentsInterface {
  filename: string;
  content: string;
  contentType: string;
}

export interface PractitionersWithCvInterface {
  serial: string;
  filename: string;
  foundCV: boolean;
}

export interface CvBuilderDataInterface {
  practitioners: CvServicePractitionersInterface[];
  format: FormatType;
}
export interface getFilesInterface {
  attachments: AttachmentsInterface[];
  practitioners: PractitionersWithCvInterface[];
  html: string;
}
export interface EditedCvInterface {
  serial: string;
  firstName: string;
  firstLetter: string;
  jrs: string;
  band: string;
  availabilityDate: string;
  contentType: string;
  filename: string;
  file?: Uint8Array;
  errorMessage?: string;
  costRate?: string;
  psAdder?: string;
}

export interface GetProjectPayload {
  id: string;
  name: string;
  clientName: string;
  projectAccountIds: string;
  startDate: string;
  endDate: string;
  comment: string;
  created: string;
  country: string;
  projectType: string;
  projectStatus: string;
  serviceLine: string;
  createdBy: string;
  actionRequired: string;
}

export enum ProjectReduceType {
  'assignment' = 'assignment',
  'log' = 'log',
}

export interface SingleProjectResponse {
  id?: number;
  projectId: string;
  name: string;
  projectTag?: string;
  clientName?: string;
  clientTag?: string;
  startDate?: string;
  endDate?: string;
  pmo?: string;
  geoPm?: string;
  geoPmTalentId?: number;
  comment?: string;
  projectAccountIds?: string;
  country?: string;
  countryId?: number;
  projectType?: string;
  projectTypeId?: number;
  projectStatus?: string;
  projectStatusId?: number;
  serviceLine?: string;
  serviceLineId?: number;
  createdBy?: string;
  createdById?: number;
}

export interface UpdateJrsInterface {
  title: string;
  serviceArea: string;
  psAdder: number;
}
export interface BulkInsertTitlesInterface {
  title: string;
}
export interface ImportRateFromFileInterface {
  rate: number;
  psAdder: number;
  band: number;
  countryCode: number;
}

export interface ProjectCreateProps {
  closeModal: () => void;
  trackFormChanges?: (fieldsEdited: boolean) => void;
}

export enum DownloadDataPages {
  Practitioners = 'practitioners',
  PractitionerAssignments = 'practitioner-assignments',
  Projects = 'projects',
  ProjectAssignments = 'project-assignments',
}

export enum ChangedFieldsIndexes {
  OldData = 0,
  NewData = 1,
}

export enum PractitionerStaticDataReloadOn {
  IsPMO = 'isPMO',
  IsPeM = 'isPeM',
  IsServiceLineLeader = 'isServiceLineLeader'
}

export interface BubbleIconInterface {
  assignmentEnd: string;
}

export interface AssignmentEndDateInterface {
  value: string;
  id: string;
  assignmentEndDateToolTip: string | null;
  setAssignmentEndDateToolTip: (id: string | null) => void;
}

export const UNIX_TIMESTAMP_IN_MILLISECONDS_LENGTH = 13;

export interface CustomFilterComboBoxProps<T> {
  customClassName: string;
  onChange: (onChangeData: { selectedItem: T }) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  selectedItem: T;
  items: T[];
  itemKeyName: 'label' | 'title' | 'name' | 'projectId' | 'clientName' | 'clientTag' | 'talentId';
  titleText?: ReactElement;
  placeholder: string;
  displaySelectedValueBolded?: boolean;
  allowInputValue?: boolean;
  loadPractitionerSuggestionsW3?: boolean;
  warn?: boolean;
  warnText?: ReactElement;
}

export interface CustomMultiSelectWithSelectAllProps<T> {
  onChange: ({ selectedItems }: { selectedItems?: T[] }) => void;
  items: readonly T[];
  hideLabel?: boolean;
  itemToString?: (item: T) => string;
  selectedItems?: T[];
  id: string;
  label?: string;
  selectAllItem: T;
}

export enum PageType {
  Practitioner = 1,
  Project = 2,
}

export enum LocalStorageName {
  PractitionerFilterTab = 'activePractitionerFilterTabPosition',
  ProjectFilterTab = 'activeProjectFilterTabPosition',
}

export interface GetBccEmailInterface {
  isBccChecked: boolean;
  emails: string[];
  userEmail: string;
}

export const toUTCDate = (inputDate: string|number|Date): Date => {
  let dateValue = inputDate;
  if (typeof dateValue === 'string' || typeof dateValue === 'number') {
    dateValue = new Date(dateValue);
  }
  return new Date(dateValue.getUTCFullYear(), dateValue.getUTCMonth(), dateValue.getUTCDate(), 0, 0, 0);
}

export const toFixedValue = (input: string|number|undefined, emptyValue = '', fixedPlaces = 2, suffix = ' €/h'): string => {
  let value = input;
  if (typeof value === 'string') {
    value = Number(value);
  }

  if (value !== undefined && value != null && !isNaN(value)) {
    return Number(value).toFixed(fixedPlaces) + suffix;
  } else {
    return emptyValue;
  }
}
