import { authorizationConfig } from '../../../config/config';
import { HttpStatus } from '@cic-boardlite/common';

export class ResponseError extends Error {
  constructor(response: Response) {
    super(response.statusText);
  }
}

export const popupParams = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=800,height=700,left=200px%,top=200px`;

function parseJSON(response: Response) {
  if (
    response.status === HttpStatus.NO_CONTENT ||
    response.status === HttpStatus.RESET_CONTENT
  ) {
    return null;
  }
  return response.json();
}

async function checkStatus(response: Response | any) {
  if (
    response.status === HttpStatus.FORBIDDEN ||
    response.status === HttpStatus.UNAUTHORIZED
  ) {
    if (localStorage.getItem(authorizationConfig.tokenName)) {
      localStorage.removeItem(authorizationConfig.tokenName);
      window.location.reload();
    }
  }

  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === HttpStatus.NOT_FOUND) {
    window.location.href = '/error/' + response.status;
  }
  throw await parseJSON(response);
}

export async function request(url: string, options: object) {
  const fetchResponse = await fetch(url, options);
  const response = await checkStatus(fetchResponse);
  return parseJSON(response);
}
