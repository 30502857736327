import { createSlice } from '@reduxjs/toolkit';
import { FeedbackStateInterface } from '../../interfaces/feedback-interfaces';

export const initialState: FeedbackStateInterface = {
  practitionersMaintainerEmails: [],
  isPractitionerMaintainerEmailsLoading: false,
  isPractitionerMaintainerEmailSet: false,
  isSendingFeedbackToMaintainers: false,
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    getPractitionersMaintainerEmails(state) {
      state.isPractitionerMaintainerEmailsLoading = true;
      state.practitionersMaintainerEmails = [];
    },
    getPractitionersMaintainerEmailsSuccess(state, action) {
      state.practitionersMaintainerEmails = action.payload;
      state.isPractitionerMaintainerEmailsLoading = false;
      state.isPractitionerMaintainerEmailSet = true;
    },
    getPractitionersMaintainerEmailsFailed(state) {
      state.isPractitionerMaintainerEmailsLoading = false;
      state.isPractitionerMaintainerEmailSet = false;
    },
    sendFeedbackToMaintainerEmail(state, action) {
      state.isSendingFeedbackToMaintainers = true;
    },
    sendFeedbackToMaintainerEmailSuccess(state, action) {
      state.isSendingFeedbackToMaintainers = false;
    },
    sendFeedbackToMaintainerEmailFailed(state) {
      state.isSendingFeedbackToMaintainers = false;
    },
  },
});

export const {
  getPractitionersMaintainerEmails,
  getPractitionersMaintainerEmailsSuccess,
  getPractitionersMaintainerEmailsFailed,
  sendFeedbackToMaintainerEmail,
  sendFeedbackToMaintainerEmailSuccess,
  sendFeedbackToMaintainerEmailFailed,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;
