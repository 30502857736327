export const calculatePractitionerRate = (
  ratesStaticData?: {
    rate: string;
    psAdder: { title: string };
    band: { title: string };
    country: { label: string };
  }[],
  country?: string,
  band?: string,
  psAdder?: string
) => {
  function findRate(standard: boolean) {
    const rate = ratesStaticData?.find(
      (rateData) =>
        rateData?.country?.label.toLowerCase() === country?.toLowerCase() &&
        rateData?.band?.title?.toLowerCase() === (standard ? '6g' : band?.toLowerCase()) &&
        rateData?.psAdder?.title?.toLowerCase() === (standard ? 'standard' : psAdder?.toLowerCase())
    )?.rate;

    return rate ? '€' + rate : '';
  }

  if (band === '6G' || band === '6G LH' || band === 'TN') return findRate(true);
  else return findRate(false);
};

export const practitionerPSA = ({
  jrsStaticData,
  jrsId,
  band,
}: {
  jrsStaticData?: {
    id: number;
    title: string;
    serviceArea: string;
    psAdder: { id: number; title: string };
  }[];
  jrsId?: number;
  band?: string;
}) => {
  if (band === '6G' || band === '6G LH' || band === 'TN') return 'Standard';
  const practitionerJrs = jrsStaticData?.find((jrs) => jrs?.id === jrsId);
  return practitionerJrs ? practitionerJrs?.psAdder?.title : '';
};
