import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import { MultiChangeInterface } from '@cic-boardlite/common';
import { triggerMultiChange } from '../data-access/multi-change/slice';

const useMultiChange = () => {
  const dispatch = useAppDispatch();

  const isLoadingMultiChange = useAppSelector((state) => state.multiChange.isLoadingMultiChange);

  const multiChange = (multiChangeData: MultiChangeInterface) => {
    return dispatch(
      triggerMultiChange({
        data: multiChangeData.data,
        modificationLogData: multiChangeData.modificationLogData,
      })
    );
  };

  return {
    isLoadingMultiChange,
    multiChange,
  };
};

export default useMultiChange;
