import { createSlice } from '@reduxjs/toolkit';
import { ProjectsStateInterface } from '../../interfaces/project-interfaces';

export const initialState: ProjectsStateInterface = {
  filteredProjectData: [],
  isLoadingFilteredProjects: false,
  isCreatingProject: false,
  wasProjectCreated: false,
  projectsClientData: [],
  isLoadingProjectsClientData: false,
  isLoadingProjectAssignments: false,
  projectAssignmentsData: [],
  filterProjectAssignmentsString: '',
  projectsIdNameData: [],
  isLoadingProjectsIdNameData: false,
  isDeletingProject: false,
  selectedAssignmentsForExtension: [],
  assignmentExtensionModalIsOpen: false,
  selectedProjectsArray: [],
  actionRequiredProjectsNumber: 0,
  isLoadingAuditLogs: false,
  geoPmNamesTalentIds: [],
  isLoadingGeoPmNamesTalentIds: false,
  auditLogsData: {
    auditLogs: [],
    numberOfAuditLogs: 0,
  },

  isLoadingProjectData: false,
  projectData: {
    id: '',
    projectId: '',
    name: '',
    projectTag: '',
    clientName: '',
    clientTag: '',
    startDate: '',
    endDate: '',
    pmo: '',
    geoPm: '',
    geoPmTalentId: '',
    comment: '',
    projectAccountIds: '',
    country: '',
    countryId: 0,
    projectType: '',
    projectTypeId: 0,
    projectStatus: '',
    projectStatusId: 0,
    serviceLine: '',
    serviceLineId: 0,
    createdBy: '',
    createdById: 0,
  },
  singleFieldUpdateData: {
    oldData: {
      id: '',
      comment: '',
      projectId: ''
    },
    newData: {
      id: '',
      comment: '',
      projectId: ''
    }
  },
  isUpdatingSingleField: false,
  isLoadingLastAssignmentEndDate: false,
  lastAssignmentEndDate: '',
  isLoadingLastAssignmentEndDateMulti: false,
  lastAssignmentEndDateMulti: []
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    getFilteredProjects(state, action) {
      state.isLoadingFilteredProjects = true;
      state.filteredProjectData = [];
    },
    getFilteredProjectsSuccess(state, action) {
      state.filteredProjectData = action.payload;
      state.isLoadingFilteredProjects = false;
    },
    getFilteredProjectsFailed(state) {
      state.isLoadingFilteredProjects = false;
    },
    loadProject(state, action) {
      state.isLoadingProjectData = true;
    },
    loadProjectSuccess(state, action) {
      state.isLoadingProjectData = false;
      state.projectData = action.payload;
    },
    loadProjectFailed(state) {
      state.isLoadingProjectData = false;
    },
    createProject(state, action) {
      state.isCreatingProject = true;
    },
    setDeleteProject(state, action) {
      state.isDeletingProject = true;
    },
    setDeleteProjectSuccess(state, action) {
      state.isDeletingProject = false;
    },
    setDeleteProjectFailed(state) {
      state.isDeletingProject = false;
    },
    createProjectFailed(state) {
      state.isCreatingProject = false;
      state.wasProjectCreated = false;
    },
    createProjectSuccess(state) {
      state.isCreatingProject = false;
      state.wasProjectCreated = true;
    },
    createProjectReset(state) {
      state.wasProjectCreated = false;
    },
    getUpdateProject(state, action) {
      state.isLoadingFilteredProjects = true;
      state.filteredProjectData = [];
    },
    getUpdateProjectFailed(state) {
      state.isLoadingFilteredProjects = false;
    },
    getProjectsClientData(state) {
      state.isLoadingProjectsClientData = true;
      state.projectsClientData = [];
    },
    getProjectsClientDataSuccess(state, action) {
      state.projectsClientData = action.payload;
      state.isLoadingProjectsClientData = false;
    },
    getProjectsClientDataFailed(state) {
      state.isLoadingProjectsClientData = false;
    },
    getProjectsIdNameData(state) {
      state.isLoadingProjectsIdNameData = true;
      state.projectsIdNameData = [];
    },
    getProjectsIdNameDataSuccess(state, action) {
      state.isLoadingProjectsIdNameData = false;
      state.projectsIdNameData = action.payload;
    },
    getProjectsIdNameDataFailed(state) {
      state.isLoadingProjectsIdNameData = false;
    },
    getProjectAssignments(state, action) {
      state.isLoadingProjectAssignments = true;
      state.projectAssignmentsData = [];
    },
    getProjectAssignmentsSuccess(state, action) {
      state.projectAssignmentsData = action.payload;
      state.isLoadingProjectAssignments = false;
    },
    getProjectAssignmentsFailed(state) {
      state.isLoadingProjectAssignments = false;
    },
    addAssignmentForExtension(state, action) {
      state.selectedAssignmentsForExtension.push(action.payload);
    },
    removeAssignmentFromExtension(state, action) {
      state.selectedAssignmentsForExtension = state.selectedAssignmentsForExtension.filter(
        (assignment) => assignment.id !== action.payload
      );
    },
    addAllAssignmentsForExtension(state, action) {
      state.selectedAssignmentsForExtension = action.payload;
    },
    removeAllAssignmentsFromExtension(state) {
      state.selectedAssignmentsForExtension = [];
    },
    openOrCloseExtensionModal(state) {
      state.assignmentExtensionModalIsOpen = !state.assignmentExtensionModalIsOpen;
    },
    addSelectedProjectToArray(state, action) {
      state.selectedProjectsArray.push(action.payload);
    },
    removeSelectedProjectFromArray(state, action) {
      const filteredProjectsIds = state.selectedProjectsArray.filter((project) => project.id !== action.payload);
      state.selectedProjectsArray = filteredProjectsIds;
    },
    clearSelectedProjectsArray(state) {
      state.selectedProjectsArray = [];
    },

    getAuditLogsData(state, action) {
      state.isLoadingAuditLogs = true;
    },
    getAuditLogsSuccess(state, action) {
      state.auditLogsData = action.payload;
      state.isLoadingAuditLogs = false;
    },
    getAuditLogsFailed(state) {
      state.isLoadingAuditLogs = false;
    },

    getGeoPmNamesTalentIds(state) {
      state.isLoadingGeoPmNamesTalentIds = true;
    },
    getGeoPmNamesTalentIdsSuccess(state, action) {
      state.geoPmNamesTalentIds = action.payload;
      state.isLoadingGeoPmNamesTalentIds = false;
    },
    getGeoPmNamesTalentIdsFailed(state) {
      state.isLoadingGeoPmNamesTalentIds = false;
    },
    setFilterProjectAssignmentsString(state, action) {
      state.filterProjectAssignmentsString = action.payload;
    },
    resetFilterProjectAssignmentsString(state) {
      state.filterProjectAssignmentsString = initialState.filterProjectAssignmentsString;
    },
    singleFieldUpdate(state, action) {
      state.isUpdatingSingleField = true;
      state.singleFieldUpdateData = action.payload;
    },
    singleFieldUpdateSuccess(state) {
      state.isUpdatingSingleField = false;
    },
    singleFieldUpdateFailed(state) {
      state.isUpdatingSingleField = false
    },
    getLastAssignmentEndDate(state, action) {
      state.isLoadingLastAssignmentEndDate = true
    },
    getLastAssignmentEndDateSuccess(state, action) {
      state.lastAssignmentEndDate = action.payload
      state.isLoadingLastAssignmentEndDate = false
    },
    getLastAssignmentEndDateFailed(state) {
      state.isLoadingLastAssignmentEndDate = false
    },
    getLastAssignmentEndDateMulti(state, action) {
      state.isLoadingLastAssignmentEndDateMulti = true
    },
    getLastAssignmentEndDateMultiSuccess(state, action) {
      state.lastAssignmentEndDateMulti = action.payload
      state.isLoadingLastAssignmentEndDateMulti = false
    },
    getLastAssignmentEndDateMultiFailed(state) {
      state.isLoadingLastAssignmentEndDateMulti = false
    }
  },
});

export const {
  getFilteredProjects,
  getFilteredProjectsSuccess,
  getFilteredProjectsFailed,
  loadProject,
  loadProjectSuccess,
  loadProjectFailed,
  createProject,
  createProjectFailed,
  createProjectSuccess,
  createProjectReset,
  setDeleteProject,
  setDeleteProjectFailed,
  setDeleteProjectSuccess,
  getUpdateProject,
  getUpdateProjectFailed,
  getProjectsClientData,
  getProjectsClientDataSuccess,
  getProjectsClientDataFailed,
  getProjectsIdNameData,
  getProjectsIdNameDataSuccess,
  getProjectsIdNameDataFailed,
  getProjectAssignments,
  getProjectAssignmentsSuccess,
  getProjectAssignmentsFailed,
  addAssignmentForExtension,
  removeAssignmentFromExtension,
  addAllAssignmentsForExtension,
  removeAllAssignmentsFromExtension,
  openOrCloseExtensionModal,
  addSelectedProjectToArray,
  removeSelectedProjectFromArray,
  clearSelectedProjectsArray,
  getGeoPmNamesTalentIds,
  getGeoPmNamesTalentIdsSuccess,
  getGeoPmNamesTalentIdsFailed,
  getAuditLogsData,
  getAuditLogsSuccess,
  getAuditLogsFailed,
  setFilterProjectAssignmentsString,
  resetFilterProjectAssignmentsString,
  singleFieldUpdate,
  singleFieldUpdateSuccess,
  singleFieldUpdateFailed,
  getLastAssignmentEndDate,
  getLastAssignmentEndDateSuccess,
  getLastAssignmentEndDateFailed,
  getLastAssignmentEndDateMulti,
  getLastAssignmentEndDateMultiSuccess,
  getLastAssignmentEndDateMultiFailed
} = projectsSlice.actions;
export default projectsSlice.reducer;
