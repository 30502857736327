import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  SideNav,
  SideNavItems,
  SideNavMenuItem,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import './page-sidebar.scss'

const PractitionerPageSidebar = () => {
  const { id } = useParams();
  const { t } = useTranslation('common');
  const nav = useNavigate();
  const location = useLocation();

  return (
    <SideNav className="practitioner-page-sidebar" aria-label="Side navigation">
      <SideNavItems>
        <SideNavMenuItem
          onClick={() => nav(`/practitioner/${id}`)}
          className="practitioner-page-button"
          isActive={location.pathname === `/practitioner/${id}`}
        >
          {t('practitionerSidebar.profile')}
        </SideNavMenuItem>
        <SideNavMenuItem
          onClick={() => nav(`/practitioner/${id}/assignments`)}
          className="practitioner-page-button"
          isActive={location.pathname === `/practitioner/${id}/assignments`}
        >
          {t('practitionerSidebar.assignments')}
        </SideNavMenuItem>
        <SideNavMenuItem
          onClick={() => nav(`/practitioner/${id}/logs`)}
          className="practitioner-page-button"
          isActive={location.pathname === `/practitioner/${id}/logs`}
        >
          {t('practitionerSidebar.logs')}
        </SideNavMenuItem>
      </SideNavItems>
    </SideNav>
  );
};
export default PractitionerPageSidebar;
