import { Button, Modal } from 'carbon-components-react';
import { CreateEditPractitionerForm } from './create-edit-form';
import { Add16 } from '@carbon/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './create-edit-style.scss';
import { useSecondaryModal, useStaticData } from '../../../custom-hooks';

const PractitionerCreateEdit = () => {
  const { t } = useTranslation('common');
  const [openCreatePractitionerModal, setOpenCreatePractitionerModal] = useState(false);
  const { staticData } = useStaticData();
  const [formReset, setFormReset] = useState<boolean>(false);
  const { trackFormChanges, formChanged, secondaryModal, closeSecondaryModal, openSecondaryModal } =
    useSecondaryModal();

  const closeModal = () => {
    if (formChanged) {
      return openSecondaryModal(true);
    }
    return setOpenCreatePractitionerModal(false);
  };

  const closeAllModals = () => {
    openSecondaryModal(false);
    setOpenCreatePractitionerModal(false);
    setFormReset(true);
  };

  const openModal = () => {
    setFormReset(false);
    setOpenCreatePractitionerModal(true);
  };

  return (
    <>
      <Button
        className="create-practitioner-button"
        hasIconOnly
        renderIcon={Add16}
        iconDescription={t(`createPractitioner.createIconDescription`)}
        onClick={openModal}
      />
      <Modal
        passiveModal
        open={openCreatePractitionerModal}
        size={'md'}
        onRequestClose={closeModal}
        className="practitioner-create-edit-modal"
      >
        <CreateEditPractitionerForm
          staticData={staticData.staticData}
          setModal={setOpenCreatePractitionerModal}
          modalOpenState={openCreatePractitionerModal}
          trackFormChanges={trackFormChanges}
          formReset={formReset}
        />
      </Modal>
      {secondaryModal && (
        <Modal
          open={secondaryModal}
          danger
          size="sm"
          modalHeading={t('formLeaveConfirmationModal.heading')}
          primaryButtonText={t('formLeaveConfirmationModal.yesButton')}
          secondaryButtonText={t('formLeaveConfirmationModal.noButton')}
          onRequestClose={closeSecondaryModal}
          onRequestSubmit={closeAllModals}
        >
          {t('formLeaveConfirmationModal.description')}
        </Modal>
      )}
    </>
  );
};

export default PractitionerCreateEdit;
