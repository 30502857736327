export enum Scope { // TODO: remove unnecessary scopes
  LIST_STATIC_DATA = 'list_static_data',
  UPDATE_STATIC_DATA = 'update_static_data',
  CREATE_PRACTITIONER = 'create_practitioner',
  UPDATE_PRACTITIONER = 'update_practitioner',
  MARK_FOR_DELETION_PRACTITIONER = 'mark_for_deletion_practitioner',
  UNMARK_FOR_DELETION_PRACTITIONER = 'unmark_for_deletion_practitioner',
  DELETE_PRACTITIONER = 'delete_practitioner',
  LIST_PRACTITIONERS = 'list_practitioners',
  GET_PRACTITIONER = 'get_practitioner',
  CREATE_PROJECT = 'create_project',
  UPDATE_PROJECT = 'update_project',
  DELETE_PROJECT = 'delete_project',
  LIST_PROJECTS = 'list_projects',
  GET_PROJECT = 'get_project',
  UPDATE_USER = 'update_user',
  LIST_USERS = 'list_users',
  GET_USER = 'get_user',
  UPDATE_ROLE = 'update_role',
  GET_SETTINGS = 'get_settings',
  UPDATE_SETTINGS = 'update_settings',
  GET_ASSIGNMENTS = 'get_assignments',
  UPDATE_ASSIGNMENT = 'update_assignment',
  CREATE_REDEPLOYMENT = 'create_redeployment',
  GET_REDEPLOYMENTS = 'get_redeployments',
  UPDATE_REDEPLOYMENT = 'update_redeployment',
  DELETE_DEMAND = 'delete_demand',
  SAVE_DEMAND = 'save_demand',
  GET_DEMAND = 'get_demand',
  LIST_DEMANDS = 'list_demands',
  GET_DEMAND_TO_PROJECT = 'get_demand_to_project',
  MERGE_DEMAND_TO_PROJECT = 'merge_demand_to_project',
  SEND_MAIL = 'send_mail',
  GET_LOGS = 'get_logs',
}
