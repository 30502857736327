import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import {
  setAssignmentCreateEditModalOpen,
  setDeleteModalOpen,
  setMultiselectModalOpen,
  setSendEmailModalOpen,
  setShowOnlyOnboardedAssignments,
} from '../data-access/common-states/slice';
import { assignmentCreateEditModalProps, DeleteModalProps, SendEmailDialogInterface } from '@cic-boardlite/common';

const useCommonState = () => {
  const dispatch = useAppDispatch();

  const isPractitionersSideBarVisible = useAppSelector((state) => state.commonStates.isPractitionersSideBarVisible);
  const isProjectsSideBarVisible = useAppSelector((state) => state.commonStates.isProjectsSideBarVisible);
  const isMainMenuVisible = useAppSelector((state) => state.commonStates.isMainMenuVisible);

  const multiChangeModalType = useAppSelector((state) => state.commonStates.multiChangeType);
  const toggleMultiChangeModal = (multiSelectType: string) => {
    dispatch(setMultiselectModalOpen(multiSelectType));
  };

  const deleteModalType = useAppSelector((state) => state.commonStates.deleteModalType);
  const toggleDeleteModal = (deleteModalType: DeleteModalProps) => {
    dispatch(setDeleteModalOpen(deleteModalType));
  };

  const assignmentCreateEditModalType = useAppSelector((state) => state.commonStates.assignmentCreateEditModalType);
  const toggleAssignmentCreateEditModal = (assignmentCreateEditModalType: assignmentCreateEditModalProps) => {
    dispatch(setAssignmentCreateEditModalOpen(assignmentCreateEditModalType));
  };

  const sendEmailModalType = useAppSelector((state) => state.commonStates.sendEmailModalType);
  const toggleSendEmailModal = (sendEmailModalType: SendEmailDialogInterface) => {
    dispatch(setSendEmailModalOpen(sendEmailModalType));
  };

  const showOnlyOnboardedAssignments = useAppSelector((state) => state.commonStates.showOnlyOnboardedAssignments);
  const toggleShowOnlyOnboardedAssignments = () => {
    dispatch(setShowOnlyOnboardedAssignments(!showOnlyOnboardedAssignments));
  };

  return {
    isPractitionersSideBarVisible,
    isProjectsSideBarVisible,
    isMainMenuVisible,
    toggleMultiChangeModal,
    multiChangeModalType,
    toggleDeleteModal,
    deleteModalType,
    toggleAssignmentCreateEditModal,
    assignmentCreateEditModalType,
    toggleSendEmailModal,
    sendEmailModalType,
    toggleShowOnlyOnboardedAssignments,
    showOnlyOnboardedAssignments,
  };
};

export default useCommonState;
