export interface BluePagesPractitionerInterface {
  callupName: string;
  co: string;
  dept_code: string;
  employeeType_code: string;
  employeeType_isManager: boolean;
  employeeType_title: string;
  hrOrganizationCode: string;
  mail: string[];
  manager: string;
  name_last: string;
  nameFull: string;
  notesEmail: string;
  org_title_bu: string;
  org_title: string;
  preferredIdentity: string;
  role: string;
  serial: string;
  telephone_mobile: string;
  uid: string;
  id: string;
  workLocation_codeK: string;
  workLocation_building: string;
  functionalManagerUid: string;
  inCountryManagerUid: string;
  practiceAlignment: string;
  preferredContactMethod: string;
  languages_lang: string[];
  preferredLanguage_lang: string;
  preferredSlackId: string;
  preferredSlackUsername: string;
  conferenceUrl: string;
  notesEmailWithDomain: string;
  nameDisplay: string;
  workLocation_campusID: string;
  address_business_zip: string;
  address_business_locality: string;
  address_business_state: string;
  address_business_country: string;
  address_business_address: string[];
  address_business_location: string;
  preferredLastName: string;
  score: number;
  resultLevel: number;
}

export const emptyBluePagesPractitioner = {
  callupName: '',
  co: '',
  dept_code: '',
  employeeType_code: '',
  employeeType_isManager: false,
  employeeType_title: '',
  hrOrganizationCode: '',
  mail: [''],
  manager: '',
  name_last: '',
  nameFull: '',
  notesEmail: '',
  org_title_bu: '',
  org_title: '',
  preferredIdentity: '',
  role: '',
  serial: '',
  telephone_mobile: '',
  uid: '',
  id: '',
  workLocation_codeK: '',
  workLocation_building: '',
  functionalManagerUid: '',
  inCountryManagerUid: '',
  practiceAlignment: '',
  preferredContactMethod: '',
  languages_lang: [''],
  preferredLanguage_lang: '',
  preferredSlackId: '',
  preferredSlackUsername: '',
  conferenceUrl: '',
  notesEmailWithDomain: '',
  nameDisplay: '',
  workLocation_campusID: '',
  address_business_zip: '',
  address_business_locality: '',
  address_business_state: '',
  address_business_country: '',
  address_business_address: [''],
  address_business_location: '',
  preferredLastName: '',
  score: 0,
  resultLevel: 0,
};

export interface GetBluePagesPractitionersInterface {
  searchConfigId: string;
  query: string;
  userId: string | null;
  matches: number;
  results: BluePagesPractitionerInterface[];
}

export interface QueryByCountry {
  query: string;
  limit?: number;
  offset?: number;
}

export interface HealthStatus {
  statusCode: number;
  statusText: string;
}

interface Address {
  business: {
    country: string;
    locality: string;
    state: string;
    zip: string;
    location: string;
    stateCo: string;
    address: string[];
  };
  preferred: {
    address1: string;
    locality: string;
    zip: string;
  };
}

interface Dept {
  title: string;
  code: string;
}

interface EmployeeType {
  isEmployee: boolean;
  isManager: boolean;
  title: string;
  code: string;
}

interface LegalEntity {
  groupCode: string;
  groupId: string;
  name: string;
  code: string;
}

interface Name {
  first: string;
  last: string;
}

interface Manager {
  nameDisplay: string;
  preferredIdentity: string;
  role: string;
  uid: string;
  name: Name;
}

interface OooSettings {
  message: boolean;
  date: boolean;
}

interface Org {
  group: string;
  title: string;
  unit: string;
  code: string;
}

interface Telephone {
  mobile: string;
}

interface WorkLocation {
  building: string;
  code: string;
  campusID: string;
}

export interface ProfileBluePagesPractitionerInterface {
  content: {
    address: Address;
    dept: Dept;
    employeeType: EmployeeType;
    legalEntity: LegalEntity;
    functionalManager: Manager;
    inCountryManager: Manager;
    name: Name;
    oooSettings: OooSettings;
    org: Org;
    telephone: Telephone;
    workLocation: WorkLocation;
    cn: string[];
    mail: string[];
    c: string;
    callupName: string;
    co: string;
    costCenter: string;
    courtesyTitle: string;
    createdDate: string;
    div: string;
    dn: string;
    employeeCountryCode: string;
    entryType: string;
    functionalManagerUid: string;
    glTeamLead: string;
    hrActive: string;
    hrOrganizationCode: string;
    imt: string;
    inCountryManagerUid: string;
    iot: string;
    manager: string;
    managerPSC: string;
    nameDisplay: string;
    nameFull: string;
    notesEmail: string;
    notesMailDomain: string;
    notesMailServer: string;
    notesShortName: string;
    preferredContactMethod: string;
    preferredIdentity: string;
    preferredLastName: string;
    preferredLanguage: string;
    preferredPhone: string;
    preferredTimeZone: string;
    role: string;
    segment: string;
    telephoneNumber: string;
    title: string;
    uid: string;
    workLocationBuilding: string;
    workLocationCampusID: string;
    workLocationCode: string;
  };
}
export interface SelectedBluePagesPractitionerInterface {
  profiles: ProfileBluePagesPractitionerInterface[];
  createdDate: Date;
  modifiedDate: Date;
  typeId: string;
  userId: string;
}
