import { CvDownload, SelectablePractitioner } from '@cic-boardlite/common';
import {
  clearAllSelectedPractitioners,
  removeSelectedPractitioner,
  openOrCloseCvModal,
  setSelectedPractitioner,
  updatePractitionersCv,
} from '../data-access/practitioner/slice';
import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';

const usePractitionerCv = () => {
  const dispatch = useAppDispatch();

  const pushPractitionerToTheList = (practitioner: SelectablePractitioner) => {
    dispatch(setSelectedPractitioner(practitioner));
  };
  const selectSelectedPractitioners = useAppSelector((state) => state.practitioners.selectedPractitioner);

  const removePractitionerFromTheList = (practitionerId: string) => {
    dispatch(removeSelectedPractitioner(practitionerId));
  };

  const removeAllSelectedPractitioners = () => {
    dispatch(clearAllSelectedPractitioners());
  };

  const isUpdatingPractitionersCv = useAppSelector((state) => state.practitioners.isUpdatingCvStatuses);

  const updateSelectedPractitionersCv = (data: CvDownload) => {
    dispatch(updatePractitionersCv(data));
  };

  const cvModalIsOpen = useAppSelector((state) => state.practitioners.cvModalIsOpen);

  const openCloseCvModal = () => {
    dispatch(openOrCloseCvModal());
  };

  return {
    pushPractitionerToTheList,
    selectSelectedPractitioners,
    removePractitionerFromTheList,
    removeAllSelectedPractitioners,
    isUpdatingPractitionersCv,
    updateSelectedPractitionersCv,
    cvModalIsOpen,
    openCloseCvModal,
  };
};

export default usePractitionerCv;
