export enum Band {
    'TN' = 1,
    '6G' = 2,
    '6A' = 3,
    '6B' = 4,
    '7A' = 5,
    '7B' = 6,
    'EIGHT' = 7,
    'NINE' = 8,
    'TEN' = 9,
    '6G LH' = 20,
    '6A LH' = 21,
    '6B LH' = 22,
    '7A LH' = 23,
    '7B LH' = 24,
    '8 LH' = 25,
    '9 LH' = 26
}