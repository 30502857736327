import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import {
  addSelectedProjectToArray,
  clearSelectedProjectsArray,
  getFilteredProjects,
  removeSelectedProjectFromArray,
} from '../data-access/project/slice';
import { ProjectSummaryInterface } from '@cic-boardlite/common';
import { useState } from 'react';

const useFilteredProjects = () => {
  const [previousFilter, setPreviousFilter] = useState<string>('');
  const dispatch = useAppDispatch();

  const setFilteredProjects = (filter: string) => {
    if (previousFilter === filter) return;
    dispatch(getFilteredProjects(filter));
    setPreviousFilter(filter);
  };

  const filteredProjectsData = useAppSelector((state) => state.projects.filteredProjectData);

  const isLoadingFilteredProjects = useAppSelector((state) => state.projects.isLoadingFilteredProjects);

  const selectedProjectsList = useAppSelector((state) => state.projects.selectedProjectsArray);

  const actionRequiredProjectsNumber = useAppSelector((state) => state.projects.actionRequiredProjectsNumber);

  const pushProjectToTheSelectedList = (data: ProjectSummaryInterface) => {
    dispatch(addSelectedProjectToArray(data));
  };

  const removeProjectFromTheSelectedList = (id: string) => {
    dispatch(removeSelectedProjectFromArray(id));
  };

  const removeAllFromProjectsSelectedList = () => {
    dispatch(clearSelectedProjectsArray());
  };

  return {
    filteredProjectsData,
    isLoadingFilteredProjects,
    setFilteredProjects,
    selectedProjectsList,
    pushProjectToTheSelectedList,
    removeProjectFromTheSelectedList,
    removeAllFromProjectsSelectedList,
    actionRequiredProjectsNumber,
  };
};

export default useFilteredProjects;
