import { isEqual, sortBy } from 'lodash';

export const compareOldAndUpdatedObjects = <T>(
  oldObject: T,
  updatedObject: T,
  keysToIncludeInReturnObject?: Array<keyof T>
) => {
  if(!updatedObject){
    return [];
  }

  const objectKeys = Object.keys(updatedObject) as Array<keyof T>;

  return objectKeys.reduce(
    (
      acc: [
        {
          [key: string]: T[keyof T];
        },
        {
          [key: string]: T[keyof T];
        }
      ],
      key
    ) => {
      if (
        (keysToIncludeInReturnObject &&
          keysToIncludeInReturnObject.includes(key)) ||
        !isEqual(updatedObject[key], oldObject[key]) ||
        (Array.isArray(updatedObject[key]) &&
          Array.isArray(oldObject[key]) &&
          !isArrayWithSameItems<T[typeof key], T[typeof key]>(
            Array(updatedObject[key]),
            Array(oldObject[key])
          ))
      ) {
        acc[0][key as string] = oldObject[key];
        acc[1][key as string] = updatedObject[key];
      }
      return acc;
    },
    [{}, {}]
  );
};

export const isArrayWithSameItems = <T, K>(
  firstArray: T[],
  secondArray: K[]
): boolean => {
  const firstArrayWithoutItemType = firstArray.map((item) =>
    typeof item === 'object' ? { ...item } : item
  );

  const secondArrayWithoutItemType = secondArray.map((item) =>
    typeof item === 'object' ? { ...item } : item
  );

  return (
    firstArrayWithoutItemType.length === secondArrayWithoutItemType.length &&
    isEqual(
      sortBy(firstArrayWithoutItemType),
      sortBy(secondArrayWithoutItemType)
    )
  );
};
