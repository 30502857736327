import { CloudDownload } from '@carbon/icons-react/next';
import { DownloadDataPages } from '@cic-boardlite/common';
import { TableToolbarAction, TableToolbarMenu } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import './data-download-toolbar.scss';
import { useFileImportExport } from '../../custom-hooks';

interface PropsInterface {
  page: string;
}

const DataDownloadMenuButton = ({ page }: PropsInterface) => {
  const { t } = useTranslation('common');

  const {
    exportAllPractitioners,
    exportOpenedPractitionerAllAssignments,
    exportAllProjects,
    exportOpenedProjectAllAssignments,
    exportAllPractitionersAssignments,
    exportAllProjectsAssignments,
  } = useFileImportExport();

  const practitionersPageButtons = [
    {
      title: t('fileImportExport.practitionersPage.allPractitioners'),
      action: () => exportAllPractitioners(),
    },
    {
      title: t('fileImportExport.practitionersPage.allAssignments'),
      action: () => exportAllPractitionersAssignments(),
    },
  ];

  const projectsPageButtons = [
    {
      title: t('fileImportExport.projectsPage.allProjects'),
      action: () => exportAllProjects(),
    },
    {
      title: t('fileImportExport.projectsPage.allAssignments'),
      action: () => exportAllProjectsAssignments(),
    },
  ];

  const practitionerAssignmentsPageButtons = [
    {
      title: t('fileImportExport.practitionerPageAssignments.all'),
      action: () => exportOpenedPractitionerAllAssignments(),
    },
  ];

  const projectAssignmentsPageButtons = [
    {
      title: t('fileImportExport.projectPageAssignments.all'),
      action: () => exportOpenedProjectAllAssignments(),
    },
  ];

  return (
    <TableToolbarMenu
      menuOptionsClass="download-toolbar-menu"
      className="download-toolbar-menu-button"
      renderIcon={CloudDownload}
      iconDescription={''}
      focusTrap={false}
    >
      {page === DownloadDataPages.Practitioners &&
        practitionersPageButtons.map((button, index) => (
          <TableToolbarAction key={index} onClick={button.action}>
            {button.title}
          </TableToolbarAction>
        ))}

      {page === DownloadDataPages.PractitionerAssignments &&
        practitionerAssignmentsPageButtons.map((button, index) => (
          <TableToolbarAction key={index} onClick={button.action}>
            {button.title}
          </TableToolbarAction>
        ))}

      {page === DownloadDataPages.Projects &&
        projectsPageButtons.map((button, index) => (
          <TableToolbarAction key={index} onClick={button.action}>
            {button.title}
          </TableToolbarAction>
        ))}

      {page === DownloadDataPages.ProjectAssignments &&
        projectAssignmentsPageButtons.map((button, index) => (
          <TableToolbarAction key={index} onClick={button.action}>
            {button.title}
          </TableToolbarAction>
        ))}
    </TableToolbarMenu>
  );
};

export default DataDownloadMenuButton;
