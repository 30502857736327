// Readme: https://github.ibm.com/cicbaltic/cic-boardlite/wiki/Developer-notes#role-guards

import { cloneElement, JSXElementConstructor, ReactElement } from 'react';
import { Permission } from '@cic-boardlite/common';
import { useScope } from '../../data-access/user/useScope';

interface PermissionsGateInterface {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  errorProps?: { [key: string]: boolean|string;
   };
  roles?: string[];
  scopes?: string[];
}

export default function PermissionsGate({
  children,
  errorProps,
  roles,
  scopes,
}: PermissionsGateInterface) {
  const { userRoles, userScopes } = useScope();

  const permissionGranted = hasPermission(roles, scopes, userRoles, userScopes);

  if (!permissionGranted) {
    if (errorProps) return cloneElement(children, { ...errorProps });
    else return <></>;
  }

  return <>{children}</>;
}

const hasPermission = (
  roles: string[] | undefined,
  scopes: string[] | undefined,
  userRoles: string[],
  userScopes: string[]
): boolean => {
  if (!roles && !scopes) return true;

  let permissionFound = false;

  if (roles) {
    roles.forEach((role) => {
      userRoles.forEach((userRole: string) => {
        if (!permissionFound) permissionFound = role === userRole;
      });
    });
  }

  if (!permissionFound) {
    const scopesMap: { [key: string]: boolean } = {};

    if (scopes) {
      scopes.forEach((scope) => {
        scopesMap[scope] = true;
      });
    }

    if (userRoles) {
      userRoles.forEach((userRole: string) => {
        if (!permissionFound && Permission[userRole]) {
          permissionFound = Permission[userRole].some(
            (permission) => scopesMap[permission]
          );
        }
      });
    }

    if (!permissionFound && userScopes) {
      if (scopes) {
        scopes.forEach((scope) => {
          userScopes.forEach((userScope: string) => {
            if (!permissionFound) permissionFound = scope === userScope;
          });
        });
      }
    }
  }

  return permissionFound;
};
