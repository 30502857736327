import { trim } from './assets/common/utils';

interface ProjectFilterFormInterface {
  id?: number;
  projectId?: string;
  projectAccountIds?: string;
  name?: string;
  clientName?: string;
  actionRequiredStatus?: { id?: number; title: string };
  projectStatus?: { id?: number; title: string };
  projectType?: { id?: number; title: string };
  serviceLine?: { id?: number; title: string };
  geoPm?: { id?: number; name: string };
  pmo?: { id?: number; title: string };
  country?: {
    id: number;
    label: string;
    code: string;
  }[];
  created?: string;
  activeFrom?: string;
  activeUntil?: string;
};

export const getProjectFilterString = (data: ProjectFilterFormInterface) => {
  let filteringString = '?';

  if (data.id) filteringString += `id=${data.id}&`;
  if (data.projectId) filteringString += `projectId=${trim(data.projectId)}&`;
  if (data.projectAccountIds) filteringString += `projectAccountIds=${trim(data.projectAccountIds)}&`;
  if (data.name) filteringString += `name=${trim(data.name)}&`;
  if (data.clientName) filteringString += `clientName=${trim(data.clientName)}&`;
  if (data.projectStatus && data.projectStatus.title) filteringString += `projectStatus=${data.projectStatus.title}&`;
  if (data.projectType && data.projectType.title)
    filteringString += `projectType=${data.projectType.title.replace('&', '%26')}&`;
  if (data.serviceLine && data.serviceLine.title) filteringString += `serviceLine=${data.serviceLine.title}&`;
  if (data.geoPm && data.geoPm.name) filteringString += `geoPm=${data.geoPm.name}&`;
  if (data.pmo && data.pmo.title) filteringString += `pmo=${data.pmo.title}&`;
  if (data.actionRequiredStatus && data.actionRequiredStatus.title)
    filteringString += `actionRequiredStatus=${data.actionRequiredStatus.title}&`;
  if (data.country) {
    for (const countryIndex in data.country) {
      filteringString += `country=${data.country[countryIndex].code}&`;
    }
  }
  if (data.created?.length) filteringString += `created=${data.created}&`;
  if (data.activeFrom?.length) filteringString += `activeFrom=${data.activeFrom}&`;
  if (data.activeUntil?.length) filteringString += `activeUntil=${data.activeUntil}&`;

  return filteringString.slice(0, -1);
};
