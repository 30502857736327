import {
  calculatePractitionerRate,
  convertDate,
  EmfStatus,
  FormInput,
  HookPractitionerObject,
  PractitionerDetailsForm,
  practitionerPSA,
  StaticDataObjectInterface,
  TalentID_LENGTH,
  validateSerialFE,
  trim,
  toUTCDate,
} from '@cic-boardlite/common';
import {
  Button,
  Checkbox,
  DatePicker,
  DatePickerInput,
  Dropdown,
  InlineLoading,
  Link,
  OnChangeData,
  RadioButton,
  RadioButtonGroup,
  TextInput,
} from 'carbon-components-react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useBluePages, useSavePractitioner } from '../../../custom-hooks';
import ReusableComboboxComponent from '../../reusableCombobox/reusableCombobox';
import { SuggestedPractitioner } from '../../../interfaces/practitionerInterfaces';
import { EmailsField } from '../../emails-field/emails-field';
import ErrorBox from '../../../ui/form-error-box/ErrorBox';
import { LabelWithAsterisk } from '../../../ui/label-with-asterisk/label-with-asterisk';
import ComboboxWithCustomFilter from '../../combobox-custom-filtering/combobox-custom-filtering';
import { useNavigate } from 'react-router-dom';
import './create-edit-style.scss';

interface Props {
  staticData: StaticDataObjectInterface;
  setModal: Dispatch<SetStateAction<boolean>>;
  modalOpenState: boolean;
  trackFormChanges?: (fieldsEdited: boolean) => void;
  formReset?: boolean;
}

export const CreateEditPractitionerForm = (props: Props) => {
  const [formIsCic, setFormIsCic] = useState(true);
  const [practitionerSuggestions, setPractitionerSuggestions] = useState<SuggestedPractitioner[]>([]);
  const [serviceLineLeaderError, setServiceLineLeaderError] = useState<string | null>(null);
  const {
    getAllBluePagesPractitionersBySerial,
    isLoadingBluePagesPractitioners,
    bluePagesPractitioners,
    selectedBluePagesPractitioner,
    isSelectingBluePagesPractitioner,
  } = useBluePages();

  const { t } = useTranslation('common');
  const nav = useNavigate();

  const { buildPractitioner, isCreatingPractitioner, practitionerCreateFailed } = useSavePractitioner();

  const [fieldsEdited, setFieldsEdited] = useState<boolean>(false);

  const formInputs: FormInput[] = [
    {
      id: 'createEditPractitionerFirstName',
      name: 'firstName',
      placeholder: t(`createEditPractitionerForm.firstName.placeholder`),
      label: t(`createEditPractitionerForm.firstName.label`),
      sharedField: true,
    },
    {
      id: 'createEditPractitionerLastName',
      name: 'lastName',
      placeholder: t(`createEditPractitionerForm.lastName.placeholder`),
      label: t(`createEditPractitionerForm.lastName.label`),
      sharedField: true,
    },
    {
      id: 'createEditPractitionerSerial',
      name: 'serial',
      placeholder: t(`createEditPractitionerForm.serial.placeholder`),
      label: t(`createEditPractitionerForm.serial.label`),
      sharedField: true,
    },
    {
      id: 'createEditAvailabilityInfo',
      name: 'availabilityInfo',
      placeholder: t(`createEditPractitionerForm.availabilityInfo.placeholder`),
      label: t(`createEditPractitionerForm.availabilityInfo.label`),
    },
  ];

  const [generatedCfhSerial] = useState(`CFH-${Date.now().toString().slice(-5)}`);

  const defaultValues = {
    firstName: '',
    lastName: '',
    serial: formIsCic ? generatedCfhSerial : '',
    availabilityInfo: '',
    serviceLine: {
      title: '',
      id: 0,
    },
    emfStatus: {
      title: 'Candidate for hiring',
      id: EmfStatus.CandidateForHiring,
    },
    contractType: {
      title: '',
      id: 0,
    },
    band: {
      title: '',
      id: 0,
    },
    country: {
      label: 'Lithuania',
      id: 111,
      code: '638',
    },
    peopleManager: {
      name: '',
      id: 0,
    },
    department: {
      title: '',
      id: 0,
    },
    jrs: {
      title: '',
      id: 0,
    },
    ccEmail: [],
    sendEmailCheckbox: false,
    isPeM: false,
    isPMO: false,
    hiringDate: new Date(),
    terminationDate: undefined,
    availabilityDate: new Date(),
    availabilityDateManuallyEdited: undefined,
    rate: '',
    serviceArea: '',
    psAdder: '',
    email: '',
    isServiceLineLeader: false,
  };

  const methods = useForm<PractitionerDetailsForm>({
    defaultValues: defaultValues,
  });

  const watchBandInput = methods.watch('band');
  const watchJrsInput = methods.watch('jrs');
  const watchCountryInput = methods.watch('country');
  const watchEmfStatus = methods.watch('emfStatus');
  const watchServiceLine = methods.watch('serviceLine');
  const watchServiceLineLeader = methods.watch('isServiceLineLeader');
  const watchHiringDate = methods.watch('hiringDate');

  const addCountryCodeToSerial = (
    serial: string,
    countryCode: string,
    isCiC: boolean,
    emfStatusId: number | undefined
  ) => {
    if (isCiC && emfStatusId === EmfStatus.CandidateForHiring) return serial;
    if (serial.length === TalentID_LENGTH.SHORT) return serial + countryCode;
    return serial;
  };

  const onSubmit = (data: PractitionerDetailsForm) => {
    let practitionerObject: HookPractitionerObject;

    if (formIsCic) {
      practitionerObject = {
        availabilityInfo: trim(data.availabilityInfo),
        band: data.band?.id,
        bandTitle: data.band?.title,
        ccEmail: data.sendEmailCheckbox ? data.ccEmail.slice(1) : [],
        emailTo: data.sendEmailCheckbox ? data.ccEmail[0] : '',
        contractType: data.contractType?.id,
        contractTypeTitle: data.contractType?.title,
        country: data.country?.id,
        countryTitle: data.country?.label,
        department: data.department?.id,
        departmentTitle: data.department?.title,
        emfStatus: data.emfStatus?.id,
        emfStatusTitle: data.emfStatus?.title,
        firstName: trim(data.firstName),
        hiringDate: data.hiringDate,
        isCic: formIsCic,
        isPMO: data.isPMO,
        isPeM: data.isPeM,
        jrs: data.jrs?.id,
        jrsTitle: data.jrs?.title,
        lastName: trim(data.lastName),
        availabilityDate: data?.availabilityDate,
        availabilityDateManuallyEdited:
          methods.formState.dirtyFields.availabilityDate &&
          convertDate(data.availabilityDate || '') !== convertDate(data.hiringDate),
        assignmentEndDate: null,
        peopleManagerPractitionerId: data.peopleManager?.id,
        sendEmail: data.sendEmailCheckbox,
        serial: addCountryCodeToSerial(data.serial, data.country.code, formIsCic, data.emfStatus?.id),
        serviceLine: data.serviceLine?.id,
        serviceLineTitle: data.serviceLine?.title,
        terminationDate: data.terminationDate,
        email: data.email,
        isServiceLineLeader: data.isServiceLineLeader,
      };
    } else {
      practitionerObject = {
        ccEmail: data.sendEmailCheckbox ? data.ccEmail.slice(1) : [],
        emailTo: data.sendEmailCheckbox ? data.ccEmail[0] : '',
        country: data.country?.id,
        countryTitle: data.country?.label,
        firstName: trim(data.firstName),
        serial: addCountryCodeToSerial(data.serial, data.country.code, formIsCic, data.emfStatus?.id),
        isCic: formIsCic,
        lastName: trim(data.lastName),
        sendEmail: data.sendEmailCheckbox,
        email: data.email,
        jrs: data.jrs?.id,
        jrsTitle: data.jrs?.title,
        band: data.band?.id,
        bandTitle: data.band?.title,
      };
    }
    buildPractitioner(practitionerObject);
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  useEffect(() => {
    if (isCreatingPractitioner) return;
    if (!practitionerCreateFailed) {
      props.setModal(false);
    }
  }, [practitionerCreateFailed, isCreatingPractitioner]);

  useEffect(() => {
    setFormIsCic(true);
  }, [props.modalOpenState]);

  useEffect(() => {
    methods.clearErrors('isServiceLineLeader');
  }, [watchEmfStatus, watchServiceLineLeader, watchServiceLine]);

  useEffect(() => {
    if (!watchHiringDate) return;

    const availabilityDate = methods.getValues('availabilityDate');
    const terminationDate = methods.getValues('terminationDate');

    if (availabilityDate && availabilityDate < watchHiringDate) {
      methods.setValue('availabilityDate', watchHiringDate);
    }

    if (terminationDate && terminationDate <= watchHiringDate) {
      methods.setValue('terminationDate', undefined);
    }
  }, [watchHiringDate]);

  const findServiceLine = () => {
    return props?.staticData?.serviceLineStaticData?.find(
      (serviceLine: {
        id: number;
        title: string;
        leader: { id: number; firstName: string; lastName: string } | null;
      }) => serviceLine.id === watchServiceLine?.id
    );
  };

  const checkIfOtherPersonIsServiceLineLeader = () => {
    const foundServiceLine = findServiceLine();

    const validation = !foundServiceLine?.leader;

    const errorMessage = !validation
      ? t('createEditPractitionerForm.serviceLineLeader.otherIsLeader', {
          serviceLine: foundServiceLine?.title,
          serviceLineLeaderName: foundServiceLine?.leader?.firstName + ' ' + foundServiceLine?.leader?.lastName,
        })
      : '';

    setServiceLineLeaderError(foundServiceLine?.leader?.firstName + ' ' + foundServiceLine?.leader?.lastName);

    return {
      validation,
      errorMessage,
    };
  };

  const navigateToServiceLineLeader = () => {
    const foundServiceLine = findServiceLine();
    nav(`/practitioner/${foundServiceLine?.leader?.id}`);
  };

  const inputChange = (inputFieldName: string, input: string) => {
    const currentEmf = methods.getValues('emfStatus');
    const query = input.toLowerCase();
    if (query?.length >= 3 && (currentEmf?.id !== EmfStatus.CandidateForHiring || !formIsCic)) {
      getAllBluePagesPractitionersBySerial({ query });
    } else {
      setPractitionerSuggestions([]);
    }
  };

  useEffect(() => {
    const currentEmf = methods.getValues('emfStatus');
    if (currentEmf?.id !== EmfStatus.CandidateForHiring || !formIsCic) {
      if (!isLoadingBluePagesPractitioners && bluePagesPractitioners.length) {
        const suggestions = bluePagesPractitioners.map((practitioner) => {
          const [firstName, lastName] = practitioner.nameFull.split(' ');
          return {
            firstName,
            lastName,
            serial: practitioner.uid,
          };
        });
        setPractitionerSuggestions(suggestions);
      } else if (!isLoadingBluePagesPractitioners && !bluePagesPractitioners.length) {
        setPractitionerSuggestions([]);
      }
    }
  }, [bluePagesPractitioners]);

  useEffect(() => {
    if (selectedBluePagesPractitioner && !isSelectingBluePagesPractitioner) {
      const bluePractitioner = selectedBluePagesPractitioner.profiles[0].content;
      const country = findCountry(bluePractitioner.employeeCountryCode);
      const emfStatus = findEmfStatus(bluePractitioner.employeeType.title);
      const peopleManager = findPeopleManager(bluePractitioner.functionalManagerUid);
      const department = findDepartment(bluePractitioner.dept.code);
      const serviceLine = findServiceLineForBP(bluePractitioner.dept.title);

      methods.setValue('firstName', bluePractitioner.name.first);
      methods.setValue('lastName', bluePractitioner.name.last);
      methods.setValue('email', bluePractitioner.mail[0]);
      methods.setValue('department', department);
      if (country) methods.setValue('country', country);
      if (emfStatus) methods.setValue('emfStatus', emfStatus);
      if (serviceLine)
        methods.setValue('serviceLine', {
          title: serviceLine.title,
          id: serviceLine.id,
        });
      if (peopleManager)
        methods.setValue('peopleManager', {
          name: `${peopleManager.firstName} ${peopleManager.lastName}`,
          id: peopleManager.id,
        });
    }
  }, [isSelectingBluePagesPractitioner]);

  function findEmfStatus(status: string) {
    const statusTitle = status.split(' ').pop()?.trim();
    return props.staticData['emfStatusStaticData'].find((option) => option.title === statusTitle);
  }

  function findDepartment(dept: string) {
    return props.staticData['departmentStaticData'].find((option) => option.title === dept);
  }

  function findPeopleManager(serial: string) {
    return props.staticData['pemInfoStaticData'].find((manager) => manager.serial === serial);
  }

  function findCountry(countryCode: string) {
    return props.staticData['countryStaticData'].find((country) => country.code === countryCode);
  }

  function findServiceLineForBP(department: string) {
    return props.staticData['departmentServiceLineMappingStaticData'].find(
      (departmentMap) => departmentMap.title === department
    )?.serviceLine;
  }

  const getPractitionerRate = () =>
    calculatePractitionerRate(
      props?.staticData?.rateStaticData,
      watchCountryInput?.label,
      watchBandInput?.title,
      getPractitionerPSA()
    );

  const getPractitionerPSA = () =>
    practitionerPSA({
      jrsStaticData: props?.staticData?.jrsStaticData,
      jrsId: watchJrsInput?.id,
      band: watchBandInput?.title,
    });

  function getServiceArea(): string {
    const jrs = props.staticData.jrsStaticData.find((jrs) => jrs.title === watchJrsInput?.title);
    return !jrs?.serviceArea ? '' : jrs?.serviceArea;
  }

  function handleSerialAndCountry(onChangeData: OnChangeData<{ id: number; title: string }>): void {
    if (
      onChangeData.selectedItem?.id !== EmfStatus.CandidateForHiring &&
      methods.watch('serial') === generatedCfhSerial
    )
      return methods.setValue('serial', '');

    if (onChangeData.selectedItem?.id === EmfStatus.CandidateForHiring) {
      methods.setValue('country', defaultValues.country);
      methods.control.unregister('serial');
    }
  }

  useEffect(() => {
    setFieldsEdited(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  useEffect(() => {
    methods.reset();
  }, []);

  useEffect(() => {
    if (formIsCic) {
      methods.reset({
        ...defaultValues,
        serial: generatedCfhSerial,
      });
    } else {
      methods.reset({
        ...defaultValues,
        serial: '',
      });
    }
  }, [formIsCic]);

  useEffect(() => {
    if (props.trackFormChanges) {
      props.trackFormChanges(fieldsEdited);
    }
  }, [fieldsEdited]);

  useEffect(() => {
    if (props.formReset) {
      methods.reset();
      setPractitionerSuggestions([]);
    }
  }, [props.formReset]);

  const validatePractitionerSerial = (serial: string) =>
    validateSerialFE(serial, formIsCic, watchCountryInput?.code, watchEmfStatus?.id);

  const inputHtml = (input: FormInput, index: number): JSX.Element => (
    <Controller
      key={index}
      name={input.name}
      control={methods.control}
      rules={
        input.name === 'serial'
          ? {
              required: t('fieldRequired', {
                fieldLabel: t(`createEditPractitionerForm.${input.name}.label`),
              }),
              validate: {
                validateSerial: (value: any) => {
                  return (
                    !validatePractitionerSerial(value).error ||
                    t(`createEditPractitionerForm.serial.${validatePractitionerSerial(value).errMessage}`) +
                      ` ${validatePractitionerSerial(value).code}`
                  );
                },
              },
            }
          : input.name === 'availabilityInfo'
          ? {
              maxLength: {
                value: 512,
                message: t(`fieldMaxLength`, {
                  fieldLabel: t(`createEditPractitionerForm.${input.name}.label`),
                  maxLength: 512,
                }),
              },
            }
          : input.name === 'firstName' || input.name === 'lastName'
          ? {
              required: t('fieldRequired', {
                fieldLabel: t(`createEditPractitionerForm.${input.name}.label`),
              }),
              maxLength: {
                value: 64,
                message: t(`fieldMaxLength`, {
                  fieldLabel: t(`createEditPractitionerForm.${input.name}.label`),
                  maxLength: 64,
                }),
              },
            }
          : {}
      }
      render={({ field: { onChange, value } }) =>
        input.name === 'serial' ? (
          <ReusableComboboxComponent
            disabled={formIsCic && methods.watch('emfStatus')?.id === EmfStatus.CandidateForHiring}
            id={input.id}
            controllerName={input.name}
            getValue={methods.getValues}
            onChange={(event) => {
              onChange(event);
              inputChange(input.name, event.toString());
            }}
            allowInputValue
            items={practitionerSuggestions}
            itemKeyName={input.name}
            title={LabelWithAsterisk(input.label)}
            placeholder={input.placeholder}
            errorName={input.name}
            errors={methods.formState.errors}
            errorMessage={methods.formState.errors['serial']?.message || ''}
            createEditPractitioner
          />
        ) : (
          <TextInput
            onChange={onChange}
            id={input.id}
            labelText={
              input.name === 'firstName' || input.name === 'lastName'
                ? LabelWithAsterisk(t(`createEditPractitionerForm.${input.name}.label`))
                : t(`createEditPractitionerForm.${input.name}.label`)
            }
            placeholder={input.placeholder}
            value={value}
            warn={!!methods.formState.errors[input.name]?.message}
            warnText={
              <span className="delete-modal-error-message">
                {methods.formState.errors[input.name]?.message && (
                  <span>{methods.formState.errors[input.name]?.message}</span>
                )}
              </span>
            }
          />
        )
      }
    />
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
        <div className="create-edit-practitioner-select">
          <div className="create-edit-practitioner-select-left" style={{ display: 'flex' }}>
            <h4>Practitioner form</h4>
            <div>
              <RadioButtonGroup name="radio-button-group" valueSelected={formIsCic ? 'true' : 'false'}>
                <RadioButton
                  labelText={t(`createEditPractitionerForm.isCic.labelTrue`)}
                  id="createEditPractitionerCicTrue"
                  value="true"
                  onClick={() => {
                    setFormIsCic(true);
                    methods.reset();
                    setPractitionerSuggestions([]);
                  }}
                />
                <RadioButton
                  id="createEditPractitionerIsCicFalse"
                  labelText={t(`createEditPractitionerForm.isCic.labelFalse`)}
                  value="false"
                  onClick={() => {
                    setFormIsCic(false);
                    methods.setValue('serial', '');
                    methods.reset();
                    setPractitionerSuggestions([]);
                  }}
                />
              </RadioButtonGroup>
            </div>
          </div>
        </div>
        <div className="create-edit-practitioner-column">
          {formInputs.map((input, index) =>
            formIsCic ? inputHtml(input, index) : input.sharedField && inputHtml(input, index)
          )}
          <Controller
            name={'country'}
            rules={{
              validate: (value) => value !== null && value.label !== '',
            }}
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <ComboboxWithCustomFilter
                customClassName="custom-filter-combobox-create-practitioner-country"
                onChange={(onChangeData) => {
                  onChange(onChangeData.selectedItem);
                  methods.clearErrors('serial');
                }}
                selectedItem={value}
                items={props.staticData['countryStaticData'].map((selectionObject) => {
                  return {
                    label: selectionObject.label,
                    id: selectionObject.id,
                    code: selectionObject.code,
                  };
                })}
                itemKeyName="label"
                titleText={LabelWithAsterisk(t(`createEditPractitionerForm.country.label`))}
                placeholder={t(`createEditPractitionerForm.country.placeholder`)}
                warn={!!methods.formState.errors['country']}
                warnText={
                  <ErrorBox>
                    {t('fieldRequired', {
                      fieldLabel: t('createEditPractitionerForm.country.label'),
                    })}
                  </ErrorBox>
                }
              />
            )}
          />
          {formIsCic && (
            <Controller
              name={'emfStatus'}
              rules={{
                validate: {
                  value: (value) => value?.title !== '',
                },
              }}
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  selectedItem={value}
                  onChange={(onChangeData) => {
                    onChange(onChangeData.selectedItem);
                    handleSerialAndCountry(onChangeData);
                  }}
                  id={'createEditPractitionerEmfStatuses'}
                  label={t(`createEditPractitionerForm.emfStatus.label`)}
                  titleText={t(`createEditPractitionerForm.emfStatus.label`)}
                  warn={!!methods.formState.errors['emfStatus']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createEditPractitionerForm.emfStatus.label'),
                      })}
                    </ErrorBox>
                  }
                  items={props.staticData.emfStatusStaticData}
                  itemToString={(item) => (item?.title ? item.title : t(`createEditPractitionerForm.emfStatus.label`))}
                />
              )}
            />
          )}
          {formIsCic && (
            <Controller
              name={'contractType'}
              rules={{
                validate: (value) => value !== null && value?.title !== '',
              }}
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-create-practitioner-contract-typ"
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  selectedItem={value ?? { id: null, title: '' }}
                  items={props.staticData.contractTypeStaticData}
                  itemKeyName="title"
                  placeholder={t(`createEditPractitionerForm.contractType.label`)}
                  titleText={LabelWithAsterisk(t(`createEditPractitionerForm.contractType.label`))}
                  warn={!!methods.formState.errors['contractType']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createEditPractitionerForm.contractType.label'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
          )}
          {formIsCic && (
            <Controller
              name={'serviceLine'}
              rules={{
                validate: {
                  value: (value) => value?.title !== '',
                },
              }}
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-create-practitioner-service-line"
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  selectedItem={value ?? { id: null, title: '' }}
                  items={props.staticData['serviceLineStaticData']}
                  itemKeyName="title"
                  placeholder={t(`createEditPractitionerForm.serviceLine.label`)}
                  titleText={LabelWithAsterisk(t(`createEditPractitionerForm.serviceLine.label`))}
                  warn={!!methods.formState.errors['serviceLine']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createEditPractitionerForm.serviceLine.label'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
          )}
          <Controller
            name={'band'}
            rules={{
              validate: (value) => value !== null && value?.title !== '',
            }}
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <ComboboxWithCustomFilter
                customClassName="custom-filter-combobox-create-practitioner-band"
                onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                selectedItem={value ?? { id: null, title: '' }}
                items={props.staticData['bandStaticData'].map((selectionObject) => {
                  return {
                    title: selectionObject.title,
                    id: selectionObject.id,
                  };
                })}
                itemKeyName="title"
                titleText={LabelWithAsterisk(t(`createEditPractitionerForm.band.label`))}
                placeholder={t(`createEditPractitionerForm.band.placeholder`)}
                warn={!!methods.formState.errors['band']}
                warnText={
                  <ErrorBox>
                    {t('fieldRequired', {
                      fieldLabel: t('createEditPractitionerForm.band.label'),
                    })}
                  </ErrorBox>
                }
              />
            )}
          />
          {formIsCic && (
            <Controller
              name="department"
              rules={{
                validate: (value) => value !== null && value?.title !== '',
              }}
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-create-practitioner-department"
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  selectedItem={value ?? { id: null, title: '' }}
                  items={props.staticData['departmentStaticData'].map((selectionObject) => {
                    return {
                      title: selectionObject.title,
                      id: selectionObject.id,
                    };
                  })}
                  itemKeyName="title"
                  titleText={LabelWithAsterisk(t(`createEditPractitionerForm.department.label`))}
                  placeholder={t(`createEditPractitionerForm.department.placeholder`)}
                  warn={!!methods.formState.errors['department']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createEditPractitionerForm.department.label'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
          )}
          {formIsCic && (
            <Controller
              name={'peopleManager'}
              rules={{
                validate: (value) => value !== null && value?.name !== '',
              }}
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-create-practitioner-people-manager"
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  selectedItem={value ?? { id: null, name: '' }}
                  items={props.staticData['pemInfoStaticData'].map((selectionObject) => {
                    return {
                      name: `${selectionObject.firstName} ${selectionObject.lastName}`,
                      id: selectionObject.id,
                    };
                  })}
                  itemKeyName="name"
                  titleText={LabelWithAsterisk(t(`createEditPractitionerForm.peopleManager.label`))}
                  placeholder={t(`createEditPractitionerForm.peopleManager.placeholder`)}
                  warn={!!methods.formState.errors['peopleManager']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createEditPractitionerForm.peopleManager.label'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
          )}
          <div className="practitioner-create-jrs-input">
            <Controller
              name={'jrs'}
              rules={{
                required: true,
                validate: {
                  value: (value) => value !== null && value?.title !== '',
                },
              }}
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-create-practitioner-jrs"
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  selectedItem={value ?? { id: null, title: '' }}
                  items={props.staticData['jrsStaticData']
                    .map((selectionObject) => {
                      return {
                        title: selectionObject.title,
                        id: selectionObject.id,
                      };
                    })
                    .sort((a, b) => a.title.localeCompare(b.title))}
                  itemKeyName="title"
                  titleText={LabelWithAsterisk(t(`createEditPractitionerForm.jrs.label`))}
                  placeholder={t(`createEditPractitionerForm.jrs.placeholder`)}
                  warn={!!methods.formState.errors['jrs']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createEditPractitionerForm.jrs.label'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
          </div>
          {formIsCic && (
            <div>
              <Controller
                control={methods.control}
                name="isPeM"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Checkbox
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value}
                    id="createEditPractitionerisPeM"
                    labelText={t(`createEditPractitionerForm.isPeM.label`)}
                  />
                )}
              />
              <Controller
                control={methods.control}
                name="isPMO"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Checkbox
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value}
                    id="createEditPractitionerisPMO"
                    labelText={t(`createEditPractitionerForm.isPMO.label`)}
                  />
                )}
              />
              <Controller
                control={methods.control}
                name="isServiceLineLeader"
                rules={{
                  validate: {
                    leaderCantBeCFH: (value) =>
                      (value && watchEmfStatus?.id !== EmfStatus.CandidateForHiring) ||
                      !value ||
                      t('createEditPractitionerForm.serviceLineLeader.cfhError'),
                    checkIfOtherIsLeader: (value) => {
                      const validationAndErrorMsg = checkIfOtherPersonIsServiceLineLeader();
                      return (
                        (value && validationAndErrorMsg.validation) || !value || validationAndErrorMsg.errorMessage
                      );
                    },
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <Checkbox
                      onChange={onChange}
                      onBlur={onBlur}
                      checked={value}
                      id="practitionerProfileIsServiceLineLeader"
                      labelText={t(`practitionerProfile.isServiceLineLeader`)}
                    />
                    {methods.formState.errors['isServiceLineLeader'] && (
                      <div className="service-line-leader-error-box">
                        <ErrorBox>
                          {methods.formState.errors['isServiceLineLeader']?.message + ' '}
                          <Link onClick={navigateToServiceLineLeader} className="service-line-leader-navigation">
                            {serviceLineLeaderError}
                          </Link>
                        </ErrorBox>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          )}
          {!formIsCic && (
            <div className="create-edit-practitioner-ps-adder">
              <Controller
                name="psAdder"
                control={methods.control}
                render={({ field: { onChange } }) => (
                  <TextInput
                    value={getPractitionerPSA()}
                    onChange={onChange}
                    id="createEditPractitionerPsAdder"
                    labelText={t('createEditPractitionerForm.psAdder.label')}
                    placeholder={t(`createEditPractitionerForm.psAdder.placeholder`)}
                    disabled={true}
                  />
                )}
              />
            </div>
          )}
        </div>
        {formIsCic && (
          <div className="create-edit-practitioner-column">
            <Controller
              name="hiringDate"
              rules={{
                required: t('fieldRequired', {
                  fieldLabel: t('createEditPractitionerForm.hiringDate.label'),
                }),
              }}
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  light
                  className="modalDateInput"
                  datePickerType="single"
                  onChange={(date) => onChange(date[0])}
                  value={value}
                  dateFormat={t('format.date_4carbonDatePicker')}
                >
                  <DatePickerInput
                    autoComplete={'off'}
                    id="createEditPractitionerHiringDateInput"
                    labelText={t('createEditPractitionerForm.hiringDate.label')}
                    placeholder={t(`createEditPractitionerForm.hiringDate.placeholder`)}
                    warn={!!methods.formState.errors['hiringDate']}
                    warnText={<ErrorBox>{methods.formState.errors['hiringDate']?.message}</ErrorBox>}
                    pattern={t('format.date_4carbonDatePickerInput')}
                  />
                </DatePicker>
              )}
            />
            <Controller
              name="terminationDate"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  light
                  className="modalDateInput"
                  id="createEditPractitionerTerminationDate"
                  datePickerType="single"
                  onChange={(date) => onChange(date[0])}
                  value={value}
                  disable={[(date) => date <= toUTCDate(watchHiringDate || new Date())]}
                  dateFormat={t('format.date_4carbonDatePicker')}
                >
                  <DatePickerInput
                    autoComplete={'off'}
                    id="createEditPractitionerTerminationDateInput"
                    labelText={t('createEditPractitionerForm.terminationDate.label')}
                    placeholder={t(`createEditPractitionerForm.terminationDate.placeholder`)}
                    pattern={t('format.date_4carbonDatePickerInput')}
                  />
                </DatePicker>
              )}
            />
            <Controller
              name="availabilityDate"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  light
                  className="modalDateInput"
                  id="createEditPractitionerAvailabilityDate"
                  datePickerType="single"
                  onChange={(date) => {
                    if (convertDate(date[0]) !== convertDate(toUTCDate(watchHiringDate || new Date()))) {
                      methods.setValue('availabilityDate', date[0], {
                        shouldDirty: true,
                      });
                      return onChange(date[0]);
                    }
                  }}
                  value={value}
                  disable={[(date) => date < toUTCDate(watchHiringDate || new Date())]}
                  dateFormat={t('format.date_4carbonDatePicker')}
                >
                  <DatePickerInput
                    autoComplete={'off'}
                    id="createEditPractitionerAvailabilityDateInput"
                    labelText={t('createEditPractitionerForm.availabilityDate.label')}
                    placeholder={t(`createEditPractitionerForm.availabilityDate.placeholder`)}
                    pattern={t('format.date_4carbonDatePickerInput')}
                  />
                </DatePicker>
              )}
            />
          </div>
        )}
        {formIsCic && (
          <div className="create-edit-practitioner-column">
            <Controller
              name="rate"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  value={getPractitionerRate()}
                  onChange={onChange}
                  id="createEditPractitionerRate"
                  labelText={t('createEditPractitionerForm.rate.label')}
                  placeholder={t(`createEditPractitionerForm.rate.placeholder`)}
                  disabled={true}
                />
              )}
            />
            <Controller
              name="serviceArea"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  className="create-edit-practitioner-serviceArea"
                  value={getServiceArea()}
                  onChange={onChange}
                  id="createEditPractitionerServiceArea"
                  labelText={t('createEditPractitionerForm.serviceArea.label')}
                  placeholder={t(`createEditPractitionerForm.serviceArea.placeholder`)}
                  disabled={true}
                  title={getServiceArea()}
                />
              )}
            />
            <Controller
              name="psAdder"
              control={methods.control}
              render={({ field: { onChange } }) => (
                <TextInput
                  value={getPractitionerPSA()}
                  onChange={onChange}
                  id="createEditPractitionerPsAdder"
                  labelText={t('createEditPractitionerForm.psAdder.label')}
                  placeholder={t(`createEditPractitionerForm.psAdder.placeholder`)}
                  disabled={true}
                />
              )}
            />
          </div>
        )}
        <div className="practitioner-email-checkbox">
          <Controller
            name="sendEmailCheckbox"
            control={methods.control}
            render={({ field: { onBlur, value } }) => (
              <Checkbox
                id="email-checkbox"
                className="field-cc-mail-checkbox"
                labelText={String(t('createEditPractitionerForm.email.label' || ''))}
                onBlur={onBlur}
                checked={value}
                onChange={(value: any) => {
                  methods.setValue('sendEmailCheckbox', value);
                  methods.clearErrors('sendEmailCheckbox');
                }}
              />
            )}
          />
        </div>
        {methods.getValues('sendEmailCheckbox') && (
          <div className="emailBox">
            <EmailsField
              checkBoxName={'sendEmailCheckbox'}
              emailBoxName={'ccEmail'}
              textAreaLabelLangKey={'ccEmail.label'}
              textAreaPlaceholderLangKey={'ccEmail.placeholder'}
            />
          </div>
        )}
        <div className="create-edit-practitioner-submit">
          <Button
            id="createEditPractitionerReset"
            kind="secondary"
            className="practitioner-create-edit-modal-button"
            size="lg"
            onClick={() => {
              methods.reset();
              !formIsCic && methods.setValue('serial', '');
              setPractitionerSuggestions([]);
            }}
          >
            Reset
          </Button>
          <Button
            id="createEditPractitionerSubmit"
            type="submit"
            disabled={isCreatingPractitioner}
            className="practitioner-create-edit-modal-button"
            size="lg"
            onClick={() => {
              methods.clearErrors('ccEmail');
              methods.getValues('sendEmailCheckbox') &&
                methods.getValues('ccEmail').length === 0 &&
                methods.setError('ccEmail', { message: t('ccEmail.required') });
            }}
          >
            {isCreatingPractitioner ? (
              <InlineLoading
                status="active"
                iconDescription={t('createEditPractitionerForm.loading')}
                description={t('createEditPractitionerForm.loading_data')}
              />
            ) : (
              <p>Save</p>
            )}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
