import { HeadersInterface } from '@cic-boardlite/common';

export const headers: HeadersInterface[] = [
  {
    key: 'serial',
    header: '',
  },
  {
    key: 'firstName',
    header: '',
  },
  {
    key: 'lastName',
    header: '',
  },
  {
    key: 'band',
    header: '',
  },
  {
    key: 'serviceLine',
    header: '',
  },
  {
    key: 'allocation',
    header: '',
  },
  {
    key: 'startDate',
    header: '',
  },
  {
    key: 'endDate',
    header: '',
  },
  {
    key: 'pmpSeat',
    header: '',
  },
  {
    key: 'assignmentStatus',
    header: '',
  },
  {
    key: 'practitionerId',
    header: '',
  },
];
