import { useTranslation } from 'react-i18next';
import { ENV_RELEASE_VERSION, ENV_RELEASE_DATE, SLACK_SUPPORT_CHANNEL } from '@cic-boardlite/common';
import { Link } from 'carbon-components-react';
import './footer.scss';

const Footer = () => {
  const { t } = useTranslation('common');

  const releaseVersion = process.env[ENV_RELEASE_VERSION];
  const releaseDate = process.env[ENV_RELEASE_DATE];

  const releaseVersionOutput = `(${releaseVersion})`;

  const dateAndVersion = `${releaseDate ? releaseDate : ''} ${
    releaseVersion ? releaseVersionOutput : ''
  }`;

  return (
    <footer className="footer">
      <p>
        {t('appName')}
      </p>
      <p>{dateAndVersion}</p>
      <p>
        {t('footer.support')}{' '}
        <a
          href={SLACK_SUPPORT_CHANNEL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer.boardlite_slack_support')}
        </a>
      </p>
      <p>
        <Link className="privacy-link" href="privacyPolicy" target="policy" rel="noopener noreferrer">
          {t('privacyPolicy.privacyPolicyFooter')}
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
