import './assignment-page-filter.scss';
import { useCommonState, usePractitionerAssignments, useProjectAssignments } from '../../custom-hooks';
import { useTranslation } from 'react-i18next';
import { Toggle } from 'carbon-components-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const AssignmentPageFilter = () => {
  const { getProjectAssignmentsData, isLoadingProjectAssignments } = useProjectAssignments();
  const { getSelectedPractitionerAssignments, isLoadingSelectedPractitionersAssignments } =
    usePractitionerAssignments();
  const { showOnlyOnboardedAssignments, toggleShowOnlyOnboardedAssignments } = useCommonState();
  const { t } = useTranslation('common');

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadAssignments();
  }, []);

  useEffect(() => {
    loadAssignments();
  }, [showOnlyOnboardedAssignments]);

  function loadAssignments() {
    const url = window.location.href;
    if (!id) return;
    if (url.includes('project') && !isLoadingProjectAssignments) getProjectAssignmentsData(id);
    if (url.includes('practitioner') && !isLoadingSelectedPractitionersAssignments)
      getSelectedPractitionerAssignments({ id });
  }

  return (
    <Toggle
      className="assignment-filter"
      labelA={t('assignmentFilter.label')}
      labelB={t('assignmentFilter.label')}
      toggled={showOnlyOnboardedAssignments}
      onChange={() => {
        toggleShowOnlyOnboardedAssignments();
      }}
      id="assignment-filter-toggle"
    />
  );
};

export default AssignmentPageFilter;
