import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { EmailValidator } from '@cic-boardlite/common';
import { SubtractAlt } from '@carbon/icons-react/next';
import './email-field.scss';
import ErrorBox from '../../ui/form-error-box/ErrorBox';
import { usePractitioners } from '../../custom-hooks';

interface EmailsFieldProps {
  textAreaLabelLangKey?: string;
  textAreaPlaceholderLangKey?: string;
  checkBoxName?: string;
  emailBoxName: 'ccEmail' | 'emailTo';
  initialEmails?: string;
}

export const EmailsField = ({
  textAreaLabelLangKey,
  textAreaPlaceholderLangKey,
  emailBoxName,
  initialEmails,
}: EmailsFieldProps) => {
  const { loadPractitionersEmails, practitionersEmailSet, practitionersEmails } = usePractitioners();

  const { t } = useTranslation('common');
  const [ccEmails, setCcEmails] = useState<string[]>([]);
  const [showOptions, setShowOptions] = useState<string>('');

  useEffect(() => {
    loadPractitionersEmails();
  }, []);

  const {
    setValue,
    formState: { errors },
    clearErrors,
    setError,
    control,
  } = useFormContext();

  const initialEmailsWatcher = useWatch({
    control,
    name: initialEmails ? initialEmails : '',
  });

  const addEmailsOnCopyCapture = (stringOfEmails: string) => {
    const arrayOfEmails = stringOfEmails
      .replace(/,|;/g, ' ')
      .split(' ')
      .filter((singleEmail) => EmailValidator.isEmail(singleEmail) && singleEmail.length <= 128);

    const arrayOfUniqueEmails = arrayOfEmails.filter(
      (singleEmail) => !ccEmails.some((ccEmail) => ccEmail.toLowerCase() === singleEmail.toLowerCase())
    );

    const arrayOfNotUniqueEmails = arrayOfEmails.filter((singleEmail) =>
      ccEmails.some((ccEmail) => ccEmail.toLowerCase() === singleEmail.toLowerCase())
    );

    setCcEmails([...ccEmails, ...arrayOfUniqueEmails]);
    setValue(emailBoxName, [...ccEmails, ...arrayOfUniqueEmails]);
    arrayOfNotUniqueEmails.length
      ? setError(emailBoxName, {
          message: t('ccEmail.duplicateOnPaste', {
            emails: arrayOfNotUniqueEmails.join(', '),
          }),
        })
      : clearErrors(emailBoxName);
  };

  const validateEmailOnInput = (email: string) => {
    return !!(EmailValidator.isEmail(email) && /\.com|\.net|\.org/.test(email));
  };

  useEffect(() => {
    initialEmailsWatcher && setCcEmails(initialEmailsWatcher);
    initialEmailsWatcher && setValue(emailBoxName, initialEmailsWatcher);
  }, [initialEmailsWatcher]);

  const handleSubmit = (email: string) => {
    if (email === '') {
      return;
    } else if (!EmailValidator.isEmail(email))
      return setError(emailBoxName, {
        message: t('ccEmail.invalid', { emails: email }),
      });
    else if (email.length > 128)
      return setError(emailBoxName, {
        message: t('ccEmail.maxLength', { emails: email }),
      });
    else if (ccEmails.some((ccEmail) => ccEmail.toLowerCase().includes(email.toLowerCase())))
      return setError(emailBoxName, {
        message: t('ccEmail.duplicate', { emails: email }),
      });
    else {
      setCcEmails([...ccEmails, email]);
      clearErrors(emailBoxName);
      setValue(emailBoxName, [...ccEmails, email]);
    }
  };

  const handleDeleteEmail = (deletedEmail: string) => {
    setCcEmails([...ccEmails.filter((email) => email !== deletedEmail)]);
    setValue(emailBoxName, [...ccEmails.filter((email) => email !== deletedEmail)]);
  };
  return (
    <div className="field-cc-all-emails-container">
      <label className="field-cc-email-input-label">{t(textAreaLabelLangKey || '')}</label>
      <div className="field-cc-email-container">
        <label className="field-cc-email-label">{t('ccEmail.to')}</label>
        {ccEmails.map((email) => (
          <div className="field-cc-email" key={email}>
            {email.includes(':') ? (
              <>
                <span className="field-cc-email-role">{`${email.split(':')[0]}:`}</span>
                &#160;
                {email.split(':')[1]}
              </>
            ) : (
              email
            )}
            <SubtractAlt className="field-cc-email-icon" onClick={() => handleDeleteEmail(email)} />
          </div>
        ))}
        <input
          className="field-cc-email-input"
          type="email"
          id="emails-input"
          list="emails-input-list"
          placeholder={ccEmails.length === 0 ? t(textAreaPlaceholderLangKey || '') : ''}
          maxLength={128}
          onChange={(e) => {
            setShowOptions(e.currentTarget.value);
            for (const email of practitionersEmails) {
              if (email === e.currentTarget.value) {
                handleSubmit(e.currentTarget.value);
                e.currentTarget.value = '';
                setShowOptions('');
              }
            }
          }}
          onPasteCapture={(e) => {
            e.preventDefault();
            addEmailsOnCopyCapture(e.clipboardData.getData('Text'));
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.key === ',' || e.key === ';' || validateEmailOnInput(e.currentTarget.value)) {
              e.preventDefault();
              handleSubmit(e.currentTarget.value);
              e.currentTarget.value = '';
              setShowOptions('');
            }
          }}
        />
        {practitionersEmailSet && showOptions.length >= 3 && (
          <datalist id="emails-input-list">
            {practitionersEmails.map((email, index) => (
              <option key={index} value={email} />
            ))}
          </datalist>
        )}
      </div>
      {!!errors[emailBoxName]?.message && (
        <div className="cc-email-error-box">
          <span className="delete-modal-error-message">
            <ErrorBox>{errors[emailBoxName]?.message?.toString()}</ErrorBox>
          </span>
        </div>
      )}
    </div>
  );
};
