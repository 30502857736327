import { createSlice } from '@reduxjs/toolkit';
import {
  assignmentCreateEditModalProps,
  DeletableObjectType,
  DeleteModalProps,
  SendEmailDialogInterface,
} from '@cic-boardlite/common';

interface CommonStatesInterface {
  isPractitionersSideBarVisible: boolean;
  isProjectsSideBarVisible: boolean;
  isMainMenuVisible: boolean;
  multiChangeType: string;
  deleteModalType: DeleteModalProps;
  assignmentCreateEditModalType: assignmentCreateEditModalProps;
  sendEmailModalType: SendEmailDialogInterface;
  showOnlyOnboardedAssignments: boolean;
}

export const initialState: CommonStatesInterface = {
  isPractitionersSideBarVisible: true,
  isProjectsSideBarVisible: true,
  isMainMenuVisible: false,
  multiChangeType: '',
  deleteModalType: {
    deleteConfirmationField: '',
    deleteObject: {
      id: '',
      practitionerId: undefined,
      projectId: '',
    },
    deletableObjectType: DeletableObjectType.none,
  },
  assignmentCreateEditModalType: {
    assignmentInfo: undefined,
    projectDetails: undefined,
    projectPage: false,
    isOpen: false,
    editingAssignment: false,
  },
  sendEmailModalType: {
    assignmentDetails: undefined,
    isOpen: false,
  },
  showOnlyOnboardedAssignments: true,
};

const commonStatesSlice = createSlice({
  name: 'commonStates',
  initialState,
  reducers: {
    togglePractitionersSideBarVisibility(state: CommonStatesInterface) {
      state.isPractitionersSideBarVisible = !state.isPractitionersSideBarVisible;
    },
    toggleProjectsSideBarVisibility(state: CommonStatesInterface) {
      state.isProjectsSideBarVisible = !state.isProjectsSideBarVisible;
    },
    toggleMainMenuVisibility(state: CommonStatesInterface) {
      state.isMainMenuVisible = !state.isMainMenuVisible;
    },
    setMultiselectModalOpen(state, action: { payload: string }) {
      state.multiChangeType = action.payload;
    },
    setDeleteModalOpen(state, action: { payload: DeleteModalProps }) {
      state.deleteModalType = action.payload;
    },
    setAssignmentCreateEditModalOpen(state, action: { payload: assignmentCreateEditModalProps }) {
      state.assignmentCreateEditModalType = { ...action.payload };
    },
    setSendEmailModalOpen(state, action: { payload: SendEmailDialogInterface }) {
      state.sendEmailModalType = { ...action.payload };
    },
    setShowOnlyOnboardedAssignments(state, action: { payload: boolean }) {
      state.showOnlyOnboardedAssignments = action.payload;
    },
  },
});

export const {
  togglePractitionersSideBarVisibility,
  toggleProjectsSideBarVisibility,
  toggleMainMenuVisibility,
  setMultiselectModalOpen,
  setDeleteModalOpen,
  setAssignmentCreateEditModalOpen,
  setSendEmailModalOpen,
  setShowOnlyOnboardedAssignments,
} = commonStatesSlice.actions;
export default commonStatesSlice.reducer;
