import { HttpStatus } from '@cic-boardlite/common';
import { Button } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import './error-page.scss';

type Status = {
  status: string;
  previousLocation: string;
};
export function ErrorPage({ statusCode }: { statusCode?: Status['status'] }) {
  const { t } = useTranslation('common');
  let { status } = useParams<Status>();
  const navigate = useNavigate();

  if (status) {
    if (!Number(status)) status = `${HttpStatus.NOT_FOUND}`;
  } else if (statusCode) status = statusCode;

  const goBackToPreviousPage = () => {
    return navigate(-1);
  };

  return (
    <div className="pageLayout">
      <h1 className="error">{t(`errorPage.error`)}</h1>
      <p className="errorMessage">
        {t(`errorPage.errorMessage`)}
        {status && t(`errorMessages.${status}`)}
      </p>
      <h3 className="suggestedActions">
        {t(`errorPage.suggestedActions`)}
        <ul className="list">
          <li>{t(`errorPage.firstSuggestedAction`)}</li>
          <li>{t(`errorPage.secondSuggestedAction`)}</li>
        </ul>
      </h3>
      <Button className="button" kind="secondary" onClick={goBackToPreviousPage}>
        {t('errorPage.buttonText')}
      </Button>
    </div>
  );
}

export default ErrorPage;
