import { getPractitionerDetails } from '../data-access/practitioner/slice';
import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';

const usePractitionerDetails = () => {
  const dispatch = useAppDispatch();

  const practitionerDetails = useAppSelector((state) => state.practitioners.selectedPractitionerDetails);

  const isLoadingPractitionerDetails = useAppSelector((state) => state.practitioners.isLoadingPractitionerDetails);

  const getSelectedPractitionerDetails = (id: string) => {
    dispatch(getPractitionerDetails(id));
  };

  return {
    practitionerDetails,
    isLoadingPractitionerDetails,
    getSelectedPractitionerDetails,
  };
};

export default usePractitionerDetails;
