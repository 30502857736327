import {
  blueServicesConfig,
  dataModifyLogsConfig,
  domain,
  practitionerConfig,
  projectConfig,
} from '../../../config/config';
import {
  call,
  CallEffect,
  put as putEffect,
  PutEffect,
  takeLatest,
  delay,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { deleteMethod, get, post, put as update } from '../state-configurations/base-api';
import {
  getFilteredProjects,
  getFilteredProjectsFailed,
  getFilteredProjectsSuccess,
  createProject,
  createProjectFailed,
  createProjectSuccess,
  createProjectReset,
  getUpdateProject,
  getUpdateProjectFailed,
  getProjectsClientDataSuccess,
  getProjectsClientDataFailed,
  getProjectsClientData,
  getProjectAssignmentsSuccess,
  getProjectAssignmentsFailed,
  getProjectAssignments,
  getProjectsIdNameDataFailed,
  getProjectsIdNameDataSuccess,
  getProjectsIdNameData,
  setDeleteProject,
  setDeleteProjectSuccess,
  setDeleteProjectFailed,
  getAuditLogsData,
  getAuditLogsSuccess,
  getAuditLogsFailed,
  removeSelectedProjectFromArray,
  loadProject,
  loadProjectSuccess,
  loadProjectFailed,
  singleFieldUpdate,
  singleFieldUpdateSuccess,
  singleFieldUpdateFailed,
  getLastAssignmentEndDate,
  getLastAssignmentEndDateSuccess,
  getLastAssignmentEndDateFailed,
  getLastAssignmentEndDateMulti,
  getLastAssignmentEndDateMultiSuccess,
  getLastAssignmentEndDateMultiFailed
} from './slice';
import { addNotification } from '../notifications/slice';
import i18next from 'i18next';
import common_en from '../../../assets/locales/en/translation.json';
import {
  AuditActionType,
  AuditEntityType,
  ChangedFieldsIndexes,
  compareOldAndUpdatedObjects,
  EmailContentResponse,
  ProjectAssignments,
  ProjectClientData,
  ProjectCreateEditPayloadInterface,
  ProjectEditPayloadInterface,
  ProjectIdNameData,
  ProjectReduceType,
  ProjectSummaryInterface,
  SingleProjectResponse,
} from '@cic-boardlite/common';
import { AnyAction } from '@reduxjs/toolkit';
import { LogsAndLogsCountInterface } from '../../interfaces/audit-interfaces';
import { setAuditLogData } from '../audit-logs/slice';
import { getGeoPmNamesTalentIds, getGeoPmNamesTalentIdsSuccess, getGeoPmNamesTalentIdsFailed } from './slice';
import { UpdateSingleFieldInterface } from '../../interfaces/project-interfaces';
import { Project, ProjectAssignmentReduced, ProjectLogReduced } from '../../interfaces/project-interfaces';

export function* handleGetFilteredProjects(action: {
  type: string;
  payload: string;
}): Generator<CallEffect<ProjectSummaryInterface[]> | PutEffect<AnyAction>, void, ProjectSummaryInterface[]> {
  try {
    const path = `${domain}/projects/${action.payload}`;
    const responseFilteredProjects = yield call(() => get<ProjectSummaryInterface[]>(path));

    yield putEffect(getFilteredProjectsSuccess(responseFilteredProjects));
  } catch (error) {
    console.log('Project saga error: ', error);
    yield putEffect(getFilteredProjectsFailed());
  }
}

export function* handleGetProject(action: {
  type: string;
  payload: {
    id: string;
    type: ProjectReduceType;
  };
}): Generator<
  CallEffect<Project | ProjectAssignmentReduced | ProjectLogReduced> | PutEffect<AnyAction>,
  void,
  Project | ProjectAssignmentReduced | ProjectLogReduced
> {
  try {
    const { id, type } = action.payload;
    const { getProject } = projectConfig;
    const path = `${getProject}/${id}${type ? `/${type}` : ''}`;

    const responseProject = yield call(() =>
      get<SingleProjectResponse | ProjectAssignmentReduced | ProjectLogReduced>(path)
    );

    yield putEffect(loadProjectSuccess(responseProject));
  } catch (error) {
    console.log('Project saga error: ', error);
    yield putEffect(loadProjectFailed());
  }
}

export function* handleGetProjectAssignments(action: {
  type: string;
  payload: string;
}): Generator<CallEffect<ProjectAssignments[]> | PutEffect<AnyAction> | SelectEffect, void, ProjectAssignments[]> {
  const showOnboardedOnly = yield select((state) => state.commonStates.showOnlyOnboardedAssignments);
  try {
    const { getProjectAssignments } = projectConfig;
    let path = getProjectAssignments + `${action.payload}`;
    if (showOnboardedOnly) {
      path += `&onboardedOnly=true`;
    }

    const responseProjectAssignments = yield call(() => get<ProjectAssignments[]>(path));
    yield putEffect(getProjectAssignmentsSuccess(responseProjectAssignments));
  } catch (error) {
    console.log('Project saga error: ', error);
    yield putEffect(getProjectAssignmentsFailed());
  }
}

export function* handleCreateProject(action: {
  type: string;
  payload: ProjectCreateEditPayloadInterface;
}): Generator<CallEffect<ProjectCreateEditPayloadInterface> | PutEffect, void, boolean | EmailContentResponse> {
  try {
    const path = `${domain}/project-service/create`;
    const { sendEmail } = blueServicesConfig;
    const decodedUrl = decodeURIComponent(window.location.href);

    const responseCreateProject = yield call(() => post<ProjectCreateEditPayloadInterface>(path, action?.payload));

    if (responseCreateProject) {
      const projectsFilter = decodedUrl.split('projects')[1];

      yield putEffect(
        addNotification({
          kind: 'success',
          title: i18next.t(common_en.createProject.projectCreationSuccess),
        })
      );
      yield putEffect(createProjectSuccess());
      yield putEffect({
        type: getFilteredProjects.type,
        payload: projectsFilter,
      });

      if (typeof responseCreateProject !== 'boolean') {
        try {
          yield call(() => post<ProjectCreateEditPayloadInterface>(sendEmail, responseCreateProject));

          yield putEffect(
            addNotification({
              kind: 'success',
              title: i18next.t(common_en.createProject.emailSentSuccess),
            })
          );

          yield putEffect(
            setAuditLogData({
              actionType: AuditActionType.Email,
              entityType: AuditEntityType.Project,
              projectId: responseCreateProject.id,
              afterReadable: {
                emailTo: responseCreateProject.emailContent.emailTo,
                emailCc: responseCreateProject.emailContent.emailCc,
                emailType: i18next.t(common_en.extraEmailTemplates.projectCreation),
              },
            })
          );
        } catch (error) {
          yield putEffect(
            addNotification({
              kind: 'error',
              title: i18next.t(common_en.createProject.emailSentFailed),
            })
          );
        }
      }
    }
  } catch (error: any) {
    console.log('Project saga error: ', error);
    yield putEffect(createProjectFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.createProject.projectCreationError),
      })
    );
  }
}

export function* resetCreatedProject() {
  yield putEffect(createProjectReset());
}

export function* handleUpdateProject(action: {
  type: string;
  payload: {
    oldData: ProjectCreateEditPayloadInterface;
    newData: ProjectCreateEditPayloadInterface;
  };
}): Generator {
  try {
    const path = `${domain}/project-service/update`;

    const changedFields = compareOldAndUpdatedObjects<ProjectCreateEditPayloadInterface>(
      action.payload.oldData,
      action.payload.newData,
      ['id']
    );

    yield call(update, path, {
      oldData: changedFields[ChangedFieldsIndexes.OldData],
      newData: changedFields[ChangedFieldsIndexes.NewData],
    });

    yield putEffect(
      addNotification({
        kind: 'success',
        title: i18next.t(common_en.editProject.projectUpdateSuccess),
      })
    );

    yield putEffect({
      type: getProjectsIdNameData.type,
    });

    yield putEffect({ type: loadProject.type, payload: { id: action.payload.newData.id, type: undefined } });
  } catch (error: any) {
    console.log('Project saga error: ', error);
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.editProject.projectUpdateFailed),
      })
    );
    yield putEffect(getUpdateProjectFailed());
  }

  yield putEffect({
    type: getFilteredProjects.type,
    payload: `?id=${action.payload.newData.id}`,
  });
}

export function* handleGetProjectClientData(): Generator<
  CallEffect<ProjectClientData[]> | PutEffect<AnyAction>,
  void,
  ProjectClientData[]
> {
  try {
    const path = `${domain}/projects/client-data`;
    const responseDynamicData = yield call(() => get<ProjectClientData[]>(path));
    yield putEffect(getProjectsClientDataSuccess(responseDynamicData));
  } catch (error) {
    console.log(error);
    yield putEffect(getProjectsClientDataFailed());
  }
}

export function* handleGetProjectsIdNameData(): Generator<
  CallEffect<ProjectIdNameData[]> | PutEffect<AnyAction>,
  void,
  ProjectIdNameData[]
> {
  try {
    const path = `${domain}/projects/projects-id-names-data`;
    const responseProjectIdNameData = yield call(() => get<ProjectIdNameData[]>(path));
    yield putEffect(getProjectsIdNameDataSuccess(responseProjectIdNameData));
  } catch (error) {
    console.log(error);
    yield putEffect(getProjectsIdNameDataFailed());
  }
}

export function* handleDeleteProject(action: { type: string; payload: ProjectEditPayloadInterface['id'] }): Generator {
  try {
    if (yield call(() => deleteMethod(`${domain}/project-service/delete/${action.payload}`))) {
      yield putEffect(
        addNotification({
          kind: 'success',
          title: i18next.t(common_en.deleteProject.deleteSuccess),
        })
      );

      const decodedUrl = decodeURIComponent(window.location.href);
      const projectsFilter = decodedUrl.split('projects')[1];

      yield putEffect(removeSelectedProjectFromArray(action.payload.toString()));
      yield putEffect(setDeleteProjectSuccess(action.payload));
      yield putEffect({
        type: getFilteredProjects.type,
        payload: projectsFilter,
      });
    }
  } catch (error: any) {
    console.error('Project delete saga error: ', error);

    const errorMessage =
      error.error.errorLangCode === 'hasAssignments'
        ? common_en.deleteProject.hasAssignments
        : common_en.deleteProject.deleteError;

    yield putEffect(
      addNotification({
        kind: 'error',
        title: i18next.t(errorMessage),
      })
    );
    yield putEffect(setDeleteProjectFailed());
  }
}

export function* handleGetGeoPmNamesTalentIds(): Generator<
  CallEffect<string[]> | PutEffect<AnyAction>,
  void,
  { name: string; talentId: string | null }[]
> {
  try {
    const { geoPmNamesTalentIds } = practitionerConfig;

    const responseGeoPmNames = yield call(() => get<string[]>(geoPmNamesTalentIds));

    if (responseGeoPmNames) {
      yield putEffect(getGeoPmNamesTalentIdsSuccess(responseGeoPmNames));
    }
  } catch (error) {
    console.log('Practitioner saga error: ', error);
    yield putEffect(getGeoPmNamesTalentIdsFailed());
  }
}

export function* handleGetAuditLogs(action: {
  type: string;
  payload: {
    id: string;
    page: number;
    itemsPerPage: number;
    filterString?: string;
  };
}): Generator<CallEffect<LogsAndLogsCountInterface> | PutEffect<AnyAction>, void, LogsAndLogsCountInterface> {
  try {
    const { getLogs } = dataModifyLogsConfig;
    let path = `${getLogs}?projectId=${action.payload.id}&page=${action.payload.page}&itemsPerPage=${action.payload.itemsPerPage}`;
    if (action.payload?.filterString?.length) path += `&filterString=${action.payload?.filterString}`;

    yield delay(300);
    const responseLogs = yield call(() => get<LogsAndLogsCountInterface>(path));

    yield putEffect(getAuditLogsSuccess(responseLogs));
  } catch (error) {
    console.log(error);
    yield putEffect(getAuditLogsFailed());
  }
}

export function* handleSingleFieldUpdate(action: {
  type: string;
  payload: {
    oldData: UpdateSingleFieldInterface;
    newData: UpdateSingleFieldInterface;
  };
}): Generator<CallEffect<boolean> | PutEffect<AnyAction>, void, LogsAndLogsCountInterface> {
  try {
    const { singleFieldUpdate } = projectConfig;
    const path = singleFieldUpdate;
    const decodedUrl = decodeURIComponent(window.location.href);
    const projectsFilter = decodedUrl.split('projects')[1];
    const response = yield call(() => update<boolean>(path, action.payload));

    if (!response) {
      yield putEffect(
        addNotification({
          kind: 'error',
          title: i18next.t(common_en.editProject.projectUpdateFailed),
        })
      );
      return;
    }

    yield putEffect(singleFieldUpdateSuccess());
    yield putEffect(
      addNotification({
        kind: 'success',
        title: i18next.t(common_en.editProject.projectUpdateSuccess),
      })
    );
    yield putEffect({
      type: getFilteredProjects.type,
      payload: projectsFilter,
    });
  } catch (error: any) {
    yield putEffect(singleFieldUpdateFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.editProject.projectUpdateFailed),
      })
    );
  }
}

export function* handleGetAssignmentEndDate(action: {
  type: string;
  payload: string
}): Generator<CallEffect<string> | PutEffect<AnyAction>, void, {endDate: string}> {
  try {
    const { lastAssignmentEndDate } = projectConfig;
    const path = lastAssignmentEndDate + action.payload;
    const response = yield call(() => get<string>(path));
    yield putEffect(getLastAssignmentEndDateSuccess(response.endDate))
  } catch (error: any) {
    console.log(error)
    yield putEffect(getLastAssignmentEndDateFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.editProject.projectLatestAssignmentEndDateFailed),
      })
    );
  }
}

export function* handleGetMultiAssignmentEndDate(action: {
  type: string;
  payload: string[]
}): Generator<CallEffect<string> | PutEffect<AnyAction>, void, {endDate: string}> {
  try {
    const dates: {id: string, date: string}[] = [];

    for(const id of action.payload) {
      const { lastAssignmentEndDate } = projectConfig;
      const path = lastAssignmentEndDate + id;
      const response = yield call(() => get<string>(path));
      dates.push({id: id, date: response.endDate});
    }

    yield putEffect(getLastAssignmentEndDateMultiSuccess(dates))
  } catch (error: any) {
    console.log(error)
    yield putEffect(getLastAssignmentEndDateMultiFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.editProject.projectLatestAssignmentEndDateFailed),
      })
    );
  }
}

export function* filteredProjectSaga() {
  yield takeLatest(getFilteredProjects.type, handleGetFilteredProjects);
  yield takeLatest(loadProject.type, handleGetProject);
  yield takeLatest(getProjectAssignments.type, handleGetProjectAssignments);
  yield takeLatest(createProject.type, handleCreateProject);
  yield takeLatest(createProject.type, resetCreatedProject);
  yield takeLatest(getUpdateProject.type, handleUpdateProject);
  yield takeLatest(setDeleteProject.type, handleDeleteProject);
  yield takeLatest(getGeoPmNamesTalentIds.type, handleGetGeoPmNamesTalentIds);
  yield takeLatest(getProjectsClientData.type, handleGetProjectClientData);
  yield takeLatest(getProjectsIdNameData.type, handleGetProjectsIdNameData);
  yield takeLatest(getAuditLogsData.type, handleGetAuditLogs);
  yield takeLatest(singleFieldUpdate.type, handleSingleFieldUpdate);
  yield takeLatest(getLastAssignmentEndDate.type, handleGetAssignmentEndDate);
  yield takeLatest(getLastAssignmentEndDateMulti.type, handleGetMultiAssignmentEndDate);
}
