import { call, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  setAuditLogData,
  setAuditLogDataFailed,
  setAuditLogDataSuccess,
} from './slice';
import { post } from '../state-configurations/base-api';
import { put } from 'redux-saga/effects';
import { dataModifyLogsConfig } from '../../../config/config';
import { DataModifyLogValues } from '@cic-boardlite/common';

export function* handleSetAuditLogData(action: {
  type: string;
  payload: DataModifyLogValues;
}): Generator<
  CallEffect<DataModifyLogValues> | PutEffect,
  void,
  DataModifyLogValues
> {
  const response = yield call(() =>
    post<DataModifyLogValues>(dataModifyLogsConfig.setLog, action.payload)
  );

  yield put(response ? setAuditLogDataSuccess() : setAuditLogDataFailed());
}

export function* auditLogsSaga() {
  yield takeLatest(setAuditLogData.type, handleSetAuditLogData);
}
