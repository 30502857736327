import { ImportDataPayloadInterface } from '@cic-boardlite/common';
import {
  getExportPractitionersSummary,
  getExportProjectsSummary,
  getExportPractitionersAssignmentsSummary,
  getExportProjectsAssignmentsSummary,
  getExportFile,
  getImportFile,
} from '../data-access/file-import-export/slice';
import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';

const useFileImportExport = () => {
  const dispatch = useAppDispatch();

  const exportFile = (fileType: string) => {
    dispatch(getExportFile(fileType));
  };

  const importFile = (data: ImportDataPayloadInterface) => {
    dispatch(getImportFile(data));
  };

  const isLoadingImportFile = useAppSelector((state) => state.fileImportExport.isLoadingImportFile);

  const selectedPractitioners = useAppSelector((state) => state.practitioners.selectedPractitioner);

  const singlePractitionerData = useAppSelector((state) => state.practitioners.selectedPractitionerDetails);

  const filteredProjects = useAppSelector((state) => state.projects.filteredProjectData);

  const selectedProjects = useAppSelector((state) => state.projects.selectedProjectsArray);

  const singleProjectAssignments = useAppSelector((state) => state.projects.projectAssignmentsData);

  const exportSelectedPractitioners = () => {
    const ids = selectedPractitioners.map((practitioner) => practitioner.id);
    dispatch(getExportPractitionersSummary(ids));
  };

  const exportAllPractitioners = () => {
    dispatch(getExportPractitionersSummary([]));
  };

  const exportOpenedPractitionerAllAssignments = () => {
    dispatch(getExportPractitionersAssignmentsSummary([singlePractitionerData.id]));
  };

  const exportAllPractitionersAssignments = () => {
    dispatch(getExportPractitionersAssignmentsSummary([]));
  };

  const exportSelectedPractitionersAssignments = () => {
    const selectedPractitionersIds = selectedPractitioners.map((practitioner) => practitioner.id);
    dispatch(getExportPractitionersAssignmentsSummary(selectedPractitionersIds));
  };

  const exportSelectedProjects = () => {
    const selectedProjectsIds = selectedProjects.map((project) => project.id);
    dispatch(getExportProjectsSummary(selectedProjectsIds));
  };

  const exportAllProjects = () => {
    dispatch(getExportProjectsSummary([]));
  };

  const exportSelectedProjectsAssignments = () => {
    const selectedProjectsIds = selectedProjects.map((project) => project.id);
    dispatch(getExportProjectsAssignmentsSummary({ projectIds: selectedProjectsIds, assignmentIds: [] }));
  };

  const exportOpenedProjectAllAssignments = () => {
    dispatch(getExportProjectsAssignmentsSummary({ projectIds: [filteredProjects[0].id], assignmentIds: [] }));
  };

  const exportOpenedProjectSelectedAssignments = () => {
    const assignmentIds = singleProjectAssignments.map((assignment) => assignment.id);
    dispatch(
      getExportProjectsAssignmentsSummary({ projectIds: [filteredProjects[0].id], assignmentIds: assignmentIds })
    );
  };

  const exportAllProjectsAssignments = () => {
    dispatch(getExportProjectsAssignmentsSummary({ projectIds: [], assignmentIds: [] }));
  };

  const isExportingPractitionersAssignmentsSummary = useAppSelector(
    (state) => state.fileImportExport.isLoadingExportPractitionersAssignmentsSummary
  );

  const isExportingProjectsAssignmentsSummary = useAppSelector(
    (state) => state.fileImportExport.isLoadingExportProjectsAssignmentsSummary
  );

  return {
    exportFile,
    importFile,
    isLoadingImportFile,
    exportSelectedPractitioners,
    exportAllPractitioners,
    exportOpenedPractitionerAllAssignments,
    exportAllProjects,
    exportOpenedProjectAllAssignments,
    exportAllPractitionersAssignments,
    exportSelectedPractitionersAssignments,
    exportAllProjectsAssignments,
    exportSelectedProjectsAssignments,
    exportSelectedProjects,
    exportOpenedProjectSelectedAssignments,
    isExportingPractitionersAssignmentsSummary,
    isExportingProjectsAssignmentsSummary,
  };
};

export default useFileImportExport;
