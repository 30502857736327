import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import { getStaticData } from '../data-access/static-data/slice';

const useStaticData = () => {
  const dispatch = useAppDispatch();

  const isStaticDataSet = useAppSelector((state) => state.staticData.isStaticDataSet);

  const loadStaticData = () => {
    if (!isStaticDataSet) dispatch(getStaticData());
  };

  const staticData = useAppSelector((state) => {
    return state.staticData;
  });

  return { isStaticDataSet, staticData, loadStaticData };
};

export default useStaticData;
