import { ArrowRight } from '@carbon/icons-react/next';
import { convertDate } from '@cic-boardlite/common';
import { Modal } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { usePractitionerAssignments, useSavePractitioner } from '../../custom-hooks';
import './update-availability-date-modal.scss';

interface PropsInterface {
  editingFromProfilePage: boolean;
  editingFromPractitionersTable: boolean;
}

const UpdateAvailabilityDateModal = ({ editingFromProfilePage, editingFromPractitionersTable }: PropsInterface) => {
  const {
    isUpdateAvailabilityDateModalOpen,
    closeUpdateAvailabilityDateModal,
    updateAvailabilityDateModalInformation,
  } = usePractitionerAssignments();

  const { editPractitioner } = useSavePractitioner();
  const { t } = useTranslation('common');

  const checkIfDateIsValid = (date: Date) => {
    return date?.toString() !== 'Invalid Date';
  };

  const displayDateInModal = (date: Date) => {
    return checkIfDateIsValid(date) ? convertDate(date) : t('availabilityDateUpdate.noInformation');
  };

  const assignmentUpdatedAndManualAvailabilityFalse =
    !editingFromPractitionersTable &&
    !editingFromProfilePage &&
    !updateAvailabilityDateModalInformation?.oldAvailabilityDateManuallyEdited;

  const handleSubmit = () => {
    const oldPractitionerData = {
      id: Number(updateAvailabilityDateModalInformation.practitionerId),
      availabilityDate: checkIfDateIsValid(updateAvailabilityDateModalInformation?.availabilityDate)
        ? new Date(updateAvailabilityDateModalInformation.availabilityDate)
        : null,
      availabilityDateManuallyEdited: updateAvailabilityDateModalInformation.oldAvailabilityDateManuallyEdited,
    };

    const updatedPractitionerData = {
      id: Number(updateAvailabilityDateModalInformation.practitionerId),
      availabilityDate: checkIfDateIsValid(updateAvailabilityDateModalInformation?.newAvailabilityDate)
        ? new Date(updateAvailabilityDateModalInformation.newAvailabilityDate)
        : null,
      availabilityDateManuallyEdited: checkIfDateIsValid(updateAvailabilityDateModalInformation?.newAvailabilityDate)
        ? false
        : true,
    };

    editPractitioner(
      oldPractitionerData,
      updatedPractitionerData,
      editingFromProfilePage,
      editingFromPractitionersTable
    );
    closeUpdateAvailabilityDateModal();
  };

  const handleRequestClose = () => {
    closeUpdateAvailabilityDateModal();
    if (assignmentUpdatedAndManualAvailabilityFalse) {
      const oldPractitionerData = {
        id: Number(updateAvailabilityDateModalInformation.practitionerId),
        availabilityDateManuallyEdited: updateAvailabilityDateModalInformation.oldAvailabilityDateManuallyEdited,
      };

      const updatedPractitionerData = {
        id: Number(updateAvailabilityDateModalInformation.practitionerId),
        availabilityDateManuallyEdited: true,
      };

      editPractitioner(
        oldPractitionerData,
        updatedPractitionerData,
        editingFromProfilePage,
        editingFromPractitionersTable
      );
    }
  };

  return (
    <Modal
      open={isUpdateAvailabilityDateModalOpen}
      modalHeading={t('availabilityDateUpdate.modalHeading')}
      primaryButtonText={t('availabilityDateUpdate.update')}
      secondaryButtonText={t('availabilityDateUpdate.cancel')}
      onRequestSubmit={handleSubmit}
      onRequestClose={handleRequestClose}
    >
      {updateAvailabilityDateModalInformation.message}
      <br />
      {t('availabilityDateUpdate.updateDates')}
      <div className="create-edit-assignment-update-availability-container">
        <div>{t('availabilityDateUpdate.currentDate')}</div>
        <div></div>
        <div>{t('availabilityDateUpdate.newDate')}</div>
        <div className="current-availability-date">
          {displayDateInModal(updateAvailabilityDateModalInformation?.availabilityDate)}
        </div>
        <ArrowRight />
        <div className="new-availability-date">
          {displayDateInModal(updateAvailabilityDateModalInformation?.newAvailabilityDate)}
        </div>
      </div>
      {assignmentUpdatedAndManualAvailabilityFalse && (
        <div className="create-edit-assignment-update-availability-cancel-message">
          {t('availabilityDateUpdate.cancelDisclaimer')}
        </div>
      )}
    </Modal>
  );
};

export default UpdateAvailabilityDateModal;
