import { FileUploaderItem } from 'carbon-components-react';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import './file-import-export.scss';

interface PropsInterface {
  setFileErrorMessage: Dispatch<SetStateAction<string>>;
  readFile: (event: ChangeEvent<HTMLInputElement>, fileType: string) => void;
  uploadedFile: File | null;
  fileErrorMessage: string;
  title: string;
  fileType: string;
  clearFileAndCollection: () => void;
}

const FileImportDropbox = ({
  setFileErrorMessage,
  readFile,
  uploadedFile,
  fileErrorMessage,
  title,
  fileType,
  clearFileAndCollection,
}: PropsInterface) => {
  const { t } = useTranslation('common');
  return (
    <div className="bx--form-item file-import-box">
      <strong className="bx--file--label">{title}</strong>
      <p className="bx--label-description">
        {t('fileImportExport.allowedFileTypes')}
      </p>
      <div className="bx--file" data-file data-file-demo-state-manager>
        <label className="bx--file-browse-btn import-dropbox">
          <div data-file-drop-container className="bx--file__drop-container">
            {t('fileImportExport.dragAndDrop')}
            <input
              accept=".xlsx, .xls"
              type="file"
              className="bx--file-input"
              multiple={false}
              onChange={(event) => {
                setFileErrorMessage('');
                readFile(event, fileType);
                event.target.value = '';
              }}
            />
          </div>
        </label>
        {uploadedFile && (
          <div className="import-file-uploader-item">
            <FileUploaderItem
              name={uploadedFile.name}
              status="edit"
              onDelete={clearFileAndCollection}
              errorBody={fileErrorMessage}
              errorSubject={t('fileImportExport.errorSubject')}
              invalid={!!fileErrorMessage.length}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FileImportDropbox;
