import { PageType } from '@cic-boardlite/common';

const defaultPractitionersTabContext = {
  jrs: [],
  band: [],
  role: [],
  isCic: {
    label: 'Is CIC',
    value: 'true',
  },
  isPMO: null,
  isPeM: null,
  serial: '',
  country: [],
  pemInfo: null,
  emfStatus: [],
  department: [],
  terminated: {
    label: 'Not terminated',
    value: 'false',
  },
  serviceArea: '',
  serviceLine: [],
  contractType: [],
  nameFirstLast: '',
  availabilityInfo: '',
  availabilityDateEnd: '',
  actionRequiredStatus: [],
  availabilityDateStart: '',
  availabilityDateManuallyEdited: null,
};

const practitionerActionRequiredTabContext = {
  terminated: { label: 'Not terminated', value: 'false' },
  actionRequired: 'true',
};

export const defaultProjectsTabContext = {
  pmo: '',
  name: '',
  geoPm: '',
  country: [],
  created: '',
  projectId: '',
  activeFrom: '',
  clientName: '',
  activeUntil: '',
  projectType: null,
  serviceLine: '',
  projectStatus: {
    id: 1,
    title: 'Active',
  },
  projectAccountIds: '',
  actionRequiredStatus: '',
};

export const practitionerDefaultTab = {
  id: 1,
  title: 'Filter',
  tabPosition: 1,
  context: defaultPractitionersTabContext,
  numberOfFilteredPractitioners: 0,
  pageType: PageType.Practitioner,
};

export const projectDefaultTab = {
  id: 1,
  title: 'Filter',
  tabPosition: 1,
  context: defaultProjectsTabContext,
  numberOfFilteredPractitioners: 0,
  pageType: PageType.Project,
};

export const practitionerActionRequiredFilter = {
  id: 0,
  title: 'Action Required',
  tabPosition: 0,
  context: practitionerActionRequiredTabContext,
  numberOfFilteredPractitioners: 0,
  pageType: PageType.Practitioner,
};

export const projectActionRequiredFilter = {
  id: 0,
  title: 'Action required',
  tabPosition: 0,
  context: {},
  numberOfFilteredPractitioners: 0,
  pageType: PageType.Project,
};
