import SendEmailTypeDropdown from './send-email-dropdown/send-email-types-dropdown-component';
import SendEmailSubject from './send-email-subject';
import { SendEmailComponentInterface } from '@cic-boardlite/common';
import SendEmailEditor from './send-email-editor/send-email-editor';
import { EmailsField } from '../emails-field/emails-field';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import './send-email-dialog.scss';
import { Checkbox } from 'carbon-components-react';

const SendEmailComp = (props: SendEmailComponentInterface) => {
  const { t } = useTranslation('common');
  const { control } = useFormContext();

  return (
    <div className="cds--grid--full-width">
      <div className="cds--row send-email-row">
        <div className="cds--col-lg-8">
          <h3>{t(`sendEmailModal.title`)}</h3>
        </div>
        <div className="cds--col-lg-5">
          <SendEmailTypeDropdown
            defaultValues={props.defaultValues}
            practitionerDetails={props.practitionerDetails}
            assignmentDetails={props.assignmentDetails}
            modalIsOpen={props.modalIsOpen}
          />
        </div>
      </div>
      <div className="cds--row send-email-row">
        <div className="cds--col-lg-16 assignment-email-cc-wrapper">
          <Controller
            name="ccEmail"
            control={control}
            rules={{
              validate: (value) => !!value.length || t('sendEmailModal.emptyCcError'),
            }}
            render={() => (
              <EmailsField
                emailBoxName={'ccEmail'}
                textAreaLabelLangKey={'ccEmail.label'}
                textAreaPlaceholderLangKey={'ccEmail.placeholder'}
                initialEmails={'initialEmails'}
              />
            )}
          />
        </div>
      </div>
      <div className="cds--row send-email-row send-email__AssignmentEmailBcc">
        <div className="cds--col-lg-16">
          <Checkbox
            id="AssignmentEmailBccCheckbox"
            labelText={t(`sendEmailModal.emailBcc`)}
            checked={props.isBccChecked}
            onChange={props.onBccChecked}
          />
        </div>
      </div>
      <div className="cds--row send-email-row">
        <div className="cds--col-lg-16">
          <SendEmailSubject defaultValues={props.defaultValues} />
        </div>
      </div>

      <div className="cds--row send-email-row">
        <div className="cds--col-lg-16">
          <SendEmailEditor />
        </div>
      </div>
    </div>
  );
};

export default SendEmailComp;
