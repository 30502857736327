import * as XLSX from 'xlsx';
import {
  ExportDataResponseInterface,
  practitionerAssignmentFileName,
  practitionerAssignmentsHeaders,
  practitionerFileName,
  practitionerHeaders,
  projectAssignmentFileName,
  projectAssignmentHeaders,
  projectFileName,
  projectHeaders,
  PractitionerExportSummary,
  PractitionerAssignmentExportSummary,
  ProjectExportSummary,
  ProjectAssignmentExportSummary,
} from '../../export-file';

export const exportAsExcelFile = (data: ExportDataResponseInterface): void => {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.json);
  XLSX.utils.sheet_add_aoa(worksheet, [data.headers], { origin: 'A1' });

  const workbook: XLSX.WorkBook = {
    Sheets: { data: worksheet },
    SheetNames: ['data'],
  };
  XLSX.writeFile(workbook, `${data.excelFileName}.xlsx`);
};

export const exportPractitioners = (practitionersSummary: PractitionerExportSummary[]) => {
  exportAsExcelFile({
    json: practitionersSummary,
    excelFileName: practitionerFileName,
    headers: practitionerHeaders,
  });
};

export const exportPractitionersAssignments = (
  practitionersAssignmentsSummary: PractitionerAssignmentExportSummary[]
) => {
  exportAsExcelFile({
    json: practitionersAssignmentsSummary,
    excelFileName: practitionerAssignmentFileName,
    headers: practitionerAssignmentsHeaders,
  });
};

export const exportProjects = (projectsSummary: ProjectExportSummary[]) => {
  exportAsExcelFile({
    json: projectsSummary,
    excelFileName: projectFileName,
    headers: projectHeaders,
  });
};

export const exportProjectsAssignments = (projectsAssignmentsSummary: ProjectAssignmentExportSummary[]) => {
  exportAsExcelFile({
    json: projectsAssignmentsSummary,
    excelFileName: projectAssignmentFileName,
    headers: projectAssignmentHeaders,
  });
};
