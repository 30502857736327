import { useTranslation } from 'react-i18next';
import { AssignmentSummaryInterface, DeletableObjectType } from '@cic-boardlite/common';
import { useParams } from 'react-router-dom';
import { whenAssignmentIsEnding } from '@cic-boardlite/common';
import { Email16, TrashCan16 } from '@carbon/icons-react';
import { Edit } from '@carbon/icons-react/next';
import { useCommonState } from '../../../custom-hooks';

const PractitionerAssignmentsTableExpandedRow = ({
  assignmentDetails,
}: {
  assignmentDetails: AssignmentSummaryInterface;
}) => {
  const { t } = useTranslation('common');
  const { id } = useParams<{ id: string }>();

  const { toggleDeleteModal, toggleAssignmentCreateEditModal, toggleSendEmailModal } = useCommonState();

  function toggleAssignmentCreateEdit() {
    toggleAssignmentCreateEditModal({
      isOpen: true,
      assignmentInfo: assignmentDetails,
      editingAssignment: true,
    });
  }
  function toggleSendEmailDialog() {
    toggleSendEmailModal({
      isOpen: true,
      assignmentDetails: assignmentDetails,
    });
  }

  const handleDeleteModal = () => {
    toggleDeleteModal({
      deleteObject: {
        id: assignmentDetails.id,
        practitionerId: Number(id),
      },
      deletableObjectType: DeletableObjectType.practitionerAssignment,
    });
  };

  return (
    <div className="element-box">
      <div className="edit-and-delete">
        <div className="cds--grid"></div>
        <div className="cds--row">
          <div className="cds--col">
            <div className="send-email-icon">
              <Email16 onClick={toggleSendEmailDialog} />
            </div>
          </div>
          <div className="cds--col">
            <TrashCan16 className="cursor-pointer" onClick={handleDeleteModal} />
          </div>
          <div className="cds--col">
            <Edit className="edit-assignment-button" id="editAssignmentButton" onClick={toggleAssignmentCreateEdit} />
          </div>
        </div>
      </div>
      <div className="edit-and-delete"></div>
      <div className="cds--grid">
        <div className="cds--row" style={{ width: 'auto' }}>
          <div className="cds--col">
            <p className="expanded-assignments-table-font-size">
              {t(`expandedAssignmentsTable.projectId`)}
              <span className="bold">{assignmentDetails.projectId}</span>
            </p>
            <p className="expanded-assignments-table-font-size">
              {t(`expandedAssignmentsTable.accountIds`)}
              <span className="bold">{assignmentDetails.accountIds}</span>
            </p>
            <p className="expanded-assignments-table-font-size">
              {t(`expandedAssignmentsTable.startDate`)}
              <span className="bold">{assignmentDetails.startDate}</span>
            </p>
            <p className="expanded-assignments-table-font-size">
              {t(`expandedAssignmentsTable.endDate`)}
              <span className={`bold ${whenAssignmentIsEnding(assignmentDetails.endDate)}`}>
                {assignmentDetails.endDate}
              </span>
            </p>
          </div>
          <div className="cds--col">
            <p className="expanded-assignments-table-font-size">
              {t(`expandedAssignmentsTable.pmpSeat`)}
              <span className="bold">{assignmentDetails.pmpSeat}</span>
            </p>
            <p className="expanded-assignments-table-font-size">
              {t(`expandedAssignmentsTable.uwhStartDate`)}
              <span className="bold">{assignmentDetails.uwhStart}</span>
            </p>
            <p className="expanded-assignments-table-font-size">
              {t(`expandedAssignmentsTable.uwhEndDate`)}
              <span className="bold">{assignmentDetails.uwhEnd}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PractitionerAssignmentsTableExpandedRow;
