import { Role } from './roles.enum';
import { Scope } from './scopes.enum';

// TODO: Add proper permissions to roles
export const Permission: { [key: string]: string[] } = {
  [Role.ADMIN]: [
    Scope.CREATE_PRACTITIONER,
    Scope.CREATE_PROJECT,
    Scope.DELETE_PRACTITIONER,
  ],
  [Role.GENERAL]: [Scope.SEND_MAIL, Scope.GET_DEMAND],
  [Role.WMP]: [Scope.GET_LOGS, Scope.LIST_PROJECTS, Scope.SAVE_DEMAND],
  [Role.SERVICE_LINE_MANAGER]: [
    Scope.GET_LOGS,
    Scope.LIST_PROJECTS,
    Scope.SAVE_DEMAND,
  ],
  [Role.PEOPLE_MANAGER]: [
    Scope.GET_LOGS,
    Scope.LIST_PROJECTS,
    Scope.SAVE_DEMAND,
  ],
};
