import HeaderNav from './ui/header-nav/header-nav';
import './app.scss';
import PractitionersSummary from './feature/practitioner/summary/summary';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { useAuthorization } from './custom-hooks/useAuthorization';
import { ToastProvider } from './ui/common/notification/notification';
import ErrorPage from './ui/error-page/error-page';
import PractitionersPage from './feature/practitioner/practitioner-page/page';
import PractitionerDetailPage from './feature/practitioner/practitioner-detail-page/detail-page';
import ProjectsPage from './feature/project/project-page/page';
import ProjectDetailPage from './feature/project/project-detail-page/detail-page';
import ProjectAssignmentPage from './feature/project/project-assignment-page/assignment-page';
import AuditLogs from './feature/audit-logs-page/audit-logs-page';
import PopupBlockedPage from './ui/popup-blocked-page/popup-blocked-page';
import { useEffect } from 'react';
import Login from './feature/login/login';
import { authorizationConfig } from '../config/config';
import Footer from './ui/footer/footer';
import { AuditEntityType, HttpStatus } from '@cic-boardlite/common';
import PractitionerAssignmentPage from './feature/practitioner/practitioner-assignment-page/assignment-page';
import Feedback from './feature/feedback/feedback';
import PrivacyPolicy from './ui/privacy-policy/privacy-policy';

function AuthProvider({ children }: { children: React.ReactNode }) {
  const { tokenExpirationChecker, checkAuthorization } =
    useAuthorization();
  const location = useLocation();
  useEffect(() => {
    const token = localStorage.getItem(authorizationConfig.tokenName);
    if (token) {
      tokenExpirationChecker();
      setTimeout(() => {
        checkAuthorization();
      }, 3000);
    }
  }, [location]);

  return <>{children}</>;
}
const ProtectedRoute = ({ isAuthorized }: { isAuthorized: boolean }) => {
  const { onLoadingLogin } = useAuthorization();
  if (onLoadingLogin) {
    return <Outlet />;
  }
  return isAuthorized ? <Outlet /> : <Navigate to="/login" />;
};

export function App() {

  const { isAuthorized } = useAuthorization();

  return (
    <BrowserRouter>
      <AuthProvider>
        <ToastProvider>
          {isAuthorized ? <HeaderNav /> : <></>}
          <Routes>
            <Route element={<ProtectedRoute isAuthorized={isAuthorized} />}>
              <Route path="/" element={<PractitionersSummary />} />
              <Route path="/practitioners" element={<PractitionersPage />} />
              <Route
                path="/practitioner/:id"
                element={<PractitionerDetailPage />}
              />
              <Route
                path="/practitioner/:id/assignments"
                element={<PractitionerAssignmentPage />}
              />
              <Route
                path="/practitioner/:id/logs"
                element={
                  <AuditLogs pageViewType={AuditEntityType.Practitioner} />
                }
              />
              <Route path="/projects/:id" element={<ProjectDetailPage />} />
              <Route
                path="/projects/:id/assignments"
                element={<ProjectAssignmentPage />}
              />
              <Route
                path="/projects/:id/logs"
                element={<AuditLogs pageViewType={AuditEntityType.Project} />}
              />
              <Route path="/projects" element={<ProjectsPage />} />
            </Route>
            <Route path="/error/:status" element={<ErrorPage />}></Route>
            <Route path="/popup-blocked" element={<PopupBlockedPage />}></Route>
            <Route
              path="/error/:status/:previousLocation"
              element={<ErrorPage />}
            ></Route>
            <Route
              path="/error/:status/:previousLocation/:previousLocation"
              element={<ErrorPage />}
            ></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
            <Route path="*" element={<ErrorPage statusCode={`${HttpStatus.NOT_FOUND}`} />} />
          </Routes>
          {isAuthorized ? <Feedback /> : <></>}
          <Footer />
        </ToastProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
