import { createSlice } from '@reduxjs/toolkit';

export const initialState: {
  isSavingAuditLog: boolean;
} = {
  isSavingAuditLog: false,
};

const auditLogsSlice = createSlice({
  name: 'auditLogs',
  initialState,
  reducers: {
    setAuditLogData(state, action) {
      state.isSavingAuditLog = true;
    },
    setAuditLogDataSuccess(state) {
      state.isSavingAuditLog = false;
    },
    setAuditLogDataFailed(state) {
      state.isSavingAuditLog = false;
    },
  },
});

export const {
  setAuditLogData,
  setAuditLogDataSuccess,
  setAuditLogDataFailed,
} = auditLogsSlice.actions;

export default auditLogsSlice.reducer;
