import { ComboBox } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { SendEmailComponentInterface, EmailContentInterface } from '@cic-boardlite/common';
import { generateEmailContent } from '../send-email-utils/generate-email-template.config';
import { EmailTemplateTypes } from './template-types.config';
import { useBluePages, usePractitioners, usePractitionerDetails, useUser } from '../../../custom-hooks';
import { useEffect, useState } from 'react';

const SendEmailTypeDropdown = (props: SendEmailComponentInterface) => {
  const [geoPmEmail, setGeoPmEmail] = useState<string | undefined>();
  const [pemEmail, setPemEmail] = useState<string>('');
  const [serviceLineLeadEmail, setServiceLineLeadEmail] = useState<string>('');
  const { t } = useTranslation('common');
  const {
    setValue,
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const sendEmailTemplateTypes: string[] = Object.values(EmailTemplateTypes);
  const { practitionerDetails } = usePractitionerDetails();
  const { userInfo } = useUser();
  const { pemWmpServiceLineLeadersEmails } = usePractitioners();
  const { getBluePagesPreferredIdentityEmail, bluePagesPreferredIdentityEmail } = useBluePages();


  const setStatePemEmail = () => {
    if (props.modalIsOpen && practitionerDetails?.peopleManager) {
      const pemInfo = pemWmpServiceLineLeadersEmails?.pemEmails?.find(
        (pemEmailData) => pemEmailData?.nameFirstLast === practitionerDetails.peopleManager
      );
      return pemInfo ? setPemEmail(t('sendEmailModal.pem') + pemInfo?.email) : setPemEmail('');
    }
  };

  const setStateServiceLineLeadEmail = () => {
    if (props.modalIsOpen && practitionerDetails?.serviceLineId) {
      const serviceLineLeadInfo = pemWmpServiceLineLeadersEmails?.serviceLineLeadersEmails?.find(
        (serviceLine) => serviceLine?.serviceLineId === practitionerDetails?.serviceLineId
      );
      return serviceLineLeadInfo
        ? setServiceLineLeadEmail(
            t('sendEmailModal.serviceLineLeader', {
              serviceLine: serviceLineLeadInfo?.serviceLineTitle,
              leaderEmail: serviceLineLeadInfo?.serviceLineLeaderEmail,
            })
          )
        : setServiceLineLeadEmail('');
    }
  };

  useEffect(() => {
    if (props.modalIsOpen) {
      setGeoPmEmail(bluePagesPreferredIdentityEmail && t('sendEmailModal.geoPM') + bluePagesPreferredIdentityEmail);
    }
  }, [props.modalIsOpen, bluePagesPreferredIdentityEmail]);

  useEffect(() => {
    if (props.modalIsOpen) {
      if (props.assignmentDetails?.geoPmTalentId) {
        getBluePagesPreferredIdentityEmail(props.assignmentDetails.geoPmTalentId);
      } else setGeoPmEmail('');

      setStatePemEmail();
      setStateServiceLineLeadEmail();
      handleGenerateEmailContent(EmailTemplateTypes.onboarding);
    }
  }, [props.modalIsOpen, pemWmpServiceLineLeadersEmails]);

  const contentTypeWatch = useWatch({
    control,
    name: 'contentType',
  });

  useEffect(() => {
    const initialEmails: string[] = [];

    switch (contentTypeWatch) {
      case EmailTemplateTypes.onboarding:
      case EmailTemplateTypes.offboarding:
      case EmailTemplateTypes.assignmentDetails:
      case EmailTemplateTypes.assignmentExtension:
        !!geoPmEmail?.length && initialEmails.push(geoPmEmail);
        practitionerDetails?.email && initialEmails.push(t('sendEmailModal.practitioner') + practitionerDetails.email);
        !!pemEmail.length && initialEmails.push(pemEmail);
        break;
      case EmailTemplateTypes.dataRemovalCert:
        practitionerDetails?.email && initialEmails.push(t('sendEmailModal.practitioner') + practitionerDetails.email);
        !!pemEmail.length && initialEmails.push(pemEmail);
        break;
      case EmailTemplateTypes.requestExtension:
        !!geoPmEmail?.length && initialEmails.push(geoPmEmail);
        for (const wmpEmail of pemWmpServiceLineLeadersEmails.wmpEmails) {
          initialEmails.push(t('sendEmailModal.wmp') + wmpEmail);
        }
        !!serviceLineLeadEmail.length && initialEmails.push(serviceLineLeadEmail);
        break;
      default:
        setValue('initialEmails', []);
        break;
    }

    const initialEmailsNoDuplicates = Array.from(new Set(initialEmails.map((email) => email.split(':')[1]))).map(
      (email) => {
        return initialEmails.find((emailWithLabel) => emailWithLabel.includes(email)) || '';
      }
    );

    setValue('initialEmails', initialEmailsNoDuplicates);
  }, [contentTypeWatch, geoPmEmail, pemEmail, serviceLineLeadEmail, pemWmpServiceLineLeadersEmails]);

  const handleGenerateEmailContent = (type: string) => {
    const templateData: EmailContentInterface = generateEmailContent(
      t,
      type,
      props.practitionerDetails,
      props.assignmentDetails,
      userInfo.displayName
    );
    setValue('subject', templateData.subject);
    setValue('html', templateData.html);
    setValue('contentType', type);
    clearErrors('ccEmail');
  };

  const emailDateString = (value: string, date?: string) => {
    return date
      ? t(value) +
          t('sendEmailModal.lastEmailSent', {
            date,
          })
      : t(value);
  };

  const emailSentDate = (value: string) => {
    switch (value) {
      case EmailTemplateTypes.onboarding:
        return emailDateString(value, props.assignmentDetails?.onboardingEmailSent);
      case EmailTemplateTypes.offboarding:
        return emailDateString(value, props.assignmentDetails?.offboardingEmailSent);
      case EmailTemplateTypes.assignmentDetails:
        return emailDateString(value, props.assignmentDetails?.assignmentDetailsEmailSent);
      case EmailTemplateTypes.assignmentExtension:
        return emailDateString(value, props.assignmentDetails?.assignmentExtensionEmailSent);
      case EmailTemplateTypes.dataRemovalCert:
        return emailDateString(value, props.assignmentDetails?.dataRemovalEmailSent);
      case EmailTemplateTypes.requestExtension:
        return emailDateString(value, props.assignmentDetails?.extensionRequestEmailSent);
      default:
        return t(value);
    }
  };

  return (
    <Controller
      name="contentType"
      rules={{
        required: {
          value: true,
          message: t(`sendEmailModal.typesDropdown.required`),
        },
      }}
      control={control}
      key={Math.random()}
      render={({ field: { onChange, value } }) => (
        <ComboBox
          className="send-email-dropdown"
          onChange={(onChangeData) => {
            onChange(onChangeData.selectedItem);
            handleGenerateEmailContent(onChangeData.selectedItem || '');
          }}
          titleText={t(`sendEmailModal.typesDropdown.label`)}
          id={'sendEmailTypesDropdown'}
          items={sendEmailTemplateTypes}
          placeholder={t(`sendEmailModal.typesDropdown.select`)}
          selectedItem={value}
          itemToString={(value) => emailSentDate(value)}
          warn={!!errors['contentType']}
          warnText={<span className="delete-modal-error-message">{errors['contentType']?.message?.toString()}</span>}
        />
      )}
    />
  );
};

export default SendEmailTypeDropdown;
