import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { authorizationConfig } from '../../config/config';
import { environment } from '../../environments/environment';
import { get } from '../data-access/state-configurations/base-api';
import { setUser } from '../data-access/user/slice';
import { useAppDispatch } from '../data-access/state-configurations/hooks';
import { HttpStatus, UserInterface } from '@cic-boardlite/common';
import { popupParams } from '../data-access/state-configurations/request-TS';

interface decodedToken {
  exp: number;
}

export function useAuthorization() {
  const { assertUser, tokenName } = authorizationConfig;
  const token: string | null = localStorage.getItem(tokenName);
  const [isAuthorized, setIsAuthorized] = useState(!!token);
  const [onLoadingLogin, setOnLoadingLogin] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (environment.production && !onLoadingLogin) {
      checkAuthorization();
    }
  }, []);

  async function checkAuthorization(): Promise<void> {
    try {
      setOnLoadingLogin(true);
      const result: UserInterface = await get<UserInterface>(assertUser);

      if (result) dispatch(setUser(result));

      if (token === null) {
        if (result?.idToken) {
          localStorage.setItem(tokenName, result.idToken);
          setOnLoadingLogin(false);
          if (window.location.href.includes('login')) {
            window.location.href = '/';
          }
          return;
        } else {
          setIsAuthorized(false);
          setOnLoadingLogin(false);

          return;
        }
      } else {
        setOnLoadingLogin(false);
        setIsAuthorized(true);
        if (result?.idToken) {
          localStorage.setItem(tokenName, result.idToken);
          setOnLoadingLogin(false);
          return;
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  }

  function tokenExpirationChecker() {
    const decodedToken: decodedToken = jwt_decode(token || '');
    const currentTimeInSeconds = (new Date().getTime() / 1000) + 60;
    if (decodedToken.exp < currentTimeInSeconds) {
      if (
        !window.location.href.includes('popup-blocked') &&
        !window.location.href.includes('assert') &&
        !window.location.href.includes('isLogin') &&
        !window.location.href.includes('login')
      ) {

        authorizationPopup();
      }
    }
  }

  return {
    tokenExpirationChecker,
    checkAuthorization,
    isAuthorized,
    onLoadingLogin,
  };
}

let oneTimeCall = false;
export function authorizationPopup(redirectUrl?: string) {
  const pop = (url: string) => {
    const popup = window.open(url, '_blank', popupParams);
    return popup !== null && typeof popup !== 'undefined' ? popup : null;
  };
  oneTimeCall = true;
  const popupWindow: Window | any = oneTimeCall
    ? pop(authorizationConfig.assertLogin)
    : null;
  if (popupWindow !== null) {
    popupWindow.document.title = 'Loading w3 login...';
    popupWindow.onbeforeunload = function (res: { target: { URL: string } }) {
      const splittedResponseUrl = res?.target.URL.split('/');
      if (splittedResponseUrl[splittedResponseUrl.length - 1] === 'forbidden') {
        oneTimeCall = false;

        window.location.href = '/error/' + HttpStatus.UNAUTHORIZED;
      } else if (
        splittedResponseUrl[splittedResponseUrl.length - 1] === 'isLogin'
      ) {
        popupWindow.close();
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      }
    };
  } else {
    window.location.href = '/popup-blocked';
  }
}
