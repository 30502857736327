import { createSlice } from '@reduxjs/toolkit';
import { UserInterface } from '@cic-boardlite/common';

export const initialState: UserInterface = {
  idToken: '',
  accessToken: '',
  refreshToken: '',
  roles: [],
  scopes: [],
  userInfo: {
    id: 0,
    uid: '',
    displayName: '',
    emailAddress: '',
  },
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser(state, action) {
      if (action.payload) {
        state.idToken = action.payload.idToken;
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.roles = action.payload.roles;
        state.scopes = action.payload.scopes;
        state.userInfo = action.payload.userInfo;
      }
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
