import { Fragment, useEffect, useState, useRef } from 'react';
import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Pagination,
  Tooltip,
} from 'carbon-components-react';
import {
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  TableContainer,
  TableSelectAll,
  TableSelectRow,
} from 'carbon-components-react/lib/components/DataTable';
import { headers } from './header-data';
import {
  DataSortOrder,
  DownloadDataPages,
  HeadersInterface,
  ProjectsDataTableInterface,
  ProjectSortFields,
  ProjectSummaryInterface,
  DEFAULT_ROWS_PER_PAGE,
  ROWS_PER_PAGE_SELECTIONS,
} from '@cic-boardlite/common';
import './page-expandable.scss';
import { useTranslation } from 'react-i18next';
import { useFilteredProjects } from '../../../custom-hooks';
import ProjectsTableExpandedRow from '../project-table-expanded-row/table-expanded-row';
import { DataTableSortState } from 'carbon-components-react/lib/components/DataTable/state/sorting';
import DataDownloadToolbar from '../../data-download-toolbar/data-download-toolbar';

const ProjectsTableExpandable = () => {
  const { t } = useTranslation('common');
  const [isRowExpanded, setIsRowExpanded] = useState([false]);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_ROWS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredProjectRows, setProjectRows] = useState<ProjectSummaryInterface[]>([]);
  const [isProject, setProject] = useState<ProjectSummaryInterface>();
  const {
    filteredProjectsData,
    isLoadingFilteredProjects,
    selectedProjectsList,
    pushProjectToTheSelectedList,
    removeProjectFromTheSelectedList,
  } = useFilteredProjects();
  const [sortKey, setSortKey] = useState('');
  const [sortKeyDirection, setSortKeyDirection] = useState<DataTableSortState>(DataSortOrder.NONE);
  const [paginationProjects, setPaginationProjects] = useState<ProjectSummaryInterface[]>([]);
  const [showAccountIdsToolTip, setShowAccountIdsToolTip] = useState<string | null>(null);
  const [overflowActive, setOverflowActive] = useState<boolean>(false);
  const overflowingText = useRef<HTMLParagraphElement | null>(null);

  const isAccountIdTextOverflown = (textContainer: HTMLParagraphElement | null): boolean => {
    return textContainer ? textContainer.offsetWidth < textContainer.scrollWidth : false;
  };

  useEffect(() => {
    setCurrentPage(1);
    setIsRowExpanded([]);
    setSortKey('');
    setCurrentPageSize(DEFAULT_ROWS_PER_PAGE);
    setPaginationProjects(filteredProjectsData);
    setProjectRows(filteredProjectsData.slice(0, currentPageSize));
  }, [filteredProjectsData]);

  useEffect(() => {
    let sortedProjects;

    if (sortKeyDirection === DataSortOrder.NONE) {
      sortedProjects = filteredProjectsData;
    } else {
      sortedProjects = [...filteredProjectsData].sort(dynamicSort(sortKey));
    }

    setPaginationProjects(sortedProjects);
    setProjectRows(sortedProjects.slice(0, currentPageSize));
  }, [sortKey, sortKeyDirection]);

  function dynamicSort(property: string) {
    let sortOrder = 0;
    if (sortKeyDirection === DataSortOrder.ASC) {
      sortOrder = -1;
    } else if (sortKeyDirection === DataSortOrder.DESC) {
      sortOrder = 1;
    }
    return function (projectA: ProjectSortFields, projectB: ProjectSortFields) {
      const key = property as keyof typeof projectA;
      if (projectA[key] === null) {
        return -1 * sortOrder;
      }
      if (projectB[key] === null) {
        return 1 * sortOrder;
      }

      const valueA = (projectA[key] || '').toLowerCase();
      const valueB = (projectB[key] || '').toLowerCase();

      let result;

      if (property === 'projectStatus') {
        result = projectStatusSort(valueA, valueB);
      } else result = valueA.localeCompare(valueB);
      return result * sortOrder;
    };
  }

  function projectStatusSort(valueA: string, valueB: string): number {
    if ((valueA === 'active' && valueB === 'closed') || (valueA === 'active' && valueB === 'inactive')) {
      return -1;
    } else if (valueA === 'inactive' && valueB === 'closed') {
      return -1;
    } else {
      return 1;
    }
  }

  function handleRowSort(header: HeadersInterface) {
    setIsRowExpanded([]);
    setCurrentPage(1);
    if (header.key === sortKey) {
      handleKeyDirection();
    } else {
      setSortKey(header.key);
      setSortKeyDirection(DataSortOrder.DESC);
    }
  }

  function handleKeyDirection() {
    if (sortKeyDirection === DataSortOrder.NONE) {
      setSortKeyDirection(DataSortOrder.DESC);
    } else if (sortKeyDirection === DataSortOrder.DESC) {
      setSortKeyDirection(DataSortOrder.ASC);
    } else {
      setSortKeyDirection(DataSortOrder.NONE);
    }
  }

  const getProjectByRowId = (id: string) => {
    const project = filteredProjectRows.find((object) => object.id === id);
    setProject(project);
  };

  const handleRowExpansion = (
    rows: ProjectSummaryInterface[],
    expandedRowId: string,
    nodeName?: string,
    index?: number
  ) => {
    setProject(undefined);
    getProjectByRowId(expandedRowId);
    if (index === undefined && nodeName !== 'svg' && nodeName !== 'path' && nodeName !== 'BUTTON') return;

    const rowExpansionStatus = [...isRowExpanded];

    rows.forEach((row, index) => (rowExpansionStatus[index] = row.id === expandedRowId && !rowExpansionStatus[index]));
    setIsRowExpanded(rowExpansionStatus);
  };

  const checkIfEveryRowIsSelected = () => {
    return (
      !!filteredProjectRows.length &&
      !!selectedProjectsList.length &&
      filteredProjectRows.every((row) => selectedProjectsList.find((project) => project.id === row.id))
    );
  };

  const selectProject = (row: ProjectSummaryInterface) => {
    const alreadySelected = selectedProjectsList.find((project) => project.id === row.id);
    if (alreadySelected) {
      removeProjectFromTheSelectedList(row.id);
    } else {
      const project = filteredProjectsData.find((project) => project.id === row.id);
      project && pushProjectToTheSelectedList(project);
    }
  };

  const selectOrRemoveAllProjects = (rows: ProjectSummaryInterface[]) => {
    if (checkIfEveryRowIsSelected()) {
      for (const row of rows) {
        const alreadySelected = selectedProjectsList.find((project) => project.id === row.id);
        if (alreadySelected) removeProjectFromTheSelectedList(row.id);
      }
    } else {
      for (const row of rows) {
        const alreadySelected = selectedProjectsList.find((project) => project.id === row.id);
        if (!alreadySelected) {
          const project = filteredProjectsData.find((project) => project.id === row.id);
          project && pushProjectToTheSelectedList(project);
        }
      }
    }
  };

  const isAccountIdsToolTipOpen = (id: string): boolean => {
    return overflowActive && showAccountIdsToolTip === id;
  };

  const getAccountIdsToolTipMessage = (value: string): string => {
    return value
      .split('')
      .map((value) => {
        if (/\s/g.test(value)) {
          return '';
        }
        if (/[,;]+/g.test(value)) {
          return '\n';
        }
        return value;
      })
      .join('');
  };

  const openAccountIdsToolTip = (target: HTMLParagraphElement, id: string) => {
    setOverflowActive(isAccountIdTextOverflown(target));
    setShowAccountIdsToolTip(id);
  };

  const closeAccountIdsToolTip = () => {
    setOverflowActive(false);
    setShowAccountIdsToolTip(null);
  };

  const handlePagination = ({ page, pageSize }: { page: number; pageSize: number }) => {
    setCurrentPage(page);
    setCurrentPageSize(pageSize);
    setIsRowExpanded([]);
    const firstRowId = pageSize * page - pageSize;
    setProjectRows(paginationProjects.slice(firstRowId, pageSize * page));
  };

  return isLoadingFilteredProjects ? (
    <DataTableSkeleton
      columnCount={headers.length + 2}
      headers={headers}
      rowCount={20}
      showHeader={false}
      showToolbar={false}
    />
  ) : (
    <>
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <DataTable headers={headers} size="md" rows={filteredProjectRows}>
          {({
            rows,
            headers,
            getTableProps,
            getHeaderProps,
            getRowProps,
            getSelectionProps,
          }: ProjectsDataTableInterface) => {
            return (
              <TableContainer>
                <DataDownloadToolbar page={DownloadDataPages.Projects} />
                <Table {...getTableProps()}>
                  <TableHead className="projectTableHeaders">
                    <TableRow>
                      <TableExpandHeader />
                      <TableSelectAll
                        {...getSelectionProps()}
                        onSelect={(e) => {
                          selectOrRemoveAllProjects(rows);
                          getSelectionProps().onSelect(e);
                        }}
                        indeterminate={!!selectedProjectsList.length && !checkIfEveryRowIsSelected()}
                        checked={checkIfEveryRowIsSelected()}
                      />
                      {headers.map((header, index) => (
                        <TableHeader
                          key={header.key}
                          {...getHeaderProps({ header })}
                          isSortable={true}
                          sortDirection={sortKeyDirection}
                          isSortHeader={header.key === sortKey}
                          onClick={() => {
                            handleRowSort(header);
                          }}
                          className={`projects-list-padding-left
                          ${header.key === 'projectId' && 'projects-list-projectId-padding-left'}
                        `}
                        >
                          {t(`projectsTableHeader.${header.key}`)}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <TableExpandRow
                            className="project-table-expandable-row"
                            onClick={(e) => {
                              handleRowExpansion(rows, row.id, (e.target as HTMLElement).nodeName, undefined);
                            }}
                            {...getRowProps({ row })}
                            isSelected={!!selectedProjectsList.find((project) => project.id === row.id)}
                            isExpanded={isRowExpanded[index] === undefined ? false : isRowExpanded[index]}
                          >
                            <TableSelectRow
                              {...getSelectionProps({ row })}
                              onChange={() => selectProject(row)}
                              checked={!!selectedProjectsList.find((project) => project.id === row.id)}
                            />
                            {row.cells.map((cell, index) => {
                              return (
                                <TableCell
                                  onClick={() => handleRowExpansion(rows, row.id, undefined, index)}
                                  key={index}
                                  className={`projects-table-max-cell-width ${
                                    cell.info.header === 'projectStatus' ? `${cell.value}-projectActionTableCell` : ''
                                  } ${cell.info.header === 'projectAccountIds' && 'projects-list-accountIds-cell'}`}
                                >
                                  {cell.info.header === 'projectAccountIds' && (
                                    <div className="projects-list-accountIds-container">
                                      <Tooltip
                                        className="projects-list-tooltip"
                                        direction="left"
                                        open={isAccountIdsToolTipOpen(row.id)}
                                      >
                                        {getAccountIdsToolTipMessage(cell.value)}
                                      </Tooltip>
                                      <p
                                        ref={overflowingText}
                                        className="project-list-accountIds-cell-row-text"
                                        onMouseEnter={(e) => openAccountIdsToolTip(e.currentTarget, row.id)}
                                        onMouseLeave={closeAccountIdsToolTip}
                                      >
                                        {cell.value}
                                      </p>
                                    </div>
                                  )}
                                  {cell.info.header !== 'projectAccountIds' && cell.value}
                                </TableCell>
                              );
                            })}
                          </TableExpandRow>
                          <TableExpandedRow colSpan={headers?.length + 2}>
                            {isProject && <ProjectsTableExpandedRow projectsInfo={isProject} />}
                          </TableExpandedRow>
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }}
        </DataTable>
      }
      <Pagination
        className="projectTablePagination"
        backwardText={t('pagination.previousPage')}
        forwardText={t('pagination.nextPage')}
        itemRangeText={(min, max, total) => t('pagination.itemRangeText', { min, max, total })}
        pageRangeText={(current, total) => t('pagination.pageRangeText', { current, total })}
        page={currentPage}
        pageSize={currentPageSize}
        pageSizes={ROWS_PER_PAGE_SELECTIONS}
        itemsPerPageText={t('pagination.itemsPerPage')}
        totalItems={filteredProjectsData.length}
        onChange={handlePagination}
      />
    </>
  );
};
export default ProjectsTableExpandable;
