import { ProjectCreateEditPayloadInterface, ProjectEditPayloadInterface } from '@cic-boardlite/common';
import { getUpdateProject, setDeleteProject, singleFieldUpdate } from '../data-access/project/slice';
import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import { UpdateSingleFieldInterface } from '../interfaces/project-interfaces';

const useEditProject = () => {
  const dispatch = useAppDispatch();

  const updateProjectDetails = (
    oldProjectData: ProjectCreateEditPayloadInterface,
    updatedProjectData: ProjectCreateEditPayloadInterface
  ) => {
    dispatch(getUpdateProject({ oldData: oldProjectData, newData: updatedProjectData }));
  };

  const deleteProject = (id: ProjectEditPayloadInterface['id']) => {
    return dispatch(setDeleteProject(id));
  };

  const updateSingleField = (oldFieldData: UpdateSingleFieldInterface, newFieldData: UpdateSingleFieldInterface) => {
    return dispatch(singleFieldUpdate({ oldData: oldFieldData, newData: newFieldData }));
  };

  const isDeletingProject = useAppSelector((state) => state.projects.isDeletingProject);

  return {
    updateProjectDetails,
    deleteProject,
    isDeletingProject,
    updateSingleField,
  };
};

export default useEditProject;
