import { DownloadDataPages } from '@cic-boardlite/common';
import { TableToolbarSearch } from 'carbon-components-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePractitionerAssignments, useProjectAssignments } from '../../custom-hooks';
import './data-download-toolbar.scss';

const DataDownloadToolbarSearch = ({ page }: { page: string }) => {
  const { t } = useTranslation('common');

  const { setPractitionerAssignmentsFilterString, resetPractitionerAssignmentsFilterString } =
    usePractitionerAssignments();

  const { setProjectAssignmentsFilterString, resetProjectAssignmentsFilterString } = useProjectAssignments();

  const handleToolbarFilter = (filterString: string) => {
    if (page === DownloadDataPages.PractitionerAssignments) {
      if (!filterString) return resetPractitionerAssignmentsFilterString();
      setPractitionerAssignmentsFilterString(filterString.trim());
    }
    if (page === DownloadDataPages.ProjectAssignments) {
      if (!filterString) return resetProjectAssignmentsFilterString();
      setProjectAssignmentsFilterString(filterString.trim());
    }
  };

  useEffect(() => {
    page === DownloadDataPages.PractitionerAssignments && resetPractitionerAssignmentsFilterString();
    page === DownloadDataPages.ProjectAssignments && resetProjectAssignmentsFilterString();
  }, []);

  return page === DownloadDataPages.PractitionerAssignments || page === DownloadDataPages.ProjectAssignments ? (
    <TableToolbarSearch
      className="data-download-toolbar-search"
      expanded
      onChange={(e) => handleToolbarFilter(e.currentTarget?.value)}
      placeholder={t('dataDownloadToolbar.filterPlaceholder')}
    />
  ) : (
    <></>
  );
};

export default DataDownloadToolbarSearch;
