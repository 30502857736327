import { useTranslation } from 'react-i18next';
import './privacy-policy.scss';

const PrivacyPolicy = () => {
  const { t } = useTranslation('common');
  const appName = t('appName');

  return (
    <div className="privacy-policy">
      <div className="policy-content">
        <h2>{t('appNameFull')} Privacy Statement</h2>

        <p>
          By accessing this application, you authorise the "{appName}" application to process your Name and TalentID
          along with the data you provide in this application. This personal information will be transferred and 
          processed by IBM United States and is encrypted while in transit.
        </p>

        <p>
          "{appName}" is an internal application designed to track and manage practitioners' profiles and assignments
          to projects at IBM CIC Baltic unit.
        </p>

        <p>
          "{appName}" does not collect any personal information that is then shared with other users or applications 
          without user express consent except for employees which are supervising practitioners employment.<br/>

          Any information tied to you and stored in "{appName}" databases will be deleted or de-identified after the
          retention period (currently 10 years) after leaving IBM.<br/>

          The following data is either displayed, processed or stored by "{appName}" application: First Name, Last 
          Name, Email, Employee TalentID, Department and any input that is provided by your supervisor in order to
          manage your recent data and keep your labor schedule as full as it could be.<br/>

          In case you believe your data is incorrect or needs to be removed from any "{appName}" data stores, you find
          any inconsistencies that cannot be updated by you or in case you want to receive a copy of what data we are 
          displaying, processing or storing about you, please notify <a href="cic.baltic.pmo@lt.ibm.com">cic.baltic.pmo@lt.ibm.com</a>
        </p>
      
        <p>
          If how data is handled is not described here, then it is described more thoroughly in the <a href="https://w3.ibm.com/w3publisher/w3-privacy-notice/">IBM Internal Privacy Statement</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
