import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import { get } from '../state-configurations/base-api';
import {
  getBluePagesPractitionersBySerial,
  getBluePagesPractitionersByCountrySuccess,
  getBluePagesPractitionersByCountryFailed,
  getPemBluePagesFailed,
  getPemBluePagesSuccess,
  getPemBluePages,
  getGeoPmBluePagesSuccess,
  getGeoPmBluePagesFailed,
  getGeoPmBluePages,
  getPreferredIdentityEmailBluePages,
  getPreferredIdentityEmailBluePagesSuccess,
  getPreferredIdentityEmailBluePagesFailed,
  pingCvBuilderSuccess,
  pingCvBuilder,
  pingCvBuilderFailed,
  pingBlueServices,
  pingBlueServicesSuccess,
  pingBlueServicesFailed,
  setSelectedBluePagesPractitionerFailed,
  setSelectedBluePagesPractitionerSuccess,
  setSelectedBluePagesPractitioner,
} from './slice';
import {
  GetBluePagesPractitionersInterface,
  HealthStatus,
  ProfileBluePagesPractitionerInterface,
  QueryByCountry,
  SelectedBluePagesPractitionerInterface,
} from '@cic-boardlite/common';
import { AnyAction } from '@reduxjs/toolkit';
import {
  bluePagesDBDomain,
  blueServicesConfig,
  bluePagesUnifiedProfileAPI,
} from '../../../config/config';

export function* handleGetBluePagesPractitionersBySerial(action: {
  type: string;
  payload: QueryByCountry;
}): Generator<
  CallEffect<GetBluePagesPractitionersInterface> | PutEffect<AnyAction>,
  void,
  GetBluePagesPractitionersInterface
> {
  try {
    const path = `${bluePagesDBDomain}/search?searchConfig=optimized_search&start=${
      action.payload.offset || 0
    }&rows=${action.payload.limit || 24}&timeout=2000&query=${
      action.payload.query
    }`;

    const responseFilteredBluePagesPractitioners = yield call(() =>
      get<GetBluePagesPractitionersInterface>(path)
    );

    yield put(
      getBluePagesPractitionersByCountrySuccess(
        responseFilteredBluePagesPractitioners.results
      )
    );
  } catch (error) {
    console.log('Bluepages saga error: ', error);
    yield put(getBluePagesPractitionersByCountryFailed());
  }
}

export function* handleSelectBluePractitioner(action: {
  type: string;
  payload: string;
}): Generator<
  CallEffect<SelectedBluePagesPractitionerInterface> | PutEffect<AnyAction>,
  void,
  SelectedBluePagesPractitionerInterface
> {
  try {
    const path = `${bluePagesUnifiedProfileAPI}/profiles/${action.payload}`;

    const responseBluePractitioner = yield call(() =>
      get<SelectedBluePagesPractitionerInterface>(path)
    );

    if (responseBluePractitioner)
      yield put(
        setSelectedBluePagesPractitionerSuccess(responseBluePractitioner)
      );
  } catch (error) {
    console.log('Bluepages PEM saga error: ', error);
    yield put(setSelectedBluePagesPractitionerFailed());
  }
}

export function* handleGetPemBluePages(action: {
  type: string;
  payload: string;
}): Generator<
  CallEffect<GetBluePagesPractitionersInterface> | PutEffect<AnyAction>,
  void,
  GetBluePagesPractitionersInterface
> {
  try {
    const path = `${bluePagesDBDomain}/search?query=${action.payload}`;
    const responseBluePagesPem = yield call(() =>
      get<GetBluePagesPractitionersInterface>(path)
    );
    yield put(getPemBluePagesSuccess(responseBluePagesPem.results[0]));
  } catch (error) {
    console.log('Bluepages PEM saga error: ', error);
    yield put(getPemBluePagesFailed());
  }
}

export function* handleGetGeoPmBluePages(action: {
  type: string;
  payload: string;
}): Generator<
  CallEffect<GetBluePagesPractitionersInterface> | PutEffect<AnyAction>,
  void,
  GetBluePagesPractitionersInterface
> {
  try {
    const path = `${bluePagesDBDomain}/search?query=${action.payload}`;

    const responseBluePagesPem = yield call(() =>
      get<GetBluePagesPractitionersInterface>(path)
    );
    yield put(getGeoPmBluePagesSuccess(responseBluePagesPem.results[0]));
  } catch (error) {
    console.log('Bluepages Geo PM saga error: ', error);
    yield put(getGeoPmBluePagesFailed());
  }
}

export function* handlePreferredIdentityEmailBluePages(action: {
  type: string;
  payload: string;
}): Generator<
  CallEffect<ProfileBluePagesPractitionerInterface> | PutEffect<AnyAction>,
  void,
  ProfileBluePagesPractitionerInterface
> {
  try {
    const path = `${bluePagesUnifiedProfileAPI}/profiles/${action.payload}/profile`;

    const responseBluePages = yield call(() =>
      get<ProfileBluePagesPractitionerInterface>(path)
    );

    yield put(
      getPreferredIdentityEmailBluePagesSuccess(
        responseBluePages?.content?.preferredIdentity
      )
    );
  } catch (error) {
    console.log('Bluepages preferred identity (email) saga error: ', error);
    yield put(getPreferredIdentityEmailBluePagesFailed());
  }
}

export function* handlePingBlueServices(action: {
  type: string;
}): Generator<
  CallEffect<HealthStatus> | PutEffect<AnyAction>,
  void,
  HealthStatus
> {
  try {
    const { healthPing } = blueServicesConfig;
    const path = healthPing;
    const responseBluePages = yield call(() => get<HealthStatus>(path));
    if (responseBluePages) yield put(pingBlueServicesSuccess());
  } catch (error) {
    console.log('Blue pages PEM saga error: ', error);
    yield put(pingBlueServicesFailed());
  }
}

export function* handlePingCvBuilder(action: {
  type: string;
}): Generator<
  CallEffect<HealthStatus> | PutEffect<AnyAction>,
  void,
  HealthStatus
> {
  try {
    const { cvBuilderPing } = blueServicesConfig;
    const path = cvBuilderPing;
    const responseCvBuilder = yield call(() => get<HealthStatus>(path));
    if (responseCvBuilder) yield put(pingCvBuilderSuccess());
  } catch (error) {
    console.log('Blue pages PEM saga error: ', error);
    yield put(pingCvBuilderFailed());
  }
}

export function* bluePagesSaga() {
  yield takeLatest(
    getBluePagesPractitionersBySerial.type,
    handleGetBluePagesPractitionersBySerial
  );
  yield takeLatest(getPemBluePages.type, handleGetPemBluePages);
  yield takeLatest(getGeoPmBluePages.type, handleGetGeoPmBluePages);
  yield takeLatest(
    getPreferredIdentityEmailBluePages.type,
    handlePreferredIdentityEmailBluePages
  );
  yield takeLatest(pingBlueServices().type, handlePingBlueServices);
  yield takeLatest(pingCvBuilder().type, handlePingCvBuilder);
  yield takeLatest(
    setSelectedBluePagesPractitioner.type,
    handleSelectBluePractitioner
  );
}
