import { EmfStatus } from "../../emf-status.enum";
import { TalentID_LENGTH } from "../constants/constants";

export const validateSerialFE = (
  serial: string,
  isCic: boolean,
  countryCode: string,
  emfStatusId?: number,
  prevEmfStatusId?: number,
) => {
  if (isCic) {
    if (emfStatusId === EmfStatus.CandidateForHiring || (emfStatusId === EmfStatus.Terminated && prevEmfStatusId === EmfStatus.CandidateForHiring)) {
      return validateSerialForCFH(serial);
    } else if (emfStatusId === EmfStatus.Contractor || (emfStatusId === EmfStatus.Terminated && prevEmfStatusId === EmfStatus.Contractor)) {
      return validateSerialForContractorAndNonCic(serial, countryCode);
    } else if (emfStatusId) {
      return validateSerialForRegular(serial, countryCode);
    } else {
      return { error: true, errMessage: 'serialSelectEmfValidation', code: '' };
    }
  }
  if (!isCic) {
    return validateSerialForContractorAndNonCic(serial, countryCode);
  }
  return { error: false };
};

const validateSerialForCFH = (serial: string) => {
  if (serial.includes(' '))
    return { error: true, errMessage: 'serialSpacesValidation', code: '' };
  if (!serial.startsWith('CFH-') || serial.length > 16)
    return { error: true, errMessage: 'serialCfhValidation', code: '' };
  return { error: false };
};

const validateSerialForContractorAndNonCic = (
  serial: string,
  countryCode: string
) => {
  if (serial.includes(' '))
    return { error: true, errMessage: 'serialSpacesValidation', code: '' };
  if (!(serial.length === TalentID_LENGTH.SHORT || serial.length === TalentID_LENGTH.FULL) || serial.startsWith('-'))
    return { error: true, errMessage: 'serialLengthValidation', code: '' };
  if (serial.length === TalentID_LENGTH.FULL && !serial.endsWith(countryCode)) {
    return {
      error: true,
      errMessage: 'serialLastNumberCountryCodeValidation',
      code: countryCode ?? '',
    };
  }
  return { error: false };
};

const validateSerialForRegular = (serial: string, countryCode: string) => {
  if (serial.includes(' '))
    return { error: true, errMessage: 'serialSpacesValidation', code: '' };
  if (!(serial.length === TalentID_LENGTH.SHORT || serial.length === TalentID_LENGTH.FULL) || isNaN(Number(serial)))
    return {
      error: true,
      errMessage: 'serialIsNumbersLengthValidation',
      code: '',
    };
  if (serial.length === TalentID_LENGTH.FULL && !serial.endsWith(countryCode))
    return {
      error: true,
      errMessage: 'serialLastNumberCountryCodeValidation',
      code: countryCode ?? '',
    };
  return { error: false };
};
