import {
  format,
  parseISO,
  differenceInWeeks,
  differenceInCalendarDays,
} from 'date-fns';
import { ASSIGNMENT_ENDING_IN_4_WEEKS, ASSIGNMENT_ENDING_IN_6_WEEKS } from '../constants/constants';

export const DateFormat = {
  date: 'yyyy-MM-dd',
  dateTime: 'yyyy-MM-dd HH:mm:ss',
};

export function convertDate(
  date: Date | string | number,
  useTime?: boolean
): string | undefined {
  if (!date) return undefined;

  date = typeof date === 'number' ? new Date(date) : date;

  const dateFormatString = useTime ? DateFormat.dateTime : DateFormat.date;

  if (typeof date === 'string') date = parseISO(date);

  return date ? format(date, dateFormatString) : undefined;
}

export function nextDayOfAvailability(date: Date) {
  const currentWeekday = new Date(date).getDay();
  const currentDate = new Date(date);
  if (currentWeekday === 6)
    return new Date(currentDate.setDate(currentDate.getDate() + 2));
  if (currentWeekday === 5)
    return new Date(currentDate.setDate(currentDate.getDate() + 3));
  return new Date(currentDate.setDate(currentDate.getDate() + 1));
}

export const whenAssignmentIsEnding = (assignmentEndDate: string) => {
  const weekDiff = differenceInWeeks(new Date(assignmentEndDate), new Date());

  if (weekDiff < 4) {
    return ASSIGNMENT_ENDING_IN_4_WEEKS;
  }

  if (weekDiff < 6) {
    return ASSIGNMENT_ENDING_IN_6_WEEKS;
  }

  return '';
};

export const getAssignmentEnding = (assignmentEndDate: string) => {
  const daysDiff = differenceInCalendarDays(new Date(assignmentEndDate), new Date());
  const amountOfWeeks = Math.trunc(daysDiff / 7);

  if (daysDiff <= 0) {
    return '0';
  }

  if (amountOfWeeks <= 0) {
    return daysDiff === 1 ? `${daysDiff} day` : `${daysDiff} days`;
  }

  if (amountOfWeeks === 1) {
    const amountOfDaysLeft = daysDiff - 7;
    return amountOfDaysLeft > 0
      ? `${amountOfWeeks} week and ${daysDiff - 7} ${
          amountOfDaysLeft === 1 ? 'day' : 'days'
        }`
      : `${amountOfWeeks} week`;
  }

  const days = amountOfWeeks * 7;
  const amountOfDaysLeft = daysDiff - days;
  return amountOfDaysLeft > 0
    ? `${amountOfWeeks} weeks and ${daysDiff - days} ${
        amountOfDaysLeft === 1 ? 'day' : 'days'
      }`
    : `${amountOfWeeks} weeks`;
};
