import { AccordionSkeleton } from 'carbon-components-react';
import { useEffect } from 'react';
import { usePractitioners } from '../../../custom-hooks';
// TODO Future use
import PractitionersAccordion from '../../../ui/practitioners-accordion/practitioners-accordion';
import './summary.scss';

const PractitionersSummary = () => {
  const { isLoadingPractitioners, allPractitionersData, getBenchAndHiringPractitioners } = usePractitioners();

  useEffect(() => {
    getBenchAndHiringPractitioners();
  }, []);

  return isLoadingPractitioners ? (
    <div className="practitionerWrapper">
      <AccordionSkeleton open={false} count={3} />
    </div>
  ) : (
    <div className="practitionerWrapper">
      {/* TODO Future use */}
      {/* <OverFlowMenu /> */}
      <PractitionersAccordion practitionersSummaryData={allPractitionersData} />
    </div>
  );
};

export default PractitionersSummary;
