import {
  Column,
  Modal,
  RadioButton,
  RadioButtonGroup,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  SkeletonText,
  InlineLoading,
  CodeSnippet,
} from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CFH_ASSIGNMENT_DATE, CvDownload, toFixedValue } from '@cic-boardlite/common';
import { useStaticData, useUser, usePractitionerCv } from '../../custom-hooks';
import { Close } from '@carbon/icons-react/next';
import { EmailsField } from '../emails-field/emails-field';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import './cv-modal-dialog.scss';

const CvDownloadModalDialog = () => {
  const {
    selectSelectedPractitioners,
    isUpdatingPractitionersCv,
    updateSelectedPractitionersCv,
    openCloseCvModal,
    cvModalIsOpen,
    removePractitionerFromTheList,
  } = usePractitionerCv();

  const { t } = useTranslation('common');
  const { loadStaticData } = useStaticData();
  const { userInfo, idToken } = useUser();

  const defaultValues = {
    initialEmails: [userInfo?.emailAddress],
    ccEmail: [],
  };

  const methods = useForm<{
    initialEmails: string[];
    ccEmail: string[];
  }>({ defaultValues: defaultValues });

  useEffect(() => {
    loadStaticData();
  }, []);

  const isTalentIdCFH = (talentId: string): boolean => {
    return talentId.startsWith('CFH-');
  };

  const practitionersInfo = selectSelectedPractitioners.map(
    ({ id, serial, nameFirstLast, band, availabilityDate, jrs, jrsId, country, fileName, costRate, psAdder }) => ({
      id,
      serial,
      nameFirstLast,
      band: band || '-',
      availabilityDate: isTalentIdCFH(serial) ? CFH_ASSIGNMENT_DATE : availabilityDate || '-',
      jrs: jrs || '-',
      jrsId,
      costRate: toFixedValue(costRate, '-'),
      psAdder: psAdder || '-',
      country,
      fileName,
    })
  );

  const headers = [
    t(`cvDownloadModal.name`),
    t(`cvDownloadModal.jrs`),
    `${t(`cvDownloadModal.band`)} / ${t(`cvDownloadModal.psAdder`)} / ${t(`cvDownloadModal.cost`)}`,
    t(`cvDownloadModal.cv`),
    t(`cvDownloadModal.availability`),
  ];

  const [attachmentType, setAttachmentType] = useState('word');

  const anonymizedName = (name?: string) => {
    if (name) {
      return name.split(' ')[0] + ' ' + name.split(' ')[1].charAt(0) + '.';
    } else return t(`cvDownloadModal.infoNotAvailable`);
  };

  const copyToClipboard = () => {
    const table = document.getElementById('table-id');
    const asteriskWrapper = document.getElementById('cv-modal-dialog-asterisk-wrapper');
    const tableCopy = document.createElement('table');
    const asteriskWrapperCopy = document.createElement('div');
    const finalElement = document.createElement('div');

    if (table) tableCopy.innerHTML = table.innerHTML;
    if (asteriskWrapper) asteriskWrapperCopy.innerHTML = asteriskWrapper.innerHTML;

    tableCopy.style.width = 'fit-content';
    tableCopy.querySelectorAll('td, th').forEach((el) => {
      if (el instanceof HTMLElement) {
        el.style.width = 'fit-content';
        el.style.textAlign = 'center';
        el.style.verticalAlign = 'middle';
        el.style.backgroundColor = 'none';
        el.style.marginRight = '10px';
        el.style.marginLeft = '10px';
        el.style.border = '1px solid black';
      }
    });

    tableCopy.querySelectorAll('td:last-child, th:last-child').forEach((el) => {
      if (el instanceof HTMLElement) {
        el.remove();
      }
    });

    finalElement.append(tableCopy, asteriskWrapperCopy)

    document.body.appendChild(finalElement);
    selectNode(finalElement);
    document.execCommand('copy');
    document.body.removeChild(finalElement);
  };

  const selectNode = (node: HTMLElement) => {
    const range = document.createRange();
    range.selectNodeContents(node);
    const select = window.getSelection();
    select?.removeAllRanges();
    select?.addRange(range);
  };

  const checkForFileName = (fileName?: string) => {
    if (isUpdatingPractitionersCv) return <SkeletonText />;
    if (fileName) return fileName;
    return '-';
  };

  const filterCcEmail = (data: string[]) => {
    return data.filter((email) => email.toLowerCase() !== userInfo.emailAddress.toLowerCase());
  };

  const handleCvDownloadSubmit = (data: { ccEmail: string[]; initialEmails: string[] }) => {
    const cvDownload: CvDownload = {
      practitioners: practitionersInfo,
      idToken,
      format: attachmentType,
      emailFrom: userInfo.emailAddress,
      emailTo: userInfo.emailAddress,
      emailCc: filterCcEmail(data.ccEmail),
    };

    updateSelectedPractitionersCv(cvDownload);
  };

  const handleRemovePractitioner = (id: string) => {
    removePractitionerFromTheList(id);
    if (selectSelectedPractitioners.length <= 1) openCloseCvModal();
  };

  const handleCloseModal = () => {
    methods.clearErrors('ccEmail');
    openCloseCvModal();
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={cvModalIsOpen}
          primaryButtonText={
            isUpdatingPractitionersCv ? (
              <InlineLoading
                status="active"
                iconDescription={t(`cvDownloadModal.sendingIconDescription`)}
                description={t(`cvDownloadModal.sending`)}
              />
            ) : (
              t(`cvDownloadModal.sendButtonText`)
            )
          }
          primaryButtonDisabled={isUpdatingPractitionersCv}
          secondaryButtonText={t(`cvDownloadModal.cancelButtonText`)}
          size="lg"
          onRequestSubmit={methods.handleSubmit(handleCvDownloadSubmit)}
          onRequestClose={handleCloseModal}
        >
          <Row>
            <Column>
              <h4>{t(`cvDownloadModal.getCV`)}</h4>
            </Column>
            <Column>
              <RadioButtonGroup
                legendText={t(`cvDownloadModal.format`)}
                name="radio-button-group"
                defaultSelected="word"
              >
                <RadioButton labelText=".doc" value="word" onClick={() => setAttachmentType('word')} />
                <RadioButton labelText=".pdf" value="pdf" onClick={() => setAttachmentType('pdf')} />
              </RadioButtonGroup>
            </Column>
          </Row>
          {cvModalIsOpen && (
            <Controller
              name="ccEmail"
              control={methods.control}
              rules={{
                validate: (value) => {
                  return !!value.length || t('sendEmailModal.emptyCcError');
                },
              }}
              render={() => (
                <EmailsField
                  emailBoxName={'ccEmail'}
                  textAreaLabelLangKey={'ccEmail.label'}
                  textAreaPlaceholderLangKey={'ccEmail.placeholder'}
                  initialEmails={'initialEmails'}
                />
              )}
            />
          )}
          <CodeSnippet
            type="single"
            className="cv-modal-copy-to-clipboard-button"
            feedback={t(`cvDownloadModal.copied`)}
            onClick={copyToClipboard}
          />
          <Table id="table-id">
            <TableHead>
              <TableRow style={{ padding: 5 }}>
                <TableHeader style={{ width: '15%' }}>{headers[0]}</TableHeader>
                <TableHeader style={{ width: '35%' }}>{headers[1]}</TableHeader>
                <TableHeader style={{ width: '20%' }}>{headers[2]}</TableHeader>
                <TableHeader style={{ width: '20%' }}>{headers[3]}</TableHeader>
                <TableHeader style={{ width: '10%' }}>{headers[4]}</TableHeader>
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {practitionersInfo.map((practitioner) => (
                <TableRow key={practitioner.serial} style={{ padding: 5 }}>
                  <TableCell>{anonymizedName(practitioner.nameFirstLast)}</TableCell>
                  <TableCell>{practitioner.jrs}</TableCell>
                  <TableCell>
                    {practitioner.band} / {practitioner.psAdder} / {practitioner.costRate}
                  </TableCell>
                  <TableCell>{checkForFileName(practitioner.fileName)}</TableCell>
                  <TableCell className={`${isTalentIdCFH(practitioner.serial) ? 'cv-modal-dialog_CFH-date' : ''}`}>
                    {isTalentIdCFH(practitioner.serial) ? CFH_ASSIGNMENT_DATE : practitioner.availabilityDate}
                  </TableCell>
                  <TableCell className="cv-modal-close-icon-cell">
                    <Close
                      className="cv-modal-remove-practitioner"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemovePractitioner(practitioner.id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
         <div id="cv-modal-dialog-asterisk-wrapper">
          <div className="modal-asterisk">{t('cvDownloadModal.asteriskOne')}</div>
          <div>{t('cvDownloadModal.asteriskTwo')}</div>
         </div>
        </Modal>
      </form>
    </FormProvider>
  );
};

export default CvDownloadModalDialog;
