import { AccordionSkeleton } from 'carbon-components-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useProject, useStaticData, useProjectAssignments } from '../../../custom-hooks';
import ProjectDetailsComponent from './details-comp';
import './detail-page.scss';
import ProjectDetailPageSidebar from './detail-sidebar';

const ProjectDetailPage = () => {
  const { isStaticDataSet, loadStaticData } = useStaticData();
  const {
    isLoadingProjectsClientData,
    loadProjectsClientData,
    loadProjectsIdNameData,
    isLoadingProjectsIdNameData,
    isLoadingGeoPmNamesTalentIds,
    getGeoPmNamesTalentIdsData,
    getProjectData,
    isLoadingProjectData,
    projectData,
  } = useProject();
  const { getAssignmentEndDate, isLoadingLastAssignmentEndDate } = useProjectAssignments();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      getProjectData(id);
      getAssignmentEndDate(id);
    }
    loadStaticData();
    loadProjectsClientData();
    loadProjectsIdNameData();
    getGeoPmNamesTalentIdsData();
  }, []);

  return (
    <>
      <ProjectDetailPageSidebar />
      {!isStaticDataSet ||
      isLoadingProjectData ||
      isLoadingLastAssignmentEndDate ||
      isLoadingProjectsClientData ||
      isLoadingGeoPmNamesTalentIds ||
      isLoadingProjectsIdNameData ? (
        <AccordionSkeleton open={false} count={3} className="project-detail-page-skeleton" />
      ) : (
        <ProjectDetailsComponent projectInfo={projectData} />
      )}
    </>
  );
};

export default ProjectDetailPage;
