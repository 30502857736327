import { useEffect, useState } from 'react';
import { useCommonState, useFilteredProjects, useFilterTabs, useProject, useStaticData } from '../../../custom-hooks';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  filterObjectToUrlParams,
  trim,
  setUrlParamsString,
  getUrlsParamsString,
  urlParamsToFilterObject,
  FilterTabData,
  getSortedFilterValues,
  PageType,
  FilterTabInterface,
  getProjectFilterString,
} from '@cic-boardlite/common';
import {
  Button,
  ComboBox,
  DatePicker,
  DatePickerInput,
  FilterableMultiSelect,
  SideNav,
  SideNavItems,
  TextInput,
} from 'carbon-components-react';
import { FilterRemove, Search } from '@carbon/icons-react/next';
import { cloneDeep, isEqual } from 'lodash';
import { initialState } from '../../../data-access/filter-tabs/slice';
import ComboboxWithCustomFilter from '../../combobox-custom-filtering/combobox-custom-filtering';
import './filter.scss';

const ProjectsFilter = (): JSX.Element => {
  const [countryKey, setCountryKey] = useState<number>(Math.random());
  const { geoPmNamesTalentIds, getGeoPmNamesTalentIdsData } = useProject();
  const { setFilteredProjects, isLoadingFilteredProjects, actionRequiredProjectsNumber } = useFilteredProjects();
  const {
    projectFilterTabsData,
    activeProjectFilterTab,
    setCurrentFilterTab,
    isCreatingFilterTab,
    isLoadingFilterTabs,
    setTabUnsavedContext,
    setIsCreatingFilterTab,
  } = useFilterTabs();
  const { isProjectsSideBarVisible } = useCommonState();
  const { staticData } = useStaticData();
  const { control, handleSubmit, reset, setValue } = useForm();
  const { t } = useTranslation('common');
  
  const currentSelectedFilters = useWatch({ control });
  const projectStatusIsActive = staticData.staticData.projectStatusStaticData[0];
  const filterClassFlip: string = isProjectsSideBarVisible ? 'projects-filter-open' : '';

  const defaultValues = {
    projectId: '',
    name: '',
    clientName: '',
    projectAccountIds: '',
    projectStatus: projectStatusIsActive,
    serviceLine: '',
    projectType: '',
    geoPm: '',
    pmo: '',
    actionRequiredStatus: '',
    country: [],
    activeFrom: '',
    activeUntil: '',
    created: '',
  };

  useEffect(() => {
    if (!activeProjectFilterTab.context && !isCreatingFilterTab.projectTab) {
      setFilteredProjects(`?projectStatus=Active`);
      reset(defaultValues);
    }
    if (!activeProjectFilterTab.context) return;

    let filter: FilterTabInterface = {
      ...(activeProjectFilterTab.unsavedContext || activeProjectFilterTab.context),
    };

    if (!isCreatingFilterTab.projectTab) {
      const urlParamsString = getUrlsParamsString();

      if (
        urlParamsString &&
        urlParamsString !== filterObjectToUrlParams(filter) &&
        projectFilterTabsData.length > 1 &&
        activeProjectFilterTab.tabPosition !== 0 &&
        !activeProjectFilterTab.unsavedContext
      ) {
        filter = urlParamsToFilterObject({
          urlParamsString,
          activeFilter: filter,
          translations: t('projectsFilter', { returnObjects: true }),
          staticData: staticData.staticData,
          pageType: PageType.Project,
        });

        setCurrentFilterTab({
          ...activeProjectFilterTab,
          unsavedContext: filter,
          pageType: PageType.Project,
        });
      } else {
        setUrlParamsString(filterObjectToUrlParams(filter));
        setFilteredProjects('?' + filterObjectToUrlParams(filter));
      }

      reset(filter);
      setCountryKey(Math.random());
    }
  }, [activeProjectFilterTab?.context, activeProjectFilterTab?.tabPosition]);

  useEffect(() => {
    if (isLoadingFilterTabs || isLoadingFilteredProjects) return;

    if (isCreatingFilterTab.projectTab && activeProjectFilterTab.unsavedContext && !actionRequiredProjectsNumber) {
      setTabUnsavedContext(
        initialState.activeProjectFilterTab.id,
        activeProjectFilterTab.unsavedContext,
        PageType.Project
      );
    }

    if (!Object.keys(currentSelectedFilters).length) return;

    if (projectFilterTabsData.length <= 1 && !isCreatingFilterTab.projectTab) {
      const hasContext = activeProjectFilterTab.context;
      const isFiltersEqual = isFilterEqual(activeProjectFilterTab.context, currentSelectedFilters);
      const isActionRequiredCount = actionRequiredProjectsNumber;
      const isNotDefaultTab = activeProjectFilterTab.id !== 0;

      if ((hasContext && !isFiltersEqual && isActionRequiredCount) || (!isActionRequiredCount && isNotDefaultTab)) {
        setIsCreatingFilterTab({
          practitionerTab: isCreatingFilterTab.practitionerTab,
          projectTab: true,
        });
        setTabUnsavedContext(initialState.activeProjectFilterTab.id, currentSelectedFilters, PageType.Project);
        return;
      }
    }

    setUrlParamsString(filterObjectToUrlParams(currentSelectedFilters));

    if (activeProjectFilterTab.tabPosition !== 0) {
      const activeFilterContext = cloneDeep(activeProjectFilterTab.unsavedContext || activeProjectFilterTab.context);

      if ('actionRequired' in currentSelectedFilters) delete currentSelectedFilters['actionRequired'];

      if (activeFilterContext && activeFilterContext['actionRequired'] in activeFilterContext)
        delete activeFilterContext['actionRequired'];

      if (!isFilterEqual(activeFilterContext, currentSelectedFilters)) {
        setTabUnsavedContext(activeProjectFilterTab.id, currentSelectedFilters, PageType.Project);
      }
    }
  }, [currentSelectedFilters]);

  useEffect(() => {
    if (!isCreatingFilterTab.projectTab) return;

    setCurrentFilterTab({
      ...initialState.activeProjectFilterTab,
      unsavedContext: activeProjectFilterTab?.unsavedContext,
      pageType: PageType.Project,
    });

    if (!activeProjectFilterTab?.unsavedContext) {
      resetHandler();
    } else {
      setFilteredProjects(`?${filterObjectToUrlParams(activeProjectFilterTab.unsavedContext)}`);

      reset(activeProjectFilterTab.unsavedContext);
    }
  }, [isCreatingFilterTab.projectTab]);

  const isFilterEqual = (filterA: FilterTabData['context'], filterB: FilterTabData['context']): boolean => {
    if (!filterA || !filterB) return false;

    return isEqual(getSortedFilterValues(filterA), getSortedFilterValues(filterB));
  };

  const resetHandler = () => {
    reset(defaultValues);
    setCountryKey(Math.random());
  };

  const onFilterReset = () => {
    if (isCreatingFilterTab.projectTab || projectFilterTabsData.length <= 1) {
      resetHandler();
      setFilteredProjects(`?projectStatus=${projectStatusIsActive.title}`);
      setUrlParamsString(`projectStatus=${projectStatusIsActive.title}`);
      return;
    }

    setCurrentFilterTab(activeProjectFilterTab);
    setUrlParamsString(filterObjectToUrlParams(activeProjectFilterTab?.context));
    setTabUnsavedContext(activeProjectFilterTab.id, undefined, PageType.Project);

    reset(activeProjectFilterTab?.context);
    setCountryKey(Math.random());
  };

  useEffect(() => {
    getGeoPmNamesTalentIdsData();
    setUrlParamsString(`projectStatus=${projectStatusIsActive.title}`);
  }, []);

  const pmo = staticData.staticData.pmoStaticData.map((pmo: { id: number; firstName: string; lastName: string }) => {
    return {
      id: pmo.id,
      title: pmo.firstName + ' ' + pmo.lastName,
    };
  });
  
  const formInputs = [
    {
      name: 'projectId',
      id: 'filterProjectId',
      placeholder: t('projectsFilter.projectId'),
    },
    {
      name: 'name',
      id: 'filterProjectName',
      placeholder: t('projectsFilter.projectName'),
    },
    {
      name: 'clientName',
      id: 'filterClientName',
      placeholder: t('projectsFilter.clientName'),
    },
    {
      name: 'projectAccountIds',
      id: 'filterProjectAccountIds',
      placeholder: t('projectsFilter.accountIds'),
    },
  ];

  const datePickers = [
    {
      name: 'activeFrom',
      id: 'filterProjectStartDate',
      placeholder: t('projectsFilter.activeFrom'),
    },
    {
      name: 'activeUntil',
      id: 'filterProjectEndDate',
      placeholder: t('projectsFilter.activeUntil'),
    },
    {
      name: 'created',
      id: 'filterProjectCreatedDate',
      placeholder: t('projectsFilter.createdDate'),
    },
  ];

  const formReadonlyComboboxes = [
    {
      name: 'projectStatus',
      id: 'filterProjectStatus',
      placeholder: t('projectsFilter.projectStatus'),
      options: staticData.staticData.projectStatusStaticData,
    },
    {
      name: 'projectType',
      id: 'filterProjectType',
      placeholder: t('projectsFilter.projectType'),
      options: staticData.staticData.projectTypeStaticData,
    },
    {
      name: 'serviceLine',
      id: 'filterServiceLine',
      placeholder: t('projectsFilter.serviceLine'),
      options: staticData.staticData.serviceLineStaticData,
    },
    {
      name: 'actionRequiredStatus',
      id: 'filterActionRequired',
      placeholder: t('projectsFilter.actionRequired.placeholder'),
      options: [
        { id: 1, title: t('projectsFilter.actionRequired.high') },
        { id: 2, title: t('projectsFilter.actionRequired.medium') },
        { id: 3, title: t('projectsFilter.actionRequired.low') },
      ],
    },
  ];

  const formFilterComboboxes = [
    {
      name: 'geoPm',
      id: 'filterGeoPm',
      placeholder: t('projectsFilter.geoPm'),
      options: geoPmNamesTalentIds.map((geoPmName) => {
        return { id: geoPmName.talentId, name: geoPmName.name };
      }),
      customClassName: 'custom-filter-combobox-projects-filter-geo-pm',
    },
    {
      name: 'pmo',
      id: 'filterPmo',
      placeholder: t('projectsFilter.pmo'),
      options: pmo,
      customClassName: 'custom-filter-combobox-projects-filter-pmo',
    },
  ];

  return (
    <>
      <div className={filterClassFlip}></div>
      <div
        className={`projects-filter-container container ${
          (isCreatingFilterTab.projectTab || activeProjectFilterTab.unsavedContext) &&
          isProjectsSideBarVisible &&
          !isLoadingFilterTabs &&
          'projects-filter-border'
        }`}
      >
        <SideNav isFixedNav isChildOfHeader={true} expanded={isProjectsSideBarVisible} aria-label="Side navigation">
          <SideNavItems className="projects-filter-side-nav">
            <form
              className="projects-filter-form"
              onSubmit={handleSubmit((data) => setFilteredProjects(getProjectFilterString(data)))}
            >
              <div>
                {formInputs.map((input, index) => (
                  <Controller
                    key={index}
                    name={input.name}
                    control={control}
                    render={({ field: { onChange, value = '' } }) => (
                      <TextInput
                        onChange={onChange}
                        onBlur={(event) => {
                          setValue(input.name, trim(event.target.value));
                        }}
                        id={input.id}
                        labelText=""
                        placeholder={input.placeholder}
                        value={value}
                        className="filter-input"
                      />
                    )}
                  />
                ))}
                {datePickers.map((field, index) => (
                  <Controller
                    key={index}
                    name={field.name}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        light
                        onChange={(date) => {
                          onChange(date);
                        }}
                        dateFormat={t('format.date_4carbonDatePicker')}
                        datePickerType="single"
                        value={value}
                        className="filter-input"
                        title={field.placeholder}
                      >
                        <DatePickerInput
                          id={field.id}
                          placeholder={field.placeholder}
                          labelText=""
                          autoComplete="off"
                          pattern={t('format.date_4carbonDatePickerInput')}
                        />
                      </DatePicker>
                    )}
                  />
                ))}
                {formReadonlyComboboxes.map((field, index) => (
                  <Controller
                    key={index}
                    name={field.name}
                    control={control}
                    render={({
                      field: { onChange, value = field.name === 'projectStatus' ? projectStatusIsActive : '' },
                    }) => (
                      <ComboBox
                        onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                        id={field.id}
                        items={field.options}
                        itemToString={(item) => item?.title}
                        placeholder={field.placeholder}
                        selectedItem={value}
                        className={`${value && 'has-selection'} read-only-combobox
                        `}
                        readOnly
                        title={field.placeholder}
                      />
                    )}
                  />
                ))}
                {formFilterComboboxes.map((field, index) => (
                  <Controller
                    key={index}
                    name={field.name}
                    control={control}
                    render={({ field: { onChange, value = '' } }) => (
                      <ComboboxWithCustomFilter
                        customClassName={field.customClassName}
                        onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                        selectedItem={value}
                        items={field.options}
                        itemKeyName={field?.options[0]?.title ? 'title' : 'name'}
                        placeholder={field.placeholder}
                        displaySelectedValueBolded
                      />
                    )}
                  />
                ))}
                <Controller
                  key={countryKey}
                  name="country"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div title={t('projectsFilter.countryLabel')}>
                      <FilterableMultiSelect
                        onChange={(onChangeData) => onChange(onChangeData.selectedItems)}
                        id="filterCountryItem"
                        items={staticData.staticData.countryStaticData}
                        placeholder={t('projectsFilter.countryLabel')}
                        hideLabel
                        initialSelectedItems={value}
                        selectedItems={value}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="projects-filter-button-wrap">
                <Button kind="secondary" renderIcon={FilterRemove} onClick={onFilterReset}>
                  {t('projectsFilter.resetButton')}
                </Button>
                <Button type="submit" renderIcon={Search}>
                  {t('projectsFilter.searchButton')}
                </Button>
              </div>
            </form>
          </SideNavItems>
        </SideNav>
      </div>
    </>
  );
};

export default ProjectsFilter;
