import { HeadersInterface } from '@cic-boardlite/common';

export const headers: HeadersInterface[] = [
  {
    key: 'nameFirstLast',
    header: '',
  },
  {
    key: 'band',
    header: '',
  },
  {
    key: 'costRate',
    header: ''
  },
  {
    key: 'availabilityDate',
    header: '',
  },
  {
    key: 'actionRequiredStatus',
    header: '',
  },
  {
    key: 'jrs',
    header: '',
  },
  {
    key: 'serviceLine',
    header: '',
  },
];
