import { useAppSelector } from '../state-configurations/hooks';

export const useScope = () => {
  const userRoles: string[] = useAppSelector((state) => state.user.roles);
  const userScopes: string[] = useAppSelector((state) => {
    return state.user.scopes;
  });

  return { userRoles, userScopes };
};
