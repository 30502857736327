import { UpdatedPractitionerSummary } from '@cic-boardlite/common';
import { createSlice } from '@reduxjs/toolkit';
import { PractitionersStateInterface } from '../../interfaces/practitionerInterfaces';

export const initialState: PractitionersStateInterface = {
  allPractitionersData: { bench: [], futureBench: [], hiringPipeline: [] },
  isLoadingPractitioners: false,
  filteredPractitionerData: {
    filteredPractitioners: [],
    numberOfFilteredPractitioners: undefined,
  },
  isLoadingActionRequiredPractitionersNumber: false,
  actionRequiredPractitionersNumber: 0,
  practitionerNames: [],
  isPractitionerNamesSet: false,
  isLoadingFilteredPractitioners: false,
  selectedPractitioner: [],
  isLoadingPractitionerNames: false,
  isUpdatingCvStatuses: false,
  selectedPractitionerDetails: {
    id: '',
    serial: '',
    isCic: false,
    firstName: '',
    lastName: '',
    practitionerTag: '',
    country: '',
    band: '',
    bandId: 0,
    jrs: '',
    jrsId: 0,
  },
  isLoadingPractitionerDetails: false,
  practitionerAssignments: [],
  filterPractitionerAssignmentsString: '',
  isLoadingPractitionerAssignments: false,
  isDeletingPractitionerAssignment: false,
  isSendingPractitionerAssignmentEmail: false,
  isCreatingPractitioner: false,
  practitionerCreateFailed: false,
  isCreatingUpdatingPractitionerAssignment: false,
  wasCreatedUpdatedPractitionerAssignment: false,
  isLoadingHiringPipelinePractitioners: false,
  hiringPipelinePractitioners: [],
  isPractitionersEmailsSet: false,
  practitionersEmailsData: [],
  practitionersPemWmpServiceLineLeadersEmails: {
    wmpEmails: [],
    pemEmails: [],
    pmoEmails: [],
    practitionerEmails: [],
    serviceLineLeadersEmails: [],
  },
  isDeletingPractitioner: false,
  isUpdateAvailabilityDateModalOpen: false,
  isCalculatingPractitionerAvailabilityDate: false,
  updateAvailabilityDateModalInfo: {
    practitionerId: -1,
    availabilityDate: new Date(),
    newAvailabilityDate: new Date(),
    message: '',
    oldAvailabilityDateManuallyEdited: undefined,
  },
  cvModalIsOpen: false,
  updateSingleFieldType: '',

  isLoadingAuditLogs: false,
  auditLogsData: {
    auditLogs: [],
    numberOfAuditLogs: 0,
  },
  practitionerAssignmentsCount: 0,
  isLoadingPractitionerAssignmentsCount: false,
};

const practitionersSlice = createSlice({
  name: 'practitioners',
  initialState,
  reducers: {
    getPractitioners(state) {
      state.isLoadingPractitioners = true;
    },
    createPractitionerFailed(state) {
      state.isCreatingPractitioner = false;
      state.practitionerCreateFailed = true;
    },
    updatePractitioner(state, action) {
      state.isLoadingPractitioners = true;
    },
    updatePractitionerFailed(state) {
      state.isLoadingPractitioners = false;
    },
    getPractitionersSuccess(state, action) {
      state.allPractitionersData.bench = action.payload.bench;
      state.allPractitionersData.futureBench = action.payload.futureBench;
      state.allPractitionersData.hiringPipeline = action.payload.hiringPipeline;
      state.isLoadingPractitioners = false;
    },
    getPractitionersFailed(state) {
      state.isLoadingPractitioners = false;
    },
    getActionRequiredPractitionersNumber(state) {
      state.isLoadingActionRequiredPractitionersNumber = true;
    },
    getActionRequiredPractitionersNumberSuccess(state, action) {
      state.actionRequiredPractitionersNumber = action.payload;
      state.isLoadingActionRequiredPractitionersNumber = false;
    },
    getActionRequiredPractitionersNumberFailed(state) {
      state.isLoadingActionRequiredPractitionersNumber = false;
    },
    getFilteredPractitioners(state, action) {
      state.isLoadingFilteredPractitioners = true;
      state.filteredPractitionerData = initialState.filteredPractitionerData;
    },
    getFilteredPractitionersSuccess(state, action) {
      state.filteredPractitionerData = action.payload;
      state.isLoadingFilteredPractitioners = false;
    },
    getFilteredPractitionersFailed(state) {
      state.isLoadingFilteredPractitioners = false;
    },
    resetFilteredPractitioners(state, action) {
      state.filteredPractitionerData = action.payload ? action.payload : initialState.filteredPractitionerData;
    },
    setSelectedPractitioner(state, action) {
      state.selectedPractitioner.push(action.payload);
    },
    removeSelectedPractitioner(state, action) {
      state.selectedPractitioner = state.selectedPractitioner.filter(
        (practitioner) => practitioner.id !== action.payload
      );
      if (!state.selectedPractitioner.length)
        state.selectedPractitioner = initialState.selectedPractitioner;
    },
    clearAllSelectedPractitioners(state) {
      state.selectedPractitioner = initialState.selectedPractitioner;
    },
    updatePractitionersCv(state, action) {
      state.isUpdatingCvStatuses = true;
    },
    updatePractitionersCvSuccess(state, action) {
      state.selectedPractitioner = addCvFilenamesToPractitioners(
        state,
        action.payload
      );
      state.isUpdatingCvStatuses = false;
    },
    updatePractitionersCvFailed(state) {
      state.isUpdatingCvStatuses = false;
    },
    getPractitionerDetails(state, action) {
      state.isLoadingPractitionerDetails = true;
    },
    getPractitionerDetailsSuccess(state, action) {
      state.selectedPractitionerDetails = action.payload;
      state.isLoadingPractitionerDetails = false;
    },
    getPractitionerDetailsFailed(state) {
      state.isLoadingPractitionerDetails = false;
    },
    getPractitionerAssignments(state, action) {
      state.isLoadingPractitionerAssignments = true;
    },
    getPractitionerAssignmentsSuccess(state, action) {
      state.practitionerAssignments = action.payload;
      state.isLoadingPractitionerAssignments = false;
    },
    getPractitionerAssignmentsFailed(state) {
      state.isLoadingPractitionerAssignments = false;
    },
    deletePractitionerAssignment(state, action) {
      state.isDeletingPractitionerAssignment = true;
    },
    deletePractitionerAssignmentSuccess(state, action) {
      state.isDeletingPractitionerAssignment = false;
    },
    deletePractitionerAssignmentFailed(state) {
      state.isDeletingPractitionerAssignment = false;
    },
    deletePractitionerAssignmentFromProjectPage(state, action) {
      state.isDeletingPractitionerAssignment = true;
    },
    deletePractitionerAssignmentFromProjectPageSuccess(state, action) {
      state.isDeletingPractitionerAssignment = false;
    },
    deletePractitionerAssignmentFromProjectPageFailed(state) {
      state.isDeletingPractitionerAssignment = false;
    },
    sendPractitionerAssignmentEmail(state, action) {
      state.isSendingPractitionerAssignmentEmail = true;
    },
    sendPractitionerAssignmentEmailSuccess(state, action) {
      state.isSendingPractitionerAssignmentEmail = false;
    },
    sendPractitionerAssignmentEmailFailed(state) {
      state.isSendingPractitionerAssignmentEmail = false;
    },
    createPractitioner(state, action) {
      state.isCreatingPractitioner = true;
    },
    createPractitionerSuccess(state) {
      state.isCreatingPractitioner = false;
      state.practitionerCreateFailed = false;
    },
    updatePractitionerSuccess(state, action) {
      return;
    },
    createPractitionerAssignment(state, action) {
      state.isCreatingUpdatingPractitionerAssignment = true;
    },
    createUpdatePractitionerAssignmentSuccess(state) {
      state.isCreatingUpdatingPractitionerAssignment = false;
      state.wasCreatedUpdatedPractitionerAssignment = true;
    },
    createUpdatePractitionerAssignmentFailed(state) {
      state.isCreatingUpdatingPractitionerAssignment = false;
      state.wasCreatedUpdatedPractitionerAssignment = false;
    },
    createUpdatePractitionerAssignmentReset(state) {
      state.wasCreatedUpdatedPractitionerAssignment = false;
    },
    updatePractitionerAssignment(state, action) {
      state.isCreatingUpdatingPractitionerAssignment = true;
    },
    getPractitionerNames(state) {
      state.isLoadingPractitionerNames = true;
    },
    getPractitionerNamesSuccess(state, action) {
      state.practitionerNames = action.payload;
      state.isLoadingPractitionerNames = false;
      state.isPractitionerNamesSet = true;
    },
    getPractitionerNamesFailed(state) {
      state.isLoadingPractitionerNames = false;
      state.isPractitionerNamesSet = false;
    },
    getHiringPipelinePractitioners(state) {
      state.isLoadingPractitionerAssignments = true;
    },
    getHiringPipelinePractitionersSuccess(state, action) {
      state.hiringPipelinePractitioners = action.payload;
      state.isLoadingHiringPipelinePractitioners = false;
    },
    getHiringPipelinePractitionersFailed(state) {
      state.isLoadingHiringPipelinePractitioners = false;
    },
    getPractitionersEmails(state) {
      state.isPractitionersEmailsSet = false;
      state.practitionersEmailsData = [];
    },
    getPractitionersEmailsSuccess(state, action) {
      const { practitionerEmails, ...remainingGeoPmPemWmpEmails } =
        action.payload;
      state.practitionersEmailsData = practitionerEmails;
      state.practitionersPemWmpServiceLineLeadersEmails =
        remainingGeoPmPemWmpEmails;
      state.isPractitionersEmailsSet = true;
    },
    getPractitionersEmailsFailed(state) {
      state.isPractitionersEmailsSet = true;
    },
    deletePractitioner(state, action) {
      state.isDeletingPractitioner = true;
    },
    deletePractitionerSuccess(state) {
      state.isDeletingPractitioner = false;
    },
    deletePractitionerFailed(state) {
      state.isDeletingPractitioner = false;
    },
    closeUpdatePractitionerAvailabilityDateModal(state) {
      state.isUpdateAvailabilityDateModalOpen = false;
      state.updateAvailabilityDateModalInfo.practitionerId = -1;
    },
    openOrCloseCvModal(state) {
      state.cvModalIsOpen = !state.cvModalIsOpen;
    },
    setUpdateSingleFieldType(state, action) {
      state.updateSingleFieldType = action.payload;
    },
    singleFieldUpdateSuccess(state, action) {
      state.updateSingleFieldType = '';
    },
    calculatePractitionerAvailabilityDate(state, action) {
      state.isCalculatingPractitionerAvailabilityDate = true;
    },
    calculatePractitionerAvailabilityDateSuccess(state, action) {
      state.updateAvailabilityDateModalInfo = {
        practitionerId: action.payload.practitionerId,
        availabilityDate: new Date(action.payload.availabilityDate),
        newAvailabilityDate: new Date(action.payload.newAvailabilityDate),
        message: action.payload.message,
        oldAvailabilityDateManuallyEdited:
          action.payload.oldAvailabilityDateManuallyEdited,
      };
      state.isUpdateAvailabilityDateModalOpen =
        action.payload.availabilityUpdateNeeded;
      state.isCalculatingPractitionerAvailabilityDate = false;
    },
    calculatePractitionerAvailabilityDateFailed(state) {
      state.isUpdateAvailabilityDateModalOpen = false;
      state.isCalculatingPractitionerAvailabilityDate = false;
    },

    getAuditLogsData(state, action) {
      state.isLoadingAuditLogs = true;
    },
    getAuditLogsSuccess(state, action) {
      state.auditLogsData = action.payload;
      state.isLoadingAuditLogs = false;
    },
    getAuditLogsFailed(state) {
      state.isLoadingAuditLogs = false;
    },
    countPractitionerAssignments(state, action) {
      state.isLoadingPractitionerAssignmentsCount = true;
    },
    countPractitionerAssignmentsSuccess(state, action) {
      state.practitionerAssignmentsCount = action.payload;
      state.isLoadingPractitionerAssignmentsCount = false;
    },
    countPractitionerAssignmentsFailed(state) {
      state.isLoadingPractitionerAssignmentsCount = false;
    },
    setFilterPractitionerAssignmentsString(state, action) {
      state.filterPractitionerAssignmentsString = action.payload;
    },
    resetFilterPractitionerAssignmentsString(state) {
      state.filterPractitionerAssignmentsString = initialState.filterPractitionerAssignmentsString;
    },
  },
});

function addCvFilenamesToPractitioners(
  state: PractitionersStateInterface,
  payload: UpdatedPractitionerSummary[]
) {
  const selectedCopy = [...state.selectedPractitioner];
  for (const updatedPractitionerObj of payload) {
    const index = selectedCopy.findIndex(
      (copiedPractitioner) =>
        copiedPractitioner.serial === updatedPractitionerObj.serial
    );
    if (index >= 0) {
      if (
        Object.prototype.hasOwnProperty.call(selectedCopy[index], 'fileName')
      ) {
        selectedCopy[index].fileName = updatedPractitionerObj.filename;
      } else {
        selectedCopy[index]['fileName'] = updatedPractitionerObj.filename;
      }
    }
  }

  return selectedCopy;
}

export const {
  getPractitioners,
  getPractitionersSuccess,
  getPractitionersFailed,
  getActionRequiredPractitionersNumber,
  getActionRequiredPractitionersNumberSuccess,
  getFilteredPractitioners,
  getFilteredPractitionersSuccess,
  getFilteredPractitionersFailed,
  resetFilteredPractitioners,
  setSelectedPractitioner,
  removeSelectedPractitioner,
  clearAllSelectedPractitioners,
  updatePractitionersCv,
  updatePractitionersCvSuccess,
  updatePractitionersCvFailed,
  getPractitionerDetails,
  getPractitionerDetailsSuccess,
  getPractitionerDetailsFailed,
  getPractitionerAssignments,
  getPractitionerAssignmentsSuccess,
  getPractitionerAssignmentsFailed,
  deletePractitionerAssignmentSuccess,
  deletePractitionerAssignment,
  deletePractitionerAssignmentFailed,
  deletePractitionerAssignmentFromProjectPage,
  sendPractitionerAssignmentEmail,
  sendPractitionerAssignmentEmailSuccess,
  sendPractitionerAssignmentEmailFailed,
  createPractitionerFailed,
  createPractitionerSuccess,
  createPractitioner,
  updatePractitionerFailed,
  updatePractitioner,
  createPractitionerAssignment,
  createUpdatePractitionerAssignmentSuccess,
  createUpdatePractitionerAssignmentFailed,
  updatePractitionerAssignment,
  createUpdatePractitionerAssignmentReset,
  getPractitionerNames,
  getPractitionerNamesSuccess,
  getPractitionerNamesFailed,
  getHiringPipelinePractitioners,
  getHiringPipelinePractitionersSuccess,
  getHiringPipelinePractitionersFailed,
  getPractitionersEmails,
  getPractitionersEmailsSuccess,
  getPractitionersEmailsFailed,
  deletePractitioner,
  deletePractitionerSuccess,
  deletePractitionerFailed,
  closeUpdatePractitionerAvailabilityDateModal,
  openOrCloseCvModal,
  setUpdateSingleFieldType,
  singleFieldUpdateSuccess,
  calculatePractitionerAvailabilityDate,
  calculatePractitionerAvailabilityDateSuccess,
  calculatePractitionerAvailabilityDateFailed,
  getAuditLogsData,
  getAuditLogsSuccess,
  getAuditLogsFailed,
  countPractitionerAssignments,
  countPractitionerAssignmentsSuccess,
  countPractitionerAssignmentsFailed,
  setFilterPractitionerAssignmentsString,
  resetFilterPractitionerAssignmentsString,
} = practitionersSlice.actions;
export default practitionersSlice.reducer;
