import { DataTable, Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from 'carbon-components-react';
import { PractitionerInterface } from '../../interfaces/practitionerInterfaces';
import { Cells, HeaderInfo, HeadersInterface, Rows, sortData, DataSortOrder, actionSort, toFixedValue } from '@cic-boardlite/common';
import { useTranslation } from 'react-i18next';
import { headers } from '../../feature/practitioner/practitioner-table-expandable/headers-data';
import { DataTableSortState } from 'carbon-components-react/lib/components/DataTable/state/sorting';
import { useNavigate } from 'react-router-dom';
import './practitioners-table.scss';
import { useCallback, useEffect, useState } from 'react';

interface PropsInterface {
  tableData: PractitionerInterface[];
  summaryType: string;
}
const PractitionersTable = ({ tableData, summaryType }: PropsInterface) => {
  const [filteredData, setFilteredData] = useState<PractitionerInterface[]>([]);
  const [initSortState, setInitSortState] = useState<boolean>(true);
  const [sortDirection, setSortDirection] = useState<DataTableSortState>(DataSortOrder.DESC);
  const [sortHeader, setSortHeader] = useState<string>('availabilityDate');

  const { t } = useTranslation('common');
  const nav = useNavigate();

  const dynamicSort = useCallback(
    (property: string) => {
      let sortOrder = 0;

      if (sortDirection === DataSortOrder.ASC) {
        sortOrder = -1;
      } else if (sortDirection === DataSortOrder.DESC) {
        sortOrder = 1;
      }

      return (practitionerA: PractitionerInterface, practitionerB: PractitionerInterface) => {
        const key = property as keyof typeof practitionerA;

        if (practitionerA[key] === null) {
          return -1 * sortOrder;
        }
        if (practitionerB[key] === null) {
          return 1 * sortOrder;
        }

        const valueA = (practitionerA[key] || '').toString().toLowerCase();
        const valueB = (practitionerB[key] || '').toString().toLowerCase();

        let result;

        if (property === 'actionRequiredStatus') {
          result = actionSort(valueA, valueB);
        } else if (property === 'band') {
          return sortData(valueA, valueB, property, sortDirection);
        } else if (property === 'costRate') {
          result = Number(valueA) > Number(valueB) ? 1 : -1;
        } else {
          result = valueA.localeCompare(valueB);
        }
        return result * sortOrder;
      };
    },
    [sortDirection]
  );

  useEffect(() => {
    if (sortDirection !== DataSortOrder.NONE) {
      const sortedData = [...tableData].sort(dynamicSort(sortHeader));
      return setFilteredData(sortedData);
    }

    return setFilteredData(tableData);
  }, [sortHeader, sortDirection, tableData, dynamicSort]);

  const handleSortDirection = (header: string) => {
    setSortHeader(header);

    if (initSortState) {
      setInitSortState(false);
    }

    if (sortDirection === DataSortOrder.NONE) {
      return setSortDirection(DataSortOrder.DESC);
    }

    if (sortDirection === DataSortOrder.DESC) {
      return setSortDirection(DataSortOrder.ASC);
    }

    return setSortDirection(DataSortOrder.NONE);
  };

  const handleNavigate = (id: string) => {
    if (summaryType === 'bench' || summaryType === 'futureBench') {
      return nav(`/practitioner/${id}/assignments`);
    }

    return nav(`/practitioner/${id}`);
  };

  const addClassName = (cell: Cells): string => {
    switch (cell.info.header) {
      case 'actionRequiredStatus':
        return `${cell.value}-practitionerSummaryActionTableCell`;
      case 'nameFirstLast':
        return 'highlighted-initial-page-nav-text';
      case 'band':
        return 'band-cell';
      case 'availabilityDate':
        return 'availability-date-cell';
      case 'jrs':
        return 'jrs-cell';
      case 'serviceLine':
        return 'service-line-cell';
      default:
        return '';
    }
  };

  const handleSortArrowDirection = (header: string): DataSortOrder.DESC | DataTableSortState => {
    return initSortState && header === 'availabilityDate' ? DataSortOrder.DESC : sortDirection;
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <DataTable useZebraStyles isSortable rows={filteredData} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
      }: {
        rows: Rows[];
        headers: HeadersInterface[];
        getHeaderProps: ({ header }: any) => HeaderInfo[];
        getRowProps: ({ row }: any) => Rows[];
      }) => (
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableHeader
                  key={index}
                  {...getHeaderProps({ header })}
                  onClick={() => handleSortDirection(header.key)}
                  isSortHeader={sortHeader === header.key}
                  sortDirection={handleSortArrowDirection(header.key)}
                >
                  {t(`tableHeader.${header.key}`)}
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} {...getRowProps({ row })}>
                {row.cells.map((cell: Cells) => (
                  <TableCell className={addClassName(cell)} key={cell.id}>
                    <span
                      onClick={() => {
                        cell.info.header === 'nameFirstLast' && handleNavigate(row.id);
                      }}
                    >
                      {cell.info.header === 'costRate' ? toFixedValue(cell.value, '-') : cell.value}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </DataTable>
  );
};

export default PractitionersTable;
