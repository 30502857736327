import { Fragment, useEffect, useState } from 'react';
import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Link,
  TooltipDefinition,
} from 'carbon-components-react';
import {
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  TableContainer,
} from 'carbon-components-react/lib/components/DataTable';
import { headers } from './headers-data';
import {
  AssignmentSummaryInterface,
  AssignmentsDataTableInterface,
  DownloadDataPages,
  checkIfAssignmentIsMain,
  filterAssignmentsWithToolbarSearchValue,
} from '@cic-boardlite/common';
import './assignment-table-expandable.scss';
import { useTranslation } from 'react-i18next';
import { usePractitionerAssignments } from '../../../custom-hooks';
import PractitionerAssignmentsTableExpandedRow from '../practitioner-assignment-table-expanded-row/assignment-table-expanded-row';
import DataDownloadToolbar from '../../data-download-toolbar/data-download-toolbar';
import { StarFilled16 } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';
import AssignmentEndDate from '../../../ui/assignments-end-date/assignment-end-date';

const PractitionerAssignmentTableExpandable = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [isRowExpanded, setIsRowExpanded] = useState([false]);
  const [filteredAssignmentRows, setFilteredAssignmentRows] = useState<AssignmentSummaryInterface[]>([]);
  const [assignment, setAssignment] = useState<AssignmentSummaryInterface>();
  const [assignmentEndDateToolTip, setAssignmentEndDateToolTip] = useState<string | null>(null);

  const {
    isLoadingSelectedPractitionersAssignments,
    practitionerAssignmentsDetails,
    practitionerAssignmentsFilterString,
  } = usePractitionerAssignments();

  useEffect(() => {
    setIsRowExpanded([]);

    if (isLoadingSelectedPractitionersAssignments) return;

    if (practitionerAssignmentsDetails?.length && !practitionerAssignmentsDetails[0].status) return;

    const practitionerAssignmentsList = practitionerAssignmentsFilterString?.length
      ? filterAssignmentsWithToolbarSearchValue(practitionerAssignmentsDetails, practitionerAssignmentsFilterString)
      : practitionerAssignmentsDetails;

    const assignmentRowData: AssignmentSummaryInterface[] = practitionerAssignmentsList.map((assignment) => ({
      id: assignment.id?.toString(),
      startDate: assignment.startDate?.toString(),
      endDate: assignment.endDate?.toString(),
      isMainAssignment: assignment.mainAssignment,
      accountIds: assignment.project.accountIds?.toString(),
      uwh: assignment.uwh,
      uwhStart: assignment.uwhStartDate?.toString(),
      uwhEnd: assignment.uwhEndDate?.toString(),
      country: assignment.project?.country?.toString(),
      pmpSeat: assignment.pmpSeat,
      projectIds: assignment.project.id,
      projectId: assignment.project.projectId?.toString(),
      projectName: assignment.project.name,
      clientName: assignment.project.clientName,
      assignmentStatus: assignment.status.title,
      assignmentStatusId: assignment.status.id,
      allocation: assignment.allocation,
      geoPm: assignment.project.geoPm,
      geoPmTalentId: assignment.project.geoPmTalentId,
      pmo: assignment.project.pmo,
      firstName: assignment.practitioner.firstName,
      lastName: assignment.practitioner.lastName,
      serial: assignment.practitioner.serial,
      onboardingEmailSent: assignment.onboardingEmailSent,
      offboardingEmailSent: assignment.offboardingEmailSent,
      assignmentDetailsEmailSent: assignment.assignmentDetailsEmailSent,
      assignmentExtensionEmailSent: assignment.assignmentExtensionEmailSent,
      extensionRequestEmailSent: assignment.extensionRequestEmailSent,
      dataRemovalEmailSent: assignment.dataRemovalEmailSent,
      cells: [],
    }));

    assignmentRowData.sort((cellA, cellB) => {
      if (cellA.assignmentStatus < cellB.assignmentStatus) {
        return 1;
      } else return -1;
    });

    setFilteredAssignmentRows(assignmentRowData);
  }, [practitionerAssignmentsFilterString]);

  const getFilteredAssignments = (id: string) => {
    return filteredAssignmentRows.find((object) => object.id === id);
  };

  const handleRowExpansion = (
    rows: AssignmentSummaryInterface[],
    expandedRowId: string,
    nodeName?: string,
    index?: number
  ) => {
    setAssignment(undefined);
    setAssignment(getFilteredAssignments(expandedRowId));

    if (index === undefined && nodeName !== 'svg' && nodeName !== 'path' && nodeName !== 'BUTTON') return;

    for (let i = 0; i < rows.length; i++) {
      isRowExpanded[i] = rows[i].id === expandedRowId && !isRowExpanded[i];
    }

    setIsRowExpanded([...isRowExpanded]);
  };

  const handleNavigate = (rowId: string) => {
    const assignment = getFilteredAssignments(rowId);
    navigate(`/projects/${assignment?.projectIds}/assignments`);
  };

  const isNotSpecificHeader = (header: string) => {
    const headerArr = ['projectId', 'endDate', 'assignmentStatus'];

    return !headerArr.includes(header);
  };

  return isLoadingSelectedPractitionersAssignments ? (
    <DataTableSkeleton
      columnCount={headers.length + 2}
      headers={headers}
      rowCount={filteredAssignmentRows.length}
      showHeader={false}
      showToolbar={false}
    />
  ) : (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <DataTable isSortable headers={headers} rows={filteredAssignmentRows}>
      {({ rows, headers, getTableProps, getHeaderProps, getRowProps }: AssignmentsDataTableInterface) => {
        return (
          <TableContainer>
            <DataDownloadToolbar page={DownloadDataPages.PractitionerAssignments} />
            {filteredAssignmentRows?.length ? (
              <Table {...getTableProps()}>
                <TableHead className="practitionerAssignmentTableHeaders">
                  <TableRow onClick={() => setIsRowExpanded([])}>
                    <TableExpandHeader />
                    {headers.map((header, index) => (
                      <TableHeader
                        key={index}
                        {...getHeaderProps({ header })}
                        className={`
                        ${header.key === 'assignmentStatus' && 'practitioner-assignment-status-header-padding'}
                        ${header.key === 'endDate' && 'practitioner-assignments-endDate-header-padding'}
                      `}
                      >
                        {t(`assignmentsTableHeader.${header.key}`)}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <Fragment key={index}>
                        <TableExpandRow
                          className="practitionerAssignmentTableExpandableRow"
                          onClick={(e) => {
                            handleRowExpansion(rows, row.id, (e.target as HTMLElement).nodeName, undefined);
                          }}
                          {...getRowProps({ row })}
                          isExpanded={isRowExpanded[index] === undefined ? false : isRowExpanded[index]}
                        >
                          {row.cells.map((cell, index) => {
                            return (
                              <TableCell
                                onClick={() => handleRowExpansion(rows, row.id, undefined, index)}
                                key={index}
                                className={`practitioner-assignments-table-max-cell-width ${
                                  cell.info.header === 'assignmentStatus' ? `${cell.value}-assignment-cell` : ''
                                }
                                ${cell.info.header === 'allocation' && 'practitioner-assignments-allocation'}
                                ${cell.info.header === 'assignmentStatus' && 'practitioner-assignments-status-padding'}
                                `}
                              >
                                {cell.info.header === 'projectId' && (
                                  <Link onClick={() => handleNavigate(row.id)} className="bold">
                                    {cell.value}
                                  </Link>
                                )}
                                {cell.info.header === 'endDate' && (
                                  <AssignmentEndDate
                                    id={row.id}
                                    value={cell.value}
                                    assignmentEndDateToolTip={assignmentEndDateToolTip}
                                    setAssignmentEndDateToolTip={setAssignmentEndDateToolTip}
                                  />
                                )}
                                {cell.info.header === 'assignmentStatus' && (
                                  <span className="assignment-status">
                                    {cell.value}
                                    {checkIfAssignmentIsMain(practitionerAssignmentsDetails, row.id) && (
                                      <TooltipDefinition
                                        align="end"
                                        direction="bottom"
                                        tooltipText={t('misc.mainAssignment')}
                                        style={{ verticalAlign: 'text-top' }}
                                      >
                                        <StarFilled16 className="main-assignment-icon" />
                                      </TooltipDefinition>
                                    )}
                                  </span>
                                )}
                                {isNotSpecificHeader(cell.info.header) && cell.value}
                              </TableCell>
                            );
                          })}
                        </TableExpandRow>
                        <TableExpandedRow colSpan={headers?.length + 2}>
                          {assignment && row.id === assignment.id && (
                            <PractitionerAssignmentsTableExpandedRow assignmentDetails={assignment} />
                          )}
                        </TableExpandedRow>
                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <div className="practitioner-assignments-no-filtered-assignments">
                {t('dataDownloadToolbar.noAssignmentsFound')}
              </div>
            )}
          </TableContainer>
        );
      }}
    </DataTable>
  );
};
export default PractitionerAssignmentTableExpandable;
