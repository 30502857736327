import {
  exportAsExcelFile,
  exportPractitionersAssignments,
  exportProjectsAssignments,
  exportPractitioners,
  exportProjects,
  ImportExportFileStateInterface,
} from '@cic-boardlite/common';
import { createSlice } from '@reduxjs/toolkit';

export const initialState: ImportExportFileStateInterface = {
  isLoadingExportFile: false,
  isLoadingImportFile: false,
  isLoadingExportPractitionersSummary: false,
  isLoadingExportProjectsSummary: false,
  isLoadingExportPractitionersAssignmentsSummary: false,
  isLoadingExportProjectsAssignmentsSummary: false,
};

const fileImportExportSlice = createSlice({
  name: 'fileImportExport',
  initialState,
  reducers: {
    getExportFile(state, action) {
      state.isLoadingExportFile = true;
    },
    getExportFileSuccess(state, action) {
      exportAsExcelFile(action.payload);
      state.isLoadingExportFile = false;
    },
    getExportFileFailed(state) {
      state.isLoadingExportFile = false;
    },
    getImportFile(state, action) {
      state.isLoadingImportFile = true;
    },
    getImportFileSuccess(state) {
      state.isLoadingImportFile = false;
    },
    getImportFileFailed(state) {
      state.isLoadingImportFile = false;
    },
    getExportPractitionersSummary(state, action) {
      state.isLoadingExportPractitionersSummary = true;
    },
    getExportPractitionersSummarySuccess(state, action) {
      exportPractitioners(action.payload);
      state.isLoadingExportPractitionersSummary = false;
    },
    getExportPractitionersSummaryFailed(state) {
      state.isLoadingExportPractitionersSummary = false;
    },
    getExportPractitionersAssignmentsSummary(state, action) {
      state.isLoadingExportPractitionersAssignmentsSummary = true;
    },
    getExportPractitionersAssignmentsSummarySuccess(state, action) {
      exportPractitionersAssignments(action.payload);
      state.isLoadingExportPractitionersAssignmentsSummary = false;
    },
    getExportPractitionersAssignmentsSummaryFailed(state) {
      state.isLoadingExportPractitionersAssignmentsSummary = false;
    },
    getExportProjectsSummary(state, action) {
      state.isLoadingExportProjectsSummary = true;
    },
    getExportProjectsSummarySuccess(state, action) {
      exportProjects(action.payload);
      state.isLoadingExportProjectsSummary = false;
    },
    getExportProjectsSummaryFailed(state) {
      state.isLoadingExportProjectsSummary = false;
    },
    getExportProjectsAssignmentsSummary(state, action) {
      state.isLoadingExportProjectsAssignmentsSummary = true;
    },
    getExportProjectsAssignmentsSummarySuccess(state, action) {
      exportProjectsAssignments(action.payload);
      state.isLoadingExportProjectsAssignmentsSummary = false;
    },
    getExportProjectsAssignmentsSummaryFailed(state) {
      state.isLoadingExportProjectsAssignmentsSummary = false;
    },
  },
});

export const {
  getExportFile,
  getExportFileSuccess,
  getExportFileFailed,
  getImportFile,
  getImportFileSuccess,
  getImportFileFailed,
  getExportPractitionersSummary,
  getExportPractitionersSummarySuccess,
  getExportPractitionersSummaryFailed,
  getExportPractitionersAssignmentsSummary,
  getExportPractitionersAssignmentsSummarySuccess,
  getExportPractitionersAssignmentsSummaryFailed,
  getExportProjectsSummary,
  getExportProjectsSummarySuccess,
  getExportProjectsSummaryFailed,
  getExportProjectsAssignmentsSummary,
  getExportProjectsAssignmentsSummarySuccess,
  getExportProjectsAssignmentsSummaryFailed,
} = fileImportExportSlice.actions;
export default fileImportExportSlice.reducer;
