import { createSlice } from '@reduxjs/toolkit';

export const initialState: any = {
  staticData: {},
  isStaticDataSet: false,
};

const staticDataSlice = createSlice({
  name: 'staticData',
  initialState,
  reducers: {
    getStaticData(state) {
      state.isStaticDataSet = false;
    },
    getStaticDataSuccess(state, action) {
      state.staticData = action.payload;
      state.isStaticDataSet = true;
    },
    getStaticDataFailed(state) {
      state.isStaticDataSet = false;
    },
  },
});

export const { getStaticData, getStaticDataSuccess, getStaticDataFailed } =
  staticDataSlice.actions;
export default staticDataSlice.reducer;
