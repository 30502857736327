import { AccordionSkeleton } from 'carbon-components-react';
import { useCommonState, usePractitioners, useStaticData } from '../../../custom-hooks';
import { DeleteModalType, PageType, MultiChangeAction } from '@cic-boardlite/common';
import PractitionerCreateEdit from '../create-edit/create-action';
import PractitionerFilterTabs from '../filter-tabs/filter-tabs';
import PractitionersTable from '../practitioner-table-expandable/table-expandable';
import './page.scss';
import PractitionersFilter from '../filter/filter';
import { useEffect } from 'react';
import CvDownloadModalDialog from '../../cv-modal-dialog/cv-modal-dialog';
import MultiChangesDialog from '../../multi-changes-dialog/multi-changes-dialog';
import { useTranslation } from 'react-i18next';
import { DeleteAction } from '../../../ui/common/delete-action/deleteAction';

const PractitionersPage = () => {
  const { isStaticDataSet, loadStaticData } = useStaticData();
  const { isPractitionersSideBarVisible } = useCommonState();
  const { loadPractitionersEmails, getActionRequiredPractitionersCount } = usePractitioners();

  useEffect(() => {
    loadPractitionersEmails();
    loadStaticData();
    getActionRequiredPractitionersCount();
  }, []);

  const { t } = useTranslation('common');
  const practitionerMultiChangeKeys = [
    {
      key: 'jrs',
      title: t('multiChange.practitionerMultiChangeKeys.jrs'),
    },
    // TODO add backend logic for this before uncommenting
    // {
    //   key: 'availabilityDate',
    //   title: t('multiChange.practitionerMultiChangeKeys.availabilityDate'),
    // },
    {
      key: 'emfStatus',
      title: t('multiChange.practitionerMultiChangeKeys.emfStatus'),
    },
  ];

  return !isStaticDataSet ? (
    <div className="practitionerWrapper">
      <AccordionSkeleton open={false} count={3} />
    </div>
  ) : (
    <>
      <MultiChangesDialog modalType={MultiChangeAction.practitioner} multiChangeKeys={practitionerMultiChangeKeys} />
      <CvDownloadModalDialog />
      <DeleteAction type={DeleteModalType.practitioner} />
      <PractitionersFilter />
      <div
        className={`${isPractitionersSideBarVisible ? 'practitionerTableWrapperSideBarWidth' : 'practitionerTableWrapper'}`}
      >
        <PractitionerFilterTabs pageType={PageType.Practitioner} />
        <PractitionersTable />
      </div>
      <PractitionerCreateEdit />
    </>
  );
};

export default PractitionersPage;
