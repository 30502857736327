import { useTranslation } from 'react-i18next';
import { usePractitionerAssignments, usePractitionerDetails } from '../../../custom-hooks';
import PractitionerAssignmentTableExpandable from '../practitioner-assignment-table-expandable/assignment-table-expandable';
import PractitionerPageSidebar from '../page-sidebar/page-sidebar';
import './assignment-page.scss';
import AssignmentPageFilter from '../../assignment-page-filter/assignment-page-filter';
import { AccordionSkeleton } from 'carbon-components-react';

const PractitionerAssignmentPageComp = () => {
  const { t } = useTranslation('common');
  const { practitionerDetails } = usePractitionerDetails();
  const { practitionerAssignmentsDetails, isLoadingSelectedPractitionersAssignments } = usePractitionerAssignments();

  return (
    <div className="d-flex practitioner-assignment-page-wrapper">
      <PractitionerPageSidebar />
      <div className="practitioner-assignment-page-profile">
        <div className="practitioner-assignment-page-container">
          <div className="practitioner-assignment-page-header">
            <h1 className="practitioner-assignment-page-title">
              {t('practitionerAssignments.title', {
                practitionerName: practitionerDetails.nameFirstLast,
                serial: practitionerDetails.serial,
              })}
            </h1>
            <AssignmentPageFilter />
          </div>
          {isLoadingSelectedPractitionersAssignments ? (
            <AccordionSkeleton open={false} count={3} />
          ) : practitionerAssignmentsDetails?.length ? (
            <PractitionerAssignmentTableExpandable />
          ) : (
            <div className="no-audit-data">{t('projectAssignments.noCurrentAssignments')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PractitionerAssignmentPageComp;
