import {
  Header,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
  HeaderContainer,
  HeaderGlobalBar,
  HeaderGlobalAction,
  Switcher,
  SwitcherItem,
  SwitcherDivider,
  HeaderPanel,
} from 'carbon-components-react/lib/components/UIShell';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../data-access/state-configurations/hooks';
import {
  toggleMainMenuVisibility,
  togglePractitionersSideBarVisibility,
  toggleProjectsSideBarVisibility,
} from '../../data-access/common-states/slice';
import { useState } from 'react';
import FileImportModal from '../../feature/file-import-export/file-import-modal';
import { Bee, Filter, Logout, Settings, Switcher as SwitcherIcon, User } from '@carbon/icons-react/next';
import ExternalServicesStatus from '../../feature/external-services-status/external-services-status';
import './header-nav.scss';
import { useCommonState, useUser } from '../../custom-hooks';
import { UserInterface } from '@cic-boardlite/common';
import { authorizationConfig } from 'apps/frontend/src/config/config';
import { get } from '../../data-access/state-configurations/base-api';

const HeaderNav = () => {
  const { t } = useTranslation('common');
  const { assertLogout } = authorizationConfig;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [importExportModalOpen, setImportExportModalOpen] = useState(false);
  const { isMainMenuVisible } = useCommonState();
  const { userInfo } = useUser();

  async function logout() {
    get(assertLogout);
    localStorage.clear();
  };

  return (
    <div className="container">
      <FileImportModal
        isOpen={importExportModalOpen}
        setImportExportModalOpen={setImportExportModalOpen}
      />
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <Header className="header" aria-label={t('header.ariaLabel')}>
            <div className="header-name-icon-container">
              {location.pathname === '/practitioners' ||
              location.pathname === '/projects' ? (
                <Filter size={24}
                  className="headerIcon pointer"
                  onClick={
                    location.pathname === '/practitioners'
                      ? () => dispatch(togglePractitionersSideBarVisibility())
                      : () => dispatch(toggleProjectsSideBarVisibility())
                  }
                />
              ) : (
                <Bee size={24} className="headerIcon" />
              )}
              <div className="pointer" onClick={() => navigate('/')}>
                <HeaderName
                  className="header-name"
                  prefix={t('header.headerNamePrefix')}
                >
                  {t('header.headerNameText')}
                </HeaderName>{' '}
              </div>
            </div>
            <HeaderNavigation aria-label="IBM [Platform]">
              <HeaderMenuItem
                isCurrentPage={location.pathname.startsWith('/practitioner')}
                className="pointer"
                onClick={() => navigate(`/practitioners`)}
              >
                {t('header.practitionersHeaderText')}
              </HeaderMenuItem>
              <HeaderMenuItem
                isCurrentPage={location.pathname.startsWith('/projects')}
                className="pointer"
                onClick={() => navigate(`/projects`)}
              >
                {t('header.projectsHeaderText')}
              </HeaderMenuItem>
            </HeaderNavigation>

            <HeaderMenuButton
              aria-label="mobile-nav-button"
              className="mobile-nav-button"
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
            />
            <SideNav
              className="header-side-navigation"
              aria-label="Side navigation"
              expanded={isSideNavExpanded}
              isPersistent={false}
            >
              <SideNavItems>
                <HeaderSideNavItems>
                  <HeaderMenuItem
                    className="pointer"
                    isCurrentPage={location.pathname.startsWith('/practitioner')}
                    onClick={() => navigate(`/practitioners`)}
                  >
                    {t('header.practitionersHeaderText')}
                  </HeaderMenuItem>
                  <HeaderMenuItem
                    className="pointer"
                    isCurrentPage={location.pathname.startsWith('/projects')}
                    onClick={() => navigate(`/projects`)}
                  >
                    {t('header.projectsHeaderText')}
                  </HeaderMenuItem>
                </HeaderSideNavItems>
              </SideNavItems>
            </SideNav>

            <HeaderGlobalBar>
              <ExternalServicesStatus className="header-service-status-icon" />

              <HeaderGlobalAction 
                aria-label={isMainMenuVisible ? t('header.closeMenu') : t('header.openMenu')} 
                aria-expanded={isMainMenuVisible} 
                isActive={isMainMenuVisible} 
                onClick={() => dispatch(toggleMainMenuVisibility())} 
                tooltipAlignment="end" 
                id="switcher-button">
                <SwitcherIcon size={20} />
              </HeaderGlobalAction>
            </HeaderGlobalBar>

            <HeaderPanel aria-label="rightSideHeaderPanel" expanded={isMainMenuVisible} onClick={() => dispatch(toggleMainMenuVisibility())}>
              <Switcher aria-label="Switcher Container" >
                <SwitcherItem aria-label="profileItem" href={"/practitioner/"+userInfo.id}>
                  <User size={20}/> {userInfo.displayName}
                </SwitcherItem>

                <SwitcherDivider />
                <SwitcherItem aria-label="importExportItem"
                  onClick={() => {
                    setImportExportModalOpen(true);
                  }}>
                  <Settings size={20}/> {t('header.importExportHeader')}
                </SwitcherItem>

                <SwitcherDivider />
                <SwitcherItem aria-label="logoutItem" href="/login" onClick={logout}>
                  <Logout size={20}/> {t('header.logout')}
                </SwitcherItem>
              </Switcher>
            </HeaderPanel>
          </Header>
        )}
      />
    </div>
  );
};

export default HeaderNav;
