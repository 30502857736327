import { ToastNotification } from 'carbon-components-react';
import { useNotification } from '../../../custom-hooks';
import { ChildProp, NotificationInterface, ToastInterface } from './notificationInterfaces';

const Notification = ({ caption, iconDescription, subtitle, kind, timeOut, title, onClose }: NotificationInterface) => {
  return (
    <div>
      <ToastNotification
        caption={caption}
        iconDescription={iconDescription}
        subtitle={subtitle}
        kind={kind}
        // time out in ms, if not needed dont use this prop
        timeout={timeOut}
        title={title}
        onCloseButtonClick={onClose}
      />
    </div>
  );
};

const ToastContainer = (children: ChildProp) => {
  return <div style={{ position: 'fixed', right: 0, top: '4rem', zIndex: 9999 }} {...children} />;
};

export function ToastProvider({ children }: ChildProp) {
  const { toasts, removeToast } = useNotification();

  return (
    <>
      {children}
      <ToastContainer>
        {toasts.map(({ caption, id, iconDescription, kind, subtitle, timeOut, title, ...rest }: ToastInterface) => (
          <Notification
            caption={caption}
            iconDescription={iconDescription}
            key={id}
            kind={kind}
            onClose={() => removeToast(id)}
            subtitle={subtitle}
            timeOut={timeOut}
            title={title}
            {...rest}
          ></Notification>
        ))}
      </ToastContainer>
    </>
  );
}
