import { all, fork } from 'redux-saga/effects';
import { practitionerSaga } from '../practitioner/saga';
import { filterTabsSaga } from '../filter-tabs/saga';
import { staticDataSaga } from '../static-data/saga';
import { filteredProjectSaga } from '../project/saga';
import { fileImportExportSaga } from '../file-import-export/saga';
import { bluePagesSaga } from '../bluepages/saga';
import { multiChangeSaga } from '../multi-change/saga';
import { auditLogsSaga } from '../audit-logs/saga';
import { feedbackSaga } from '../feedback/saga';

export function* watcherSaga() {
  yield all([
    fork(practitionerSaga),
    fork(staticDataSaga),
    fork(filterTabsSaga),
    fork(filteredProjectSaga),
    fork(fileImportExportSaga),
    fork(bluePagesSaga),
    fork(multiChangeSaga),
    fork(auditLogsSaga),
    fork(feedbackSaga)
  ]);
}
