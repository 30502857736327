import { addDays, parseISO } from "date-fns";

enum ActionRequiredStatus {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum ActionRequiredDaysShift {
  HighPriority = 9,
  MediumPriority = 29,
}

export const calculateActionRequired = (endDate: Date) => {
  const providedDate = (typeof endDate == 'string') ? parseISO(endDate) : endDate;
  const now = new Date();

  const highPriorityDays: Date = addDays(now, ActionRequiredDaysShift.HighPriority);
  const mediumPriorityDays: Date = addDays(now, ActionRequiredDaysShift.MediumPriority);

  if (providedDate >= mediumPriorityDays) {
    return ActionRequiredStatus.Low;
  } else {
    if (providedDate < highPriorityDays) {
      return ActionRequiredStatus.High;
    } else {
      return ActionRequiredStatus.Medium;
    }
  }
};
