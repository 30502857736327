import {
  AssignmentCreateEditProps,
  AssignmentStatus,
  convertDate,
  CreateEditAssignmentFormInterface,
  CreateEditAssignmentInterface,
  formatCcEmailsArray,
  orderDataByASCII,
  trim,
} from '@cic-boardlite/common';
import { Button, Checkbox, DatePicker, DatePickerInput, Dropdown, TextInput } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EmailsField } from '../emails-field/emails-field';
import { usePractitionerAssignments, usePractitionerDetails, usePractitioners } from '../../custom-hooks';
import ErrorBox from '../../ui/form-error-box/ErrorBox';
import { LabelWithAsterisk } from '../../ui/label-with-asterisk/label-with-asterisk';
import ComboboxWithCustomFilter from '../combobox-custom-filtering/combobox-custom-filtering';
import './assignment-create-edit.scss';

enum DateValidation {
  NotANumber = 'NaN',
}

const CreateEditAssignmentForm = (props: AssignmentCreateEditProps) => {
  const { t } = useTranslation('common');
  const { id } = useParams<{ id: string }>();
  const {
    buildPractitionerAssignment,
    editPractitionerAssignment,
    practitionerAssignmentsDetails,
    getSelectedPractitionerAssignments,
  } = usePractitionerAssignments();
  const { practitionerDetails } = usePractitionerDetails();
  const [showUwhDates, setShowUwhDates] = useState(
    props.assignmentInfo
      ? props.assignmentInfo.uwh
        ? props.assignmentInfo.uwh
        : false
      : props.projectDetails
      ? props.projectDetails.uwh
      : false
  );
  const [assignmentStartDate, setAssignmentStartDate] = useState(
    props.projectPage
      ? props.projectDetails?.startDate
        ? convertDate(props.projectDetails?.startDate)
        : ''
      : props.assignmentInfo?.startDate
      ? convertDate(props.assignmentInfo?.startDate)
      : ''
  );
  const [uwhStartDate, setUwhStartDate] = useState(
    props.editingAssignment ? (props.assignmentInfo?.uwhStart ? convertDate(props.assignmentInfo?.uwhStart) : '') : ''
  );
  const [selectedPractitionerIdForAssignment, setSelectedPractitionerIdForAssignment] = useState(0);
  const [fieldsEdited, setFieldsEdited] = useState<boolean>(false);

  const { practitionerNames, pemWmpServiceLineLeadersEmails } = usePractitioners();

  useEffect(() => {
    if (props.projectPage) {
      if (selectedPractitionerIdForAssignment > 0) {
        getSelectedPractitionerAssignments({ id: String(selectedPractitionerIdForAssignment) });
      } else if (props.projectDetails?.practitionerId)
        getSelectedPractitionerAssignments({ id: String(props.projectDetails?.practitionerId) });
    }
  }, [selectedPractitionerIdForAssignment]);

  const checkIfPractitionerHasOtherMainAssignment = () => {
    return props.assignmentInfo
      ? !!practitionerAssignmentsDetails.find(
          (assignment) => assignment.mainAssignment && String(assignment.id) !== String(props.assignmentInfo?.id)
        )
      : !!practitionerAssignmentsDetails.find(
          (assignment) => assignment.mainAssignment && String(assignment.id) !== String(props.projectDetails?.id)
        );
  };

  const statusDropdown = {
    name: 'status',
    id: 'filterActionRequired',
    titleText: t('createEditAssignment.status.label'),
    label: t('createEditAssignment.status.placeholder'),
    options: [
      { id: 1, title: t('createEditAssignment.status.options.onboarded') },
      { id: 2, title: t('createEditAssignment.status.options.offboarded') },
    ],
  };

  const defaultValues = {
    id: props.editingAssignment ? props.assignmentInfo?.id : '',
    practitioner: { id: Number(id), name: '' },
    project: {
      id: props.editingAssignment ? props.assignmentInfo?.projectIds : 0,
      projectId: props.editingAssignment ? props.assignmentInfo?.projectId : '',
      accountIds: props?.editingAssignment ? props.assignmentInfo?.accountIds : '',
      name: props.editingAssignment ? props.assignmentInfo?.projectName : '',
      clientName: props.editingAssignment ? props.assignmentInfo?.clientName : '',
    },
    startDate: props.assignmentInfo?.startDate && new Date(props.assignmentInfo.startDate).getTime(),
    endDate: props.assignmentInfo?.endDate && new Date(props.assignmentInfo.endDate).getTime(),
    uwh: props.editingAssignment ? !!props.assignmentInfo?.uwh : false,
    uwhStartDate: props.assignmentInfo?.uwhStart && new Date(props.assignmentInfo.uwhStart).getTime(),
    uwhEndDate: props.assignmentInfo?.uwhEnd && new Date(props.assignmentInfo.uwhEnd).getTime(),
    pmpSeat: props.assignmentInfo?.pmpSeat ? props.assignmentInfo?.pmpSeat : '',
    allocation: props.editingAssignment && !!props.assignmentInfo?.allocation ? props.assignmentInfo?.allocation : '',
    status: {
      id: props.editingAssignment ? props.assignmentInfo?.assignmentStatusId : 0,
      title: props.editingAssignment ? props.assignmentInfo?.assignmentStatus : '',
    },
    mainAssignment: props.editingAssignment ? props.assignmentInfo?.isMainAssignment : false,
    emailTo: '',
    ccEmail: [],
    notifyWmps: false,
    initialWmpEmails: [],
  };

  const projectDetailsDefaultValues = {
    id: props.editingAssignment ? props.projectDetails?.id : '',
    practitioner: {
      id: props.projectDetails?.practitionerId || 0,
      name: props.projectDetails?.firstName
        ? props.projectDetails?.firstName + ' ' + props.projectDetails?.lastName || ''
        : '',
    },
    project: {
      id: props.editingAssignment ? Number(props?.projectDetails?.projectIds) : Number(props.projectDetails?.id),
      accountIds: props?.projectDetails ? props.projectDetails?.projectId : '',
      name: props.projectDetails ? props.projectDetails?.projectName : '',
      clientName: props.projectDetails ? props.projectDetails?.clientName : '',
    },
    startDate: props.projectDetails?.startDate && new Date(props.projectDetails?.startDate).getTime(),
    endDate: props.projectDetails?.endDate && new Date(props.projectDetails?.endDate).getTime(),
    uwh: props.projectDetails?.uwh || false,
    uwhStartDate: props.projectDetails?.uwhStart && new Date(props.projectDetails?.uwhStart).getTime(),
    uwhEndDate: props.projectDetails?.uwhEnd && new Date(props.projectDetails?.uwhEnd).getTime(),
    pmpSeat: props.projectDetails?.pmpSeat || '',
    allocation: props.projectDetails?.allocation || '',
    status: {
      id: props.projectDetails?.assignmentStatusId || 0,
      title: props.projectDetails?.assignmentStatus || '',
    },
    mainAssignment: props.projectDetails?.isMainAssignment || false,
    emailTo: '',
    ccEmail: [],
    notifyWmps: false,
    initialWmpEmails: [],
  };

  const defaultValuesToUse = props.projectPage ? projectDetailsDefaultValues : defaultValues;

  const methods = useForm<CreateEditAssignmentFormInterface>({
    defaultValues: { ...defaultValuesToUse },
  });

  const {
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = methods;

  const addInitialWmpEmails = () => {
    methods.setValue(
      'initialWmpEmails',
      pemWmpServiceLineLeadersEmails.wmpEmails.map((wmpEmail) => t('sendEmailModal.wmp') + wmpEmail)
    );
  };

  const watchNotifyByEmail = methods.watch('notifyWmps');

  useEffect(() => {
    watchNotifyByEmail && addInitialWmpEmails();
  }, [pemWmpServiceLineLeadersEmails.wmpEmails, watchNotifyByEmail]);

  const handleSubmitForm = (data: CreateEditAssignmentFormInterface) => {
    const oldAssignment: CreateEditAssignmentInterface = {
      id: Number(data.id),
      practitioner: defaultValuesToUse.practitioner.id || 0,
      practitionerTitle: defaultValuesToUse.practitioner.name || '',
      project: Number(defaultValuesToUse.project.id),
      projectTitle: defaultValuesToUse.project.name || '',
      accountIds: defaultValuesToUse.project.accountIds || '',
      startDate: convertDate(defaultValuesToUse.startDate || '') || '',
      endDate: convertDate(defaultValuesToUse.endDate || '') || '',
      uwh: defaultValuesToUse.uwh || false,
      uwhStart: defaultValuesToUse.uwh ? convertDate(defaultValuesToUse.uwhStartDate || '') || '' : null,
      uwhEnd: props.assignmentInfo?.uwh ? convertDate(defaultValuesToUse.uwhStartDate || '') || '' : null,
      pmpSeat: defaultValuesToUse.pmpSeat ? defaultValuesToUse.pmpSeat : null,
      allocation: defaultValuesToUse.allocation ? defaultValuesToUse.allocation : null,
      assignmentStatus: defaultValuesToUse.status.id || 0,
      assignmentStatusTitle: defaultValuesToUse.status.title || '',
      isMainAssignment: defaultValuesToUse.mainAssignment || false,
      availabilityDate: convertDate(practitionerDetails.availabilityDate || '') || '',
    };

    const currentAssignmentData: CreateEditAssignmentInterface = {
      id: Number(data.id),
      practitioner: data.practitioner.id,
      practitionerTitle: data.practitioner.name,
      project: Number(data.project.id),
      projectTitle: data.project?.name || props.projectDetails?.name || '',
      accountIds: data.project.accountIds,
      startDate: convertDate(data.startDate) || '',
      endDate: convertDate(data.endDate) || '',
      uwh: data.uwh,
      uwhStart: data.uwh ? convertDate(data.uwhStartDate) || '' : null,
      uwhEnd: data.uwh ? convertDate(data.uwhEndDate) || '' : null,
      pmpSeat: data.pmpSeat ? trim(data.pmpSeat) : null,
      allocation: data.allocation ? trim(data.allocation) : null,
      assignmentStatus: data.status.id,
      assignmentStatusTitle: data.status.title,
      isMainAssignment: data.mainAssignment,
      emailTo: data.notifyWmps ? formatCcEmailsArray(data.ccEmail)[0] : undefined,
      ccEmail: data.notifyWmps ? formatCcEmailsArray(data.ccEmail).slice(1) : undefined,
      notifyWmps: data.notifyWmps,
      projectsPage: !!props.projectPage,
      availabilityDate: convertDate(practitionerDetails.availabilityDate || '') || '',
    };

    if (props.editingAssignment) return editPractitionerAssignment(oldAssignment, currentAssignmentData);
    return buildPractitionerAssignment(currentAssignmentData);
  };

  const validateAssignmentEndDate = (value: string | number) => {
    return (
      String(value) !== DateValidation.NotANumber &&
      new Date(value).getTime() >=
        new Date(methods.getValues('startDate') ? methods.getValues('startDate') : 0).getTime()
    );
  };

  const maxAssignmentEndDate = () => {
    if (props.projectPage) {
      const currentlyEditedProject = props?.projectInfo?.find((project) => String(project.id) === id);
      return currentlyEditedProject?.endDate;
    } else {
      return props.projectInfo.find((project) => project.projectId === methods.watch('project')?.projectId)?.endDate;
    }
  };

  const validateUwhStartDate = (value: string | number) => {
    return !showUwhDates ? true : String(value) !== DateValidation.NotANumber && !!methods.getValues('uwhStartDate');
  };

  const validateUwhEndDate = (value: string | number) => {
    return !showUwhDates
      ? true
      : String(value) !== DateValidation.NotANumber &&
          new Date(value).getTime() >=
            new Date(methods.getValues('uwhStartDate') ? methods.getValues('uwhStartDate') : 0).getTime();
  };

  useEffect(() => {
    setFieldsEdited(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (props.trackFormChanges) {
      props.trackFormChanges(fieldsEdited);
    }
  }, [fieldsEdited]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit((data) => handleSubmitForm(data))}>
        <div className="create-edit-assignment-form">
          {props.projectPage ? (
            <Controller
              name="practitioner"
              control={methods.control}
              rules={{
                required: {
                  value: true,
                  message: t('fieldRequired', {
                    fieldLabel: t('createEditAssignment.practitioner.titleText'),
                  }),
                },
                validate: (value) => {
                  return !!methods.getValues('practitioner').id;
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-create-edit-assignment-practitioner"
                  onChange={(onChangeData) => {
                    onChange(onChangeData.selectedItem);
                    onChangeData.selectedItem?.id
                      ? setSelectedPractitionerIdForAssignment(Number(onChangeData.selectedItem.id))
                      : setSelectedPractitionerIdForAssignment(0);
                  }}
                  selectedItem={value ?? { id: null, name: '' }}
                  items={practitionerNames ? practitionerNames : []}
                  itemKeyName="name"
                  titleText={LabelWithAsterisk(t('createEditAssignment.practitioner.titleText'))}
                  placeholder={t('createEditAssignment.practitioner.label')}
                  warn={!!methods.formState.errors['practitioner']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createEditAssignment.practitioner.titleText'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
          ) : (
            <>
              <Controller
                name="project"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                  <ComboboxWithCustomFilter
                    customClassName="custom-filter-combobox-create-edit-assignment-project-id"
                    onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                    selectedItem={value}
                    items={orderDataByASCII(props.projectInfo.slice(), ['projectId'])}
                    itemKeyName="projectId"
                    titleText={LabelWithAsterisk(t('createEditAssignment.projectId.titleText'))}
                    placeholder={t('createEditAssignment.projectId.label')}
                    warn={!!methods.formState.errors['project']}
                    warnText={
                      <ErrorBox>
                        {t('fieldRequired', {
                          fieldLabel: t('createEditAssignment.projectId.titleText'),
                        })}
                      </ErrorBox>
                    }
                  />
                )}
              />
              <Controller
                name="project"
                control={methods.control}
                render={({ field: { value } }) => (
                  <TextInput
                    id="createUpdateAssignmentAccountIds"
                    labelText={t('createEditAssignment.accountIds')}
                    value={value?.accountIds || ''}
                    disabled={true}
                    placeholder={t('createEditAssignment.accountIds')}
                  />
                )}
              />
              <Controller
                name="project"
                control={methods.control}
                render={({ field: { value } }) => (
                  <TextInput
                    id="createUpdateAssignmentClientName"
                    labelText={t('createEditAssignment.clientName')}
                    value={value?.clientName || ''}
                    disabled={true}
                    placeholder={t('createEditAssignment.clientName')}
                  />
                )}
              />
              <Controller
                name="project"
                rules={{
                  validate: (value) => value !== null && value?.name !== '',
                }}
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                  <ComboboxWithCustomFilter
                    customClassName="custom-filter-combobox-create-edit-assignment-project-name"
                    onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                    selectedItem={value}
                    items={orderDataByASCII(props.projectInfo.slice(), ['name'])}
                    itemKeyName="name"
                    titleText={LabelWithAsterisk(t('createEditAssignment.projectName.titleText'))}
                    placeholder={t('createEditAssignment.projectName.label')}
                    warn={!!methods.formState.errors['project']}
                    warnText={
                      <ErrorBox>
                        {t('fieldRequired', {
                          fieldLabel: t('createEditAssignment.projectName.titleText'),
                        })}
                      </ErrorBox>
                    }
                  />
                )}
              />
            </>
          )}

          <Controller
            name="startDate"
            control={methods.control}
            rules={{
              required: {
                value: true,
                message: t('fieldRequired', {
                  fieldLabel: t('createEditAssignment.startDate.label'),
                }),
              },
              validate: (value) => value.toString() !== DateValidation.NotANumber,
            }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                light
                datePickerType="single"
                onChange={(_dates: Date[], _currentDateString: string) => {
                  setAssignmentStartDate(_currentDateString);
                  return onChange(new Date(_currentDateString)?.getTime());
                }}
                value={value}
                dateFormat={t('format.date_4carbonDatePicker')}
              >
                <DatePickerInput
                  id="createEditAssignmentStartDate"
                  autoComplete="off"
                  labelText={LabelWithAsterisk(t(`createEditAssignment.startDate.label`))}
                  placeholder={t(`date.placeholder`)}
                  size="md"
                  warn={!!methods.formState.errors['startDate']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createEditAssignment.startDate.label'),
                      })}
                    </ErrorBox>
                  }
                  pattern={t('format.date_4carbonDatePickerInput')}
                />
              </DatePicker>
            )}
          />
          <Controller
            name="endDate"
            control={methods.control}
            rules={{
              required: {
                value: true,
                message: t('fieldRequired', {
                  fieldLabel: t('createEditAssignment.endDate.label'),
                }),
              },
              validate: (value) => validateAssignmentEndDate(value),
            }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                light
                datePickerType="single"
                onChange={(_dates: Date[], _currentDateString: string) =>
                  onChange(new Date(_currentDateString).getTime())
                }
                value={value}
                disable={[
                  (date) => maxAssignmentEndDate() !== undefined && date > new Date(maxAssignmentEndDate() || date),
                  (date) => assignmentStartDate !== undefined && date < new Date(assignmentStartDate || date),
                ]}
                dateFormat={t('format.date_4carbonDatePicker')}
              >
                <DatePickerInput
                  id="createEditAssignmentEndDate"
                  autoComplete="off"
                  labelText={LabelWithAsterisk(t(`createEditAssignment.endDate.label`))}
                  placeholder={t(`date.placeholder`)}
                  size="md"
                  warn={!!methods.formState.errors['endDate']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createEditAssignment.endDate.label'),
                      })}
                    </ErrorBox>
                  }
                  pattern={t('format.date_4carbonDatePickerInput')}
                />
              </DatePicker>
            )}
          />
          <Controller
            name="pmpSeat"
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                onChange={onChange}
                id="pmpSeat"
                labelText={t(`createEditAssignment.pmpSeat.label`)}
                placeholder={t(`createEditAssignment.pmpSeat.placeholder`)}
                value={value}
              />
            )}
          />
          <Controller
            name="allocation"
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                onChange={onChange}
                id="createEditAssignmentAllocation"
                labelText={t(`createEditAssignment.allocation.label`)}
                placeholder={t(`createEditAssignment.allocation.placeholder`)}
                value={value}
              />
            )}
          />
          <Controller
            name="status"
            control={methods.control}
            rules={{
              required: {
                value: true,
                message: t('fieldRequired', {
                  fieldLabel: t('createEditAssignment.status.label'),
                }),
              },
              validate: {
                value: (value) => value?.title !== '',
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                onChange={(onChangeData) => {
                  onChange(onChangeData.selectedItem);
                  if (onChangeData?.selectedItem?.id === AssignmentStatus.Onboarded) {
                    methods.clearErrors('mainAssignment');
                    methods.setValue('mainAssignment', false);
                  }
                }}
                id="createEditAssignmentStatus"
                titleText={LabelWithAsterisk(t('createEditAssignment.status.label'))}
                label={t('createEditAssignment.status.placeholder')}
                items={statusDropdown.options}
                itemToString={(item) => (item.title ? item.title : t('createEditAssignment.status.placeholder'))}
                selectedItem={value}
                warn={!!methods.formState.errors['status']}
                warnText={
                  <ErrorBox>
                    {t('fieldRequired', {
                      fieldLabel: t('createEditAssignment.status.label'),
                    })}
                  </ErrorBox>
                }
              />
            )}
          />
          <Controller
            control={methods.control}
            name="mainAssignment"
            rules={{
              validate: {
                notCheckedIfOffboarded: (value) =>
                  methods.getValues('status').id === AssignmentStatus.Onboarded ||
                  !value ||
                  t('createEditAssignment.mainAssignment.canNotSetOffboardedToMain'),
                hasOtherMain: (value) =>
                  !value ||
                  (value && !checkIfPractitionerHasOtherMainAssignment()) ||
                  t('createEditAssignment.mainAssignment.hasOtherMain'),
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="create-edit-assignment-form-main-assignment-wrapper">
                <Checkbox
                  onChange={onChange}
                  onBlur={onBlur}
                  checked={value}
                  id="createEditAssignmentMainAssignment"
                  labelText={
                    <span className="create-edit-assignment-mainAssignment-checkbox">
                      {t(`createEditAssignment.mainAssignment.label`)}
                    </span>
                  }
                />
                {methods.formState.errors['mainAssignment'] && (
                  <ErrorBox>{methods.formState.errors['mainAssignment']?.message}</ErrorBox>
                )}
              </div>
            )}
          />
          <Controller
            control={methods.control}
            name="uwh"
            render={({ field: { onChange, onBlur, value } }) => (
              <Checkbox
                onChange={(event: boolean) => {
                  setShowUwhDates(event);
                  onChange(event);
                }}
                onBlur={onBlur}
                checked={value}
                id="createEditAssignmentUwh"
                labelText={
                  <span className="create-edit-assignment-uwh-checkbox">{t(`createEditAssignment.uwh.label`)}</span>
                }
              />
            )}
          />

          <Controller
            name="notifyWmps"
            control={methods.control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Checkbox
                id="email-checkbox"
                className="field-cc-mail-checkbox"
                labelText={String(t('createEditAssignment.notifyByEmail' || ''))}
                onBlur={onBlur}
                checked={value}
                onChange={(value: any) => {
                  methods.setValue('notifyWmps', value, { shouldDirty: true });
                  methods.clearErrors('notifyWmps');
                }}
              />
            )}
          />

          <Controller
            name="uwhStartDate"
            control={methods.control}
            rules={{
              validate: (value) => validateUwhStartDate(value),
            }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                style={{
                  display: showUwhDates ? 'block' : 'none',
                }}
                light
                datePickerType="single"
                onChange={(_dates: Date[], _currentDateString: string) => {
                  setUwhStartDate(_currentDateString);
                  onChange(new Date(_currentDateString).getTime());
                }}
                value={value}
                dateFormat={t('format.date_4carbonDatePicker')}
              >
                <DatePickerInput
                  id="createEditAssignmentUwhStartDate"
                  autoComplete="off"
                  labelText={t(`createEditAssignment.uwhStartDate.label`)}
                  placeholder={t(`date.placeholder`)}
                  warn={!!methods.formState.errors['uwhStartDate']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createEditAssignment.uwhStartDate.label'),
                      })}
                    </ErrorBox>
                  }
                  pattern={t('format.date_4carbonDatePickerInput')}
                />
              </DatePicker>
            )}
          />
          <Controller
            name="uwhEndDate"
            control={methods.control}
            rules={{
              validate: (value) => validateUwhEndDate(value),
            }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                style={{
                  display: showUwhDates ? 'block' : 'none',
                }}
                light
                datePickerType="single"
                onChange={(_dates: Date[], _currentDateString: string) =>
                  onChange(new Date(_currentDateString).getTime())
                }
                value={value}
                minDate={uwhStartDate}
                dateFormat={t('format.date_4carbonDatePicker')}
              >
                <DatePickerInput
                  id="createEditAssignmentUwhEndDate"
                  autoComplete="off"
                  labelText={t(`createEditAssignment.uwhEndDate.label`)}
                  placeholder={t(`date.placeholder`)}
                  warn={!!methods.formState.errors['uwhEndDate']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createEditAssignment.uwhEndDate.label'),
                      })}
                    </ErrorBox>
                  }
                  pattern={t('format.date_4carbonDatePickerInput')}
                />
              </DatePicker>
            )}
          />
        </div>
        {methods.getValues('notifyWmps') && (
          <div className="emailBox">
            <EmailsField
              checkBoxName={'notifyWmps'}
              emailBoxName={'ccEmail'}
              textAreaLabelLangKey={'ccEmail.label'}
              textAreaPlaceholderLangKey={'ccEmail.placeholder'}
              initialEmails={'initialWmpEmails'}
            />
          </div>
        )}
        <div className="create-edit-assignment-submit">
          <Button
            id="createEditAssignmentReset"
            kind="secondary"
            className="assignment-create-edit-modal-button"
            size="lg"
            onClick={() => {
              methods.reset();
              setShowUwhDates(props.assignmentInfo ? props.assignmentInfo.uwh : false);
              setAssignmentStartDate(
                props.projectPage
                  ? props.projectDetails?.startDate
                    ? convertDate(props.projectDetails?.startDate)
                    : ''
                  : props.assignmentInfo?.startDate
                  ? convertDate(props.assignmentInfo?.startDate)
                  : ''
              );
              setUwhStartDate(
                props.editingAssignment
                  ? props.assignmentInfo?.uwhStart
                    ? convertDate(props.assignmentInfo?.uwhStart)
                    : ''
                  : ''
              );
            }}
            disabled={props.editingAssignment ? !isDirty : false}
          >
            {props.editingAssignment ? t(`createEditAssignment.cancel`) : t(`createEditAssignment.reset`)}
          </Button>
          <Button
            id="createEditAssignmentSubmit"
            type="submit"
            className="assignment-create-edit-modal-button"
            size="lg"
            disabled={props.editingAssignment ? !isDirty || isSubmitting || isSubmitSuccessful : false}
            onClick={() => {
              methods.clearErrors('ccEmail');
              methods.getValues('notifyWmps') &&
                methods.getValues('ccEmail').length === 0 &&
                methods.setError('ccEmail', { message: t('ccEmail.required') });
            }}
          >
            {t(`createEditAssignment.save`)}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateEditAssignmentForm;
