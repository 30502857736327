import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import { getFilteredPractitioners, resetFilteredPractitioners } from '../data-access/practitioner/slice';
import { FilteredPractitionerDataInterface } from '../interfaces/practitionerInterfaces';

const useFilteredPractitioners = () => {
  const dispatch = useAppDispatch();
  const [practitionerFilterCreatedUrl, setPractitionerFilterCreatedUrl] = useState('');

  useEffect(() => {
    if (practitionerFilterCreatedUrl) {
      dispatch(getFilteredPractitioners(practitionerFilterCreatedUrl));
    }
  }, [practitionerFilterCreatedUrl]);

  const filteredPractitionersData = useAppSelector((state) => state.practitioners.filteredPractitionerData);

  const isLoadingFilteredPractitioners = useAppSelector((state) => state.practitioners.isLoadingFilteredPractitioners);

  const resetFilteredPractitionersData = (filteredPractitionerData: FilteredPractitionerDataInterface | undefined) => {
    return dispatch(resetFilteredPractitioners(filteredPractitionerData));
  };

  return {
    filteredPractitionersData,
    isLoadingFilteredPractitioners,
    setPractitionerFilterCreatedUrl,
    resetFilteredPractitionersData,
  };
};

export default useFilteredPractitioners;
