import {
  ButtonSkeleton,
  CheckboxSkeleton,
  Grid,
  Row,
  TextInputSkeleton,
} from 'carbon-components-react';
import './create-style.scss';

const CreateProjectFormSkeleton = () => {
  return (
    <Grid className="create-project-form-skeleton-grid">
      <Row className="create-project-form-skeleton-row">
        <TextInputSkeleton />
        <TextInputSkeleton />
        <TextInputSkeleton />
      </Row>
      <Row className="create-project-form-skeleton-row">
        <TextInputSkeleton />
        <TextInputSkeleton />
        <TextInputSkeleton />
      </Row>
      <Row className="create-project-form-skeleton-row">
        <TextInputSkeleton />
        <TextInputSkeleton />
        <TextInputSkeleton />
      </Row>
      <Row className="create-project-form-skeleton-row">
        <TextInputSkeleton />
        <TextInputSkeleton />
        <TextInputSkeleton />
      </Row>
      <Row className="create-project-form-skeleton-row">
        <TextInputSkeleton />
        <TextInputSkeleton />
      </Row>
      <Row className="create-project-form-skeleton-row">
        <CheckboxSkeleton />
      </Row>
      <Row className="create-project-form-skeleton-buttons">
        <ButtonSkeleton style={{ width: '100%' }} />
        <ButtonSkeleton style={{ width: '100%' }} />
      </Row>
    </Grid>
  );
};

export default CreateProjectFormSkeleton;
