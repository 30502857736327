import { DeletableObjectType, DeleteModalInputInterface, DeleteModalType } from '@cic-boardlite/common';
import { InlineLoading, Modal, TextInput } from 'carbon-components-react';
import { useEffect } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCommonState, useEditProject, usePractitioners, usePractitionerAssignments } from '../../../custom-hooks';
import { initialState } from '../../../data-access/common-states/slice';
import './deleteAction.scss';

export const DeleteAction = ({ type }: { type: DeleteModalType }) => {
  const { deleteModalType, toggleDeleteModal } = useCommonState();
  const { removePractitioner, isRemovingPractitioner } = usePractitioners();
  const { deleteProject, isDeletingProject } = useEditProject();
  const { deleteSelectedPractitionerAssignment, isDeletingPractitionerAssignment } = usePractitionerAssignments();
  const { deleteSelectedPractitionerAssignmentFromProjectPage } = usePractitionerAssignments();

  const { t } = useTranslation('common');

  const isInputConfirmationField = !!deleteModalType.deleteConfirmationField;
  const resolver: Resolver<DeleteModalInputInterface> = (values) => {
    return {
      values: values.delete ? values : {},
      errors:
        values.delete !== deleteModalType.deleteConfirmationField && isInputConfirmationField
          ? {
              delete: {
                type: 'required',
                message: t(`deleteModal.${deleteModalType.deletableObjectType}.errorMessage`),
              },
            }
          : {},
    };
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DeleteModalInputInterface>({ resolver });

  const handleCloseModal = () => {
    toggleDeleteModal(initialState.deleteModalType);
    reset({ delete: '' });
  };

  const onSubmit = () => {
    if (deleteModalType.deletableObjectType === DeletableObjectType.project) {
      deleteProject(Number(deleteModalType.deleteObject.id));
    }
    else if (deleteModalType.deletableObjectType === DeletableObjectType.practitioner) {
      removePractitioner(deleteModalType.deleteObject.id);
    }
    else if (
      deleteModalType.deletableObjectType === DeletableObjectType.practitionerAssignment &&
      deleteModalType.deleteObject.practitionerId
    ) {
      deleteSelectedPractitionerAssignment(
        deleteModalType.deleteObject.id,
        String(deleteModalType.deleteObject.practitionerId)
      );
    }
    else if (deleteModalType.deletableObjectType === DeletableObjectType.projectAssignment) {
      deleteSelectedPractitionerAssignmentFromProjectPage(
        deleteModalType.deleteObject.id,
        deleteModalType.deleteObject.practitionerId || undefined,
        deleteModalType.deleteObject.projectId
      );
    }
  };

  useEffect(() => {
    if (!isDeletingProject && !isRemovingPractitioner && !isDeletingPractitionerAssignment) {
      handleCloseModal();
    }
  }, [isDeletingProject, isRemovingPractitioner, isDeletingPractitionerAssignment]);

  return (
    <Modal
      size="sm"
      className="delete-modal"
      primaryButtonText={
        isDeletingProject || isRemovingPractitioner || isDeletingPractitionerAssignment ? (
          <InlineLoading
            status="active"
            iconDescription={t(`deleteModal.loading.iconDescription`)}
            description={t(`deleteModal.loading.description`)}
          />
        ) : (
          t('deleteModal.submitButtonText')
        )
      }
      primaryButtonDisabled={isRemovingPractitioner || isDeletingProject || isDeletingPractitionerAssignment}
      secondaryButtonText={t('deleteModal.cancelButtonText')}
      onRequestSubmit={handleSubmit(onSubmit)}
      open={deleteModalType.deletableObjectType !== DeletableObjectType.none}
      onRequestClose={handleCloseModal}
      preventCloseOnClickOutside
    >
      <h3 className="marginBottom">{t(`deleteModal.${type}.modalHeader`)}</h3>
      <p className="marginBottom">{t(`deleteModal.${type}.modalText`)}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isInputConfirmationField && (
          <Controller
            name="delete"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { onChange, value = '' } }) => (
              <TextInput
                onChange={onChange}
                data-modal-primary-focus
                id="delete"
                labelText={t(`deleteModal.${type}.textInputLabel`) + `(${deleteModalType['deleteConfirmationField']})`}
                placeholder={t(`deleteModal.${type}.inputTextPlaceholder`)}
                value={value}
                warn={!!errors['delete']?.message}
                warnText={<span className="delete-modal-error-message">{errors['delete']?.message}</span>}
              />
            )}
          />
        )}
      </form>
    </Modal>
  );
};
