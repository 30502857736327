import { useEffect } from 'react';
import {
  Checkbox,
  DatePicker,
  DatePickerInput,
  Dropdown,
  InlineLoading,
  Modal,
  TextInput,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import {
  useCommonState,
  useFilteredProjects,
  useMultiChange,
  usePractitioners,
  usePractitionerCv,
  useProjectAssignments,
  useStaticData,
  useProject,
} from '../../custom-hooks';
import {
  AssignmentStatus,
  AssignmentTargetField,
  convertDate,
  formatCcEmailsArray,
  MultiChangeAction,
  MultiChangeData,
  PractitionerTargetField,
  ProjectStatus,
  ProjectTargetField,
  StaticDataInterface,
} from '@cic-boardlite/common';
import { ArrowShiftDown24 } from '@carbon/icons-react';
import { EmailsField } from '../emails-field/emails-field';
import ErrorBox from '../../ui/form-error-box/ErrorBox';
import { SelectedAssignmentForExtension } from '../../interfaces/project-interfaces';
import ComboboxWithCustomFilter from '../combobox-custom-filtering/combobox-custom-filtering';
import './multi-changes-dialog.scss';

interface Props {
  modalType: string;
  multiChangeKeys: {
    key: string;
    title: string;
  }[];
  overrideDropdown?: {
    [key: string]: {
      value: boolean | number | string;
      title: string;
    }[];
  };
}

const MultiChangesDialog = ({ modalType, multiChangeKeys, overrideDropdown }: Props) => {
  const { t } = useTranslation('common');
  const { staticData }: { staticData: StaticDataInterface } = useStaticData();
  const { multiChangeModalType, toggleMultiChangeModal } = useCommonState();
  const { multiChange, isLoadingMultiChange } = useMultiChange();
  const { selectSelectedPractitioners } = usePractitionerCv();
  const { selectedProjectsList, isLoadingFilteredProjects } = useFilteredProjects();
  const { projectData } = useProject();
  const { assignmentExtensionArray, projectAssignments, getMultiLastAssignmentEndDate, lastAssignmentEndDateMulti } =
    useProjectAssignments();
  const { pemWmpServiceLineLeadersEmails } = usePractitioners();

  const latestAvailableProjectsEndDate = lastAssignmentEndDateMulti.length > 0 ? lastAssignmentEndDateMulti
    .map((assignment) => assignment.date)
    .sort()
    .slice(-1)[0] : '';

  useEffect(() => {
    if (modalType !== MultiChangeAction.project || isLoadingFilteredProjects) return;

    getMultiLastAssignmentEndDate(selectedProjectsList.map((project) => project.id));
  }, [selectedProjectsList]);

  const projectHasAssignment = lastAssignmentEndDateMulti.some((projectLastAssignment) => projectLastAssignment.date?.length > 0);

  const defaultValues = {
    targetField: {
      key: '',
      title: '',
    },
    targetFieldValue: {
      title: '',
      value: '',
    },
    targetFieldValueDate: '',
    targetFieldValueFreeText: '',
    emailTo: '',
    ccEmail: [],
    notifyWmps: false,
    initialWmpEmails: [''],
  };

  const methods = useForm({ defaultValues: defaultValues });

  const addInitialWmpEmails = () => {
    methods.setValue(
      'initialWmpEmails',
      pemWmpServiceLineLeadersEmails.wmpEmails.map((wmpEmail) => t('sendEmailModal.wmp') + wmpEmail)
    );
  };

  const watchNotifyByEmail = methods.watch('notifyWmps');

  useEffect(() => {
    watchNotifyByEmail && addInitialWmpEmails();
  }, [pemWmpServiceLineLeadersEmails.wmpEmails, watchNotifyByEmail]);

  const handleOnSubmit = (formData: typeof defaultValues) => {
    let data: MultiChangeData;

    if (modalType === MultiChangeAction.practitioner) {
      data = {
        targetField: formData.targetField.key,
        targetFieldValue: formData.targetFieldValue.value,
        ids: selectSelectedPractitioners.map((x) => x.serial),
        multiChangeAction: MultiChangeAction.practitioner,
      };
    } else if (modalType === MultiChangeAction.project) {
      data = {
        targetField: formData.targetField.key,
        targetFieldValue:
          formData.targetField.key === ProjectTargetField.pmo
            ? formData.targetFieldValue.title
            : formData.targetFieldValue.value || formData.targetFieldValueDate,
        ids: selectedProjectsList.map((x) => x.projectId),
        multiChangeAction: MultiChangeAction.project,
      };
    } else {
      data = {
        targetField: formData.targetField.key,
        targetFieldValue:
          convertDate(formData.targetFieldValueDate) ||
          formData.targetFieldValueFreeText ||
          formData.targetFieldValue.value,
        ids: assignmentExtensionArray.map((x) => x.id),
        multiChangeAction: MultiChangeAction.assignment,
      };

      if (formData.notifyWmps) {
        data.emailTo = formatCcEmailsArray(formData?.ccEmail)[0];
        data.ccEmail = formatCcEmailsArray(formData?.ccEmail).slice(1) || undefined;
        data.sendEmail = formData?.notifyWmps;
        data.namesForMultiChange = assignmentExtensionArray.map((x) => x.firstLastName);
        data.projectId = projectData.projectId;
      }
    }
    multiChange({
      data,
      modificationLogData: getModificationLogData(formData, modalType, data),
    });
  };

  const getModificationLogData = (
    selectedNewData: typeof defaultValues,
    entity: string,
    multiChangeData: MultiChangeData
  ) => {
    const dataToLog: {
      oldData: { [key: string]: any };
      newData: { [key: string]: any };
    }[] = [];

    switch (entity) {
      case MultiChangeAction.practitioner:
        selectSelectedPractitioners.forEach((practitioner, index) => {
          let oldData: { [key: string]: any } = {};
          let newData: { [key: string]: any } = {};

          if (selectedNewData.targetField.key === PractitionerTargetField.jrs) {
            oldData = {
              jrs: practitioner.jrsId,
              jrsTitle: practitioner.jrs,
            };
            newData = {
              jrs: selectedNewData.targetFieldValue.value,
              jrsTitle: selectedNewData.targetFieldValue.title,
            };
          } else if (selectedNewData.targetField.key === PractitionerTargetField.emfStatus) {
            oldData = {
              emfStatus: practitioner.emfStatusId,
              emfStatusTitle: practitioner.emfStatus,
            };
            newData = {
              emfStatus: selectedNewData.targetFieldValue.value,
              emfStatusTitle: selectedNewData.targetFieldValue.title,
            };
          }

          dataToLog.push({
            oldData: {
              id: Number(practitioner.id),
              practitionerTitle: practitioner.nameFirstLast,
              ...oldData,
            },
            newData: {
              id: Number(practitioner.id),
              practitionerTitle: practitioner.nameFirstLast,
              ...newData,
            },
          });
        });
        break;
      case MultiChangeAction.project:
        selectedProjectsList.forEach((project, index) => {
          let oldData: { [key: string]: any } = {};
          let newData: { [key: string]: any } = {};

          if (selectedNewData.targetField.key === ProjectTargetField.projectStatus) {
            oldData = {
              projectStatus: project.projectStatusId,
              projectStatusTitle: project.projectStatus,
            };
            newData = {
              projectStatus: selectedNewData.targetFieldValue.value,
              projectStatusTitle: selectedNewData.targetFieldValue.title,
            };
          } else if (selectedNewData.targetField.key === ProjectTargetField.pmo) {
            oldData = {
              pmo: project.pmo,
            };
            newData = {
              pmo: selectedNewData.targetFieldValue.title,
            };
          } else if (selectedNewData.targetField.key === ProjectTargetField.endDate) {
            oldData = {
              endDate: project.endDate,
            };
            newData = {
              endDate: selectedNewData.targetFieldValueDate,
            };
          }

          dataToLog.push({
            oldData: {
              id: Number(project.id),
              projectTitle: selectedProjectsList[index].name,
              ...oldData,
            },
            newData: {
              id: Number(project.id),
              projectTitle: selectedProjectsList[index].name,
              ...newData,
            },
          });
        });
        break;
      case MultiChangeAction.assignment:
        {
          const assignmentsToChange = projectAssignments.filter((assignment) =>
            assignmentExtensionArray.some((assignmentExtension) => Number(assignmentExtension.id) === assignment.id)
          );

          assignmentsToChange.sort((a, b) => a.id - b.id);
          const assignmentExtensionArraySorted = [...assignmentExtensionArray].sort(
            (a, b) => Number(a.id) - Number(b.id)
          );

          const assignmentsToChangeWithExtension = assignmentsToChange.map((assignment, index) => {
            return {
              ...assignment,
              ...assignmentExtensionArraySorted[index],
            };
          });

          assignmentsToChangeWithExtension.forEach((assignment, index) => {
            const mainAssignmentStatusChangedToOffboard =
              selectedNewData.targetField.key === AssignmentTargetField.assignmentStatus &&
              multiChangeData.targetFieldValue === AssignmentStatus.Offboarded &&
              assignment.isMainAssignment;

            dataToLog.push({
              oldData: {
                id: Number(assignment.id),
                practitioner: assignment.practitioner.id,
                practitionerTitle: assignmentExtensionArray[index].firstLastName,
                project: assignment.project.id,
                projectTitle: assignment.project.name,
                ...(selectedNewData.targetField.key === AssignmentTargetField.assignmentStatus
                  ? {
                      assignmentStatus: assignment['status'].id,
                      assignmentStatusTitle: assignment['status'].title,
                    }
                  : {
                      [selectedNewData.targetField.key]:
                        assignmentExtensionArray[index][
                          selectedNewData.targetField.key as keyof SelectedAssignmentForExtension
                        ],
                    }),
                ...(mainAssignmentStatusChangedToOffboard ? { isMainAssignment: assignment.isMainAssignment } : {}),
              },
              newData: {
                id: Number(assignment.id),
                practitioner: assignment.practitioner.id,
                practitionerTitle: assignmentExtensionArray[index].firstLastName,
                project: assignment.project.id,
                projectTitle: assignment.project.name,
                ...(selectedNewData.targetField.key === AssignmentTargetField.assignmentStatus
                  ? {
                      assignmentStatus: selectedNewData.targetFieldValue.value,
                      assignmentStatusTitle: selectedNewData.targetFieldValue.title,
                    }
                  : {
                      [selectedNewData.targetField.key]: multiChangeData.targetFieldValue,
                    }),
                ...(mainAssignmentStatusChangedToOffboard ? { isMainAssignment: false } : {}),
              },
            });
          });
        }
        break;
    }

    return dataToLog;
  };

  useEffect(() => {
    if (!isLoadingMultiChange) {
      closeModal();
    }
  }, [isLoadingMultiChange]);

  const closeModal = () => {
    toggleMultiChangeModal('');
    methods.reset();
  };

  const countSelectedItems = () => {
    if (modalType === MultiChangeAction.practitioner) {
      return selectSelectedPractitioners.length;
    }
    if (modalType === MultiChangeAction.project) {
      return selectedProjectsList.length;
    }
    return assignmentExtensionArray.length;
  };

  const isPrimaryButtonDisabled = () => {
    const isTargetFieldSelected = methods.watch('targetField')?.title || methods.watch('targetField');
    const isTargetFieldValueSelected =
      methods.watch('targetFieldValue')?.title || methods.watch('targetFieldValueDate');
    const isTargetFieldValueDateSelected = methods.watch('targetFieldValueDate');
    const isTargetFieldValueFreeTextSelected = methods.watch('targetFieldValueFreeText');
    return !(
      !isLoadingMultiChange &&
      isTargetFieldSelected &&
      (isTargetFieldValueSelected || isTargetFieldValueDateSelected || isTargetFieldValueFreeTextSelected)
    );
  };

  const returnTargetFieldValueSelection = (staticDataKey: string) => {
    return staticData.staticData?.[`${staticDataKey}StaticData`].map((selectionObject) => {
      return {
        title:
          'title' in selectionObject
            ? selectionObject.title
            : 'label' in selectionObject
            ? selectionObject.label
            : 'firstName' in selectionObject
            ? `${selectionObject.firstName} ${selectionObject.lastName}`
            : '',
        value: Number(selectionObject.id),
      };
    });
  };

  const validateEndDate = (value: string): boolean => {
    if (lastAssignmentEndDateMulti.length === 0 || modalType !== MultiChangeAction.project) return true;

    return lastAssignmentEndDateMulti.every((assignment) => {
      return new Date(value).getTime() <= new Date(assignment.date).getTime();
    });
  };

  const endDateInitialValue = (value: string): Date | string => {
    if (lastAssignmentEndDateMulti.length === 0 || modalType !== MultiChangeAction.project) return value;

    const date = new Date(latestAvailableProjectsEndDate);
    date.setDate(date.getDate() + 1);
    return date;
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={modalType === multiChangeModalType}
          primaryButtonText={
            isLoadingMultiChange ? (
              <InlineLoading
                status="active"
                iconDescription={t('multiChange.loading')}
                description={t('multiChange.loading_data')}
              />
            ) : (
              t('multiChange.save')
            )
          }
          onRequestSubmit={() => {
            methods.clearErrors('ccEmail');
            methods.getValues('notifyWmps') &&
              methods.getValues('ccEmail').length === 0 &&
              methods.setError('ccEmail', { message: t('ccEmail.required') });
            methods.handleSubmit(handleOnSubmit)();
          }}
          secondaryButtonText={t('multiChange.cancel')}
          onRequestClose={closeModal}
          primaryButtonDisabled={isPrimaryButtonDisabled()}
          className="multi-changes-modal"
        >
          <h4>{t(`multiChange.title`)}</h4>
          <p>
            {t('multiChange.selectedItemsCount', {
              count: countSelectedItems(),
            })}
          </p>
          <div className="multi-changes-modal__input-container">
            <Controller
              name={'targetField'}
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  selectedItem={value}
                  onChange={(onChangeData) => {
                    methods.setValue('targetFieldValue', {
                      title: '',
                      value: '',
                    });
                    methods.setValue('targetFieldValueDate', '');
                    methods.setValue('targetFieldValueFreeText', '');
                    onChange(onChangeData.selectedItem);
                  }}
                  className="multi-changes-modal__dropdown"
                  titleText={t('multiChange.targetFieldTitle')}
                  id="editProjectType"
                  items={multiChangeKeys}
                  itemToString={(item) => (item?.title ? item.title : t('multiChange.targetFieldLabel'))}
                  label={t('multiChange.targetFieldLabel')}
                />
              )}
            />
            {methods.watch('targetField').key && <ArrowShiftDown24 />}
            {methods.watch('targetField').key &&
              (methods.watch('targetField').key === 'allocation' ? (
                <Controller
                  name="targetFieldValueFreeText"
                  rules={{
                    validate: (value) => value.length <= 64 || t('multiChange.targetFieldValueFreeTextLength'),
                  }}
                  control={methods.control}
                  defaultValue=""
                  render={({ field: { onChange, value, name } }) => (
                    <TextInput
                      warn={!!methods.formState.errors['targetFieldValueFreeText']?.message}
                      warnText={<ErrorBox>{methods.formState.errors['targetFieldValueFreeText']?.message}</ErrorBox>}
                      onChange={onChange}
                      id="targetFieldValueFreeText"
                      labelText={t(`multiChange.targetFieldValueLabel`)}
                      hideLabel={true}
                      placeholder={t(`multiChange.targetFieldValueFreeTextPlaceholder`)}
                    />
                  )}
                />
              ) : !methods.watch('targetField').key.toLowerCase().includes('date') ? (
                <Controller
                  name={'targetFieldValue'}
                  control={methods.control}
                  rules={{
                    validate: (value) => {
                      return projectHasAssignment && (+value?.value === ProjectStatus.Closed)
                        ? t('multiChange.projectHasAssignments')
                        : true;
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ComboboxWithCustomFilter
                      customClassName="custom-filter-combobox-multi-change-target-field-value"
                      onChange={(onChangeData) => {
                        onChange(onChangeData.selectedItem);
                      }}
                      selectedItem={value}
                      items={
                        overrideDropdown
                          ? overrideDropdown[methods.getValues('targetField').key]
                          : returnTargetFieldValueSelection(methods.getValues('targetField').key)
                      }
                      itemKeyName="title"
                      titleText={t(`multiChange.targetFieldValueLabel`)}
                      placeholder={
                        methods.getValues('targetField').key ? t(`multiChange.targetFieldValuePlaceholder`) : ''
                      }
                      warn={!!methods.formState.errors['targetFieldValue']?.message}
                      warnText={<ErrorBox>{methods.formState.errors['targetFieldValue']?.message}</ErrorBox>}
                    />
                  )}
                />
              ) : (
                <Controller
                  name="targetFieldValueDate"
                  control={methods.control}
                  rules={{
                    validate: {
                      validateDate: (value) => {
                        return (
                          !!validateEndDate(value) ||
                          t(`multiChange.projectEndDate`, {
                            date: latestAvailableProjectsEndDate,
                          })
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      light
                      className="multi-changes-modal__date-picker"
                      datePickerType="single"
                      dateFormat={t('format.date_4carbonDatePicker')}
                      onChange={(onChangeData) => {
                        onChange(onChangeData[0]);
                      }}
                      value={endDateInitialValue(value)}
                      disable={[
                        (date: Date) =>
                          latestAvailableProjectsEndDate.length > 0 && modalType === MultiChangeAction.project
                            ? date <= new Date(latestAvailableProjectsEndDate)
                            : false,
                      ]}
                    >
                      <DatePickerInput
                        autoComplete="off"
                        id="multiSelectDate"
                        hideLabel={true}
                        labelText="value"
                        placeholder={t(`multiChange.datePicker`)}
                        pattern={t('format.date_4carbonDatePickerInput')}
                        warn={!!methods.formState.errors['targetFieldValueDate']}
                        warnText={<ErrorBox>{methods.formState.errors['targetFieldValueDate']?.message}</ErrorBox>}
                      />
                    </DatePicker>
                  )}
                />
              ))}
            {modalType === MultiChangeAction.assignment && methods.watch('targetField').key && (
              <Controller
                name="notifyWmps"
                control={methods.control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Checkbox
                    id="email-checkbox"
                    className="field-cc-mail-checkbox multiChangeEmailCheckbox"
                    labelText={String(t('createEditAssignment.notifyByEmail' || ''))}
                    onBlur={onBlur}
                    checked={value}
                    onChange={(value: boolean) => {
                      methods.setValue('notifyWmps', value);
                      methods.clearErrors('notifyWmps');
                    }}
                  />
                )}
              />
            )}
            {methods.watch('notifyWmps') && (
              <div className="multiChangeEmailBox">
                <EmailsField
                  checkBoxName={'notifyWmps'}
                  emailBoxName={'ccEmail'}
                  textAreaLabelLangKey={'ccEmail.label'}
                  textAreaPlaceholderLangKey={'ccEmail.placeholder'}
                  initialEmails={'initialWmpEmails'}
                />
              </div>
            )}
          </div>
        </Modal>
      </form>
    </FormProvider>
  );
};

export default MultiChangesDialog;
