import { useProject, useProjectAssignments } from '../../../custom-hooks';
import './assignment-page.scss';
import ProjectDetailPageSidebar from '../project-detail-page/detail-sidebar';
import ProjectAssignmentTableExpandable from '../project-assignment-table-expandable/assignment-table-expandable';
import { AccordionSkeleton } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import AssignmentPageFilter from '../../assignment-page-filter/assignment-page-filter';

const ProjectAssignmentPageComponent = () => {
  const { isLoadingProjectAssignments, projectAssignments } = useProjectAssignments();
  const { projectData } = useProject();
  const { t } = useTranslation('common');

  return (
    <div className="d-flex project-assignment-page-wrapper">
      <ProjectDetailPageSidebar />
      <div className="project-assignment-page-profile ">
        <div className="project-assignment-page-container">
          <div className="project-assignment-page-header">
            <h1 className="project-assignment-page-title">
              {t('projectAssignments.title', {
                projectName: projectData?.name || '',
                projectId: projectData?.projectId || '',
              })}
            </h1>
            <AssignmentPageFilter />
          </div>
          {isLoadingProjectAssignments ? (
            <AccordionSkeleton open={false} count={3} />
          ) : projectAssignments.length ? (
            <ProjectAssignmentTableExpandable />
          ) : (
            <div className="no-audit-data">{t('projectAssignments.noCurrentAssignments')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectAssignmentPageComponent;
