import { forwardRef } from 'react';
import { Tooltip } from 'carbon-components-react/lib/components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import BubbleIcon from '../bubble-icon/bubble-icon';
import { AssignmentEndDateInterface } from '@cic-boardlite/common';
import {
  whenAssignmentIsEnding,
  getAssignmentEnding,
} from '@cic-boardlite/common';

const AssignmentEndDate = ({
  value,
  id,
  assignmentEndDateToolTip,
  setAssignmentEndDateToolTip,
}: AssignmentEndDateInterface) => {
  const { t } = useTranslation('common');

  const isEndDateEndingSoon = whenAssignmentIsEnding(value).length > 0;

  const toolTipMessage =
    +getAssignmentEnding(value) === 0
      ? t('misc.assignmentEnded')
      : t('misc.assignmentEnding', {
          number: getAssignmentEnding(value),
        });

  return (
    <span
      className="assignment-endDate"
      onMouseEnter={() => setAssignmentEndDateToolTip(id)}
      onMouseLeave={() => setAssignmentEndDateToolTip(null)}
    >
      {!isEndDateEndingSoon && (
        <Tooltip
          open={false}
          renderIcon={forwardRef(() => (
            <BubbleIcon assignmentEnd="not-ending-soon" />
          ))}
        ></Tooltip>
      )}
      {isEndDateEndingSoon && (
        <Tooltip
          align="center"
          direction="left"
          autoOrientation
          open={assignmentEndDateToolTip === id}
          renderIcon={forwardRef(() => (
            <BubbleIcon assignmentEnd={whenAssignmentIsEnding(value)} />
          ))}
        >
          {toolTipMessage}
        </Tooltip>
      )}{' '}
      {value}
    </span>
  );
};

export default AssignmentEndDate;
