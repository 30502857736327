import { EmfStatus } from "../../emf-status.enum";
import { TalentID_LENGTH } from "../constants/constants";

export const validateSerialBE = (
  countryCode: string,
  serial: string,
  isCic: boolean,
  emfStatusId?: number,
  prevEmfStatusId?: number,
) => {
  if (emfStatusId === EmfStatus.CandidateForHiring || (emfStatusId === EmfStatus.Terminated && prevEmfStatusId === EmfStatus.CandidateForHiring)) {
    return validateSerialForCFH(serial);
  } else  if (emfStatusId === EmfStatus.Contractor || !isCic || (emfStatusId === EmfStatus.Terminated && prevEmfStatusId === EmfStatus.Contractor)) {
    return validateSerialForContractorAndNonCic(serial, countryCode);
  } else {
    return validateSerialForRegular(serial, countryCode);
  }
};

const validateSerialForCFH = (serial: string) => {
  if (serial.includes(' '))
    return {
      error: true,
      errorMsg: 'Candidate for hiring TalentID cannot have spaces',
    };
  if (!serial.startsWith('CFH-') || serial.length > 16)
    return {
      error: true,
      errorMsg:
        "Candidate for hiring TalentID has to start with 'CFH-' and have max 16 symbols",
    };
  return { error: false };
};

const validateSerialForContractorAndNonCic = (
  serial: string,
  countryCode: string
) => {
  if (serial.includes(' '))
    return {
      error: true,
      errorMsg: 'Practitioner TalentID cannot have spaces',
    };
  if (!(serial.length === TalentID_LENGTH.SHORT || serial.length === TalentID_LENGTH.FULL) || serial.startsWith('-'))
    return {
      error: true,
      errorMsg:
      `Practitioner TalentID has to be ${TalentID_LENGTH.SHORT} or ${TalentID_LENGTH.FULL} symbols and not start with '-'`,
    };
  if (serial.length === TalentID_LENGTH.FULL && !serial.endsWith(countryCode))
    return {
      error: true,
      errorMsg: `Practitioner TalentID has to end with country code ${countryCode}`,
    };
  return { error: false };
};

const validateSerialForRegular = (serial: string, countryCode: string) => {
  if (serial.includes(' '))
    return {
      error: true,
      errorMsg: 'Practitioner TalentID cannot have spaces',
    };
  if (!(serial.length === TalentID_LENGTH.SHORT || serial.length === TalentID_LENGTH.FULL) || isNaN(Number(serial)))
    return {
      error: true,
      errorMsg: `Practitioner TalentID has to be ${TalentID_LENGTH.SHORT} or ${TalentID_LENGTH.FULL} numbers`,
    };
  if (serial.length === TalentID_LENGTH.FULL && !serial.endsWith(countryCode))
    return {
      error: true,
      errorMsg: `Practitioner TalentID has to end with country code ${countryCode}`,
    };
  return { error: false };
};
