export enum UserAuthorizationStatus {
  NO_ACCESS = 'no-access',
  IS_AUTHORIZED = 'is-authorized',
}
export interface UserInterface {
  idToken: string;
  roles: string[];
  scopes: string[];
  userInfo: UserInfoInterface;
  accessToken?: string;
  refreshToken?: string;
  userAuthorizationStatus?: UserAuthorizationStatus;
}
export interface UserInfoInterface {
  id: number;
  displayName: string;
  uid: string;
  emailAddress: string;
}
