import {
  useAppDispatch,
  useAppSelector,
} from '../data-access/state-configurations/hooks';
import { getAuditLogsData as getPractitionerAuditLogsData } from '../data-access/practitioner/slice';
import { getAuditLogsData as getProjectAuditLogsData } from '../data-access/project/slice';   
import { setAuditLogData } from '../data-access/audit-logs/slice';
import { AuditEntityType, DataModifyLogValues } from '@cic-boardlite/common';
import { omit } from 'lodash';

 const useAuditLogs = () => {
   const dispatch = useAppDispatch();

   const isSavingAuditLog: boolean = useAppSelector((state) => state.auditLogs.isSavingAuditLog);

   const isLoadingPractitionerAuditLogs = useAppSelector((state) => state.practitioners.isLoadingAuditLogs);

   const practitionerAuditLogsData = useAppSelector((state) => state.practitioners.auditLogsData);

   const isLoadingProjectAuditLogs = useAppSelector((state) => state.projects.isLoadingAuditLogs);

   const projectAuditLogsData = useAppSelector((state) => state.projects.auditLogsData);

   const setAuditLog = (auditLogData: DataModifyLogValues) => {
     return dispatch(setAuditLogData(auditLogData));
   };

   const getAuditLogs = (getAuditLogsData: {
     id: string;
     auditableEntityType: AuditEntityType;
     page: number;
     itemsPerPage: number;
     filterString?: string;
   }) => {
     if (getAuditLogsData.auditableEntityType === AuditEntityType.Practitioner) {
       dispatch(getPractitionerAuditLogsData(omit(getAuditLogsData, 'auditableEntityType')));
     } else {
       dispatch(getProjectAuditLogsData(omit(getAuditLogsData, 'auditableEntityType')));
     }
   };

   return {
     isSavingAuditLog,

     isLoadingPractitionerAuditLogs,
     practitionerAuditLogsData,

     isLoadingProjectAuditLogs,
     projectAuditLogsData,

     setAuditLog,
     getAuditLogs,
   };
 };

 export default useAuditLogs;
