import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import { HookPractitionerObject } from '@cic-boardlite/common';
import { createPractitioner, updatePractitioner } from '../data-access/practitioner/slice';

const useSavePractitioner = () => {
  const dispatch = useAppDispatch();

  const isCreatingPractitioner = useAppSelector((state) => state.practitioners.isCreatingPractitioner);

  const practitionerCreateFailed = useAppSelector((state) => state.practitioners.practitionerCreateFailed);

  const buildPractitioner = (practitionerData: HookPractitionerObject) => {
    return dispatch(createPractitioner(practitionerData));
  };

  const editPractitioner = (
    oldPractitionerData: HookPractitionerObject,
    updatedPractitionerData: HookPractitionerObject,
    updatingFromProfile: boolean,
    updatingFromPractitionersTable: boolean
  ) => {
    return dispatch(
      updatePractitioner({
        oldData: oldPractitionerData,
        newData: updatedPractitionerData,
        updatingFromProfile,
        updatingFromPractitionersTable,
      })
    );
  };

  return {
    buildPractitioner,
    editPractitioner,
    isCreatingPractitioner,
    practitionerCreateFailed,
  };
};

export default useSavePractitioner;
