import { createSlice } from '@reduxjs/toolkit';
import {
  LocalStorageName,
  PageType,
  FilterTabData,
  FilterTabsState,
  setUrlParamsString,
  filterObjectToUrlParams,
} from '@cic-boardlite/common';

export const initialState: FilterTabsState = {
  isCreatingFilterTab: {
    practitionerTab: false,
    projectTab: false,
  },
  isLoadingFilterTabs: false,
  isSavingFilterTab: false,
  isDeletingFilterTab: false,
  practitionerFilterTabsData: [],
  projectFilterTabsData: [],
  activePractitionerFilterTab: {
    id: -1,
    title: '',
    context: undefined,
    tabPosition: -1,
    numberOfFilteredPractitioners: undefined,
    unsavedContext: undefined,
    pageType: 1,
  },
  activeProjectFilterTab: {
    id: -1,
    title: '',
    context: undefined,
    tabPosition: -1,
    numberOfFilteredPractitioners: undefined,
    unsavedContext: undefined,
    pageType: 2,
  },
  isDefaultProjectTabCreated: false,
  isDefaultPractitionerTabCreated: false
};

const filterTabsSlice = createSlice({
  name: 'filterTabs',
  initialState,
  reducers: {
    getFilterTabsData(state, action) {
      state.isLoadingFilterTabs = true;
    },
    getFilterTabsDataSuccess(state, action) {
      const { filtersData, pageType } = action.payload;
      const stateType = pageType === PageType.Practitioner ? 'practitionerFilterTabsData' : 'projectFilterTabsData';
      const localStorageName =
        pageType === PageType.Practitioner ? LocalStorageName.PractitionerFilterTab : LocalStorageName.ProjectFilterTab;
      const activeFilterStateType =
        pageType === PageType.Practitioner ? 'activePractitionerFilterTab' : 'activeProjectFilterTab';
      const isCreatingFilterStateType = pageType === PageType.Practitioner ? 'practitionerTab' : 'projectTab';

      if (!state.isDeletingFilterTab && !state.isCreatingFilterTab[isCreatingFilterStateType]) {
        state[stateType] = filtersData.map((filter: FilterTabData, index: number) => {
          return {
            ...filter,
            unsavedContext: state[stateType][index]?.unsavedContext,
            pageType: pageType,
          };
        });

        if (
          state.isCreatingFilterTab[isCreatingFilterStateType] &&
          state[activeFilterStateType].id === initialState[activeFilterStateType].id
        ) {
          return;
        }
        const activeFilterTabPosition = localStorage.getItem(localStorageName);

        if (activeFilterTabPosition && state[stateType][+activeFilterTabPosition]) {
          state[activeFilterStateType] = state[stateType][+activeFilterTabPosition];
          setUrlParamsString(filterObjectToUrlParams(state[activeFilterStateType].context));
        } else if (state[stateType][1]) {
          state[activeFilterStateType] = state[stateType][1];
          setUrlParamsString(filterObjectToUrlParams(state[activeFilterStateType].context));
        }
      }

      state.isLoadingFilterTabs = false;
    },
    getFilterTabsDataFailed(state) {
      state.isLoadingFilterTabs = false;
    },

    setFilterTabData(state, action) {
      state.isSavingFilterTab = true;
    },
    setFilterTabDataSuccess(state, action) {
      const filtersDataStateType =
        action.payload.pageType === PageType.Practitioner ? 'practitionerFilterTabsData' : 'projectFilterTabsData';
      state[filtersDataStateType] = state[filtersDataStateType].map((filter) => {
        return filter.id === action.payload.id
          ? {
              ...filter,
              unsavedContext: undefined,
              numberOfFilteredPractitioners: action.payload.numberOfFilteredPractitioners,
              pageType: action.payload.pageType,
            }
          : filter;
      });

      state.isSavingFilterTab = false;
      
      if(action.payload.pageType === PageType.Practitioner) {
        state.isDefaultPractitionerTabCreated = true;
      }

      if(action.payload.pageType === PageType.Project) {
        state.isDefaultProjectTabCreated = true;
      }
    },
    setFilterTabDataFailed(state) {
      state.isSavingFilterTab = false;
    },

    setDeleteFilterTabData(state, action) {
      state.isDeletingFilterTab = true;
    },
    setDeleteFilterTabDataSuccess(state, action) {
      state.isDeletingFilterTab = false;

      const activeFilterStateType =
        action.payload.pageType === PageType.Practitioner ? 'activePractitionerFilterTab' : 'activeProjectFilterTab';

      state[activeFilterStateType] = initialState[activeFilterStateType];

      setUrlParamsString('');
    },
    setDeleteFilterTabDataFailed(state) {
      state.isDeletingFilterTab = false;
    },

    setCurrentFilterTabData(
      state,
      action: {
        payload: FilterTabData;
        type: string;
      }
    ) {
      const activeFilterStateType =
        action.payload.pageType === PageType.Practitioner ? 'activePractitionerFilterTab' : 'activeProjectFilterTab';

      state[activeFilterStateType] = action.payload;
    },
    setIsCreatingFilterTabData(state, action) {
      state.isCreatingFilterTab = action.payload;
    },
    setTabUnsavedContextData(state, action) {
      if (!action.payload.id) {
        return;
      }

      const activeFilterStateType =
        action.payload.pageType === PageType.Practitioner ? 'activePractitionerFilterTab' : 'activeProjectFilterTab';

      if (
        state.isCreatingFilterTab[
          action.payload.pageType === PageType.Practitioner ? 'practitionerTab' : 'projectTab'
        ] &&
        action.payload.id === initialState[activeFilterStateType].id
      ) {
        state[activeFilterStateType].unsavedContext = action.payload.unsavedContext;

        return;
      }

      const stateType =
        action.payload.pageType === PageType.Practitioner ? 'practitionerFilterTabsData' : 'projectFilterTabsData';

      state[stateType].find((tab) => {
        if (tab.id === action.payload.id) {
          tab.unsavedContext = action.payload.unsavedContext;
          return true;
        }
        return false;
      });

      state[activeFilterStateType].unsavedContext = action.payload.unsavedContext;
    },
  },
});

export const {
  getFilterTabsData,
  getFilterTabsDataSuccess,
  getFilterTabsDataFailed,
  setFilterTabData,
  setFilterTabDataSuccess,
  setFilterTabDataFailed,
  setDeleteFilterTabData,
  setDeleteFilterTabDataSuccess,
  setDeleteFilterTabDataFailed,
  setCurrentFilterTabData,
  setIsCreatingFilterTabData,
  setTabUnsavedContextData,
} = filterTabsSlice.actions;
export default filterTabsSlice.reducer;
