import { Modal } from 'carbon-components-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonState, useProject, usePractitioners, useSecondaryModal } from '../../custom-hooks';
import CreateEditAssignmentForm from './create-edit-assignment-form';
import CreateEditAssignmentFormSkeleton from './create-edit-assignment-form-skeleton';
import UpdateAvailabilityDateModal from '../update-availability-date-modal/update-availability-date-modal';
import { initialState } from '../../data-access/common-states/slice';
import './assignment-create-edit.scss';

const AssignmentCreateEdit = () => {
  const { t } = useTranslation('common');
  const { projectsIdNameData, loadProjectsIdNameData, isLoadingProjectsIdNameData } = useProject();

  const { toggleAssignmentCreateEditModal, assignmentCreateEditModalType } = useCommonState();

  const { isLoadingPractitionerNames, getPractitionersNames, practitionerNames } = usePractitioners();

  const { trackFormChanges, formChanged, secondaryModal, closeSecondaryModal, openSecondaryModal } =
    useSecondaryModal();

  useEffect(() => {
    if (!practitionerNames.length) getPractitionersNames();
    if (!projectsIdNameData.length) loadProjectsIdNameData();

    return () => {
      closeModal();
    };
  }, []);

  const closeModal = () => {
    if (formChanged) {
      return openSecondaryModal(true);
    }
    return toggleAssignmentCreateEditModal(initialState.assignmentCreateEditModalType);
  };

  const closeAllModals = () => {
    openSecondaryModal(false);
    toggleAssignmentCreateEditModal(initialState.assignmentCreateEditModalType);
  };

  return (
    <>
      <UpdateAvailabilityDateModal editingFromProfilePage={false} editingFromPractitionersTable={false} />
      {assignmentCreateEditModalType.isOpen && (
        <Modal
          passiveModal
          open={assignmentCreateEditModalType.isOpen}
          onRequestClose={closeModal}
          className="create-edit-assignment-modal"
        >
          {isLoadingProjectsIdNameData || isLoadingPractitionerNames ? (
            <CreateEditAssignmentFormSkeleton />
          ) : (
            <CreateEditAssignmentForm
              closeModal={closeModal}
              projectInfo={projectsIdNameData}
              assignmentInfo={assignmentCreateEditModalType.assignmentInfo}
              editingAssignment={assignmentCreateEditModalType.editingAssignment}
              projectPage={assignmentCreateEditModalType.projectPage}
              projectDetails={assignmentCreateEditModalType.projectDetails}
              trackFormChanges={trackFormChanges}
            />
          )}
        </Modal>
      )}
      {secondaryModal && (
        <Modal
          open={secondaryModal}
          danger
          size="sm"
          modalHeading={t('formLeaveConfirmationModal.heading')}
          primaryButtonText={t('formLeaveConfirmationModal.yesButton')}
          secondaryButtonText={t('formLeaveConfirmationModal.noButton')}
          onRequestClose={closeSecondaryModal}
          onRequestSubmit={closeAllModals}
        >
          {t('formLeaveConfirmationModal.description')}
        </Modal>
      )}
    </>
  );
};

export default AssignmentCreateEdit;
