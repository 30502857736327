import { Button, Loading } from 'carbon-components-react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  authorizationPopup,
  useAuthorization,
} from '../../custom-hooks/useAuthorization';
import { useEffect, useState } from 'react';
import { HttpStatus } from '@cic-boardlite/common';

type Status = {
  status: string;
  previousLocation: string;
};
export function PopupBlockedPage() {
  let { status, previousLocation } = useParams<Status>();
  const { checkAuthorization } = useAuthorization();

  if (previousLocation) previousLocation = '../' + previousLocation;
  const { t } = useTranslation('common');
  if (status) {
    if (!Number(status)) status = `${HttpStatus.NOT_FOUND}`;
  }

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      checkAuthorization();
    }, 3000);
    return () => {
      setIsLoading(false);
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="pageLayout">
      <h1 className="error">{t(`popupBlockedPage.error`)}</h1>
      <p className="errorMessage">{t(`popupBlockedPage.errorMessage`)}</p>
      <h3 className="suggestedActions">
        {t(`errorPage.suggestedActions`)}
        <ul className="list">
          <li>
            <Trans>
              {t(`popupBlockedPage.firstSuggestedAction`, {
                page_url: window.origin,
              })}
            </Trans>
          </li>
          <li>
            {t(`popupBlockedPage.secondSuggestedAction`)}
            <strong>{t(`loginPage.loginW3`)}</strong>
          </li>
        </ul>
      </h3>
      <Button
        className="button"
        kind="secondary"
        onClick={() => {
          authorizationPopup('/');
          setIsLoading(true);
        }}
      >
        {isLoading ? (
          <div>
            <span className="loading-indicator">
              <Loading
                description="Active loading indicator"
                withOverlay={false}
                small
              />
            </span>
            <span>{t('loginPage.loginInProgress')}</span>
          </div>
        ) : (
          <>{t('loginPage.loginW3')}</>
        )}
      </Button>
    </div>
  );
}

export default PopupBlockedPage;
