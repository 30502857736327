import {
  ButtonSkeleton,
  Grid,
  Row,
  TextInputSkeleton,
} from 'carbon-components-react';
import './assignment-create-edit.scss';

const CreateEditAssignmentFormSkeleton = () => {
  return (
    <Grid className="create-edit-assignment-form-skeleton-grid">
      <Row className="create-edit-assignment-form-skeleton-row">
        <TextInputSkeleton />
        <TextInputSkeleton />
        <TextInputSkeleton />
      </Row>
      <Row className="create-edit-assignment-form-skeleton-row">
        <TextInputSkeleton />
        <TextInputSkeleton />
        <TextInputSkeleton />
      </Row>
      <Row className="create-edit-assignment-form-skeleton-row">
        <TextInputSkeleton />
        <TextInputSkeleton />
        <TextInputSkeleton />
      </Row>
      <Row className="create-edit-assignment-form-skeleton-row">
        <TextInputSkeleton />
        <TextInputSkeleton />
        <TextInputSkeleton />
      </Row>
      <Row className="create-edit-assignment-form-skeleton-buttons">
        <ButtonSkeleton style={{ width: '100%' }} />
        <ButtonSkeleton style={{ width: '100%' }} />
      </Row>
    </Grid>
  );
};

export default CreateEditAssignmentFormSkeleton;
