import {
  Button,
  ComboBox,
  DatePicker,
  DatePickerInput,
  FilterableMultiSelect,
  SideNav,
  SideNavItems,
  TextInput,
} from 'carbon-components-react';
import {
  useCommonState,
  useFilteredPractitioners,
  usePractitioners,
  useFilterTabs,
  useStaticData,
  useUser,
} from '../../../custom-hooks';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { practitionersFilterConfig } from '../../../../config/config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initialState } from '../../../data-access/filter-tabs/slice';
import { FilterRemove, Search } from '@carbon/icons-react/next';
import { isEqual, cloneDeep } from 'lodash';
import {
  cicOnlyFields,
  concatFirstLastNames,
  FilterTabInterface,
  filterObjectToUrlParams,
  filterOutCicFields,
  FilterTabData,
  getSortedFilterValues,
  getUrlsParamsString,
  PageType,
  Role,
  RoleStaticDataInterface,
  setUrlParamsString,
  trim,
  urlParamsToFilterObject
} from '@cic-boardlite/common';
import ComboboxWithCustomFilter from '../../combobox-custom-filtering/combobox-custom-filtering';
import MultiSelectWithSelectAll from '../../multiselect-select-all/multiselect-select-all';
import './filter.scss';

const PractitionersFilter = () => {
  const { t } = useTranslation('common');
  const { staticData } = useStaticData();
  const { roles } = useUser();

  const defaultFilters = {
    availabilityInfo: '',
    band: [],
    actionRequiredStatus: [],
    contractType: [],
    country: [],
    department: [],
    emfStatus: [],
    isPMO: null,
    isPeM: null,
    isCic: {
      label: t('practitionersFilter.isCic.options.isCic'),
      value: 'true',
    },
    availabilityDateManuallyEdited: null,
    jrs: [],
    nameFirstLast: '',
    serial: '',
    serviceArea: '',
    serviceLine: [],
    terminated: {
      label: t('practitionersFilter.terminated.options.notTerminated'),
      value: 'false',
    },
    availabilityDateStart: '',
    availabilityDateEnd: '',
    role: [],
    pemInfo: null,
  };
  const formInputs = [
    {
      name: 'nameFirstLast',
      id: 'filterNameFirstLast',
      placeholder: t('practitionersFilter.nameFirstLast.placeholder'),
    },
    {
      name: 'serial',
      id: 'filterSerial',
      placeholder: t('practitionersFilter.serial.placeholder'),
    },
    {
      name: 'serviceArea',
      id: 'filterServiceArea',
      placeholder: t('practitionersFilter.serviceArea.placeholder'),
      cicOnly: true,
    },
    {
      name: 'availabilityInfo',
      id: 'filterAvailabilityInfo',
      placeholder: t('practitionersFilter.availabilityInfo.placeholder'),
    },
  ];
  const formDropdowns = [
    {
      name: 'isPMO',
      id: 'filterIsPMO',
      placeholder: t('practitionersFilter.isPMO.label'),
      options: [
        { label: t('practitionersFilter.isPMO.options.isPMO'), value: 'true' },
        {
          label: t('practitionersFilter.isPMO.options.notPMO'),
          value: 'false',
        },
      ],
      cicOnly: true,
    },
    {
      name: 'isPeM',
      id: 'filterIsPeM',
      placeholder: t('practitionersFilter.isPeM.label'),
      options: [
        { label: t('practitionersFilter.isPeM.options.isPeM'), value: 'true' },
        {
          label: t('practitionersFilter.isPeM.options.notPeM'),
          value: 'false',
        },
      ],
      cicOnly: true,
    },
    {
      name: 'availabilityDateManuallyEdited',
      id: 'filterAvailabilityDateManuallyEdited',
      placeholder: t('practitionersFilter.availabilityDateManuallyEdited.label'),
      options: [
        {
          label: t('practitionersFilter.availabilityDateManuallyEdited.options.isAvailabilityDateManuallyEdited'),
          value: 'true',
        },
        {
          label: t('practitionersFilter.availabilityDateManuallyEdited.options.notAvailabilityDateManuallyEdited'),
          value: 'false',
        },
      ],
    },
  ];
  const formDropdownsWithDefaultSet = [
    {
      name: 'isCic',
      id: 'filterIsCic',
      placeholder: t('practitionersFilter.isCic.label'),
      options: [
        { label: t('practitionersFilter.isCic.options.isCic'), value: 'true' },
        {
          label: t('practitionersFilter.isCic.options.notCic'),
          value: 'false',
        },
      ],
    },
    {
      name: 'terminated',
      id: 'filterTerminated',
      placeholder: t('practitionersFilter.terminated.label'),
      options: [
        {
          label: t('practitionersFilter.terminated.options.isTerminated'),
          value: 'true',
        },
        {
          label: t('practitionersFilter.terminated.options.notTerminated'),
          value: 'false',
        },
      ],
      cicOnly: true,
    },
  ];
  const formMultiSelects = [
    {
      name: 'emfStatus',
      label: t('practitionersFilter.emfStatus.label'),
      options: staticData.staticData.emfStatusStaticData.slice(),
      cicOnly: true,
    },
    {
      name: 'department',
      label: t('practitionersFilter.department.label'),
      options: staticData.staticData.departmentStaticData.slice(),
      cicOnly: true,
    },
    {
      name: 'serviceLine',
      label: t('practitionersFilter.serviceLine.label'),
      options: staticData.staticData.serviceLineStaticData.slice(),
      cicOnly: true,
    },
    {
      name: 'actionRequiredStatus',
      label: t('practitionersFilter.actionRequiredStatus.label'),
      options: [
        {
          id: 1,
          title: t('practitionersFilter.actionRequiredStatus.options.high'),
        },
        {
          id: 2,
          title: t('practitionersFilter.actionRequiredStatus.options.medium'),
        },
        {
          id: 3,
          title: t('practitionersFilter.actionRequiredStatus.options.low'),
        },
      ],
    },
  ];

  if (roles?.length && roles.includes(Role.ADMIN))
    formMultiSelects.push({
      name: 'role',
      label: t('Roles'),
      options: staticData.staticData.roleStaticData.map((role: RoleStaticDataInterface) => ({
        id: role.id,
        title: role.title,
        description: role.description,
      })),
      cicOnly: true,
    });

  const { control, handleSubmit, reset, setValue, watch } = useForm();
  const [jrsKey, setJrsKey] = useState(Math.random());
  const [countryKey, setCountryKey] = useState(Math.random());
  const { isPractitionersSideBarVisible } = useCommonState();
  const { setPractitionerFilterCreatedUrl, isLoadingFilteredPractitioners } = useFilteredPractitioners();

  const {
    practitionerFilterTabsData,
    activePractitionerFilterTab,
    setCurrentFilterTab,
    isCreatingFilterTab,
    isLoadingFilterTabs,
    setTabUnsavedContext,
    setIsCreatingFilterTab,
  } = useFilterTabs();

  const { actionRequiredPractitionersNumber } = usePractitioners();

  const currentSelectedFilters = useWatch({
    control,
  });

  const currentCic = watch('isCic');

  useEffect(() => {
    setValue('isCic', formDropdownsWithDefaultSet[0].options[0]);
    setValue('terminated', formDropdownsWithDefaultSet[1].options[1]);
  }, []);

  useEffect(() => {
    if (!activePractitionerFilterTab.context && !isCreatingFilterTab.practitionerTab) {
      setPractitionerFilterCreatedUrl(`${practitionersFilterConfig.getPractitionersFilter}`);
      setUrlParamsString(practitionersFilterConfig.getPractitionersFilter.split('?')[1]);
      reset(defaultFilters);
    }
    if (!activePractitionerFilterTab.context) return;

    let filter: FilterTabInterface = {
      ...(activePractitionerFilterTab.unsavedContext || activePractitionerFilterTab.context),
    };

    if (!isCreatingFilterTab.practitionerTab) {
      let urlParamsString = getUrlsParamsString();

      if (urlParamsString.includes('isCic=false')) urlParamsString = filterOutCicFields(urlParamsString);

      if (
        urlParamsString &&
        urlParamsString !== filterObjectToUrlParams(filter) &&
        practitionerFilterTabsData.length > 1 &&
        activePractitionerFilterTab.tabPosition !== 0 &&
        !activePractitionerFilterTab.unsavedContext
      ) {
        setPractitionerFilterCreatedUrl(
          `${practitionersFilterConfig.getPractitionersFilter.split('?')[0]}?${urlParamsString}`
        );

        filter = urlParamsToFilterObject({
          urlParamsString,
          activeFilter: filter,
          translations: t('practitionersFilter', { returnObjects: true }),
          staticData: staticData.staticData,
          pageType: PageType.Practitioner,
        });

        setCurrentFilterTab({
          ...activePractitionerFilterTab,
          unsavedContext: filter,
          pageType: PageType.Practitioner,
        });
      } else {
        getPractitionerDataByQuery(filter);
        setUrlParamsString(filterObjectToUrlParams(filter));
      }

      reset(filter);
      setCountryKey(Math.random());
      setJrsKey(Math.random());
    }
  }, [activePractitionerFilterTab?.context, activePractitionerFilterTab?.tabPosition]);

  useEffect(() => {
    if (isLoadingFilterTabs || isLoadingFilteredPractitioners) return;

    if (
      isCreatingFilterTab.practitionerTab &&
      activePractitionerFilterTab.unsavedContext &&
      !actionRequiredPractitionersNumber
    ) {
      setTabUnsavedContext(
        initialState.activePractitionerFilterTab.id,
        activePractitionerFilterTab.unsavedContext,
        PageType.Practitioner
      );
    }

    if (!Object.keys(currentSelectedFilters).length) return;

    if (practitionerFilterTabsData.length <= 1 && !isCreatingFilterTab.practitionerTab) {
      const hasContext = activePractitionerFilterTab.context;
      const isFiltersEqual = isFilterEqual(activePractitionerFilterTab.context, currentSelectedFilters);
      const isActionRequiredCount = actionRequiredPractitionersNumber;
      const isNotDefaultTab = activePractitionerFilterTab.id !== 0;

      if ((hasContext && !isFiltersEqual && isActionRequiredCount) || (!isActionRequiredCount && isNotDefaultTab)) {
        setIsCreatingFilterTab({
          practitionerTab: true,
          projectTab: isCreatingFilterTab.projectTab,
        });
        setTabUnsavedContext(
          initialState.activePractitionerFilterTab.id,
          currentSelectedFilters,
          PageType.Practitioner
        );
        return;
      }
    }

    setUrlParamsString(filterObjectToUrlParams(currentSelectedFilters));

    if (activePractitionerFilterTab.tabPosition !== 0) {
      const activeFilterContext = cloneDeep(
        activePractitionerFilterTab.unsavedContext || activePractitionerFilterTab.context
      );

      if ('actionRequired' in currentSelectedFilters) delete currentSelectedFilters['actionRequired'];

      if (activeFilterContext && activeFilterContext['actionRequired'] in activeFilterContext)
        delete activeFilterContext['actionRequired'];

      if (!isFilterEqual(activeFilterContext, currentSelectedFilters)) {
        setTabUnsavedContext(activePractitionerFilterTab.id, currentSelectedFilters, PageType.Practitioner);
      }
    }
  }, [currentSelectedFilters]);

  useEffect(() => {
    if (!isCreatingFilterTab.practitionerTab) return;

    setCurrentFilterTab({
      ...initialState.activePractitionerFilterTab,
      unsavedContext: activePractitionerFilterTab?.unsavedContext,
      pageType: PageType.Practitioner,
    });

    if (!activePractitionerFilterTab?.unsavedContext) {
      resetHandler();
    } else {
      setPractitionerFilterCreatedUrl(
        `${practitionersFilterConfig.getPractitionersFilter.split('?')[0]}?${filterObjectToUrlParams(
          activePractitionerFilterTab.unsavedContext
        )}`
      );

      reset(activePractitionerFilterTab.unsavedContext);
    }
  }, [isCreatingFilterTab.practitionerTab]);

  const isFilterEqual = (filterA: FilterTabData['context'], filterB: FilterTabData['context']): boolean => {
    if (!filterA || !filterB) return false;

    return isEqual(getSortedFilterValues(filterA), getSortedFilterValues(filterB));
  };

  const getPractitionerDataByQuery = (data: FilterTabInterface & { actionRequired?: string }) => {
    const dataKeyArray = Object.keys(data);

    let path = `${practitionersFilterConfig.getPractitionersFilter.split('?')[0]}?`;
    if (data.availabilityInfo) path += `availabilityInfo=${trim(data.availabilityInfo)}&`;
    if (data.nameFirstLast) path += `nameFirstLast=${trim(data.nameFirstLast)}&`;
    if (data.serial) path += `serial=${trim(data.serial)}&`;
    if (data.serviceArea) path += `serviceArea=${trim(data.serviceArea)}&`;
    if (data.isPMO?.value) path += `isPMO=${data.isPMO.value}&`;
    if (data.isPeM?.value) path += `isPeM=${data.isPeM.value}&`;
    if (data.isCic?.value) path += `isCic=${data.isCic.value}&`;
    if (data.terminated?.value) path += `terminated=${data.terminated.value}&`;
    if (data?.actionRequired) path += `actionRequired=${data.actionRequired}&`;
    if (data.availabilityDateStart) path += `availabilityDateStart=${new Date(Number(data.availabilityDateStart))}&`;
    if (data.availabilityDateEnd) path += `availabilityDateEnd=${new Date(Number(data.availabilityDateEnd))}&`;
    if (data.availabilityDateManuallyEdited?.value)
      path += `availabilityDateManuallyEdited=${data.availabilityDateManuallyEdited.value}&`;
    if (data?.pemInfo?.id) path += `pemInfo=${data.pemInfo.id}&`;
    dataKeyArray.forEach((key) =>
      [
        'country',
        'role',
        'band',
        'actionRequiredStatus',
        'contractType',
        'department',
        'emfStatus',
        'jrs',
        'serviceLine',
      ].includes(key) &&
      data[key] &&
      data[key].length > 0
        ? data[key].forEach((item: { title: string; code: number }) => {
            if (key === 'country') {
              path += key + `=${item.code}&`;
            } else if (key === 'role') { 
              path += key + `=${item.title.toLowerCase()}&`;
            } else {
              path += key + `=${item.title}&`;
            }
          })
        : null
    );

    if (data.isCic?.value === 'false') {
      path = filtersToExclude(cicOnlyFields, path);
    }

    const url = path.slice(0, -1);
    setPractitionerFilterCreatedUrl(url);
  };

  const filtersToExclude = (filters: string[], filterParamsString: string) => {
    const patterns = filters.map((item) => `${item}(=)(.*?)(&)`);
    return filterParamsString.replace(RegExp(patterns.join('|'), 'g'), '');
  };

  const filterClassFlip = isPractitionersSideBarVisible ? 'practitioners-filter-open' : '';

  const resetHandler = () => {
    reset(defaultFilters);
    setCountryKey(Math.random());
    setJrsKey(Math.random());
  };

  const onFilterReset = () => {
    if (isCreatingFilterTab.practitionerTab || practitionerFilterTabsData.length <= 1) {
      resetHandler();
      setPractitionerFilterCreatedUrl(practitionersFilterConfig.getPractitionersFilter);
      setUrlParamsString('');
      return;
    }

    setCurrentFilterTab(activePractitionerFilterTab);
    setUrlParamsString(filterObjectToUrlParams(activePractitionerFilterTab?.context));
    setTabUnsavedContext(activePractitionerFilterTab.id, undefined, PageType.Practitioner);

    reset(activePractitionerFilterTab?.context);
    setJrsKey(Math.random());
    setCountryKey(Math.random());
  };

  const shouldRenderMappedInput = (inputCicOnly?: boolean) => {
    if (!inputCicOnly) return true;

    return (
      !currentCic ||
      currentCic.value === 'true' ||
      (currentCic.value === 'false' && !inputCicOnly) ||
      currentCic.value === ''
    );
  };

  const getFormsMultiSelectTitle = (name: string): string => {
    return name[0].toUpperCase() + name.slice(1).split(/(?=[A-Z])/).join(" ").toLowerCase();
  };

  return (
    <>
      <div className={filterClassFlip}></div>
      <div
        className={`practitioners-filter-container container ${
          (isCreatingFilterTab.practitionerTab || activePractitionerFilterTab.unsavedContext) &&
          isPractitionersSideBarVisible &&
          !isLoadingFilterTabs &&
          'practitioners-filter-border'
        }`}
      >
        <SideNav isFixedNav expanded={isPractitionersSideBarVisible} isChildOfHeader={true} aria-label="Side navigation">
          <SideNavItems className="practitioner-filter-side-nav">
            <form
              className="practitioner-filter-form"
              onSubmit={handleSubmit((data) => {
                getPractitionerDataByQuery(data);
              })}
            >
              <div>
                {formInputs.map(
                  (input, index) =>
                    shouldRenderMappedInput(input.cicOnly) && (
                      <Controller
                        key={index}
                        name={input.name}
                        defaultValue=""
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            className="filter-input"
                            onChange={onChange}
                            onBlur={(event) => {
                              setValue(input.name, trim(event.target.value));
                            }}
                            id={input.id}
                            hideLabel
                            labelText=""
                            placeholder={input.placeholder}
                            value={value}
                          />
                        )}
                      />
                    )
                )}
                {shouldRenderMappedInput(true) && (
                  <Controller
                    name="pemInfo"
                    control={control}
                    render={({ field: { onChange, value = '' } }) => (
                      <ComboboxWithCustomFilter
                        customClassName={`custom-filter-combobox-practitioners-filter-pem-info`}
                        onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                        selectedItem={concatFirstLastNames(value)}
                        items={staticData.staticData.pemInfoStaticData.map(
                          (pem: { id: number; serial: string; firstName: string; lastName: string }) => ({
                            id: pem?.id,
                            name: (pem?.firstName ?? '') + ' ' + (pem?.lastName ?? ''),
                          })
                        )}
                        itemKeyName="name"
                        placeholder={t('practitionersFilter.pem.placeholder')}
                        displaySelectedValueBolded
                      />
                    )}
                  />
                )}
                {formMultiSelects.map(
                  (field, index) =>
                    shouldRenderMappedInput(field.cicOnly) && (
                      <Controller
                        key={index}
                        name={field.name}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <div title={getFormsMultiSelectTitle(field.name)}>
                            <MultiSelectWithSelectAll
                              onChange={(onChangeData) => onChange(onChangeData.selectedItems)}
                              id={field.name}
                              itemToString={(item) => {
                                return item ? item?.title?.charAt(0)?.toUpperCase() + item?.title?.slice(1) : '';
                              }}
                              items={field.options}
                              label={field.label}
                              hideLabel
                              selectedItems={Array.isArray(value) ? value : []}
                              selectAllItem={{
                                id: 0,
                                title: t('multiselectSelectAll.selectAll'),
                              }}
                            />
                          </div>
                        )}
                      />
                    )
                )}
                {formDropdowns.map(
                  (field, index) =>
                    shouldRenderMappedInput(field.cicOnly) && (
                      <Controller
                        key={index}
                        name={field.name}
                        control={control}
                        render={({ field: { onChange, value = '' } }) => (
                          <ComboBox
                            onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                            id={field.id}
                            items={field.options}
                            itemToString={(item) => (item ? item?.label : '')}
                            placeholder={field.placeholder}
                            selectedItem={value}
                            className={`${value?.value ? 'has-selection' : ''} read-only-combobox`}
                            readOnly
                            title={field.placeholder}
                          />
                        )}
                      />
                    )
                )}
                <Controller
                  name="band"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div title={t('practitionersFilter.band.label')}>
                      <MultiSelectWithSelectAll
                        onChange={(onChangeData) => onChange(onChangeData.selectedItems)}
                        id="filter-band"
                        itemToString={(item: { id: number; title: string | undefined }) => item?.title ?? ''}
                        items={staticData.staticData.bandStaticData.slice()}
                        label={t('practitionersFilter.band.label')}
                        hideLabel
                        selectedItems={Array.isArray(value) ? value : []}
                        selectAllItem={{
                          id: 0,
                          title: t('multiselectSelectAll.selectAll'),
                        }}
                      />
                    </div>
                  )}
                />
                <Controller
                  key={jrsKey}
                  name="jrs"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div title={t('practitionersFilter.jrs.labelPlaceholder')}>
                      <FilterableMultiSelect
                        onChange={(onChangeData) => onChange(onChangeData.selectedItems)}
                        id="filter-jrs-item"
                        itemToString={(item: {
                          id: number;
                          title: string;
                          serviceArea: string;
                          psAdder: { id: number; title: string };
                        }) => (item ? item?.title : '')}
                        items={staticData.staticData.jrsStaticData}
                        label={t('practitionersFilter.jrs.labelPlaceholder')}
                        hideLabel
                        placeholder={t('practitionersFilter.jrs.labelPlaceholder')}
                        initialSelectedItems={value}
                        selectedItems={Array.isArray(value) ? value : []}
                        useTitleInItem
                      />
                    </div>
                  )}
                />
                <Controller
                  key={countryKey}
                  name="country"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div title={t('practitionersFilter.country.labelPlaceholder')}>
                        <FilterableMultiSelect
                          onChange={(onChangeData) => onChange(onChangeData.selectedItems)}
                          id="filterCountryItem"
                          itemToString={(item: { id: number; label: string; code: string }) => (item ? item?.label : '')}
                          items={staticData.staticData.countryStaticData}
                          label={t('practitionersFilter.country.labelPlaceholder')}
                          hideLabel
                          placeholder={t('practitionersFilter.country.labelPlaceholder')}
                          initialSelectedItems={value}
                          selectedItems={Array.isArray(value) ? value : []}
                        />
                      </div>
                    );
                  }}
                />
                {shouldRenderMappedInput(true) && (
                  <Controller
                    name="contractType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div title={t('practitionersFilter.contractType.labelPlaceholder')}>
                        <MultiSelectWithSelectAll
                          onChange={(onChangeData) => onChange(onChangeData.selectedItems)}
                          id="filter-contract-typeItem"
                          itemToString={(item: { id: number; title: string | undefined }) => item?.title ?? ''}
                          items={staticData.staticData.contractTypeStaticData.slice()}
                          label={t('practitionersFilter.contractType.labelPlaceholder')}
                          hideLabel
                          selectedItems={Array.isArray(value) ? value : []}
                          selectAllItem={{
                            id: 0,
                            title: t('multiselectSelectAll.selectAll'),
                          }}
                        />
                      </div>
                    )}
                  />
                )}
                <Controller
                  name="availabilityDateStart"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      light
                      datePickerType="single"
                      onChange={(_dates: Date[], _currentDateString: string) =>
                        onChange(new Date(_currentDateString).getTime())
                      }
                      dateFormat={t('format.date_4carbonDatePicker')}
                      value={value}
                      className="filter-input"
                      title={t('practitionersFilter.availabilityDateStartPlaceholder')}
                    >
                      <DatePickerInput
                        id="filterAvailabilityDateStart"
                        labelText={''}
                        hideLabel
                        placeholder={t('practitionersFilter.availabilityDateStartPlaceholder')}
                        size="xl"
                        pattern={t('format.date_4carbonDatePickerInput')}
                      />
                    </DatePicker>
                  )}
                />
                <Controller
                  name="availabilityDateEnd"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      light
                      datePickerType="single"
                      onChange={(_dates: Date[], _currentDateString: string) =>
                        onChange(new Date(_currentDateString).getTime())
                      }
                      dateFormat={t('format.date_4carbonDatePicker')}
                      value={value}
                      className="filter-input"
                      title={t('practitionersFilter.availabilityDateEndPlaceholder')}
                    >
                      <DatePickerInput
                        id="filterAvailabilityDateEnd"
                        labelText={''}
                        hideLabel
                        placeholder={t('practitionersFilter.availabilityDateEndPlaceholder')}
                        pattern={t('format.date_4carbonDatePickerInput')}
                        size="xl"
                      />
                    </DatePicker>
                  )}
                />
                {formDropdownsWithDefaultSet.map((field, index) => {
                  return (
                    shouldRenderMappedInput(field.cicOnly) && (
                      <Controller
                        key={index}
                        name={field.name}
                        control={control}
                        render={({ field: { onChange, value = '' } }) => {
                          return (
                            <ComboBox
                              onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                              id={field.id}
                              items={field.options}
                              itemToString={(item) => (item ? item?.label : '')}
                              placeholder={field.placeholder}
                              selectedItem={value}
                              className={`${value?.value ? 'has-selection' : ''} read-only-combobox`}
                              readOnly
                              title={field.placeholder}
                            />
                          );
                        }}
                      />
                    )
                  );
                })}
              </div>
              <div className="practitioner-filter-button-wrap">
                <Button kind="secondary" renderIcon={FilterRemove} onClick={onFilterReset}>
                  <div className="flex">{t('practitionersFilter.resetFormButton')}</div>
                </Button>
                <Button type="submit" renderIcon={Search}>
                  {t('practitionersFilter.submitFormButton')}
                </Button>
              </div>
            </form>
          </SideNavItems>
        </SideNav>
      </div>
    </>
  );
};

export default PractitionersFilter;
