import React, { useEffect } from 'react';
import {
  CheckmarkOutline20,
  Error20,
  CheckmarkOutline16,
  Error16,
  Warning16,
} from '@carbon/icons-react';
import 'carbon-components/scss/globals/scss/styles.scss';
import { useTranslation } from 'react-i18next';
import { Loading, TooltipDefinition } from 'carbon-components-react';
import './external-services-status.scss';
import { useBluePages } from '../../custom-hooks';

function ExternalServicesStatus({ className }: { className?: string }) {
  const { t } = useTranslation('common');
  const {
    checkBlueServices,
    checkCvBuilderStatus,
    isBlueServicesOnline,
    isLoadingBlueServices,
    isCvBuilderOnline,
    isLoadingCvBuilder,
    externalServicesInterval,
    setExternalServicesIntervalId,
  } = useBluePages();

  useEffect(() => {
    if (!externalServicesInterval) {
      pingServices();
      const intervalId = setInterval(() => {
        pingServices();
      }, 60000);
      setExternalServicesIntervalId(intervalId);
    }
  }, []);

  function pingServices() {
    checkBlueServices();
    checkCvBuilderStatus();
  }

  return (
    <TooltipDefinition
      className={'external-services-status-tooltip ' + className}
      align="end"
      onClick={pingServices}
      tooltipText={
        <div className="External-services-tooltip-content">
          <div className="External-services-tooltip-row">
            {isLoadingBlueServices ? (
              <Loading
                className="External-services-tooltip-loading"
                small={true}
                withOverlay={false}
              />
            ) : isBlueServicesOnline ? (
              <CheckmarkOutline20 className="success-icon-color External-services-tooltip-row-icon" />
            ) : (
              <Error20 className="error-icon-color External-services-tooltip-row-icon" />
            )}
            <p>{t('ExternalServicesStatus.blueServices')}</p>
          </div>
          <div className="External-services-tooltip-row">
            {isLoadingCvBuilder ? (
              <Loading
                className="External-services-tooltip-loading"
                small={true}
                withOverlay={false}
              />
            ) : isCvBuilderOnline ? (
              <CheckmarkOutline20 className="success-icon-color External-services-tooltip-row-icon" />
            ) : (
              <Error20 className="error-icon-color External-services-tooltip-row-icon" />
            )}
            <p>{t('ExternalServicesStatus.cvWizard')}</p>
          </div>
          {!isBlueServicesOnline && !isCvBuilderOnline && (
            <p className="external-services-status-tooltip-suggestion-row">
              {t('ExternalServicesStatus.vpnSuggestion')}
            </p>
          )}
        </div>
      }
    >
      {isBlueServicesOnline && isCvBuilderOnline ? (
        <CheckmarkOutline16 className="success-icon-color pointer" />
      ) : isBlueServicesOnline || isCvBuilderOnline ? (
        <Warning16 className="warning-icon-color pointer" />
      ) : (
        <Error16 className="error-icon-color pointer" />
      )}
    </TooltipDefinition>
  );
}

export default ExternalServicesStatus;
