import { useEffect } from 'react';
import { getHiringPipelinePractitioners } from '../data-access/practitioner/slice';
import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';

const useHiringPipelinePractitioner = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getHiringPipelinePractitioners());
  }, []);

  const hiringPipelinePractitioners = useAppSelector((state) => state.practitioners.hiringPipelinePractitioners);

  const isLoadingHiringPipelinePractitioners = useAppSelector(
    (state) => state.practitioners.isLoadingHiringPipelinePractitioners
  );

  return {
    hiringPipelinePractitioners,
    isLoadingHiringPipelinePractitioners,
  };
};

export default useHiringPipelinePractitioner;
