import { Paste } from '@carbon/icons-react/next';
import { ImportExportDataInterface } from '@cic-boardlite/common';
import { Grid, InlineLoading, Modal, Row, TooltipDefinition } from 'carbon-components-react';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { WorkBook, WorkSheet } from 'xlsx';
import { useFileImportExport, usePractitioners } from '../../custom-hooks';
import FileImportDropbox from './file-import-dropbox';

interface PropsInterface {
  isOpen: boolean;
  setImportExportModalOpen: Dispatch<SetStateAction<boolean>>;
}

const FileImportModal = ({ isOpen, setImportExportModalOpen }: PropsInterface) => {
  const [collectionType, setCollectionType] = useState<string>('');
  const [collectionArray, setCollectionArray] = useState<ImportExportDataInterface[]>([]);
  const [fileErrorMessage, setFileErrorMessage] = useState<string>('');
  const [dropboxDetails, setDropboxDetails] = useState<{
    title: string;
    fileType: string;
  }>({ title: '', fileType: '' });
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const { exportFile, importFile, isLoadingImportFile } = useFileImportExport();
  const { getActionRequiredPractitionersCount } = usePractitioners();
  const { t } = useTranslation('common');

  enum FileTypes {
    Jrs = 'jrs',
    Rates = 'rates',
  }

  const headersToKeys = (headers: string[]) => {
    const keys = headers.map((header: string) => {
      switch (header.toString().toLowerCase()) {
        case 'jrs':
          return 'title';
        case 'jrs service area':
          return 'serviceArea';
        case 'country code':
          return 'countryCode';
        case 'ps adder':
          return 'psAdder';
        default:
          return header.toString().toLowerCase();
      }
    });
    return keys;
  };

  const readFile = (event: ChangeEvent<HTMLInputElement>, fileType: string) => {
    if (event.target.files) {
      const file: File = event.target.files[0];
      if (file && file.size > 500000) {
        return setFileErrorMessage(t('fileImportExport.fileExceedsSize'));
      }
      setUploadedFile(event.target.files[0]);
      const reader: FileReader = new FileReader();
      reader.onloadend = () => {
        const workbook: WorkBook = XLSX.read(reader.result, { type: 'binary' });
        const worksheet: WorkSheet = workbook.Sheets[workbook.SheetNames[0]];
        const sheetRows = XLSX.utils.sheet_to_json<string[]>(worksheet, {
          blankrows: false,
          raw: true,
          header: 1,
        });
        validateHeaders(sheetRows, fileType);
      };

      reader.readAsBinaryString(file);
    }
  };

  const validateHeaders = (sheetRows: string[][], fileType: string) => {
    let requiredHeaders: string[] = [];
    switch (fileType) {
      case FileTypes.Jrs:
        requiredHeaders = ['JRS', 'JRS Service Area', 'PS Adder'];
        break;
      case FileTypes.Rates:
        requiredHeaders = ['Band', 'Country code', 'PS Adder', 'Rate'];
        break;
      default:
        return;
    }

    let headersAreValid = true;
    const errorMessage = t('fileImportExport.missingHeaders');
    let missingHeaders = '';

    const headersToLowerCase = sheetRows[0].map((header: string) => header.toString().toLowerCase());

    requiredHeaders.forEach((requiredHeader: string) => {
      if (headersToLowerCase.indexOf(requiredHeader.toLowerCase()) < 0) {
        headersAreValid = false;
        missingHeaders += ` ${requiredHeader},`;
      }
    });

    return headersAreValid
      ? formatAndUploadFile(sheetRows, fileType)
      : setFileErrorMessage(errorMessage + missingHeaders.slice(0, -1) + '.');
  };

  const formatAndUploadFile = (dataRows: string[][], fileType: string) => {
    const fileObjectArray: ImportExportDataInterface[] = [];
    const headers = dataRows.splice(0, 1)[0];
    const keys = headersToKeys(headers);

    if (fileType === FileTypes.Rates) {
      const rateHeaderIndex = headers.findIndex((header) => header.toLowerCase() === 'rate');

      const isAnyRatesIncorrect = dataRows.some(
        (row) => isNaN(Number(row[rateHeaderIndex])) || Number(row[rateHeaderIndex]) < 0
      );
      if (isAnyRatesIncorrect) return setFileErrorMessage(t('fileImportExport.invalidRates'));
    }
    dataRows.forEach((row: string[]) => {
      const obj = {};
      row.forEach((value: string, index: number) => {
        Object.assign(obj, {
          [keys[index]]: value.toString().trim(),
        });
      });
      if (Object.keys(obj).length === headers.length) fileObjectArray.push(obj);
    });
    setCollectionType(fileType);
    setCollectionArray(fileObjectArray);
  };

  const clearFileAndCollection = () => {
    setUploadedFile(null);
    setCollectionType('');
    setCollectionArray([]);
  };

  const closeModal = () => {
    setImportExportModalOpen(false);
    setDropboxDetails({ title: '', fileType: '' });
    clearFileAndCollection();
  };

  return (
    <Modal
      className="file-import-modal"
      open={isOpen}
      primaryButtonText={
        isLoadingImportFile ? (
          <InlineLoading
            status="active"
            iconDescription={t(`fileImportExport.importingIconDescription`)}
            description={t(`fileImportExport.importing`)}
          />
        ) : (
          t('fileImportExport.import')
        )
      }
      primaryButtonDisabled={!collectionType.length || isLoadingImportFile || !!fileErrorMessage}
      secondaryButtonText={t('fileImportExport.cancel')}
      onRequestSubmit={() => {
        importFile({
          fileType: collectionType,
          fileObjects: collectionArray,
        });
        closeModal();
      }}
      onRequestClose={closeModal}
      modalHeading={t('fileImportExport.modalHeading')}
    >
      <Grid className="file-import-grid">
        <Row>
          <span className="import-title-span">{t('fileImportExport.jrsDataFile')}</span>
          <div className="import-export-tooltip-wrapper">
            <TooltipDefinition
              onFocus={(e) => e.currentTarget.blur()}
              className="import-export-modal-tooltip"
              tooltipText={t('fileImportExport.exportTooltip', {
                fileType: 'JRS',
              })}
              onClick={() => exportFile(FileTypes.Jrs)}
            >
              {t('fileImportExport.jrsDataFilename')}
            </TooltipDefinition>
            <TooltipDefinition
              onFocus={(e) => e.currentTarget.blur()}
              className="import-export-modal-tooltip"
              tooltipText={t('fileImportExport.importTooltip', {
                fileType: 'JRS',
              })}
            >
              <Paste
                onClick={() => {
                  setDropboxDetails({
                    title: t('fileImportExport.jrsTitle'),
                    fileType: FileTypes.Jrs,
                  });
                  getActionRequiredPractitionersCount();
                  clearFileAndCollection();
                }}
              />
            </TooltipDefinition>
          </div>
        </Row>
        <Row>
          <span className="import-title-span">{t('fileImportExport.bandDataFile')}</span>
          <div className="import-export-tooltip-wrapper">
            <TooltipDefinition
              onFocus={(e) => e.currentTarget.blur()}
              className="import-export-modal-tooltip"
              tooltipText={t('fileImportExport.exportTooltip', {
                fileType: 'Band',
              })}
              onClick={() => exportFile(FileTypes.Rates)}
            >
              {t('fileImportExport.bandDataFilename')}
            </TooltipDefinition>
            <TooltipDefinition
              onFocus={(e) => e.currentTarget.blur()}
              className="import-export-modal-tooltip"
              tooltipText={t('fileImportExport.importTooltip', {
                fileType: 'Bands',
              })}
            >
              <Paste
                onClick={() => {
                  setDropboxDetails({
                    title: t('fileImportExport.bandTitle'),
                    fileType: FileTypes.Rates,
                  });
                  clearFileAndCollection();
                }}
              />
            </TooltipDefinition>
          </div>
        </Row>
      </Grid>

      {!!dropboxDetails.title.length && (
        <FileImportDropbox
          setFileErrorMessage={setFileErrorMessage}
          fileErrorMessage={fileErrorMessage}
          readFile={readFile}
          uploadedFile={uploadedFile}
          title={dropboxDetails.title}
          fileType={dropboxDetails.fileType}
          clearFileAndCollection={clearFileAndCollection}
        />
      )}
    </Modal>
  );
};

export default FileImportModal;
