import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import { sendFeedbackToMaintainerEmail, getPractitionersMaintainerEmails } from '../data-access/feedback/slice';
import { EmailContentFeedbackResponse } from '@cic-boardlite/common';

const useFeedback = () => {
  const dispatch = useAppDispatch();

  const isMaintainerEmailSet = useAppSelector(
    (state) => state.feedback.isPractitionerMaintainerEmailSet
  );

  const getMaintainerEmails = () => {
    if(isMaintainerEmailSet) return;
    
    dispatch(getPractitionersMaintainerEmails());
  };

  const isLoadingMaintainerEmails = useAppSelector(
    (state) => state.feedback.isPractitionerMaintainerEmailsLoading
  );

  const maintainerEmails = useAppSelector(
    (state) => state.feedback.practitionersMaintainerEmails
  );

  const sendFeedbackToMaintainers = (data: EmailContentFeedbackResponse) => {
    dispatch(sendFeedbackToMaintainerEmail(data))
  };

  return {
    getMaintainerEmails,
    isLoadingMaintainerEmails,
    maintainerEmails,
    sendFeedbackToMaintainers
  };
};

export default useFeedback;
