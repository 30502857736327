import { GetBccEmailInterface, GetPractitionerAssignments } from '../../common';
import { ProjectAssignments } from '../../assignment';
import { replaceSpecialCharactersCallback } from '../replace-letters/replace-letters';

export const checkIfAssignmentIsMain = (
  assignmentsArray: (GetPractitionerAssignments | ProjectAssignments)[],
  rowId: string
) => {
  const assignment = assignmentsArray.find(
    (assignment) => String(assignment.id) === rowId
  );
  return assignment?.mainAssignment;
};

export const formatObjectWithPractitionerAssignmentsEndDates = (
  practitionerAssignments: {
    endDate: Date;
    isMainAssignment: boolean;
    assignmentStatus: { id: number };
  }[]
): {
  mainAssignmentEnd?: Date;
  activeAssignmentEnd?: Date;
  inactiveAssignmentEnd?: Date;
} => {
  const mainAssignment = practitionerAssignments.find(
    (assignment) => assignment?.isMainAssignment
  );
  const mainAssignmentEndDate = mainAssignment?.endDate;

  const newestActiveAssignment = practitionerAssignments
    .filter(
      (assignment) =>
        assignment?.assignmentStatus?.id === 1 && !assignment.isMainAssignment
    )
    .sort(
      (a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
    )[0]?.endDate;

  const latestInactiveAssignment = practitionerAssignments
    .filter((assignment) => assignment?.assignmentStatus?.id === 2)
    .sort(
      (a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
    )[0]?.endDate;

  return {
    mainAssignmentEnd: mainAssignmentEndDate,
    activeAssignmentEnd: newestActiveAssignment,
    inactiveAssignmentEnd: latestInactiveAssignment,
  };
};

export const filterAssignmentsWithToolbarSearchValue = <
  T extends ProjectAssignments | GetPractitionerAssignments
>(
  assignments: T[],
  filterString: string
): T[] => {
  const filteredAssignments = assignments?.filter((assignment) =>
    compareFilterToAllNestedObjects(assignment, filterString)
  );
  return filteredAssignments;
};

const compareFilterToAllNestedObjects = (
  obj: ProjectAssignments | GetPractitionerAssignments,
  filterString: string
) => {
  const filterStringToAscii =
    replaceSpecialCharactersCallback(filterString).toLowerCase();

  for (const value of Object.values(obj)) {
    if (typeof value === 'string') {
      const valueToAscii =
        replaceSpecialCharactersCallback(value).toLowerCase();
      if (valueToAscii.includes(filterStringToAscii)) return true;
    } else if (typeof value === 'object' && value) {
      if (compareFilterToAllNestedObjects(value, filterStringToAscii))
        return true;
    }
  }
  return false;
};

export const getBccEmail = (data: GetBccEmailInterface): string => {
  if (!data.isBccChecked) return '';

  if (data.emails.includes(data.userEmail)) {
    return '';
  };

  return data.userEmail
};
