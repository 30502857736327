import { ComboBox, InlineLoading } from 'carbon-components-react';
import { FocusEventHandler, FormEvent } from 'react';
import { DeepMap, FieldError, get, useFormContext, UseFormGetValues } from 'react-hook-form';
import { SuggestedPractitioner } from '../../interfaces/practitionerInterfaces';
import { useBluePages } from '../../custom-hooks';
import './reusableCombobox.scss';
import { useTranslation } from 'react-i18next';

interface PropsInterface {
  id: string;
  controllerName: string;
  getValue: UseFormGetValues<any>;
  onChange: (...event: (string | object)[]) => void;
  allowInputValue?: boolean;
  filtering?: boolean;
  removeConnectedValuesIndividually?: boolean;
  items: (string | object)[];
  itemKeyName?: string;
  title: string | JSX.Element;
  placeholder: string;
  errorName?: string;
  errors: Partial<DeepMap<Record<string, any>, FieldError>>;
  errorMessage?: string;
  createEditPractitioner?: boolean;
  className?: string;
  disabled?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

interface ChangeDataInterface {
  selectedItem: object;
}

const ReusableComboboxComponent = ({
  id,
  controllerName,
  getValue,
  onChange,
  allowInputValue,
  filtering,
  removeConnectedValuesIndividually,
  items,
  itemKeyName,
  title,
  placeholder,
  errorName,
  errors,
  errorMessage,
  createEditPractitioner,
  className,
  disabled,
  onBlur,
}: PropsInterface) => {
  let errorMessages;
  errorName ? (errorMessages = get(errors, errorName)) : (errorMessages = get(errors, controllerName));

  const { setValue } = useFormContext();
  const { t } = useTranslation('common');

  const { selectBluePagesPractitioner, isSelectingBluePagesPractitioner } = useBluePages();

  const addRemainingObjectValue = (controllerName: string, itemKeyName: string, input?: boolean) => {
    const value = getValue(controllerName);
    let obj = {};

    let valueKeys = Object.keys(value);

    valueKeys = valueKeys.filter((key) => key !== itemKeyName);

    if (valueKeys?.length) {
      if (removeConnectedValuesIndividually || input) {
        for (const indexOfKey in valueKeys) {
          obj = {
            ...obj,
            [valueKeys[indexOfKey]]: value[valueKeys[indexOfKey]],
          };
        }
      } else {
        for (const indexOfKey in valueKeys) {
          obj = {
            ...obj,
            [valueKeys[indexOfKey]]: '',
          };
        }
      }
    }
    return obj;
  };

  const onChangeFn = (selectedItem: object) => {
    if (itemKeyName) {
      !selectedItem &&
        onChange({
          [itemKeyName]: '',
          ...addRemainingObjectValue(controllerName, itemKeyName),
        });

      selectedItem && onChange(selectedItem);
    } else onChange(selectedItem);
  };

  const onInputFn = (e: FormEvent<HTMLInputElement>) => {
    itemKeyName &&
      !createEditPractitioner &&
      onChange({
        [itemKeyName]: e.currentTarget.value,
        ...addRemainingObjectValue(controllerName, itemKeyName, true),
      });
    !itemKeyName && onChange(e.currentTarget.value);
    createEditPractitioner && itemKeyName && onChange(e.currentTarget.value);
  };

  const filterItemsFn = (item: string | { [key: string]: string }, inputValue?: string) => {
    if (typeof item === 'string' && inputValue) {
      return item.toLowerCase().includes(inputValue.toLowerCase());
    }
    if (typeof item === 'object' && itemKeyName && inputValue) {
      return item[itemKeyName].toLowerCase().includes(inputValue.toLowerCase());
    }
    return item;
  };

  const itemToStringFn = (item: string | { [key: string]: string }) => {
    if (typeof item === 'string') {
      return item ? item : '';
    }
    if (typeof item === 'object' && itemKeyName && item) {
      return item[itemKeyName] ? item[itemKeyName] : '';
    }
    return '';
  };

  const onPractitionerChange = (selectedItem: SuggestedPractitioner) => {
    if (selectedItem?.serial) {
      setValue('serial', selectedItem['serial']);
      selectBluePagesPractitioner(selectedItem.serial);
    }
  };

  const itemToPractitionerString = (item: string | { [key: string]: string }) => {
    if (typeof item === 'string') {
      return item ? item : '';
    }
    if (typeof item === 'object') {
      return itemKeyName === 'serial'
        ? item['serial'] + ` [${item['firstName']} ${item['lastName']}]`
        : item['firstName'] + ' ' + item['lastName'];
    }
    return '';
  };

  const itemTransform = (item: string | { [key: string]: string }): string => {
    return createEditPractitioner ? itemToPractitionerString(item) : itemToStringFn(item);
  };

  const handleChange = ({ selectedItem }: ChangeDataInterface) => {
    createEditPractitioner ? onPractitionerChange(selectedItem) : onChangeFn(selectedItem);
  };

  return !isSelectingBluePagesPractitioner ? (
    <ComboBox
      onChange={handleChange}
      onInput={(e) => {
        allowInputValue && onInputFn(e);
      }}
      id={id}
      disabled={disabled || isSelectingBluePagesPractitioner || false}
      items={items}
      className={className}
      itemToString={itemTransform}
      selectedItem={getValue(controllerName) || ''}
      titleText={title}
      warn={!!(errors && errorMessages)}
      warnText={<span className="delete-modal-error-message">{errorMessage}</span>}
      placeholder={placeholder}
      shouldFilterItem={({ item, inputValue }) => (filtering ? filterItemsFn(item, inputValue) : item)}
      onBlur={onBlur}
    />
  ) : (
    <div className="loading-wrapper">
      <span className="loading-header">
        <p className="loading-text">{t('reusableCombobox.loadingHeader')}</p>
        <sup className="asterisk">*</sup>
      </span>
      <InlineLoading
        status="active"
        description={t('reusableCombobox.loadingDescription', {
          talentID: getValue('serial'),
        })}
        className="combo-box-loading"
      />
    </div>
  );
};

export default ReusableComboboxComponent;
