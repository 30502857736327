import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextArea } from 'carbon-components-react';
import { EditOff, Save } from '@carbon/icons-react/next';
import { KeyboardEvent } from 'react';
import ErrorBox from 'apps/frontend/src/app/ui/form-error-box/ErrorBox';
import './EditComment.scss';
import { useEditProject } from 'apps/frontend/src/app/custom-hooks';
import { UpdateSingleFieldInterface } from 'apps/frontend/src/app/interfaces/project-interfaces';

interface EditCommentInterface extends UpdateSingleFieldInterface {
  onCloseCommentEdit: () => void;
}

const EditComment = ({ comment, projectId, onCloseCommentEdit, id }: EditCommentInterface) => {
  const { updateSingleField } = useEditProject();
  const { t } = useTranslation('common');

  const defaultValue = {
    comment: comment || '',
  };

  const methods = useForm<{ comment: string }>({
    defaultValues: defaultValue,
  });

  const {
    formState: { errors, isDirty, isSubmitting, isSubmitSuccessful },
  } = methods;

  const handleOnEnterSubmit = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  const handleSubmitForm = (data: { comment: string }) => {
    const oldComment = {
      comment: defaultValue.comment,
      projectId: projectId,
      id: id,
    };

    const newComment = {
      comment: data.comment,
      projectId: projectId,
      id: id,
    };

    updateSingleField(oldComment, newComment);
    return onCloseCommentEdit();
  };

  return (
    <FormProvider {...methods}>
      <form className="expanded-projects-table__comment-form">
        <Controller
          name="comment"
          control={methods.control}
          rules={{
            maxLength: {
              value: 512,
              message: t('maxLengthMessage', { maxLength: 512 }),
            },
          }}
          render={({ field: { onChange, value } }) => (
            <TextArea
              light
              onChange={onChange}
              value={value}
              labelText=""
              rows={2}
              invalid={!!errors['comment']}
              invalidText={<ErrorBox>{errors['comment']?.message}</ErrorBox>}
              onKeyDown={(e) => handleOnEnterSubmit(e)}
            />
          )}
        />
        <div className="expanded-projects-table__comment-form-buttons">
          <button
            className="expanded-projects-table__comment-submit-btn"
            disabled={!isDirty || isSubmitting || isSubmitSuccessful}
            onClick={methods.handleSubmit((data) => handleSubmitForm(data))}
          >
            <Save size={16} />
          </button>
          <EditOff className="expanded-projects-table__comment-close" size={16} onClick={onCloseCommentEdit} />
        </div>
      </form>
    </FormProvider>
  );
};

export default EditComment;
