import {
  AssignmentSummaryInterface,
  GetPractitionerDetails,
  EmailContentInterface,
  convertDate,
} from '@cic-boardlite/common';
import { TFunction } from 'react-i18next';
import { EmailTemplateTypes } from '../send-email-dropdown/template-types.config';

export const generateEmailContent = (
  t: TFunction<'common', undefined>,
  type: string,
  practitionerDetails: GetPractitionerDetails | undefined,
  assignmentDetails: AssignmentSummaryInterface | undefined,
  sender: string
): EmailContentInterface => {
  let emailContent: EmailContentInterface = {
    subject: '',
    html: '',
  };

  switch (type) {
    case EmailTemplateTypes.onboarding:
      emailContent = {
        subject: `On boarding for ${practitionerDetails?.firstName} ${practitionerDetails?.lastName[0]}. on ${assignmentDetails?.projectName} project`,
        html: `<p>Dear <strong>${assignmentDetails?.geoPm?.split(' ')[0]}</strong>,<br></p>
        <p><strong>${practitionerDetails?.firstName} ${practitionerDetails?.lastName[0]}.</strong> will be onboarded on <strong>${
          assignmentDetails?.projectName
        }</strong> project on <strong>${
          assignmentDetails?.startDate
            ? convertDate(new Date(assignmentDetails.startDate))
            : ''
        }</strong>. <strong>${practitionerDetails?.firstName} ${practitionerDetails?.lastName[0]}.</strong> will start claiming on this project on <strong>${
          assignmentDetails?.startDate
            ? convertDate(new Date(assignmentDetails.startDate))
            : ''
        }</strong>.</p>
        <p>Please ensure the appropriate and needed accesses to client/project team rooms, networks, and systems were granted to <strong>${practitionerDetails?.firstName} ${practitionerDetails?.lastName[0]}</strong>.</p>
        <p>Please notify us with respect to these accesses in order to be able to provide proof in case of an audit.</p>
        <p>Please update the necessary project documentation (roles and responsibilities, SOD matrix, Workforce Member Master Log / Access Control List) to include the new member.</p>
        <p>Please be informed that this is a standard e-mail which is mandatory for the CIC CEE onboarding process.<br></p>
        <p>Thank you,</p>
        <p>${sender}</p>
        `,
      };
      break;

    case EmailTemplateTypes.offboarding:
      emailContent = {
        subject: `Employee to be off boarded from Project ${assignmentDetails?.projectName}: ${practitionerDetails?.firstName} ${practitionerDetails?.lastName[0]}.`,
        html: `<p>Dear All,<br></p>
        <p>Please be informed that on <strong>${
          assignmentDetails?.endDate
            ? convertDate(new Date(assignmentDetails.endDate))
            : ''
        }</strong> <strong>${practitionerDetails?.firstName} ${practitionerDetails?.lastName[0]}.</strong> will be offboarded from <strong>${
          assignmentDetails?.projectName
        }</strong> project and she/he will not claim any more time on the respective Account ID.</p>
        <p>Please ensure the actions described below are taken:</p>
          <p>
          - <strong>${
            assignmentDetails?.projectName
          }</strong> project representative should be informed that the above-mentioned practitioner will no longer be working on the project starting with the mentioned date. <br/>
          - All accesses to customer systems and environments should be revoked in 48 hours. <br/>
          - SOD matrix might need to be updated.<br/>
          - Software licenses issued to the project or area should be returned by the practitioner to you or <strong>${
            assignmentDetails?.projectName
          }</strong> Project representative<br/>
          - Documents and information related to the project or area should be returned by the practitioner to you or <strong>${
            assignmentDetails?.projectName
          }</strong> Project representative<br/>
          - Confidential documents should be returned by the practitioner to you or <strong>${
            assignmentDetails?.projectName
          }</strong> project representative (or destroyed as required).<br/>
          - If applicable, desktop and laptops(s) should be returned by the practitioner to you or to the  <strong>${
            assignmentDetails?.projectName
          }</strong> project representative<br/>
          - If client hardware, all IBM confidential information should be removed in a manner so that it cannot be recreated. <br/>
          - Hard disk should be reformatted if necessary.<br/>
          - The Workforce Member Master List/Access Control List should be updated to reflect the departed GBS workforce member.
          </p>
        <p>Please be informed that this is a standard e-mail which is mandatory for the CIC CEE off-boarding process.<br/></p>
        <p>Thank you,</p>
        <p>${sender}</p>`,
      };
      break;

    case EmailTemplateTypes.assignmentDetails:
      emailContent = {
        subject: `All approvals are in place for ${practitionerDetails?.firstName} ${practitionerDetails?.lastName[0]}. assignment on ${assignmentDetails?.projectName} project`,
        html: `
          <p>Hello <strong>${practitionerDetails?.firstName}</strong>,<br></p>
          <p>Please be informed that your assignment to <strong>${
            assignmentDetails?.projectName
          }</strong> project was fully approved.</p>
          <p>The details of the assignment are below:</p>
          <table>
            <tr>
              <th>Start date</th>
              <th>End date</th>
              <th>Allocation</th>
            </tr>
            <tr>
              <td>${
                assignmentDetails?.startDate
                  ? convertDate(new Date(assignmentDetails.startDate))
                  : ''
              }</td>
              <td>${
                assignmentDetails?.endDate
                  ? convertDate(new Date(assignmentDetails.endDate))
                  : ''
              }</td>
              <td>${assignmentDetails?.allocation || ''}</td>
            </tr>
          </table>
          <p>You can start claiming your time in Time@IBM to the project from <strong>${
            assignmentDetails?.startDate
              ? convertDate(new Date(assignmentDetails.startDate))
              : ''
          }</strong>, but no more claiming can be done after <strong>${
          assignmentDetails?.endDate
            ? convertDate(new Date(assignmentDetails.endDate))
            : ''
        }</strong>, unless there is an extension of your assignment - we will inform you by separate email in case your assignment is extended.</p>
          <p>Your claim code and work item on <strong>${
            assignmentDetails?.projectName
          }</strong> project are:<br/>
          <strong>Account ID:</strong> xxxxx<br/>
          <strong>Work item:</strong> xxxxx</p>
          <p><strong>Any overtime work must be approved in Workday in advance.</strong><br></p>
          <p>Thanks and good luck,</p>
          <p>${sender}</p>
          `,
      };
      break;

    case EmailTemplateTypes.assignmentExtension:
      emailContent = {
        subject: `All approvals are in place for ${practitionerDetails?.firstName} ${practitionerDetails?.lastName[0]}. assignment extension on ${assignmentDetails?.projectName} project`,
        html: `
        <p>Hello <strong>${practitionerDetails?.firstName}</strong>,<br></p>
        <p>Please be informed that all approvals for your assignment extension on <strong>${
          assignmentDetails?.projectName
        }</strong> project are in place.
        <p><strong>New end date: ${
          assignmentDetails?.endDate
            ? convertDate(new Date(assignmentDetails.endDate))
            : ''
        }</strong><br/>
        <strong>Allocation: ${assignmentDetails?.allocation || ''}</strong></p>
        <p>Your work/claiming outside this period/allocation is not allowed without prior agreement with your country Workforce Management Professional or your project PMO.</p>
        <p>In case of any doubts about claiming, or any other administrative questions please contact your PMO.<br></p>
        <p>Thanks and good luck with the project,</p>
        <p>${sender}</p>
        `,
      };
      break;

    case EmailTemplateTypes.requestExtension:
      emailContent = {
        subject: `Resources extension request for project ${assignmentDetails?.projectName}`,
        html: `
        <p>Dear <strong>${assignmentDetails?.geoPm?.split(' ')[0]},</strong><br></p>
        <p>Please note below listed resource(s) assignment in ${
          assignmentDetails?.projectName
        }</strong> ends on:</p>
          <strong>
          ${practitionerDetails?.firstName} ${practitionerDetails?.lastName[0]}., TalentID ${assignmentDetails?.serial} - ${
          assignmentDetails?.endDate
            ? convertDate(new Date(assignmentDetails.endDate))
            : ''
        }.
        </strong><br/>
        <p>Please confirm resource(s) extension in the project by specifying the new end date of assignment.<br/></p>
        <p>Thank you,</p>
        <p>${sender}</p>
        `,
      };
      break;

    case EmailTemplateTypes.dataRemovalCert:
      emailContent = {
        subject: `Off-Boarding Data Removal Certification`,
        html: `
        <p>Hello <strong>${practitionerDetails?.firstName}</strong>,<br></p>
        <p>On <strong>${
          assignmentDetails?.endDate
            ? convertDate(new Date(assignmentDetails.endDate))
            : ''
        }</strong> you are rolling-off from <strong>${
          assignmentDetails?.projectName
        }</strong> project. It means, no further work can be done for the project and no claiming is allowed to project's claim code.</p>
        <p>As part of Off-Boarding procedure, <strong style='text-decoration:underline'>please fill in the Off-Boarding Data Removal Certification below, get your People Manager's approval via email and forward it to your PMO.</strong></p>
        <p><strong>Off-Boarding Data Removal Certification:</strong></p>
        <style>
        </style>
        <table style='border-spacing: 0; border-collapse: collapse'>
          <tr>
            <th style='border: 1px solid black; min-width: 100px; padding:1em; background-color: #E6E6E6'>Employee Name</th>
            <td style='border: 1px solid black; min-width: 100px; padding:1em;'></td>
            <th style='border: 1px solid black; min-width: 100px; padding:1em; background-color: #E6E6E6'>Manager Name</th>
            <td style='border: 1px solid black; min-width: 100px; padding:1em;'></td>
          </tr>
          <tr>
            <th style='border: 1px solid black; min-width: 100px; padding:1em; background-color: #E6E6E6'>IBM Employee/Contractor #</th>
            <td style='border: 1px solid black; min-width: 100px; padding:1em;'></td>
            <th style='border: 1px solid black; min-width: 100px; padding:1em; background-color: #E6E6E6'>Date of Certification</th>
            <td style='border: 1px solid black; min-width: 100px; padding:1em; color:#0000FF'>yyyy-mm-dd</td>
          </tr>
          <tr>
            <th style='border: 1px solid black; min-width: 100px; padding:1em; background-color: #E6E6E6'>Project Name</th>
            <td style='border: 1px solid black; min-width: 100px; padding:1em;' colspan='3'><strong>${
              assignmentDetails?.projectName
            }</strong></td>
          </tr>
        </table>
        <br/><br/>
        <table style='border-spacing: 0; border-collapse: collapse'>
          <tr>
            <th style='border: 1px solid black; min-width: 100px; padding:1em; background-color: #E6E6E6' colspan='2'>Equipment Type</th>
            <th style='border: 1px solid black; min-width: 100px; padding:1em; background-color: #E6E6E6'>Date of Data Removal</th>
            <th style='border: 1px solid black; min-width: 100px; padding:1em; background-color: #E6E6E6'>Method of Data Removal</th>
          </tr>
          <tr>
            <th style='border: 1px solid black; min-width: 100px; padding:1em; background-color: #E6E6E6'>Laptop/Desktop Machine # and TalentID</th>
            <td style='border: 1px solid black; min-width: 100px; padding:1em;'></td>
            <td style='border: 1px solid black; min-width: 100px; padding:1em; color:#0000FF'>yyyy-mm-dd</td>
            <td style='border: 1px solid black; min-width: 100px; padding:1em; color:#0000FF'>(for example, Delete or IBM Secure Data Disposal)</td>
          </tr>
          <tr>
            <th style='border: 1px solid black; min-width: 100px; padding:1em; background-color: #E6E6E6'>Listing of other portable storage media used</th>
            <td style='border: 1px solid black; min-width: 100px; padding:1em;'></td>
            <td style='border: 1px solid black; min-width: 100px; padding:1em; color:#0000FF'>yyyy-mm-dd</td>
            <td style='border: 1px solid black; min-width: 100px; padding:1em; color:#0000FF'>(for example, Delete or IBM Secure Data Disposal)</td>
          </tr>
          <tr>
            <th style='border: 1px solid black; min-width: 100px; padding:1em; background-color: #E6E6E6'>Listing of other portable storage media used</th>
            <td style='border: 1px solid black; min-width: 100px; padding:1em;'></td>
            <td style='border: 1px solid black; min-width: 100px; padding:1em; color:#0000FF'>yyyy-mm-dd</td>
            <td style='border: 1px solid black; min-width: 100px; padding:1em; color:#0000FF'>(for example, Delete or IBM Secure Data Disposal)</td>
          </tr>
        </table>
        <p>I <strong>${
          practitionerDetails?.nameFirstLast
        }</strong> certify that I have assessed the contents of my laptop hard disk and other storage media listed above and that I have removed and disposed of all Client Confidential Information, Personal Information, Sensitive Personal Information, and client-owned documentation, including client information and documentation from previous assignments.
        I have done this using an IBM provided secure purge tool.  I also certify that I have not copied any of this data to any personal media from which I can later recover this data.  Lastly, I certify that I have not provided this data to any other person or company prior to removing it from my laptop and other media listed.
        </p>
        <p>
        I also certify that all documents that IBM owns and can be retained (and that I am retaining) for reuse for this project have been cleansed to meet contractual requirements and are cleansed of references to any Clients, if applicable.
        Signatures of the Employee and the Manager are provided via email.
        </p>
        <p>
        Completed certifications are stored in the project teamroom and retained for a minimum of 24 months from off-boarding.<br>
        </p>
        <p>Thank you,</p>
        <p>${sender}</p>
        `,
      };
      break;
    default:
      break;
  }
  return emailContent;
};
