export const ASSIGNMENT_ENDING_IN_6_WEEKS = 'assignment-ending-6';
export const ASSIGNMENT_ENDING_IN_4_WEEKS = 'assignment-ending-4';
export const ASSIGNMENT_NOT_ENDING_SOON = 'not-ending-soon';

export const TalentID_LENGTH = {
  SHORT: 6,
  FULL: 9
};

export const SLACK_SUPPORT_CHANNEL = 'https://ibmbalticway.slack.com/archives/C04KQE866R3';

export const ENV_RELEASE_VERSION = 'NX_RELEASE_VERSION';
export const ENV_RELEASE_DATE = 'NX_RELEASE_DATE';

export const FEEDBACK_MAX_IMG_UPLOAD_SIZE = 10 * 1024 * 1024;

export const PRACTITIONER_DATA_RETENTION_PERION_IN_YEARS = 10;
export const PRACTITIONER_DATA_RETENTION_PERION_IN_MS = PRACTITIONER_DATA_RETENTION_PERION_IN_YEARS * 365 * 24 * 60 * 60 * 1000;

export const CFH_ASSIGNMENT_DATE = "4 weeks after project confirms onboarding";
