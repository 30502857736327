export const environment = {
  production: false,
  BLUE_PAGES_SERVICE: 
    'https://w3-unifiedprofile-search.dal1a.cirrus.ibm.com',
  BLUE_PAGES_UNIFIED_PROFILE_API:
    'https://w3-unifiedprofile-api.dal1a.cirrus.ibm.com/v3',
  BLUE_SERVICES_DOMAIN:
    'https://blue-services.wdc1a.ciocloud.nonprod.intranet.ibm.com',
};

// if you run blue pages services locally => change
// BLUE_SERVICES_DOMAIN to http://localhost:3005
