import { MultiSelect } from 'carbon-components-react';
import { CustomMultiSelectWithSelectAllProps } from '@cic-boardlite/common';
import { useEffect, useState } from 'react';

interface CustomItemInterface {
    id?: number;
    label?: string;
    title?: string;
}

const MultiSelectWithSelectAll = <T extends CustomItemInterface>({
    onChange,
    items,
    selectedItems,
    label,
    id,
    itemToString,
    selectAllItem
}: CustomMultiSelectWithSelectAllProps<T>) => {
    const [selection, setSelection] = useState<T[] | undefined>(selectedItems);
    const allItems = [selectAllItem, ...items];

    const handleChange = (onChangeData: { selectedItems: T[] }) => {
        let newSelectedItems: T[] = []
        const wasAllSelected = selection?.find((item) => item?.id === 0);

        const currentlySelectedItems = onChangeData?.selectedItems;
        const isAllSelected = currentlySelectedItems?.find(
            (item) => item?.id === 0
        );
        if (
            (!wasAllSelected && isAllSelected) ||
            currentlySelectedItems?.length === items?.length
        )
            newSelectedItems = allItems;
        if (
            (!wasAllSelected && !isAllSelected) ||
            (wasAllSelected && isAllSelected)
        )
            newSelectedItems = currentlySelectedItems?.filter(
                (item) => item?.id !== 0
            );
        if (wasAllSelected && !isAllSelected) newSelectedItems = [];
        setSelection(newSelectedItems);
    };

    useEffect(() => {
        onChange({ selectedItems: selection });
    }, [selection]);

    return (
        <MultiSelect
            onChange={(onChangeData) => handleChange(onChangeData)}
            id={id}
            itemToString={itemToString}
            items={allItems}
            label={label}
            hideLabel
            selectedItems={selectedItems}
            sortItems={() => allItems}
        />
    );
};

export default MultiSelectWithSelectAll;
