import { Accordion, AccordionItem } from 'carbon-components-react';
import { PractitionersSummary } from '../../interfaces/practitionerInterfaces';
import PractitionersTable from '../practitioners-table/practitioners-table';
import { useTranslation } from 'react-i18next';
import './practitioners-accordion.scss';

interface PractitionersAccordionProps {
  practitionersSummaryData: PractitionersSummary;
}

const PractitionersAccordion = ({
  practitionersSummaryData,
}: PractitionersAccordionProps) => {
  const { t } = useTranslation('common');
  const accordionsList = Object.keys(practitionersSummaryData) as Array<
    keyof typeof practitionersSummaryData
  >;
  return (
    <Accordion>
      {accordionsList.map((accordion) => (
        <AccordionItem
          key={accordion}
          disabled={!practitionersSummaryData[accordion]?.length}
          title={`${t(`accordionPractitionersTitle.${[accordion]}`)} - ${
            practitionersSummaryData[accordion]?.length
          }`}
        >
          <PractitionersTable
            tableData={practitionersSummaryData[accordion]}
            summaryType={accordion}
          />
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default PractitionersAccordion;
