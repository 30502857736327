import { addNotification, removeNotification } from '../data-access/notifications/slice';
import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import { AddToastInterface } from '../ui/common/notification/notificationInterfaces';

const useNotification = () => {
  const dispatch = useAppDispatch();

  const toasts = useAppSelector((state) => state.notifications.toasts);

  const removeToast = (id: number) => {
    dispatch(removeNotification(id));
  };

  const addToast = (data: AddToastInterface) => {
    dispatch(addNotification(data));
  };

  return { toasts, removeToast, addToast };
};

export default useNotification;
