import {
  convertDate,
  ProjectClientData,
  ProjectCreateEditPayloadInterface,
  ProjectIdNameData,
  ProjectStatus,
  toUTCDate,
  trim,
} from '@cic-boardlite/common';
import { Button, DatePicker, DatePickerInput, Dropdown, TextArea, TextInput } from 'carbon-components-react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBluePages, useEditProject, useProject, useProjectAssignments, useStaticData } from '../../../custom-hooks';
import { Project, ProjectEditInterface } from '../../../interfaces/project-interfaces';
import './detail-page.scss';
import { useEffect, useState } from 'react';
import ErrorBox from '../../../ui/form-error-box/ErrorBox';
import { useNavigate } from 'react-router-dom';
import { LabelWithAsterisk } from '../../../ui/label-with-asterisk/label-with-asterisk';
import ComboboxWithCustomFilter from '../../combobox-custom-filtering/combobox-custom-filtering';

const filterDuplicateClientData = (arr: ProjectClientData[], key: 'clientName' | 'clientTag') => {
  const uniqueClientData = new Set();
  return arr.filter((el) => !uniqueClientData.has(el[key]) && uniqueClientData.add(el[key]));
};

enum DateValidation {
  NotANumber = 'NaN',
}

interface PropsInterface {
  projectInfo: Project;
}

const ProjectDetailsComponent = ({ projectInfo }: PropsInterface) => {
  const defaultValues = {
    id: Number(projectInfo?.id),
    projectId: projectInfo?.projectId,
    projectName: projectInfo?.name,
    projectTag: projectInfo?.projectTag,
    clientNameTag: {
      clientName: projectInfo?.clientName,
      clientTag: projectInfo?.clientTag,
    },
    country: { id: projectInfo?.countryId, label: projectInfo?.country },
    projectType: {
      id: projectInfo?.projectTypeId,
      title: projectInfo?.projectType,
    },
    serviceLine: {
      id: projectInfo?.serviceLineId,
      title: projectInfo?.serviceLine,
    },
    projectStatus: {
      id: projectInfo?.projectStatusId,
      title: projectInfo?.projectStatus,
    },
    startDate: new Date(projectInfo?.startDate),
    endDate: new Date(projectInfo?.endDate),
    pmo: { name: projectInfo?.pmo },
    geoPmNameTalentId: {
      name: projectInfo?.geoPm,
      talentId: projectInfo?.geoPmTalentId ?? '',
    },
    comment: projectInfo?.comment,
    accountIDs: projectInfo?.projectAccountIds?.toString(),
    createdBy: projectInfo?.createdById,
  };

  const [projectStartDate, setProjectStartDate] = useState(
    projectInfo?.startDate ? convertDate(new Date(projectInfo?.startDate)) : ''
  );
  const [itemsForGeoPmList, setItemsForGeoPmList] = useState<{ name: string; talentId: string }[]>([]);

  const methods = useForm<any>({
    defaultValues: defaultValues,
  });

  const {
    formState: { isDirty, isSubmitting, isSubmitSuccessful, errors },
  } = methods;

  const { staticData, loadStaticData } = useStaticData();
  const { projectsClientData, projectsIdNameData, geoPmNamesTalentIds } = useProject();
  const { updateProjectDetails } = useEditProject();
  const { bluePagesPractitioners } = useBluePages();
  const { projectAssignments } = useProjectAssignments();

  const { lastAssignmentEndDate } = useProjectAssignments();
  const projectLastAssignmentEndDate = lastAssignmentEndDate?.length ? toUTCDate(lastAssignmentEndDate) : null;
  const nav = useNavigate();
  const { t } = useTranslation('common');
  

  const existingProjectsIDs: string[] = projectsIdNameData.map(
    (projectInfo: ProjectIdNameData) => projectInfo.projectId
  );

  const checkIfProjectIdExists = (array: string[], value: string, currentProjectId: string) => {
    const arrayToLowerCase = array.map((item) => item.toLowerCase());
    const valueToLowerCase = value.toLowerCase().trim();
    if (currentProjectId.toLowerCase() === valueToLowerCase) return true;
    return arrayToLowerCase.indexOf(valueToLowerCase) < 0;
  };

  const staticDataObj = staticData?.staticData;

  useEffect(() => {
    loadStaticData();
  }, []);

  useEffect(() => {
    if (!projectStartDate) return;

    const endDate = convertDate(methods.getValues('endDate'));

    if (!endDate || endDate < projectStartDate) {
      methods.setValue('endDate', projectStartDate);
    }
  }, [projectStartDate]);

  useEffect(() => {
    const bluePagesGeoPmList = bluePagesPractitioners.map((practitioner) => ({
      name: practitioner?.nameFull,
      talentId: practitioner.id + ` [${practitioner?.nameFull}]`,
    }));

    const uniqueGeoPmListFromDB = geoPmNamesTalentIds
      .filter((geoPm) => geoPm.talentId)
      .map((item) => ({ ...item, talentId: item?.talentId + ` [${item?.name}]` }))
      .filter((geoPm) => !bluePagesGeoPmList.find((bpGeo) => bpGeo.talentId === geoPm.talentId));

    const geoPmSuggestionList = [...bluePagesGeoPmList, ...uniqueGeoPmListFromDB];

    setItemsForGeoPmList(geoPmSuggestionList);
  }, [bluePagesPractitioners]);

  const handleSubmitForm = (data: ProjectEditInterface) => {
    const oldProject: ProjectCreateEditPayloadInterface = {
      id: Number(projectInfo.id),
      projectId: projectInfo.projectId,
      name: projectInfo?.name || '',
      clientName: projectInfo.clientName,
      projectAccountIds: projectInfo.projectAccountIds,
      startDate: projectInfo.startDate,
      endDate: projectInfo.endDate,
      comment: projectInfo.comment,
      country: projectInfo.countryId,
      countryTitle: projectInfo.country,
      projectType: projectInfo.projectTypeId,
      projectTypeTitle: projectInfo.projectType,
      projectStatusTitle: projectInfo.projectStatus,
      projectStatus: projectInfo.projectStatusId,
      serviceLineTitle: projectInfo.serviceLine,
      serviceLine: projectInfo.serviceLineId,
      clientTag: projectInfo.clientTag,
      pmo: projectInfo.pmo,
      geoPm: projectInfo.geoPm,
      geoPmTalentId: projectInfo.geoPmTalentId,
    };

    const updatedProject: ProjectCreateEditPayloadInterface = {
      id: Number(data.id),
      projectId: trim(data.projectId),
      name: trim(data?.projectName) || '',
      clientName: trim(data.clientNameTag.clientName),
      projectAccountIds: data.accountIDs,
      startDate: data.startDate ? convertDate(data.startDate) || '' : '',
      endDate: data.endDate ? convertDate(data.endDate) || '' : '',
      comment: trim(data.comment),
      country: data.country.id,
      countryTitle: data.country.label,
      projectType: data.projectType.id,
      projectTypeTitle: data.projectType.title,
      projectStatusTitle: data.projectStatus.title,
      projectStatus: data.projectStatus.id,
      serviceLineTitle: data.serviceLine.title,
      serviceLine: data.serviceLine.id,
      clientTag: trim(data.clientNameTag.clientTag),
      pmo: data.pmo?.name ?? '',
      geoPm: data.geoPmNameTalentId.name,
      geoPmTalentId: data.geoPmNameTalentId.talentId,
    };
    return updateProjectDetails(oldProject, updatedProject);
  };

  const validateEndDate = (value: number): boolean => {
    if (!projectLastAssignmentEndDate) return true;

    return new Date(value).getTime() >= projectLastAssignmentEndDate.getTime();
  };

  const endDateInitialValue = (value: number): number => {
    if (!projectLastAssignmentEndDate) return value;

    return Math.max(value, projectLastAssignmentEndDate.getTime());
  };

  return (
    <div className="project-detail-comp-wrapper">
      <h1 className="project-detail-comp-title">{t('editProject.project')}</h1>
      <FormProvider {...methods}>
        <form className="project-details-form">
          <div className="project-detail-page-input-grid">
            <Controller
              name="projectId"
              control={methods.control}
              rules={{
                validate: {
                  validateDuplicate: (value) =>
                    checkIfProjectIdExists(existingProjectsIDs, value, projectInfo.projectId) ||
                    t(`editProject.projectIdExists`),
                },
                required: { value: true, message: t('editProject.required') },
                maxLength: {
                  value: 128,
                  message: t('maxLengthMessage', { maxLength: 128 }),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  onChange={onChange}
                  id="editProjectId"
                  labelText={LabelWithAsterisk(t('editProject.projectId'))}
                  placeholder={t('editProject.projectId')}
                  value={value ? value : ''}
                  warn={!!methods.formState.errors['projectId']}
                  warnText={<ErrorBox>{methods.formState.errors['projectId']?.message?.toString()}</ErrorBox>}
                />
              )}
            />
            <Controller
              name="projectName"
              control={methods.control}
              rules={{
                required: { value: true, message: t('editProject.required') },
                maxLength: {
                  value: 128,
                  message: t('maxLengthMessage', { maxLength: 128 }),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  onChange={onChange}
                  id="editProjectName"
                  labelText={LabelWithAsterisk(t('editProject.projectName'))}
                  placeholder={t('editProject.projectName')}
                  value={value ? value : ''}
                  warn={!!methods.formState.errors['projectName']}
                  warnText={<ErrorBox>{methods.formState.errors['projectName']?.message?.toString()}</ErrorBox>}
                />
              )}
            />
            <Controller
              name="clientNameTag"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-edit-project-client-name"
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  selectedItem={value ?? { clientName: '', clientTag: '' }}
                  items={filterDuplicateClientData(projectsClientData, 'clientName') || []}
                  itemKeyName="clientName"
                  titleText={LabelWithAsterisk(t('editProject.clientName'))}
                  placeholder={t('editProject.clientName')}
                  allowInputValue
                  warn={!!methods.formState.errors['clientNameTag'] && !value?.clientName?.length}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('editProject.clientName'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
            <Controller
              name="clientNameTag"
              control={methods.control}
              rules={{
                validate: (value) => {
                  if (value?.clientName?.length && value?.clientTag?.length) {
                    methods.clearErrors('clientNameTag');
                    return true;
                  } else return false;
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-edit-project-client-tag"
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  selectedItem={value ?? { clientName: '', clientTag: '' }}
                  items={filterDuplicateClientData(projectsClientData, 'clientTag') || []}
                  itemKeyName="clientTag"
                  titleText={LabelWithAsterisk(t('editProject.clientTag'))}
                  placeholder={t('editProject.clientTag')}
                  allowInputValue
                  warn={!!methods.formState.errors['clientNameTag'] && !value?.clientTag?.length}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('editProject.clientTag'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
            <Controller
              name="geoPmNameTalentId"
              control={methods.control}
              rules={{
                required: true,
                validate: (value) => !!value?.talentId?.length,
              }}
              render={({ field: { onChange, value } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-edit-project-geo-pm-talent-id"
                  onChange={(onChangeData) => {
                    onChange({
                      talentId: onChangeData.selectedItem?.talentId?.split(' ')[0] ?? '',
                      name: onChangeData.selectedItem?.name ?? '',
                    });
                  }}
                  selectedItem={value}
                  items={itemsForGeoPmList}
                  itemKeyName="talentId"
                  titleText={LabelWithAsterisk(t('editProject.geoPmTalentId'))}
                  placeholder={t('editProject.selectGeoPmTalentId')}
                  loadPractitionerSuggestionsW3
                  warn={!!methods.formState.errors['geoPmNameTalentId'] || !value?.talentId}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('editProject.geoPmTalentId'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
            <Controller
              name="geoPmNameTalentId"
              render={({ field: { value } }) => (
                <TextInput
                  value={value?.name ?? ''}
                  labelText={t('editProject.geoPM')}
                  id="edit-project-geo-pm-name"
                  disabled
                  placeholder={t('editProject.geoPM')}
                />
              )}
            />
            <Controller
              name="pmo"
              control={methods.control}
              rules={{
                required: true,
                validate: (value) => value?.name?.length,
              }}
              render={({ field: { onChange, value } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-edit-project-pmo"
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  selectedItem={value ?? { name: '' }}
                  items={
                    staticDataObj?.pmoStaticData?.map((pmo: { id: number; firstName: string; lastName: string }) => ({
                      name: (pmo?.firstName ?? '') + ' ' + (pmo?.lastName ?? ''),
                    })) || []
                  }
                  itemKeyName="name"
                  titleText={LabelWithAsterisk(t('editProject.pmo'))}
                  placeholder={t('editProject.selectPmo')}
                  warn={!!methods.formState.errors['pmo']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('editProject.pmo'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
            <Controller
              name="country"
              control={methods.control}
              rules={{
                validate: (value) => value !== null && value.label !== '',
              }}
              render={({ field: { onChange, value = '' } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-edit-project-country"
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  selectedItem={value ?? { id: null, label: '', code: '' }}
                  items={staticDataObj?.countryStaticData || []}
                  itemKeyName="label"
                  titleText={LabelWithAsterisk(t('editProject.country'))}
                  placeholder={t('editProject.selectCountry')}
                  warn={!!methods.formState.errors['country']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('editProject.country'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
            <Controller
              name="projectType"
              control={methods.control}
              rules={{
                validate: (value) => value.id !== undefined,
              }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  titleText={LabelWithAsterisk(t('editProject.projectType'))}
                  id="editProjectType"
                  items={staticDataObj?.projectTypeStaticData || []}
                  itemToString={(item) => (item.title ? item.title : t('editProject.select'))}
                  label=""
                  selectedItem={value}
                  warn={!!methods.formState.errors['projectType']}
                  warnText={<ErrorBox>{t('editProject.required')}</ErrorBox>}
                />
              )}
            />
            <Controller
              name="serviceLine"
              control={methods.control}
              rules={{
                validate: (value) => value.id !== undefined,
              }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  titleText={LabelWithAsterisk(t('editProject.serviceLine'))}
                  id="editServiceLine"
                  items={staticDataObj?.serviceLineStaticData || []}
                  itemToString={(item) => (item.title ? item.title : t('editProject.select'))}
                  label=""
                  selectedItem={value}
                  warn={!!methods.formState.errors['serviceLine']}
                  warnText={<ErrorBox>{t('editProject.required')}</ErrorBox>}
                />
              )}
            />
            <Controller
              name="startDate"
              control={methods.control}
              rules={{
                required: true,
                validate: (value) => value.toString() !== DateValidation.NotANumber,
              }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  light
                  onChange={(_dates: Date[], _currentDateString: string) => {
                    setProjectStartDate(_currentDateString);
                    onChange(new Date(_currentDateString).getTime());
                  }}
                  dateFormat={t('format.date_4carbonDatePicker')}
                  datePickerType="single"
                  value={value}
                >
                  <DatePickerInput
                    id="editStartDate"
                    placeholder={t('date.placeholder')}
                    labelText={LabelWithAsterisk(t('editProject.startDate'))}
                    autoComplete="off"
                    warn={!!methods.formState.errors['startDate']}
                    warnText={<ErrorBox>{t('editProject.required')}</ErrorBox>}
                    pattern={t('format.date_4carbonDatePickerInput')}
                  />
                </DatePicker>
              )}
            />
            <Controller
              name="endDate"
              control={methods.control}
              rules={{
                required: true,
                validate: {
                  validateInputDate: (value) => {
                    return !!validateEndDate(value) || t('editProject.requiredEndDate');
                  },
                  validateInput: (value) => {
                    return (
                      (value.toString() !== DateValidation.NotANumber &&
                        methods.getValues('startDate') &&
                        new Date(value).getTime() >= new Date(methods.getValues('startDate')).getTime()) ||
                      t('editProject.required')
                    );
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  light
                  onChange={(_dates: Date[], _currentDateString: string) =>
                    onChange(new Date(_currentDateString).getTime())
                  }
                  dateFormat={t('format.date_4carbonDatePicker')}
                  datePickerType="single"
                  value={endDateInitialValue(value)}
                  disable={[
                    (date: Date) => date < toUTCDate(projectStartDate || new Date()),
                    (date: Date) => (projectLastAssignmentEndDate ? date < projectLastAssignmentEndDate : false),
                  ]}
                >
                  <DatePickerInput
                    id="editEndDate"
                    placeholder={t('date.placeholder')}
                    labelText={LabelWithAsterisk(t('editProject.endDate'))}
                    autoComplete="off"
                    warn={!!methods.formState.errors['endDate']}
                    warnText={<ErrorBox>{errors['endDate']?.message?.toString()}</ErrorBox>}
                    pattern={t('format.date_4carbonDatePickerInput')}
                  />
                </DatePicker>
              )}
            />
            <Controller
              name="projectStatus"
              control={methods.control}
              rules={{
                validate: (value) => {
                  return projectAssignments.length > 0 && value?.id === ProjectStatus.Closed
                    ? t('editProject.projectNotClosed')
                    : true;
                },
                required: { value: true, message: t('editProject.required') },
              }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  onChange={(onChangeData) => {
                    onChange(onChangeData.selectedItem);
                  }}
                  id="editProjectStatus"
                  items={staticDataObj?.projectStatusStaticData || []}
                  itemToString={(item) => (item.title ? item.title : t('editProject.select'))}
                  titleText={LabelWithAsterisk(t('editProject.projectStatus'))}
                  label=""
                  selectedItem={value}
                  warn={!!methods.formState.errors['projectStatus']}
                  warnText={<ErrorBox>{errors['projectStatus']?.message?.toString()}</ErrorBox>}
                />
              )}
            />
          </div>
          <div className="project-detail-page-input-grid-2">
            <Controller
              name="accountIDs"
              control={methods.control}
              rules={{
                required: { value: true, message: t('editProject.required') },
                maxLength: {
                  value: 5000,
                  message: t('maxLengthMessage', { maxLength: 5000 }),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  light
                  onChange={onChange}
                  id="editAccountIDs"
                  labelText={LabelWithAsterisk(t('editProject.accountIds'))}
                  placeholder={t('editProject.accountIdsPlaceholder')}
                  value={value}
                  invalid={!!methods.formState.errors['accountIDs']}
                  invalidText={methods.formState.errors['accountIDs']?.message?.toString()}
                  rows={2}
                />
              )}
            />
            <Controller
              name="comment"
              control={methods.control}
              rules={{
                maxLength: {
                  value: 512,
                  message: t('maxLengthMessage', { maxLength: 512 }),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  light
                  onChange={onChange}
                  id="editComment"
                  labelText={t('editProject.comment')}
                  placeholder=""
                  value={value}
                  invalid={!!methods.formState.errors['comment']}
                  invalidText={methods.formState.errors['comment']?.message?.toString()}
                  rows={2}
                />
              )}
            />
          </div>

          <div className="project-detail-page-button-container">
            <div className="project-details-submit-button-wrap">
              <Button
                kind="secondary"
                onClick={() => {
                  methods.formState.isDirty ? methods.reset(defaultValues) : nav('/projects');
                }}
              >
                {methods.formState.isDirty ? t('editProject.reset') : t('editProject.cancel')}
              </Button>
            </div>
            <div className="project-details-submit-button-wrap">
              <Button
                onClick={methods.handleSubmit((data) => {
                  handleSubmitForm(data);
                })}
                kind="primary"
                disabled={!isDirty || isSubmitting || isSubmitSuccessful}
              >
                {' '}
                {t('editProject.save')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ProjectDetailsComponent;
