import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {config} from './send-email-editor.config';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import React, { useState} from 'react';
import './send-email-editor.scss';

const SendEmailEditor = () => {

  const {t} = useTranslation('common');
  const {formState:{errors}, control, getValues} = useFormContext()
  const [editorValue, setEditorValue] = useState<string>(getValues('html'));


  return (
    <>
      <Controller
        name='html'
        rules={{
          required: {
            value: true,
            message: t(`sendEmailModal.textEditorInput.required`),
          },
        }}
        control={control}
        render={({field: {onChange, value}}) => (
          <CKEditor
            config={config}
            editor={ClassicEditor}
            data={value}
            onChange={(event: any, editor: any) => {
              onChange(editor.getData())
              setEditorValue(editor.getData())
            }}

          />
        )}
      />
      {editorValue === '' && <span className='send-email-modal-error-message'>
        {errors['html']?.message?.toString()}</span>}
    </>
  )

};

export default SendEmailEditor;
