import { ButtonSkeleton, Grid, Row, TextAreaSkeleton } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import './feedback-form-skeleton.scss';

const FeedbackFormSkeleton = () => {
  const { t } = useTranslation('common');
  return (
    <Grid className="feedback__modal-form-skeleton-grid">
      <Row>
        <h4>{t('feedback.boardlite')}</h4>
      </Row>
      <Row className="feedback__modal-form-skeleton-row">
        <TextAreaSkeleton />
        <TextAreaSkeleton />
      </Row>
      <Row className="feedback__modal-form-skeleton-buttons">
        <ButtonSkeleton />
        <ButtonSkeleton />
      </Row>
    </Grid>
  );
};

export default FeedbackFormSkeleton;
