import {TextInput} from 'carbon-components-react';
import {useTranslation} from 'react-i18next';
import {Controller, useFormContext} from 'react-hook-form';
import {SendEmailComponentInterface} from '@cic-boardlite/common';
import './send-email-dialog.scss';

const SendEmailSubject = (props: SendEmailComponentInterface) => {
  const {t} = useTranslation('common');
  const {control, formState:{errors}} = useFormContext()
  return (
    <Controller
      name='subject'
      rules={{
        required: {
          value: true,
          message: t(`sendEmailModal.subjectTextInput.required`),
        },
      }}

      control={control}
      render={({field: {onChange, value}}) => (
        <TextInput
          onChange={onChange}
          labelText={t('sendEmailModal.subjectTextInput.label')}
          placeholder={t('sendEmailModal.subjectTextInput.placeholder')}
          value={value}
          id='subject'
          warn={!!errors['subject']}
          warnText={
            <span className='send-email-modal-error-message'>
          {errors['subject']?.message?.toString()}
            </span>
          }
        />
      )}
    />

  );
};

export default SendEmailSubject;
