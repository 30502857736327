import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import {
  getPractitionerNames,
  getPractitionersEmails,
  getPractitioners,
  deletePractitioner,
  setUpdateSingleFieldType,
  getActionRequiredPractitionersNumber,
} from '../data-access/practitioner/slice';

const usePractitioners = () => {
  const dispatch = useAppDispatch();

  const getBenchAndHiringPractitioners = () => {
    dispatch(getPractitioners());
  };

  const allPractitionersData = useAppSelector((state) => state.practitioners.allPractitionersData);

  const isLoadingPractitioners = useAppSelector((state) => state.practitioners.isLoadingPractitioners);

  const getActionRequiredPractitionersCount = () => {
    dispatch(getActionRequiredPractitionersNumber());
  };

  const actionRequiredPractitionersNumber = useAppSelector(
    (state) => state.practitioners.actionRequiredPractitionersNumber
  );

  const isLoadingActionRequiredPractitionersNumber = useAppSelector(
    (state) => state.practitioners.isLoadingActionRequiredPractitionersNumber
  );

  const practitionerNamesSet = useAppSelector((state) => state.practitioners.isPractitionerNamesSet);

  const getPractitionersNames = () => {
    if (!practitionerNamesSet) dispatch(getPractitionerNames());
  };

  const practitionersNames = useAppSelector((state) => state.practitioners.practitionerNames);

  const isLoadingPractitionersNames = useAppSelector((state) => state.practitioners.isLoadingPractitionerNames);

  const loadPractitionersEmails = () => {
    if (!practitionersEmailSet) dispatch(getPractitionersEmails());
  };

  const practitionersEmailSet = useAppSelector((state) => state.practitioners.isPractitionersEmailsSet);

  const practitionersEmails = useAppSelector((state) => state.practitioners.practitionersEmailsData);

  const pemWmpServiceLineLeadersEmails = useAppSelector(
    (state) => state.practitioners.practitionersPemWmpServiceLineLeadersEmails
  );

  const removePractitioner = (id: string) => {
    dispatch(deletePractitioner(id));
  };

  const isRemovingPractitioner = useAppSelector((state) => state.practitioners.isDeletingPractitioner);

  const toggleSingleFieldComponent = (type: string) => {
    dispatch(setUpdateSingleFieldType(type));
  };

  const currentSingleFieldComponentType = useAppSelector((state) => state.practitioners.updateSingleFieldType);

  return {
    getActionRequiredPractitionersCount,
    actionRequiredPractitionersNumber,
    isLoadingActionRequiredPractitionersNumber,
    isLoadingPractitioners,
    allPractitionersData,
    practitionerNames: practitionersNames,
    isLoadingPractitionerNames: isLoadingPractitionersNames,
    getPractitionersNames,
    loadPractitionersEmails,
    practitionersEmailSet,
    practitionersEmails,
    removePractitioner,
    isRemovingPractitioner,
    getBenchAndHiringPractitioners,
    toggleSingleFieldComponent,
    currentSingleFieldComponentType,
    pemWmpServiceLineLeadersEmails,
  };
};

export default usePractitioners;
