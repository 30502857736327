import './audit-logs-page.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuditLogs, usePractitionerDetails } from '../../custom-hooks';
import { useEffect, useMemo, useState } from 'react';
import AuditLogsList from '../audit-logs-list/audit-logs-list';
import PractitionerPageSidebar from '../practitioner/page-sidebar/page-sidebar';
import ProjectDetailPageSidebar from '../project/project-detail-page/detail-sidebar';
import { AuditEntityType, ProjectReduceType } from '@cic-boardlite/common';
import useProject from '../../custom-hooks/useProject';

const AuditLogsPage = ({ pageViewType }: { pageViewType: AuditEntityType }) => {
  const [isBlocksExpanded, setBlocksExpanded] = useState(false);
  const { t } = useTranslation('common');
  const showPractitionerContent = pageViewType === AuditEntityType.Practitioner;

  const { id } = useParams<{ id: string }>();
  const { practitionerDetails, isLoadingPractitionerDetails, getSelectedPractitionerDetails } =
    usePractitionerDetails();
  const { getProjectData, projectData, isLoadingProjectData } = useProject();
  const { getAuditLogs } = useAuditLogs();

  useEffect(() => {
    if (id) {
      if (!isLoadingPractitionerDetails && pageViewType === AuditEntityType.Practitioner)
        getSelectedPractitionerDetails(id);
      if (!isLoadingProjectData && pageViewType === AuditEntityType.Project) getProjectData(id, ProjectReduceType.log);
    }
  }, []);

  useMemo(() => {
    if (id) {
      getAuditLogs({
        id,
        auditableEntityType: pageViewType,
        page: 1,
        itemsPerPage: 10,
      });
    }
  }, []);

  function getHeader() {
    if (pageViewType === AuditEntityType.Practitioner) {
      return t('audit.header', {
        name: practitionerDetails?.nameFirstLast || '',
        id: practitionerDetails?.serial || '',
      });
    } else {
      return t('audit.header', {
        name: projectData.name || '',
        id: projectData.projectId || '',
      });
    }
  }

  return (
    <div className="d-flex page-wrapper">
      {showPractitionerContent ? <PractitionerPageSidebar /> : <ProjectDetailPageSidebar />}
      <div className="page-profile ">
        <div>
          <h1 className="page-title">{getHeader()}</h1>
          <AuditLogsList pageViewType={pageViewType} expand={isBlocksExpanded} setBlocksExpand={setBlocksExpanded} />
        </div>
      </div>
    </div>
  );
};

export default AuditLogsPage;
