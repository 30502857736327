import { AxiosResponseHeaders } from 'axios';
import { PractitionerUserRoleInterface } from './common';

export enum FormatType {
  pdf = 'pdf',
  doc = 'word',
}

export interface CvServicePractitionersInterface {
  serial: string;
  nameFirstLast: string;
  band?: string;
  jrs?: string;
  availabilityDate?: string;
  costRate?: string;
  psAdder?: string;
}

export interface CvServiceInterface {
  emailFrom: string;
  practitioners: CvServicePractitionersInterface[];
  format: FormatType;
  emailTo: string;
  emailCc?: string[];
}

export interface CvBuilderHttpReturnInterface {
  statusCode: number;
  statusText: string;
  cv?: ArrayBuffer;
  headers?: AxiosResponseHeaders;
}

export interface PractitionerServiceInterface {
  id?: number;
  isCic: boolean;
  firstName: string;
  lastName: string;
  country: number;
  serial?: string;
  band?: number;
  department?: number;
  hiringDate?: Date;
  serviceLine?: number;
  peopleManagerPractitionerId?: number;
  emfStatus?: number;
  contractType?: number;
  jrs?: number;
  availabilityDate?: Date;
  availabilityInfo?: string;
  isPeM?: boolean;
  isPMO?: boolean;
  terminationDate?: Date;
  userRole?: { id: number }[];
  email?: string;
}

export interface CreatePractitionerServiceInterface {
  isCic: boolean;
  firstName: string;
  lastName: string;
  practitionerTag: string;
  country: number;
  serial: string;
  band?: number;
  department?: number;
  hiringDate?: Date;
  serviceLine?: number;
  peopleManagerPractitionerId?: number;
  peopleManagerTitle?: string;
  emfStatus?: number;
  contractType?: number;
  jrs?: number;
  availabilityInfo?: string;
  availabilityDate?: Date;
  assignmentEndDate?: Date;
  isPeM?: boolean;
  isPMO?: boolean;
  terminationDate?: Date;
  sendEmail: boolean;
  ccEmail?: string[];
  countryTitle?: string;
  bandTitle?: string;
  departmentTitle?: string;
  serviceLineTitle?: string;
  emfStatusTitle?: string;
  contractTypeTitle?: string;
  jrsTitle?: string;
  email?: string;
  isServiceLineLeader?: boolean;
}

export interface UpdatePractitionerServiceInterface {
  id: number;
  isCic: boolean;
  firstName: string;
  lastName: string;
  practitionerTag: string;
  country: number;
  serial: string;
  band?: number;
  department?: number;
  hiringDate?: Date;
  serviceLine?: number;
  peopleManagerPractitionerId?: number;
  emfStatus?: number;
  contractType?: number;
  jrs?: number;
  availabilityDate?: Date;
  availabilityInfo?: string;
  isPeM?: boolean;
  isPMO?: boolean;
  terminationDate?: Date;
  userRole?: PractitionerUserRoleInterface[];
  emailTo: string;
  sendEmail: boolean;
  ccEmail?: string[];
  countryTitle?: string;
  bandTitle?: string;
  departmentTitle?: string;
  serviceLineTitle?: string;
  emfStatusTitle?: string;
  contractTypeTitle?: string;
  jrsTitle?: string;
  assignmentEndDate?: Date;
  notes?: string;
}

export interface PractitionerDetailsForm {
  firstName: string;
  lastName: string;
  serial: string;
  availabilityInfo?: string;
  practitionerTag: string;
  serviceLine?: {
    title: string;
    id: number;
  };
  emfStatus?: {
    title: string;
    id: number;
  };
  contractType?: {
    title: string;
    id?: number;
  };
  band?: {
    title: string;
    id: number;
  };
  country: {
    label: string;
    id: number;
    code: string;
  };
  peopleManager?: {
    name: string;
    id: number;
  };
  department?: {
    title: string;
    id: number;
  };
  jrs?: {
    title: string;
    id: number;
  };
  ccEmail: string[];
  sendEmailCheckbox: boolean;
  isPeM?: boolean;
  isPMO?: boolean;
  hiringDate: Date;
  terminationDate?: Date | undefined;
  assignmentEndDate?: Date;
  availabilityDate?: Date | undefined;
  availabilityDateManuallyEdited?: boolean;
  userRole?: { id: number; title: string }[];
  psAdder: string;
  serviceArea: string;
  rate: string;
  email: string;
  isServiceLineLeader?: boolean;
}

export interface HookPractitionerObject {
  id?: number;
  isCic?: boolean;
  firstName?: string;
  lastName?: string;
  country?: number;
  serial?: string;
  band?: number | null;
  department?: number | null;
  serviceLine?: number | null;
  peopleManagerPractitionerId?: number | null;
  peopleManagerTitle?: string;
  emfStatus?: number | null;
  contractType?: number | null;
  jrs?: number | null;
  hiringDate?: Date | null;
  terminationDate?: Date | null;
  availabilityDate?: Date | null;
  availabilityInfo?: string;
  isPeM?: boolean;
  isPMO?: boolean;
  userRole?: PractitionerUserRoleInterface[];
  sendEmail?: boolean;
  emailTo?: string;
  ccEmail?: string[];
  countryTitle?: string;
  bandTitle?: string;
  departmentTitle?: string;
  serviceLineTitle?: string;
  emfStatusTitle?: string;
  contractTypeTitle?: string;
  jrsTitle?: string;
  assignmentEndDate?: Date | null;
  notes?: string;
  email?: string;
  availabilityDateManuallyEdited?: boolean;
  isServiceLineLeader?: boolean;
}

export interface FormInput {
  id: string;
  name: 'firstName' | 'lastName' | 'serial' | 'availabilityInfo';
  label: string;
  placeholder: string;
  sharedField?: boolean;
}

export interface PractitionerSortFields {
  band: string;
  actionRequiredStatus: string;
  availabilityDate: string;
  serviceLine: string;
  nameFirstLast?: string;
  jrs?: string;
}

export enum SingleFieldUpdateType {
  Notes = 'notes',
  AvailabilityInfo = 'availabilityInfo',
  AvailabilityDate = 'availabilityDate',
}

export interface PractitionerAvailabilityCalculateResponseInterface {
  availabilityUpdateNeeded: boolean;
  practitionerId?: number;
  availabilityDate?: string;
  newAvailabilityDate?: string;
  message?: string;
  oldAvailabilityDateManuallyEdited?: boolean;
}

export interface PractitionerEmailNameData {
  nameFirstLast: string;
  email: string;
}

export interface ServiceLineLeaderEmailData {
  serviceLineId: number;
  serviceLineTitle: string;
  serviceLineLeaderEmail: string;
}

export interface PractitionersEmailsResponseInterface {
  practitionerEmails: string[];
  wmpEmails: string[];
  pemEmails: PractitionerEmailNameData[];
  pmoEmails: PractitionerEmailNameData[];
  serviceLineLeadersEmails: ServiceLineLeaderEmailData[];
}
