/* eslint-disable no-prototype-builtins */
import { AuditActionType, AuditEntityType, convertDate } from '@cic-boardlite/common';
import './audit-log-block.scss';
import { useState, useEffect } from 'react';
import {
  Add,
  ChevronDown,
  ChevronUp,
  Close,
  DocumentAdd,
  DocumentBlank,
  DocumentSubtract,
  Edit,
  Email,
  Erase,
  NewTab,
  Renew,
  SendAlt,
  Subtract,
  Unknown,
} from '@carbon/icons-react/next';
import { TFunction, useTranslation } from 'react-i18next';
import { TooltipDefinition } from 'carbon-components-react';
import { AuditFieldUpdateInfo, LogSummaryInterface } from '../../interfaces/audit-interfaces';

function getActionIcon(logActionType: AuditActionType, logEntityType: AuditEntityType) {
  switch (logActionType) {
    case AuditActionType.Insert:
      switch (logEntityType) {
        case AuditEntityType.Practitioner:
        case AuditEntityType.Project:
          return <NewTab />;
        case AuditEntityType.Assignment:
          return <DocumentAdd />;
        default:
          return <Add />;
      }

    case AuditActionType.Update:
      switch (logEntityType) {
        case AuditEntityType.Practitioner:
        case AuditEntityType.Project:
          return <Edit />;
        case AuditEntityType.Assignment:
          return <DocumentBlank />;
        default:
          return <Renew />;
      }

    case AuditActionType.Delete:
      switch (logEntityType) {
        case AuditEntityType.Practitioner:
        case AuditEntityType.Project:
          return <Erase />;
        case AuditEntityType.Assignment:
          return <DocumentSubtract />;
        default:
          return <Close />;
      }

    case AuditActionType.Email:
      return <Email />;

    default:
      return <Unknown />;
  }
}

function getActionTitle(log: LogSummaryInterface, pageViewType: AuditEntityType, t: TFunction) {
  const pageViewTypeName = AuditEntityType[pageViewType];

  switch (log.actionType) {
    case AuditActionType.Insert:
      switch (log.entityType) {
        case AuditEntityType.Assignment:
          return t(`audit.title.addAssignment_${pageViewTypeName}View`, {
            name: pageViewType === AuditEntityType.Practitioner ? log.project?.projectName : log.practitioner?.fullName,
          });
        case AuditEntityType.Practitioner:
          return t('audit.title.addPractitioner', {
            name: log.practitioner?.fullName,
          });
        case AuditEntityType.Project:
          return t('audit.title.addProject', {
            name: log.project?.projectName,
          });
        default:
          return t('audit.title.unknownDataAdded');
      }

    case AuditActionType.Update:
      switch (log.entityType) {
        case AuditEntityType.Assignment:
          return t(`audit.title.updateAssignment_${pageViewTypeName}View`, {
            name: pageViewType === AuditEntityType.Practitioner ? log.project?.projectName : log.practitioner?.fullName,
          });
        case AuditEntityType.Practitioner:
        case AuditEntityType.Project:
        default:
          return t('audit.title.update');
      }

    case AuditActionType.Delete:
      switch (log.entityType) {
        case AuditEntityType.Assignment:
          return t(`audit.title.removeAssignment_${pageViewTypeName}View`, {
            name: pageViewType === AuditEntityType.Practitioner ? log.project?.projectName : log.practitioner?.fullName,
          });
        case AuditEntityType.Practitioner:
          return t('audit.title.removePractitioner', {
            name: log.practitioner?.fullName,
          });
        case AuditEntityType.Project:
          return t('audit.title.removeProject', {
            name: log.project?.projectName,
          });
        default:
          return t('audit.title.unknownDataRemoved');
      }

    case AuditActionType.Email:
      return t('audit.title.sentEmail', {
        emailType: log.email?.type,
        emailTo: log.email?.address,
      });

    default:
      return t('audit.title.unknownAction');
  }
}

function getFieldLabel(fieldName: string, t: TFunction) {
  const labelKey = 'audit.fields.' + fieldName;
  const labelValue = t(labelKey);
  return labelKey !== labelValue ? labelValue : fieldName;
}

function isObjectEmpty(dataObject: any) {
  return (
    dataObject === null ||
    dataObject === undefined ||
    (typeof dataObject === 'object' && !Object.keys(dataObject).length) ||
    (typeof dataObject === 'string' && !dataObject.length)
  );
}

function buildFieldUpdateLogs(
  oldValues: object,
  newValues: object,
  actionType: AuditActionType
): AuditFieldUpdateInfo[] {
  const logMessages: AuditFieldUpdateInfo[] = [];

  for (const key in newValues) {
    if (newValues.hasOwnProperty(key)) {
      const oldValue = oldValues[key as keyof object];
      const newValue = newValues[key as keyof object];

      if (!isObjectEmpty(newValue) && !isObjectEmpty(oldValue)) {
        const valueUpdateLog = {
          field: key,
          action: AuditActionType.Update,
          oldValue: oldValue,
          newValue: newValue,
        } as AuditFieldUpdateInfo;

        logMessages.push(valueUpdateLog);
      } else if (!isObjectEmpty(oldValue) && isObjectEmpty(newValue)) {
        const valueRemoveLog = {
          field: key,
          action: AuditActionType.Delete,
          oldValue: oldValue,
        } as AuditFieldUpdateInfo;

        logMessages.push(valueRemoveLog);
      } else if (isObjectEmpty(oldValue) && !isObjectEmpty(newValue)) {
        let valueInsertLog: AuditFieldUpdateInfo = {
          field: key,
          action: AuditActionType.Insert,
          newValue: newValue,
        };

        if (actionType === AuditActionType.Email) {
          valueInsertLog = {
            field: key,
            action: actionType,
            newValue: newValue,
          };
        }

        logMessages.push(valueInsertLog);
      }
    }
  }

  for (const key in oldValues) {
    if (!newValues.hasOwnProperty(key as keyof object)) {
      const oldValue = oldValues[key as keyof object];

      const valueRemoveLog = {
        field: key,
        action: AuditActionType.Delete,
        oldValue: oldValue,
      } as AuditFieldUpdateInfo;

      logMessages.push(valueRemoveLog);
    }
  }
  return logMessages;
}

const AuditLogBlock = ({
  logData,
  pageViewType,
  expand,
}: {
  logData: LogSummaryInterface;
  pageViewType: AuditEntityType;
  expand: boolean;
}) => {
  const { t } = useTranslation('common');

  const [isExpanded, setExpanded] = useState(expand || false);

  useEffect(() => {
    setExpanded(expand);
  }, [expand, logData]);

  const changeList = buildFieldUpdateLogs(logData.beforeReadable, logData.afterReadable, logData.actionType).sort(
    (changeA, changeB) => {
      const actionA = changeA.action;
      const actionB = changeB.action;

      if (actionA !== actionB) {
        return actionA === AuditActionType.Insert ||
          (actionA === AuditActionType.Update && actionB === AuditActionType.Delete)
          ? -1
          : 1;
      } else {
        const fieldA = changeA.field;
        const fieldB = changeB.field;

        return fieldA.localeCompare(fieldB, undefined, { numeric: true });
      }
    }
  );

  const getSingleChangeValue = (value?: any): string => {
    if (value === true || value === false) {
      return t(`misc.${value}`);
    } else {
      return value === undefined ? '' : value;
    }
  };

  return (
    <div className="audit_log_block">
      <div className="audit_log_header" onClick={() => setExpanded(!isExpanded)}>
        <div className="audit_log_expand">
          <div>{isExpanded ? <ChevronUp /> : <ChevronDown />}</div>
        </div>
        <div className="audit_log_icon">
          <div>{getActionIcon(logData.actionType, logData.entityType)}</div>
        </div>
        <div className="audit_log_title">
          <span>{getActionTitle(logData, pageViewType, t)}</span>
          <span>
            {logData?.modifiedBySystem ? t('audit.modifiedBySystem') : logData.modifiedBy?.fullName} /{' '}
            {convertDate(logData.modifiedOn, true)}
          </span>
        </div>
      </div>
      {isExpanded ? (
        <div className="audit_log_content audit_log_content_box_shadow">
          <div></div>
          <div className="audit_change_header">{t('audit.changeTbl.field')}</div>
          <div
            className={`audit_change_header ${
              !Object.keys(logData.beforeReadable).length ? 'audit-header-disabled' : ''
            }`}
          >
            {t('audit.changeTbl.oldValue')}
          </div>
          <div
            className={`audit_change_header ${
              !Object.keys(logData.afterReadable).length ? 'audit-header-disabled' : ''
            }`}
          >
            {t('audit.changeTbl.newValue')}
          </div>
          {changeList.map((singleChange, index) => (
            <div key={'change_' + index} className="audit_log_values">
              <div>
                <TooltipDefinition
                  className="audit-change-entry-tooltip"
                  direction="top"
                  tooltipText={t(
                    `audit.changeTbl.tooltip_${
                      Object.keys(AuditActionType)[Object.values(AuditActionType).indexOf(singleChange.action)]
                    }`
                  )}
                >
                  {singleChange.action === AuditActionType.Insert ? (
                    <Add />
                  ) : singleChange.action === AuditActionType.Delete ? (
                    <Subtract />
                  ) : singleChange.action === AuditActionType.Email ? (
                    <SendAlt />
                  ) : (
                    <Renew />
                  )}
                </TooltipDefinition>
              </div>
              <div className="log-value-cell" key={'field_' + index}>
                {getFieldLabel(singleChange.field, t)}
              </div>
              <div className="log-value-cell" key={'oldValue_' + index}>
                {getSingleChangeValue(singleChange.oldValue)}
              </div>
              <div className="log-value-cell" key={'newValue_' + index}>
                {getSingleChangeValue(singleChange.newValue)}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default AuditLogBlock;
