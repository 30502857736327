import { Modal } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import SendEmailComp from './send-email-comp';
import { useForm, FormProvider } from 'react-hook-form';
import { SendEmailContentFormInterface, formatCcEmailsArray, getBccEmail } from '@cic-boardlite/common';
import { usePractitionerDetails, usePractitionerAssignments, useUser, useCommonState } from '../../custom-hooks';
import { useParams } from 'react-router-dom';
import { EmailTemplateTypes } from './send-email-dropdown/template-types.config';
import { useEffect, useState } from 'react';
import { initialState } from '../../data-access/common-states/slice';
import './send-email-dialog.scss';

function SendEmailDialog({ projectsPage }: { projectsPage: boolean }) {
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState<boolean>(false);
  const [isBccChecked, setIsBccChecked] = useState<boolean>(true);
  const { sendEmailModalType, toggleSendEmailModal } = useCommonState();
  const { t } = useTranslation('common');

  const { practitionerDetails } = usePractitionerDetails();
  const { sendSelectedPractitionerAssignmentEmail } = usePractitionerAssignments();
  const { userInfo } = useUser();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setOpenSendEmailDialog(sendEmailModalType.isOpen);
  }, [sendEmailModalType]);

  const defaultValues = {
    assignmentId: '',
    subject: '',
    html: '',
    contentType: '',
    initialEmails: [],
    ccEmail: [],
  };

  const methods = useForm<SendEmailContentFormInterface>({
    defaultValues: defaultValues,
  });

  const handleCloseModal = () => {
    methods.reset(defaultValues);
    toggleSendEmailModal(initialState.sendEmailModalType);
  };

  const handleBccChecked = () => {
    setIsBccChecked((prevState) => !prevState);
  };

  const getLastEmailSentDate = (emailType: string) => {
    switch (emailType) {
      case EmailTemplateTypes.onboarding.split('.').pop() + 'EmailSent':
        return sendEmailModalType.assignmentDetails?.onboardingEmailSent;
      case EmailTemplateTypes.offboarding.split('.').pop() + 'EmailSent':
        return sendEmailModalType.assignmentDetails?.offboardingEmailSent;
      case EmailTemplateTypes.assignmentDetails.split('.').pop() + 'EmailSent':
        return sendEmailModalType.assignmentDetails?.assignmentDetailsEmailSent;
      case EmailTemplateTypes.assignmentExtension.split('.').pop() + 'EmailSent':
        return sendEmailModalType.assignmentDetails?.assignmentExtensionEmailSent;
      case EmailTemplateTypes.dataRemovalCert.split('.').pop() + 'EmailSent':
        return sendEmailModalType.assignmentDetails?.dataRemovalEmailSent;
      case EmailTemplateTypes.requestExtension.split('.').pop() + 'EmailSent':
        return sendEmailModalType.assignmentDetails?.extensionRequestEmailSent;
      default:
        return String(new Date());
    }
  };

  const onSubmit = (data: SendEmailContentFormInterface) => {
    const emailType = data.contentType.split('.').pop() + 'EmailSent';

    if (sendEmailModalType?.assignmentDetails?.projectIds)
      sendSelectedPractitionerAssignmentEmail({
        practitionerId: projectsPage ? Number(sendEmailModalType?.assignmentDetails?.practitionerId) : Number(id),
        assignmentId: sendEmailModalType?.assignmentDetails?.id,
        contentType: emailType,
        subject: data.subject,
        html: data.html,
        emailCc: formatCcEmailsArray(methods.getValues('ccEmail')).slice(1),
        emailFrom: userInfo.emailAddress,
        emailTo: formatCcEmailsArray(methods.getValues('ccEmail'))[0],
        emailBcc: getBccEmail({
          isBccChecked: isBccChecked,
          userEmail: userInfo.emailAddress,
          emails: formatCcEmailsArray(methods.getValues('ccEmail')),
        }),
        lastEmailSent: getLastEmailSentDate(emailType),
        projectsPage: projectsPage,
        projectId: sendEmailModalType.assignmentDetails.projectIds,
      });

    methods.reset(defaultValues);
    handleCloseModal();
  };

  return sendEmailModalType.assignmentDetails ? (
    <Modal
      className="send-email-modal"
      size="lg"
      primaryButtonText={t('sendEmailModal.sendButtonText')}
      secondaryButtonText={t('sendEmailModal.cancelButtonText')}
      onRequestSubmit={methods.handleSubmit(onSubmit)}
      open={openSendEmailDialog}
      onRequestClose={handleCloseModal}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SendEmailComp
            practitionerDetails={practitionerDetails}
            assignmentDetails={sendEmailModalType.assignmentDetails}
            defaultValues={defaultValues}
            modalIsOpen={openSendEmailDialog}
            onBccChecked={handleBccChecked}
            isBccChecked={isBccChecked}
          />
        </form>
      </FormProvider>
    </Modal>
  ) : (
    <></>
  );
}

export default SendEmailDialog;
