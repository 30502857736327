export interface ImportExportDataInterface {
  title?: string;
  serviceArea?: string;
  band?: string;
  countryCode?: string;
  psAdder?: string;
  rate?: string;
}
export interface ImportDataPayloadInterface {
  fileType: string;
  fileObjects: ImportExportDataInterface[];
}
export interface PractitionerExportSummary {
  nameFirstLast: string;
  serial: string;
  isCic: string;
  availabilityDate: string;
  availabilityInfo: string;
  actionRequired: string;
  assignmentEndDate: string;
  terminated: string;
  band: string;
  costRate: string;
  jrs: string;
  psAdder: string;
  department: string;
  isPeM: string;
  isPMO: string;
  country: string;
  emfStatus: string;
  hiringDate: string;
  terminationDate: string;
  contractType: string;
  serviceLine: string;
  peopleManager: string;
  notesId: string;
  intranetId: string;
  serviceArea: string;
}

export interface PractitionerAssignmentExportSummary
  extends PractitionerAssignmentExportPractitionerInfo,
    PractitionerAssignmentExportAssignmentInfo {}

export interface PractitionerAssignmentExportPractitionerInfo {
  nameFirstLast: string;
  availabilityDate: string;
  availabilityInfo: string;
  actionRequired: string;
  assignmentEndDate: string;
  terminated: string;
  band: string;
  costRate: string;
  jrs: string;
  psAdder: string;
  department: string;
  isPMO: string;
  isPeM: string;
  country: string;
  emfStatus: string;
  hiringDate: string;
  terminationDate: string;
  contractType: string;
  serviceLine: string;
  peopleManager: string;
  serial: string;
  serviceArea: string;
}

export interface PractitionerAssignmentExportAssignmentInfo {
  projectId: string;
  projectName: string;
  clientName: string;
  accountIds: string;
  pmo: string;
  startDate: string;
  endDate: string;
  allocation: string;
  mainAssignment: string;
  pmpSeat: string;
  status: string;
}

export interface ProjectExportSummary {
  projectId?: string;
  projectName?: string;
  projectTag?: string;
  clientName?: string;
  country?: string;
  clientTag?: string;
  serviceLine?: string;
  projectType?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  accountIds?: string;
  comment?: string;
  pmo?: string;
  geoPm?: string;
}

export interface ProjectAssignmentExportSummary
  extends ProjectAssignmentExportProjectInfo,
    ProjectAssignmentExportAssignmentInfo {}
export interface ProjectAssignmentExportProjectInfo {
  projectId: string;
  projectName: string;
  clientName: string;
  accountIds: string;
  country: string;
  projectType: string;
  status: string;
  startDate: string;
  endDate: string;
  projectActionRequiredStatus: string;
  serviceLine: string;
  comment: string;
  relatedPractitioners: string;
  created?: string;
  createdBy?: string;
  pmo: string;
  geoPm: string;
}

export interface ProjectAssignmentExportAssignmentInfo {
  assignmentStartDate: string;
  assignmentEndDate: string;
  allocation: string;
  pmpSeat: string;
  isMainAssignment: string;
  serial: string;
  firstName: string;
  lastName: string;
  notificationEmail: string;
  uwh: string;
  assignmentStatus: string;
}
export interface ExportDataResponseInterface {
  json: (
    | ImportDataPayloadInterface
    | PractitionerExportSummary
    | PractitionerAssignmentExportSummary
    | ProjectExportSummary
    | ProjectAssignmentExportSummary
  )[];
  excelFileName: string;
  headers: string[];
}

export interface ImportExportFileStateInterface {
  isLoadingExportFile: boolean;
  isLoadingImportFile: boolean;
  isLoadingExportPractitionersSummary: boolean;
  isLoadingExportProjectsSummary: boolean;
  isLoadingExportPractitionersAssignmentsSummary: boolean;
  isLoadingExportProjectsAssignmentsSummary: boolean;
}

export const practitionerHeaders = [
  'Name Surname',
  'TalentID',
  'Is CIC',
  'Availability Date',
  'Availability Info',
  'Action Required',
  'Assignment End Date',
  'Terminated',
  'Band',
  'Cost rate',
  'PS Adder',
  'JRS',
  'Department',
  'Is PeM',
  'Is PMO',
  'Country',
  'EMF status',
  'Hiring Date',
  'Termination Date',
  'Contract Type',
  'Service Line',
  'People Manager',
  'Notes ID',
  'Intranet ID',
  'Service Area',
];

export const practitionerAssignmentsHeaders = [
  'Name Surname',
  'Availability Date',
  'Availability Info',
  'Action Required',
  'Assignment End Date',
  'Terminated',
  'Band',
  'Cost Rate',
  'PS Adder',
  'JRS',
  'Department',
  'Is PeM',
  'Is PMO',
  'Country',
  'Emf Status',
  'Hiring Date',
  'Termination Date',
  'Contract Type',
  'Service Line',
  'People Manager',
  'TalentID',
  'Service Area',
  'Project Id',
  'Project Name',
  'Client Name',
  'Start Date',
  'End Date',
  'Allocation',
  'Account IDs',
  'Status',
  'PMO',
  'Is Main Assignment',
  'Pmp Seat',
];

export const projectHeaders = [
  'Project ID',
  'Project Name',
  'Project Tag',
  'Client Name',
  'Contract Country',
  'Client Tag',
  'Service Line',
  'Project Type',
  'Start Date',
  'End Date',
  'Status',
  'Project Accounts IDs',
  'Comment',
  'PMO',
  'Geo PM',
];

export const projectAssignmentHeaders = [
  'Project Id',
  'Project Name',
  'Project Client Name',
  'Project Account IDs',
  'Project Contract Country',
  'Project Type',
  'Project Status',
  'Project Start Date',
  'Project End Date',
  'Project Action Required Status',
  'Project Service Line',
  'Project Comment',
  'Project Related Practitioners',
  'Project Created',
  'Project Created By',
  'Project PMO',
  'Project Geo PM',
  'Start Date',
  'End Date',
  'Allocation',
  'Pmp Seat',
  'Is Main Assignment',
  'Practitioner TalentID',
  'Practitioner First Name',
  'Practitioner Last Name',
  'Notification Emails',
  'UWH',
  'Assignment Status',
];

export const practitionerFileName = 'practitioners';
export const practitionerAssignmentFileName = 'practitioner-assignments';
export const projectFileName = 'projects';
export const projectAssignmentFileName = 'project-assignments';
