import { AttachmentsInterface, FeedbackAttachmentsInterface } from './common';

export enum EmailTemplate {
  CV = 'cv-mail-template',
  PROJECT_CREATE = 'project-create-mail-template',
  PROJECT_UPDATE = 'project-update-mail-template',
  PRACTITIONER_CREATE = 'practitioner-create-template',
  PRACTITIONER_UPDATE = 'practitioner-update-template',
  ASSIGNMENT = 'assignment-mail-template',
  SIMPLE = 'simple-mail-template',
}

interface ProjectEmailNotificationBodyInterface {
  projectId?: string;
  pmo: string;
  geoPm: string;
  accountIds: string;
  projectName: string;
  startDate: string;
  endDate: string;
  status: string;
  contractCountry: string;
  serviceLine: string;
  clientName: string;
  clientTag: string;
  comment: string;
}

interface PractitionerEmailBodyInterface {
  practitionerName: string;
  serial: string;
  jrs: string;
  band: string;
  department: string;
  isPeM: string;
  isPMO: string;
  country: string;
  emfStatus: string;
  hiringDate: string;
  terminationDate: string;
  contractType: string;
  serviceLine: string;
  isCic: string;
}

interface AssignmentEmailBodyInterface {
  practitionerName: string;
  practitionerTalentId: string;
  projectName: string;
  projectId: string;
  clientName: string;
  accountIds: string;
  startDate: string;
  endDate: string;
  uwhStartDate: string;
  uwhEndDate: string;
  pmpSeat: string;
  allocation: string;
  status: string;
  mainAssignment: string;
}

export interface BaseEmailContent {
  emailFrom: string;
  emailTo: string;
  emailCc?: string[];
  customTemplate?: string;
  htmlEmailBody?:
    | string
    | ProjectEmailNotificationBodyInterface
    | PractitionerEmailBodyInterface
    | AssignmentEmailBodyInterface;

}

export interface EmailContent extends BaseEmailContent {
  attachments?: AttachmentsInterface[];
}

export interface SendFeedbackEmailContent extends BaseEmailContent{
  attachments?: FeedbackAttachmentsInterface[];
}

export interface EmailContentResponse {
  emailContent: EmailContent;
  subject: string;
  emailTemplate: EmailTemplate;
  id: number;
}

export interface EmailContentFeedbackResponse {
  emailContent: SendFeedbackEmailContent;
  emailTemplate: EmailTemplate;
  subject: string;
}

export interface CreateUpdateAssignmentResponse {
  assignmentId: number;
  emailContent?: EmailContent;
  subject?: string;
  emailTemplate?: EmailTemplate;
}
