import { Fragment, useEffect, useState } from 'react';
import {
  DataTable,
  DataTableSkeleton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TooltipDefinition,
} from 'carbon-components-react';
import {
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  TableContainer,
  TableSelectAll,
  TableSelectRow,
  SortRowData,
} from 'carbon-components-react/lib/components/DataTable';
import { headers } from './headers-data';
import {
  ProjectAssignmentSummaryInterface,
  ProjectAssignmentDataTableInterface,
  DownloadDataPages,
  checkIfAssignmentIsMain,
  sortData,
  filterAssignmentsWithToolbarSearchValue,
} from '@cic-boardlite/common';
import './assignment-table-expandable.scss';
import { useTranslation } from 'react-i18next';
import { useProjectAssignments, usePractitionerDetails } from '../../../custom-hooks';
import ProjectAssignmentsTableExpandedRow from '../project-assignment-table-expanded-row/assignment-table-expanded-row';
import MultipleExtensionRequestModal from './multiple-assignment-extension-request-modal';
import { useNavigate } from 'react-router-dom';
import DataDownloadToolbar from '../../data-download-toolbar/data-download-toolbar';
import { StarFilled16 } from '@carbon/icons-react';
import AssignmentEndDate from '../../../ui/assignments-end-date/assignment-end-date';

const ProjectAssignmentTableExpandable = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const [isRowExpanded, setIsRowExpanded] = useState([false]);
  const [filteredAssignmentRows, setAssignmentRows] = useState<ProjectAssignmentSummaryInterface[]>([]);
  const [assignment, setAssignment] = useState<ProjectAssignmentSummaryInterface>();
  const [assignmentEndDateToolTip, setAssignmentEndDateToolTip] = useState<string | null>(null);
  const { getSelectedPractitionerDetails } = usePractitionerDetails();
  const {
    isLoadingProjectAssignments,
    projectAssignments,
    addAssignmentToExtensionArray,
    removeAssignmentFromExtensionArray,
    assignmentExtensionArray,
    removeAllAssignmentsFromExtensionArray,
    addAllAssignmentsToExtensionArray,
    projectAssignmentsFilterString,
  } = useProjectAssignments();

  useEffect(() => {
    const projectAssignmentsList = projectAssignmentsFilterString?.length
      ? filterAssignmentsWithToolbarSearchValue(projectAssignments, projectAssignmentsFilterString)
      : projectAssignments;

    const convertedAssignmentRows = projectAssignmentsList.map((assignment) => {
      return {
        id: assignment.id?.toString(),
        practitionerId: assignment?.practitioner?.id || undefined,
        serial: assignment?.practitioner?.serial || '',
        firstName: assignment?.practitioner?.firstName || '',
        lastName: assignment?.practitioner?.lastName || '',
        band: assignment?.practitioner?.band?.title || '',
        pmpSeat: assignment.pmpSeat,
        uwhStart: assignment.uwhStartDate?.toString(),
        uwhEnd: assignment.uwhEndDate?.toString(),
        allocation: assignment.allocation,
        startDate: assignment.startDate?.toString(),
        endDate: assignment.endDate?.toString(),
        serviceLine: assignment?.practitioner?.serviceLine?.title || '',
        assignmentStatus: assignment.status.title,
        assignmentExtensionEmailSent: assignment.assignmentExtensionEmailSent,
        isMainAssignment: assignment.mainAssignment,
        accountIds: assignment.project.accountIds?.toString(),
        uwh: assignment.uwh,
        country: assignment.project?.country?.toString(),
        projectIds: assignment.project.id,
        projectId: assignment.project.projectId?.toString(),
        projectName: assignment.project.name,
        clientName: assignment.project.clientName,
        assignmentStatusId: assignment.status.id,
        geoPm: assignment.project.geoPm,
        geoPmTalentId: assignment.project.geoPmTalentId,
        projectAccountIds: assignment?.project?.accountIds,
        onboardingEmailSent: assignment.onboardingEmailSent,
        offboardingEmailSent: assignment.offboardingEmailSent,
        assignmentDetailsEmailSent: assignment.assignmentDetailsEmailSent,
        extensionRequestEmailSent: assignment.extensionRequestEmailSent,
        dataRemovalEmailSent: assignment.dataRemovalEmailSent,
        cells: [],
      };
    });

    convertedAssignmentRows.sort((cellA, cellB) => {
      if (cellA.assignmentStatus < cellB.assignmentStatus) {
        return 1;
      } else return -1;
    });
    setIsRowExpanded([]);
    setAssignmentRows(convertedAssignmentRows);
  }, [projectAssignmentsFilterString]);

  useEffect(() => {
    removeAllAssignmentsFromExtensionArray();
  }, []);

  const getAssignmentByRowId = (id: string) => {
    const filteredAssignment = filteredAssignmentRows.find((object) => object.id === id);
    setAssignment(filteredAssignment);
    if (filteredAssignment && filteredAssignment.practitionerId) {
      getSelectedPractitionerDetails(filteredAssignment?.practitionerId.toString());
    }
  };

  const handleRowExpansion = (
    rows: ProjectAssignmentSummaryInterface[],
    expandedRowId: string,
    nodeName?: string,
    index?: number
  ) => {
    setAssignment(undefined);
    getAssignmentByRowId(expandedRowId);
    if (index === undefined && nodeName !== 'svg' && nodeName !== 'path' && nodeName !== 'BUTTON') return;

    const whatRowsExpanded = [...isRowExpanded];
    for (let i = 0; i < rows.length; i++) {
      whatRowsExpanded[i] = rows[i].id === expandedRowId && !isRowExpanded[i];
    }
    setIsRowExpanded(whatRowsExpanded);
  };

  const formatAssignmentExtensionObject = (row: ProjectAssignmentSummaryInterface) => {
    const foundAssignment = projectAssignments.find((assignment) => String(assignment.id) === row.id);

    return (
      foundAssignment && {
        serial: foundAssignment.practitioner.serial,
        id: row.id,
        firstLastName: foundAssignment.practitioner.firstName + ' ' + foundAssignment.practitioner.lastName,
        endDate: foundAssignment.endDate,
        allocation: foundAssignment.allocation,
        assignmentExtensionEmailSent: foundAssignment.assignmentExtensionEmailSent
          ? new Date(foundAssignment.assignmentExtensionEmailSent)
          : null,
        extensionRequestEmailSent: foundAssignment.assignmentExtensionEmailSent
          ? new Date(foundAssignment.extensionRequestEmailSent)
          : null,
        isMainAssignment: foundAssignment.mainAssignment,
      }
    );
  };

  const selectAssignmentForExtension = (row: ProjectAssignmentSummaryInterface) => {
    setAssignment(assignment);
    const alreadyExists = assignmentExtensionArray.some((assignment) => assignment.id === row.id);
    if (alreadyExists) {
      removeAssignmentFromExtensionArray(row.id);
    } else {
      const formattedAssignment = formatAssignmentExtensionObject(row);
      formattedAssignment && addAssignmentToExtensionArray(formattedAssignment);
    }
  };

  const selectOrRemoveAllAssignmentsForExtension = (rows: ProjectAssignmentSummaryInterface[]) => {
    if (assignmentExtensionArray.length !== filteredAssignmentRows.length && !!filteredAssignmentRows.length) {
      const assignmentsArray = [];
      for (const row of rows) {
        const formattedAssignment = formatAssignmentExtensionObject(row);
        formattedAssignment && assignmentsArray.push(formattedAssignment);
      }

      addAllAssignmentsToExtensionArray(assignmentsArray);
    } else removeAllAssignmentsFromExtensionArray();
  };

  const isNotSpecificHeader = (header: string) => {
    const headerArr = ['serial', 'endDate', 'assignmentStatus'];

    if (headerArr.includes(header)) {
      return false;
    }

    return true;
  };

  const isRowSelectable = (row: ProjectAssignmentSummaryInterface) => {
    const practitionerId = row.cells.find((col) => {
      return col.info.header === 'practitionerId';
    })?.value;
    return !practitionerId;
  };

  return isLoadingProjectAssignments ? (
    <DataTableSkeleton
      columnCount={headers.length + 2}
      headers={headers}
      rowCount={filteredAssignmentRows.length}
      showHeader={false}
      showToolbar={false}
    />
  ) : (
    <>
      <MultipleExtensionRequestModal />
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <DataTable
          isSortable
          headers={headers}
          size="md"
          rows={filteredAssignmentRows}
          sortRow={(cellA: any, cellB: any, data: SortRowData) => {
            return sortData(cellA, cellB, data.key, data.sortDirection, data.locale);
          }}
        >
          {({
            rows,
            headers,
            getTableProps,
            getHeaderProps,
            getSelectionProps,
            getRowProps,
          }: ProjectAssignmentDataTableInterface) => {
            return (
              <TableContainer>
                <DataDownloadToolbar page={DownloadDataPages.ProjectAssignments} />
                {filteredAssignmentRows.length ? (
                  <Table {...getTableProps()}>
                    <TableHead className="projectAssignmentTableHeaders">
                      <TableRow>
                        <TableExpandHeader />
                        <TableSelectAll
                          {...getSelectionProps()}
                          onSelect={(e) => {
                            selectOrRemoveAllAssignmentsForExtension(rows);
                            getSelectionProps().onSelect(e);
                          }}
                          indeterminate={
                            assignmentExtensionArray.length !== filteredAssignmentRows.length &&
                            !!assignmentExtensionArray.length
                          }
                          checked={
                            assignmentExtensionArray.length === filteredAssignmentRows.length &&
                            !!filteredAssignmentRows.length
                          }
                        />
                        {headers.map(
                          (header, index) =>
                            header.key !== 'practitionerId' && (
                              <TableHeader
                                key={index}
                                {...getHeaderProps({ header })}
                                onClickCapture={() => setIsRowExpanded([])}
                                className={`project-assignments-padding-left
                              ${header.key === 'serial' && 'project-assignments-serial-padding-left'}
                              ${header.key === 'endDate' && 'project-assignments-endDate-padding-left'}
                            `}
                              >
                                {t(`assignmentsTableHeader.${header.key}`)}
                              </TableHeader>
                            )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => {
                        return (
                          <Fragment key={index}>
                            <TableExpandRow
                              className="projectAssignmentTableExpandableRow"
                              onClick={(e) => {
                                handleRowExpansion(rows, row.id, (e.target as HTMLElement).nodeName, undefined);
                              }}
                              {...getRowProps({ row })}
                              isSelected={assignmentExtensionArray.some((assignment) => assignment.id === row.id)}
                              isExpanded={isRowExpanded[index] === undefined ? false : isRowExpanded[index]}
                            >
                              {row.id && (
                                <TableSelectRow
                                  {...getSelectionProps({ row })}
                                  onChange={() => {
                                    selectAssignmentForExtension(row);
                                  }}
                                  checked={assignmentExtensionArray.some((assignment) => assignment.id === row.id)}
                                  disabled={isRowSelectable(row)}
                                  className={isRowSelectable(row) ? 'selectTableRow' : ''}
                                />
                              )}
                              {row.cells.map((cell, index) => {
                                return (
                                  cell.info.header !== 'practitionerId' && (
                                    <TableCell
                                      onClick={() => handleRowExpansion(rows, row.id, undefined, index)}
                                      key={index}
                                      className={`project-assignments-table-max-cell-width ${
                                        cell.info.header === 'assignmentStatus' ? `${cell.value}-assignment-cell` : ''
                                      }`}
                                    >
                                      {cell.info.header === 'serial' && (
                                        <Link
                                          onClick={() => {
                                            const practitionerId = row.cells.find(
                                              (assignment) => assignment.info.header === 'practitionerId'
                                            );
                                            navigate(`/practitioner/${practitionerId?.value}/assignments`);
                                          }}
                                        >
                                          {cell.value}
                                        </Link>
                                      )}
                                      {cell.info.header === 'endDate' && (
                                        <AssignmentEndDate
                                          id={row.id}
                                          value={cell.value}
                                          assignmentEndDateToolTip={assignmentEndDateToolTip}
                                          setAssignmentEndDateToolTip={setAssignmentEndDateToolTip}
                                        />
                                      )}
                                      {cell.info.header === 'assignmentStatus' && (
                                        <span className="assignment-status">
                                          {cell.value}
                                          {checkIfAssignmentIsMain(projectAssignments, row.id) && (
                                            <TooltipDefinition
                                              align="end"
                                              direction="bottom"
                                              tooltipText={t('misc.mainAssignment')}
                                              style={{
                                                verticalAlign: 'text-top',
                                              }}
                                            >
                                              <StarFilled16 className="main-assignment-icon" />
                                            </TooltipDefinition>
                                          )}
                                        </span>
                                      )}
                                      {isNotSpecificHeader(cell.info.header) && cell.value}
                                    </TableCell>
                                  )
                                );
                              })}
                            </TableExpandRow>
                            <TableExpandedRow colSpan={headers?.length + 2}>
                              {assignment && isRowExpanded[index] && (
                                <ProjectAssignmentsTableExpandedRow assignmentDetails={assignment} />
                              )}
                            </TableExpandedRow>
                          </Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <div className="project-assignments-no-filtered-assignments">
                    {t('dataDownloadToolbar.noAssignmentsFound')}
                  </div>
                )}
              </TableContainer>
            );
          }}
        </DataTable>
      }
    </>
  );
};

export default ProjectAssignmentTableExpandable;
