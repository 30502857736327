import { FilterTabInterface } from '../../practitioners-filter';

export const cicOnlyFields = [
  'terminated',
  'emfStatus',
  'contractType',
  'serviceLine',
  'role',
  'department',
  'serviceArea',
  'isPMO',
  'isPeM',
  'pemInfo',
];

export const practitionerOnlyFields = [
  'jrs',
  'band',
  'role',
  'isCic',
  'isPMO',
  'isPeM',
  'serial',
  'pemInfo',
  'deletion',
  'emfStatus',
  'department',
  'terminated',
  'nameFirstLast',
  'serviceArea',
  'contractType',
  'availabilityDateEnd',
  'availabilityDateStart',
  'availabilityInfo',
  'availabilityDateManuallyEdited',
];

export const projectOnlyFields = [
  'activeFrom',
  'activeUntil',
  'created',
  'projectStatus',
  'pmo',
  'projectType',
  'geoPm',
  'projectId',
  'name',
  'clientName',
  'projectAccountIds',
];

const emptyFilterSkeleton: FilterTabInterface = {
  jrs: [],
  band: [],
  role: [],
  isCic: {
    label: '',
    value: '',
  },
  isPMO: {
    label: '',
    value: '',
  },
  isPeM: {
    label: '',
    value: '',
  },
  serial: '',
  country: [],
  pemInfo: {
    id: 0,
    serial: '',
    lastName: '',
    firstName: '',
  },
  emfStatus: [],
  department: [],
  terminated: {
    label: '',
    value: '',
  },
  nameFirstLast: '',
  serviceArea: '',
  serviceLine: [],
  contractType: [],
  availabilityDateEnd: undefined,
  availabilityDateStart: undefined,
  availabilityInfo: '',
  actionRequiredStatus: [],

  activeFrom: undefined,
  activeUntil: undefined,
  created: undefined,
  projectStatus: { id: 0, title: '' },
  pmo: { id: 0, title: '' },
  projectType: { id: 0, title: '' },
  geoPm: { id: 0, name: '' },
  projectId: '',
  name: '',
  clientName: '',
  projectAccountIds: '',
};

export const emptyFilter = Object.freeze(emptyFilterSkeleton);
