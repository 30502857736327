import { configureStore, combineReducers } from '@reduxjs/toolkit';
import commonStatesReducer from '../common-states/slice';
import practitionersReducer from '../practitioner/slice';
import staticDataReducer from '../static-data/slice';
import filterTabsSlice from '../filter-tabs/slice';
import userReducer from '../user/slice';
import notificationReducer from '../notifications/slice';
import projectsReducer from '../project/slice';
import fileImportExportReducer from '../file-import-export/slice';
import createSagaMiddleware from 'redux-saga';
import { watcherSaga } from './root-saga';
import bluePagesReducer from '../bluepages/slice';
import multiChangeReducer from '../multi-change/slice';
import auditLogsReducer from '../audit-logs/slice';
import feedBackReducer from '../feedback/slice';

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  commonStates: commonStatesReducer,
  practitioners: practitionersReducer,
  staticData: staticDataReducer,
  user: userReducer,
  filterTabs: filterTabsSlice,
  notifications: notificationReducer,
  projects: projectsReducer,
  fileImportExport: fileImportExportReducer,
  bluePages: bluePagesReducer,
  multiChange: multiChangeReducer,
  auditLogs: auditLogsReducer,
  feedback: feedBackReducer
});

export const store = configureStore({
  reducer,
  middleware: [sagaMiddleware],
});
sagaMiddleware.run(watcherSaga);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
