import { PageType, StaticDataObjectInterface, UNIX_TIMESTAMP_IN_MILLISECONDS_LENGTH } from '../../common';
import { FilterIdAndTitle, FilterTabInterface } from '../../practitioners-filter';
import { cloneDeep, findIndex, isEqual, isObject } from 'lodash';
import { StringMap } from 'i18next';
import { cicOnlyFields, emptyFilter, practitionerOnlyFields, projectOnlyFields } from './const';
import { convertDate } from '../date-converter/date-converter';

interface DataToBuildFilterFromUrlParams {
  urlParamsString: string;
  activeFilter: FilterTabInterface;
  translations: StringMap;
  staticData: StaticDataObjectInterface;
  pageType: number;
}

export const filterOutCicFields = (urlParamsString: string) => {
  const urlParamsArr = urlParamsString.split('&');
  const filteredArr = urlParamsArr.filter((param) => !cicOnlyFields.includes(param.split('=')[0]));
  return filteredArr.join('&');
};

export const filterObjectToUrlParams = (filter?: FilterTabInterface | undefined) => {
  if (!filter) return '';

  const sortedFilter = getSortedFilterValues({ ...filter });

  let entries = Object.entries(sortedFilter);

  if (sortedFilter?.isCic && sortedFilter.isCic.value !== 'true' && sortedFilter?.isCic?.value !== '')
    entries = Object.entries(sortedFilter).filter(([key]) => {
      return !cicOnlyFields.includes(key);
    });

  const params: string[] = entries.reduce((acc: string[], [key, value]) => {
    if (!value) return acc;

    if (Array.isArray(value)) {
      acc.push(
        ...value.map(
          (item) => `${key}=${encodeURIComponent(item?.title || item?.value || item?.code || item?.id || item)}`
        )
      );
    } else if (typeof value === 'object') {
      const val = value?.title || value?.value || value?.code || value?.name || value?.id || value;

      if (typeof val !== 'object') acc.push(`${key}=${encodeURIComponent(val)}`);
    } else {
      if (
        typeof value === 'number' &&
        isFinite(value) &&
        String(value).length === UNIX_TIMESTAMP_IN_MILLISECONDS_LENGTH
      )
        value = convertDate(value);

      acc.push(`${key}=${encodeURIComponent(value)}`);
    }

    return acc;
  }, []);

  return params.join('&');
};

export const getSortedFilterValues = (filter?: FilterTabInterface): FilterTabInterface => {
  if (!filter) return {};

  const [filterArrays, filterOtherValues] = Object.entries(filter)
    .sort()
    .reduce(
      (
        acc: [FilterTabInterface[keyof FilterTabInterface], FilterTabInterface[keyof FilterTabInterface]],
        [key, value]
      ) => {
        if (Array.isArray(value))
          acc[0][key] = [...value].sort((valueA: string, valueB: string) => {
            if (typeof valueA === 'object') return isEqual(valueA, valueB) ? 0 : 1;

            return valueA.localeCompare(valueB);
          });
        else acc[1][key] = value;

        return acc;
      },
      [[], []]
    );

  return { ...filterOtherValues, ...filterArrays };
};

export const urlParamsToFilterObject = ({
  urlParamsString,
  activeFilter,
  translations,
  staticData,
  pageType,
}: DataToBuildFilterFromUrlParams) => {
  let filter: FilterTabInterface = cloneDeep(emptyFilter);

  const keysToRemove = pageType === PageType.Practitioner ? projectOnlyFields : practitionerOnlyFields;

  for (const key of keysToRemove) {
    delete filter[key];
  }

  if (pageType === PageType.Practitioner) {
    filter['serviceLine'] = [];
    filter['actionRequiredStatus'] = [];
  } else {
    filter['serviceLine'] = undefined;
    filter['actionRequiredStatus'] = undefined;
  }

  filter = buildFilterObjectFromUrlParams(urlParamsString, filter);
  filter = deepMergeFilters(filter, cloneDeep(activeFilter));
  filter = setLabelTranslations(filter, translations);
  filter = setCorrectValueFromStaticData(filter, staticData);

  return filter;
};

export const buildFilterObjectFromUrlParams = (urlParamsString: string, filter: FilterTabInterface) => {
  const urlParams = new URLSearchParams(urlParamsString);
  const filterKeys = Object.keys(filter);

  urlParams.forEach((value, key) => {
    if (!value) return;

    switch (key) {
      case 'pemInfo':
        if (filter.pemInfo) {
          filter.pemInfo.id = parseInt(value);
        }
        break;
      case 'jrs':
      case 'band':
      case 'role':
      case 'emfStatus':
      case 'department':
      case 'contractType':
        filter[key]?.push({ title: value });
        break;
      case 'actionRequiredStatus':
      case 'serviceLine':
      case 'pmo':
      case 'projectType':
      case 'projectStatus':
        {
          if (Array.isArray(filter[key])) {
            (filter[key] as FilterIdAndTitle[])?.push({ title: value });
          } else {
            filter[key] = { title: value };
          }
        }
        break;
      case 'country':
        filter[key]?.push({ code: value });
        break;
      case 'availabilityDateStart':
      case 'availabilityDateEnd':
      case 'activeFrom':
      case 'activeUntil':
      case 'created':
        filter[key] = new Date(value).getTime();
        break;
      case 'geoPm':
        filter[key] = { name: value };
        break;
      case 'isPMO':
      case 'isPeM':
      case 'isCic':
      case 'terminated':
      case 'availabilityDateManuallyEdited':
        filter[key] = { value: value };
        if (key === 'isCic' && value === 'false') {
          cicOnlyFields.forEach((field) => delete filter[field]);
        }
        break;
      default:
        if (filterKeys.includes(key) && Object.prototype.hasOwnProperty.call(filter, key)) {
          filter[key] = value;
        }
        break;
    }
  });

  return filter;
};

export const deepMergeFilters = (
  urlParamsObjectData: FilterTabInterface,
  currentFilterObjectData: FilterTabInterface
) => {
  for (const key in urlParamsObjectData) {
    if (!(key in urlParamsObjectData)) continue;

    if (isObject(urlParamsObjectData[key])) {
      if (
        Array.isArray(urlParamsObjectData[key]) &&
        currentFilterObjectData[key] &&
        !isEqual(urlParamsObjectData[key], currentFilterObjectData[key])
      ) {
        currentFilterObjectData[key] = urlParamsObjectData[key].map((item: FilterTabInterface) => {
          const currentItem = currentFilterObjectData[key].find((currentItem: FilterTabInterface) =>
            isEqual(item, currentItem)
          );

          if (currentItem) {
            return deepMergeFilters(item, currentItem);
          }

          return item;
        });

        continue;
      }

      currentFilterObjectData[key] = deepMergeFilters(urlParamsObjectData[key], currentFilterObjectData[key] || {});
    } else {
      if (
        currentFilterObjectData[key]?.id &&
        urlParamsObjectData[key] &&
        typeof urlParamsObjectData[key] === 'string' &&
        !isNaN(Number(urlParamsObjectData[key]))
      ) {
        currentFilterObjectData[key].id = Number(urlParamsObjectData[key]);
      } else {
        currentFilterObjectData[key] = urlParamsObjectData[key];
      }
    }
  }

  return currentFilterObjectData;
};

export const setLabelTranslations = (filter: FilterTabInterface, translations: StringMap) => {
  if (!filter || !translations) return filter;

  Object.entries(filter).forEach(([filterKey, filterValue]) => {
    if (!translations[filterKey]) return;

    const translation = translations[filterKey];

    if (Array.isArray(filterValue) && translation.options) {
      const translatedOptionsKeys = Object.keys(translation.options);

      filterValue.forEach((value, index) => {
        if (translatedOptionsKeys.includes(filterValue[index]?.title.toLowerCase())) {
          filterValue[index] = {
            title: translation.options[filterValue[index].title.toLowerCase()],
            id:
              findIndex(translatedOptionsKeys, (optionKey) => optionKey === filterValue[index].title.toLowerCase()) + 1,
          };
        }
      });
    }

    if (!filterValue?.value) return;

    if (filterValue.value === 'true') {
      const langKey = filterKey.includes('is')
        ? `is${filterKey.slice(2)}`
        : `is${filterKey.charAt(0).toUpperCase()}${filterKey.slice(1)}`;
      filter[filterKey].label = translation.options?.[langKey];
    } else if (filterValue.value === 'false') {
      const langKey = filterKey.includes('is')
        ? `not${filterKey.slice(2)}`
        : `not${filterKey.charAt(0).toUpperCase()}${filterKey.slice(1)}`;
      filter[filterKey].label = translation.options?.[langKey];
    } else if (typeof filterValue.value === 'string' && translation.options?.[filterValue.value]) {
      filter[filterKey].label = translation.options[filterValue.value];
    }
  });

  return filter;
};

export const setCorrectValueFromStaticData = (filter: FilterTabInterface, staticData: StaticDataObjectInterface) => {
  const keysToLookFor = ['title', 'code'];

  Object.entries(filter).forEach(([key, value]) => {
    if (!value) return;

    const staticDataArray: { [key: string]: any }[] =
      staticData[(key + 'StaticData') as keyof StaticDataObjectInterface];

    if (!staticDataArray) return;

    filter[key] = findMatchingItem(value, staticDataArray, keysToLookFor);
  });

  return filter;
};

const findMatchingItem = (
  value: { [key: string]: any }[],
  staticDataArray: { [key: string]: any }[],
  keysToLookFor: string[]
) => {
  if (Array.isArray(value)) {
    return value.map((item) =>
      staticDataArray.find((staticDataItem) => isMatching(item, staticDataItem, keysToLookFor))
    );
  } else if (typeof value === 'object') {
    return staticDataArray.find((staticDataItem) => isMatching(value, staticDataItem, [...keysToLookFor, 'id']));
  }

  return value;
};

const isMatching = (item: { [key: string]: any }, staticDataItem: { [key: string]: any }, keysToLookFor: string[]) => {
  return keysToLookFor.some(
    (keyToLookFor) =>
      staticDataItem[keyToLookFor] &&
      item[keyToLookFor] &&
      String(staticDataItem[keyToLookFor])?.toLowerCase() === String(item[keyToLookFor])?.toLowerCase()
  );
};

export const concatFirstLastNames = (item: { firstName?: string; lastName?: string; name?: string }) => {
  return item?.firstName && !item?.name
    ? {
        ...item,
        name: (item?.firstName ?? '') + ' ' + (item?.lastName ?? ''),
      }
    : item;
};
