import React, { useEffect, useState } from 'react';
import { AuditActionType, AuditEntityType, convertDate } from '@cic-boardlite/common';
import './audit-logs-list.scss';
import { useAuditLogs } from '../../custom-hooks';
import AuditLogBlock from '../audit-log-block/audit-log-block';
import Pagination from '../../ui/pagination/pagination';
import { ChevronUp, ChevronDown } from '@carbon/icons-react/next';
import { useTranslation } from 'react-i18next';
import { LogInterface, LogSummaryInterface } from '../../interfaces/audit-interfaces';
import { AccordionSkeleton, Button, Search } from 'carbon-components-react';
import { useParams } from 'react-router-dom';

const AuditLogsList = ({
  pageViewType,
  expand,
  setBlocksExpand,
}: {
  pageViewType: AuditEntityType;
  expand: boolean;
  setBlocksExpand: (expand: boolean) => void;
}) => {
  const { t } = useTranslation('common');

  const [auditLogsData, setAuditLogsData] = useState<LogSummaryInterface[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const { id } = useParams<{ id: string }>();

  const {
    practitionerAuditLogsData,
    projectAuditLogsData,
    getAuditLogs,
    isLoadingPractitionerAuditLogs,
    isLoadingProjectAuditLogs,
  } = useAuditLogs();

  useEffect(() => {
    const storedAuditLogs =
      pageViewType === AuditEntityType.Practitioner
        ? practitionerAuditLogsData.auditLogs
        : projectAuditLogsData.auditLogs;

    const mappedAuditLogs: LogSummaryInterface[] = storedAuditLogs.map((log: LogInterface): LogSummaryInterface => {
      return {
        beforeReadable: log.beforeReadable || {},
        afterReadable: log.afterReadable || {},

        modifiedOn: convertDate(log.modifiedOn, true) || '',
        modifiedBy: {
          id: log.modifiedBy?.id,
          fullName: log.modifiedBy?.firstName
            ? log.modifiedBy.firstName.concat(' ', log.modifiedBy?.lastName || '')
            : t('audit.unknown'),
        },
        modifiedBySystem: log.modifiedBySystem,
        actionType: log.actionType.id as AuditActionType,
        entityType: log.entityType.id as AuditEntityType,

        practitioner: log.practitionerId
          ? {
              id: log.practitionerId.id,
              fullName: log.practitionerId.firstName.concat(' ', log.practitionerId.lastName),
            }
          : undefined,
        project: log.projectId
          ? {
              id: log.projectId.id,
              projectName: log.projectId.name,
            }
          : undefined,
        assignmentId: log.assignmentId?.id,
        email: {
          type: log.afterReadable['emailType'],
          address:
            log.afterReadable['emailTo'] +
            (log.afterReadable['emailCc']?.length ? `, ${log.afterReadable['emailCc'].join(', ')}` : ''),
        },
      };
    });

    setAuditLogsData(mappedAuditLogs);
  }, [practitionerAuditLogsData, projectAuditLogsData]);

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchValue(value);
    setBlocksExpand(!!value?.length);
    getAuditLogs({
      id: String(id),
      auditableEntityType: pageViewType,
      page: 1,
      itemsPerPage: 10,
      filterString: value,
    });
  }

  return (
    <div className="audit-logs-grid">
      {(!!auditLogsData?.length || !!searchValue?.length) && (
        <div className="audit-logs-searchBar">
          <Search
            size="lg"
            labelText={t('audit.searchLabel')}
            placeholder={t('audit.searchPlaceholder')}
            closeButtonLabelText={t('audit.searchCloseLabelText')}
            id="search-1"
            className="audit-logs-search"
            onChange={(event) => {
              handleSearchChange(event);
            }}
          />
          <div className="audit-logs-button-wrapper">
            <Button
              onClick={() => {
                setBlocksExpand(!expand);
              }}
              size={'md'}
              kind={expand ? 'secondary' : 'primary'}
              renderIcon={expand ? ChevronUp : ChevronDown}
            >
              {expand ? t('audit.collapseAll') : t('audit.expandAll')}
            </Button>
          </div>
        </div>
      )}

      {isLoadingPractitionerAuditLogs || isLoadingProjectAuditLogs ? (
        <AccordionSkeleton className="audit-data-accordion" open={false} count={1} />
      ) : auditLogsData.length ? (
        auditLogsData.map((log, index) => (
          <AuditLogBlock logData={log} pageViewType={pageViewType} key={'logBlock_' + index} expand={expand} />
        ))
      ) : (
        <div className="no-audit-data">{t('audit.noData')}</div>
      )}

      {!!auditLogsData?.length && (
        <div className="audit-logs-pagination">
          <Pagination
            totalItems={
              pageViewType === AuditEntityType.Practitioner
                ? practitionerAuditLogsData.numberOfAuditLogs
                : projectAuditLogsData.numberOfAuditLogs
            }
            pageViewType={pageViewType}
            itemsPerPage={10}
            filterString={searchValue}
          />
        </div>
      )}
    </div>
  );
};
export default AuditLogsList;
