import {
  getStaticData,
  getStaticDataFailed,
  getStaticDataSuccess,
} from './slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from '../state-configurations/base-api';
import { sagaConfig } from '../../../config/config';

export function* handleGetStaticData(): Generator {
  const { staticDataAPI } = sagaConfig;

  try {
    const responseStaticData = yield call(get, staticDataAPI);
    yield put(getStaticDataSuccess(responseStaticData));
  } catch (error) {
    console.log(error);
    yield put(getStaticDataFailed());
  }
}

export function* staticDataSaga() {
  yield takeLatest(getStaticData.type, handleGetStaticData);
}
