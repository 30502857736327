import { CustomFilterComboBoxProps, orderDataByASCII } from '@cic-boardlite/common';
import { ComboBox } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { useBluePages } from '../../custom-hooks';
import './combobox-custom-filtering.scss';
interface CustomItemInterface {
  id?: number | string | null;
  label?: string;
  title?: string;
  code?: string;
  name?: string;
  projectId?: string;
  clientName?: string;
  clientTag?: string;
  talentId?: string;
}

const ComboboxWithCustomFilter = <T extends CustomItemInterface>({
  customClassName,
  onChange,
  onBlur,
  selectedItem,
  items,
  itemKeyName,
  titleText,
  placeholder,
  displaySelectedValueBolded,
  loadPractitionerSuggestionsW3,
  allowInputValue,
  warn,
  warnText,
}: CustomFilterComboBoxProps<T>) => {
  const [displayedItems, setDisplayedItems] = useState<T[]>(items);
  const [filterString, setFilterString] = useState<string>('');
  const [className, setClassName] = useState<string>(customClassName);
  const { getAllBluePagesPractitionersBySerial } = useBluePages();
  const comboBoxId = customClassName;

  const formatObjectOfFilteredItems = (filterString: string) => {
    const itemsThatMatchFilterString = items.filter((item) =>
      item?.[itemKeyName]?.toLowerCase()?.includes(filterString?.trim()?.toLowerCase())
    );

    const itemsThatNotMatchFilterString = items.filter(
      (item) => !item?.[itemKeyName]?.toLowerCase()?.includes(filterString?.trim()?.toLowerCase())
    );

    return {
      matchFilter: orderDataByASCII(itemsThatMatchFilterString, [itemKeyName]),
      notMatchFilter: orderDataByASCII(itemsThatNotMatchFilterString, [itemKeyName]),
    };
  };

  const filterAndSetItems = (filterString: string) => {
    setTimeout(() => {
      const filteredItems = formatObjectOfFilteredItems(filterString);

      if (filteredItems.matchFilter?.length) {
        setDisplayedItems([...filteredItems.matchFilter, ...filteredItems.notMatchFilter]);
      } else setDisplayedItems([]);
    }, 0);
  };

  const handleOnInputChange = (inputValue?: string) => {
    setFilterString(inputValue ?? '');

    if (loadPractitionerSuggestionsW3 && inputValue && inputValue?.length >= 3) {
      getAllBluePagesPractitionersBySerial({ query: inputValue });
    } else {
      if (allowInputValue) {
        selectedItem[itemKeyName] = inputValue;
        onChange({ selectedItem });
      }

      if (!inputValue?.length) return setDisplayedItems(items);
      filterAndSetItems(inputValue);
    }
  };

  const removeBottomBordersAndFalseHighlights = () => {
    const parentComboBox = document.querySelector(`.${customClassName}`);
    const parentMenuBox = parentComboBox?.querySelector('.bx--list-box__menu');
    if (parentMenuBox) {
      const disabledElements = parentMenuBox.querySelectorAll('.custom-last-filtered-item-separator');
      disabledElements.forEach((element) => {
        element.classList.remove('custom-last-filtered-item-separator');
      });

      const highlightedElements = parentMenuBox.querySelectorAll('.bx--list-box__menu-item--highlighted');
      highlightedElements.forEach((element, index) => {
        if (index === 0) return;
        element.classList.remove('bx--list-box__menu-item--highlighted');
      });
    }
  };

  const addBorderToFilterMatchingItem = () => {
    const itemsThatMatchFilterString = items.filter((item) =>
      item?.[itemKeyName]?.toLowerCase()?.includes(filterString?.trim()?.toLowerCase())
    );

    if (!itemsThatMatchFilterString?.length || itemsThatMatchFilterString?.length === items?.length) return;

    const lastFilterMatchingItem =
      document.querySelectorAll('.bx--list-box__menu-item')[itemsThatMatchFilterString.length - 1];
    if (lastFilterMatchingItem) lastFilterMatchingItem.classList.add('custom-last-filtered-item-separator');
  };

  useEffect(() => {
    removeBottomBordersAndFalseHighlights();
    addBorderToFilterMatchingItem();
  }, [displayedItems, filterString]);

  useEffect(() => {
    if (!loadPractitionerSuggestionsW3) return;
    filterAndSetItems(filterString);
  }, [items]);

  useEffect(() => {
    if (displaySelectedValueBolded) {
      if (selectedItem?.[itemKeyName]?.length && !className.includes('has-selection')) {
        return setClassName(className + ' has-selection');
      } else if (!selectedItem?.[itemKeyName]?.length && className.includes('has-selection'))
        return setClassName(className.replace(' has-selection', ''));
    }
  }, [selectedItem]);

  return (
    <ComboBox
      id={comboBoxId}
      className={className}
      onChange={onChange}
      onBlur={onBlur}
      onToggleClick={() => filterAndSetItems(selectedItem?.[itemKeyName] ?? '')}
      onMouseUp={() => filterAndSetItems(selectedItem?.[itemKeyName] ?? '')}
      downshiftProps={{
        highlightedIndex: 0,
      }}
      selectedItem={selectedItem}
      items={displayedItems}
      itemToString={(item) => item?.[itemKeyName] ?? ''}
      warn={warn}
      warnText={warnText}
      titleText={titleText}
      title={placeholder}
      placeholder={placeholder}
      onInputChange={(inputValue) => handleOnInputChange(inputValue)}
    ></ComboBox>
  );
};

export default ComboboxWithCustomFilter;
