import {
  getGeoPmNamesTalentIds,
  getProjectsClientData,
  getProjectsIdNameData,
  loadProject,
} from '../data-access/project/slice';
import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import { ProjectReduceType } from '@cic-boardlite/common';

const useProject = () => {
  const dispatch = useAppDispatch();

  const loadProjectsClientData = () => {
    dispatch(getProjectsClientData());
  };
  const isLoadingProjectsClientData = useAppSelector((state) => state.projects.isLoadingProjectsClientData);
  const projectsClientData = useAppSelector((state) => state.projects.projectsClientData);
  const loadProjectsIdNameData = () => {
    dispatch(getProjectsIdNameData());
  };
  const isLoadingProjectsIdNameData = useAppSelector((state) => state.projects.isLoadingProjectsIdNameData);
  const projectsIdNameData = useAppSelector((state) => state.projects.projectsIdNameData);

  const getGeoPmNamesTalentIdsData = () => {
    dispatch(getGeoPmNamesTalentIds());
  };

  const geoPmNamesTalentIds = useAppSelector((state) => state.projects.geoPmNamesTalentIds);

  const isLoadingGeoPmNamesTalentIds = useAppSelector((state) => state.projects.isLoadingGeoPmNamesTalentIds);

  const getProjectData = (id: string, type?: ProjectReduceType) => {
    dispatch(loadProject({ id, type }));
  };

  const isLoadingProjectData = useAppSelector((state) => state.projects.isLoadingProjectData);

  const projectData = useAppSelector((state) => state.projects.projectData);

  return {
    isLoadingProjectsClientData,
    loadProjectsClientData,
    projectsClientData,
    loadProjectsIdNameData,
    isLoadingProjectsIdNameData,
    projectsIdNameData,
    getGeoPmNamesTalentIdsData,
    geoPmNamesTalentIds,
    isLoadingGeoPmNamesTalentIds,
    getProjectData,
    isLoadingProjectData,
    projectData,
  };
};

export default useProject;
