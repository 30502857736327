import { createSlice } from '@reduxjs/toolkit';
import {
  AddToastInterface,
  NotificationStateInterface,
  ToastInterface,
} from '../../ui/common/notification/notificationInterfaces';
import { DEFAULT_NOTIFICATION_DISPLAY_TIME } from '../../ui/constants/constants';

export const initialState: NotificationStateInterface = {
  toasts: [],
  toastsCount: 0,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(state, action: { payload: AddToastInterface }) {
      const id = state.toastsCount++;
      const newToast: ToastInterface = {
        id,
        kind: action.payload.kind,
        title: action.payload.title,
        caption: action.payload.caption,
        iconDescription: action.payload.iconDescription,
        subtitle: action.payload.subtitle,
        timeOut: action?.payload?.timeOut || DEFAULT_NOTIFICATION_DISPLAY_TIME,
      };
      state.toasts.push(newToast);
    },
    removeNotification(state, action) {
      state.toasts.filter((toast) => toast.id !== action.payload);
    },
  },
});

export const { addNotification, removeNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
