import { useEffect, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFeedback, useUser } from '../../../custom-hooks';
import {
  EmailContentFeedbackResponse,
  EmailTemplate,
  FeedbackAttachmentsInterface,
  FEEDBACK_MAX_IMG_UPLOAD_SIZE,
} from '@cic-boardlite/common';
import { LabelWithAsterisk } from '../../../ui/label-with-asterisk/label-with-asterisk';
import { defaultFeedbackValuesInterface, FeedbackProps } from '../../../interfaces/feedback-interfaces';
import { Button, Modal, TextArea, FileUploaderDropContainer, FileUploaderItem } from 'carbon-components-react';
import ErrorBox from '../../../ui/form-error-box/ErrorBox';
import FeedbackFormSkeleton from './feedback-modal-form-skeleton/feedback-form-skeleton';
import './feedback-modal.scss';

const FeedbackModal = ({ open, onCloseModal, trackFormChanges, onSubmitClose }: FeedbackProps) => {
  const [textEdited, setTextEdited] = useState<boolean>(false);
  const [uploadEdited, setUploadEdited] = useState<boolean>(false);
  const [maintainerError, setMaintainerError] = useState<string | null>(null);
  const [imageList, setImageList] = useState<File[]>([]);

  const { maintainerEmails, getMaintainerEmails, sendFeedbackToMaintainers, isLoadingMaintainerEmails } = useFeedback();
  const { userInfo } = useUser();
  const { t } = useTranslation('common');

  const defaultValues = {
    feedback: '',
    image: null,
  };

  const methods = useForm<defaultFeedbackValuesInterface>({
    defaultValues: defaultValues,
  });

  const {
    reset,
    clearErrors,
    formState: { isDirty, isSubmitting, isSubmitSuccessful, errors },
  } = methods;

  useEffect(() => {
    getMaintainerEmails();
  }, []);

  useEffect(() => {
    if (textEdited || uploadEdited) {
      return trackFormChanges(true);
    }

    return trackFormChanges(false);
  }, [textEdited, trackFormChanges, uploadEdited]);

  useEffect(() => {
    setTextEdited(isDirty);
  }, [isDirty]);

  const handleReset = () => {
    clearErrors();
    reset(defaultValues);
    setImageList([]);
    setMaintainerError(null);
  };

  const handleCloseModal = () => {
    if (textEdited || uploadEdited) {
      return onCloseModal();
    }

    handleReset();
    onCloseModal();
  };

  const handleChange = (files: File[] | null) => {
    if (!files || !files.length) return;

    setImageList((prevState) => [...prevState, ...files]);
    setUploadEdited(true);
  };

  const transformImageToBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
    });
  };

  const handleAttachments = async (images: File[]): Promise<FeedbackAttachmentsInterface[]> => {
    const attachmentsArray: FeedbackAttachmentsInterface[] = [];

    for (const imageFile of images) {
      const base64Image = await transformImageToBase64(imageFile);
      attachmentsArray.push({
        filename: imageFile.name,
        content: base64Image,
        contentType: 'multipart/form-data',
      });
    }

    return attachmentsArray;
  };

  const handleSubmit = async (data: defaultFeedbackValuesInterface) => {
    if (userInfo.emailAddress.length === 0) {
      return setMaintainerError(t('feedback.noUserEmail'));
    }

    const sendEmailObject: EmailContentFeedbackResponse = {
      subject: `${t('feedback.boardlite')} from: ${userInfo.displayName} (${userInfo.emailAddress})`,
      emailTemplate: EmailTemplate.SIMPLE,
      emailContent: {
        htmlEmailBody: data.feedback,
        emailTo: maintainerEmails[0] ? maintainerEmails[0] : '',
        emailCc: maintainerEmails.slice(1),
        emailFrom: userInfo.emailAddress ? userInfo.emailAddress : '',
        attachments: imageList.length > 0 ? await handleAttachments(imageList) : [],
      },
    };

    sendFeedbackToMaintainers(sendEmailObject);

    if (maintainerEmails.length === 0) {
      return setMaintainerError(t('feedback.contactAdmin'));
    }

    return onSubmitClose();
  };

  const isImageSizeTooBig = (): boolean => {
    const totalImageSize = imageList.reduce((prevImageSize, currImage) => prevImageSize + currImage.size, 0);

    if (totalImageSize > FEEDBACK_MAX_IMG_UPLOAD_SIZE) {
      return true;
    }

    return false;
  };

  const isSubmitBtnDisabled = (): boolean => {
    return !isDirty || isImageSizeTooBig() || isSubmitting || isSubmitSuccessful;
  };

  const handleRemoveImage = (index: number) => {
    const newImageState = imageList.filter((_, idx) => index !== idx);

    if (newImageState.length === 0) {
      setUploadEdited(false);
    }

    return setImageList(newImageState);
  };

  return (
    <Modal open={open} onRequestClose={handleCloseModal} passiveModal className="feedback__modal">
      {!isLoadingMaintainerEmails && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => handleSubmit(data))} className="feedback__modal-form">
            <div className="feedback__modal-form-title">
              <h4>{t('feedback.boardlite')}</h4>
            </div>
            <div className="feedback__modal-form-input-container">
              <Controller
                name="feedback"
                control={methods.control}
                rules={{
                  required: {
                    value: true,
                    message: t('fieldRequired', { fieldLabel: t('feedback.title') }),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextArea
                    id="feedbackText"
                    labelText={LabelWithAsterisk(t('feedback.feedbackText'))}
                    placeholder={t('feedback.enterFeedback')}
                    onChange={onChange}
                    value={value}
                    invalid={!!errors['feedback']}
                    invalidText={<ErrorBox>{errors.feedback?.message}</ErrorBox>}
                    rows={2}
                  />
                )}
              />
              <Controller
                name="image"
                control={methods.control}
                render={() => (
                  <FileUploaderDropContainer
                    accept={['.jpg', '.jpeg', '.png']}
                    labelText={t('feedback.feedbackImageUploader')}
                    multiple
                    onAddFiles={(_, content) => handleChange(content.addedFiles)}
                  />
                )}
              />
              {isImageSizeTooBig() && <ErrorBox>{t('feedback.maxImageSize')}</ErrorBox>}
              {imageList.length > 0 && (
                <div className="feedback__modal-form-upload-item-container">
                  {imageList.map((image, idx) => {
                    return (
                      <FileUploaderItem
                        key={`${idx} ${Math.random()}`}
                        name={image.name}
                        status="edit"
                        onDelete={() => handleRemoveImage(idx)}
                      />
                    );
                  })}
                </div>
              )}
              {maintainerError && <ErrorBox>{maintainerError}</ErrorBox>}
            </div>
            <div className="feedback__modal-button-wrapper">
              <Button kind="secondary" onClick={handleReset}>
                {t('practitionersFilter.resetFormButton')}
              </Button>
              <Button type="submit" disabled={isSubmitBtnDisabled()}>
                {t('feedback.sendFeedback')}
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      {isLoadingMaintainerEmails && <FeedbackFormSkeleton />}
    </Modal>
  );
};

export default FeedbackModal;
