import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import {
  createPractitionerAssignment,
  updatePractitionerAssignment,
  getPractitionerAssignments,
  createUpdatePractitionerAssignmentReset,
  deletePractitionerAssignment,
  sendPractitionerAssignmentEmail,
  closeUpdatePractitionerAvailabilityDateModal,
  calculatePractitionerAvailabilityDate,
  deletePractitionerAssignmentFromProjectPage,
  countPractitionerAssignments,
  resetFilterPractitionerAssignmentsString,
  setFilterPractitionerAssignmentsString,
} from '../data-access/practitioner/slice';
import { CreateEditAssignmentInterface, SendEmailContentInterface } from '@cic-boardlite/common';

const usePractitionerAssignments = () => {
  const dispatch = useAppDispatch();

  const practitionerAssignmentsDetails = useAppSelector((state) => state.practitioners.practitionerAssignments);

  const isLoadingSelectedPractitionersAssignments = useAppSelector(
    (state) => state.practitioners.isLoadingPractitionerAssignments
  );

  const getSelectedPractitionerAssignments = ({
    id,
    reducedAssignments,
    onboardedOnly,
  }: {
    id: string;
    reducedAssignments?: boolean;
    onboardedOnly?: boolean;
  }) => {
    dispatch(getPractitionerAssignments({ id, reducedAssignments: reducedAssignments, onboardedOnly }));
  };

  const isDeletingPractitionerAssignment = useAppSelector(
    (state) => state.practitioners.isDeletingPractitionerAssignment
  );

  const deleteSelectedPractitionerAssignment = (id: string, practitionerId: string | undefined) => {
    dispatch(deletePractitionerAssignment({ id, practitionerId }));
  };

  const deleteSelectedPractitionerAssignmentFromProjectPage = (
    id: string,
    practitionerId?: number,
    projectId?: string
  ) => {
    dispatch(
      deletePractitionerAssignmentFromProjectPage({
        id,
        practitionerId,
        projectId,
      })
    );
  };

  const isSendingPractitionerAssignmentEmail = useAppSelector(
    (state) => state.practitioners.isDeletingPractitionerAssignment
  );

  const sendSelectedPractitionerAssignmentEmail = (data: SendEmailContentInterface) => {
    dispatch(sendPractitionerAssignmentEmail(data));
  };

  const isCreatingUpdatingPractitionerAssignment = useAppSelector(
    (state) => state.practitioners.isCreatingUpdatingPractitionerAssignment
  );

  const wasCreatedUpdatedPractitionerAssignment = useAppSelector(
    (state) => state.practitioners.wasCreatedUpdatedPractitionerAssignment
  );

  const wasCreateUpdatePractitionerAssignmentReset = () => {
    dispatch(createUpdatePractitionerAssignmentReset());
  };

  const buildPractitionerAssignment = (assignmentData: CreateEditAssignmentInterface) => {
    dispatch(createPractitionerAssignment(assignmentData));
  };

  const editPractitionerAssignment = (
    oldAssignmentData: CreateEditAssignmentInterface,
    updatedAssignmentData: CreateEditAssignmentInterface
  ) => {
    dispatch(
      updatePractitionerAssignment({
        oldData: oldAssignmentData,
        newData: updatedAssignmentData,
      })
    );
  };

  const isUpdateAvailabilityDateModalOpen = useAppSelector(
    (state) => state.practitioners.isUpdateAvailabilityDateModalOpen
  );

  const updateAvailabilityDateModalInformation = useAppSelector(
    (state) => state.practitioners.updateAvailabilityDateModalInfo
  );

  const closeUpdateAvailabilityDateModal = () => {
    dispatch(closeUpdatePractitionerAvailabilityDateModal());
  };

  const triggerCalculatePractitionerAvailabilityDate = (data: {
    recalculateOnAssignmentUpdate: boolean;
    practitionerId: number;
  }) => {
    dispatch(calculatePractitionerAvailabilityDate(data));
  };

  const practitionerAssignmentsCount = useAppSelector((state) => state.practitioners.practitionerAssignmentsCount);

  const isLoadingPractitionerAssignmentsCount = useAppSelector(
    (state) => state.practitioners.isLoadingPractitionerAssignmentsCount
  );

  const getPractitionerAssignmentsCount = (id: number) => {
    dispatch(countPractitionerAssignments(id));
  };

  const practitionerAssignmentsFilterString = useAppSelector(
    (state) => state.practitioners.filterPractitionerAssignmentsString
  );

  const setPractitionerAssignmentsFilterString = (filterString: string) => {
    dispatch(setFilterPractitionerAssignmentsString(filterString));
  };

  const resetPractitionerAssignmentsFilterString = () => {
    dispatch(resetFilterPractitionerAssignmentsString());
  };

  return {
    isLoadingSelectedPractitionersAssignments,
    practitionerAssignmentsDetails,
    getSelectedPractitionerAssignments,
    isCreatingUpdatingPractitionerAssignment,
    wasCreatedUpdatedPractitionerAssignment,
    isDeletingPractitionerAssignment,
    deleteSelectedPractitionerAssignment,
    isSendingPractitionerAssignmentEmail,
    sendSelectedPractitionerAssignmentEmail,
    buildPractitionerAssignment,
    editPractitionerAssignment,
    wasCreateUpdatePractitionerAssignmentReset,
    isUpdateAvailabilityDateModalOpen,
    closeUpdateAvailabilityDateModal,
    updateAvailabilityDateModalInformation,
    deleteSelectedPractitionerAssignmentFromProjectPage,
    triggerCalculatePractitionerAvailabilityDate,
    practitionerAssignmentsCount,
    isLoadingPractitionerAssignmentsCount,
    getPractitionerAssignmentsCount,
    practitionerAssignmentsFilterString,
    setPractitionerAssignmentsFilterString,
    resetPractitionerAssignmentsFilterString,
  };
};

export default usePractitionerAssignments;
