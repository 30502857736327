import {
  GetPractitionerDetails,
  getPractitionerPictureLink,
  JrsStaticDataInterface,
  profilePicturePath,
  Role,
  HookPractitionerObject,
  calculatePractitionerRate,
  practitionerPSA,
  validateSerialFE,
  EmfStatus,
  TalentID_LENGTH,
  trim,
  AssignmentStatus,
  toUTCDate,
} from '@cic-boardlite/common';
import {
  Checkbox,
  TextInput,
  Button,
  Dropdown,
  DatePicker,
  MultiSelect,
  DatePickerInput,
  TextArea,
  Link,
  TooltipDefinition,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, FormProvider, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { usePractitionerAssignments, useSavePractitioner, useStaticData } from '../../../custom-hooks';
import './detail-page.scss';
import { PractitionerDetailEditInterface } from '../../../interfaces/practitionerInterfaces';
import PractitionerPageSidebar from '../page-sidebar/page-sidebar';
import ErrorBox from '../../../ui/form-error-box/ErrorBox';
import { useNavigate } from 'react-router-dom';
import PermissionsGate from '../../permission-gate/permission-gate';
import { Renew } from '@carbon/icons-react/next';
import { LabelWithAsterisk } from '../../../ui/label-with-asterisk/label-with-asterisk';
import ComboboxWithCustomFilter from '../../combobox-custom-filtering/combobox-custom-filtering';

interface Props {
  practitionersInfo: GetPractitionerDetails;
}

enum DateValidation {
  InvalidDate = 'Invalid Date',
  NotANumber = 'NaN',
}

export const strToLowerCase = (input: string) => {
  return input.toLowerCase();
};

const PractitionerDetailPageCompNotCic = ({ practitionersInfo }: Props) => {
  const [profileImage, setProfileImage] = useState<string>('');
  const [serviceLineLeaderError, setServiceLineLeaderError] = useState<string | null>(null);
  const { staticData, loadStaticData } = useStaticData();
  const { editPractitioner } = useSavePractitioner();
  const { triggerCalculatePractitionerAvailabilityDate } = usePractitionerAssignments();
  const { t } = useTranslation('common');
  const nav = useNavigate();

  const staticDataObj = staticData?.staticData;

  useEffect(() => {
    loadStaticData();
    setProfileImage(getPractitionerPictureLink(practitionersInfo.serial, practitionersInfo.countryCode));
  }, []);

  const dateInitValue = (date: string | undefined): Date | undefined => {
    if (typeof date === 'string') {
      return new Date(date);
    }
    return date;
  };

  const defaultValues = {
    firstName: practitionersInfo.firstName,
    lastName: practitionersInfo.lastName,
    role: practitionersInfo.role,
    band: { id: practitionersInfo.bandId, title: practitionersInfo.band },
    country: {
      id: practitionersInfo.countryId,
      label: practitionersInfo.country,
      code: practitionersInfo.countryCode?.toString(),
    },
    department: {
      id: practitionersInfo.departmentId,
      title: practitionersInfo.department,
    },
    serial: practitionersInfo.serial,
    hiringDate: dateInitValue(practitionersInfo.hiringDate),
    serviceLine: {
      id: practitionersInfo.serviceLineId,
      title: practitionersInfo.serviceLine,
    },
    peopleManager: {
      id: practitionersInfo.peopleManagerPractitionerId,
      name: practitionersInfo.peopleManager,
    },
    emfStatus: {
      id: practitionersInfo.emfStatusId,
      title: practitionersInfo.emfStatus,
    },
    contractType: {
      id: practitionersInfo.contractTypeId,
      title: practitionersInfo.contractType,
    },
    jrs: {
      id: practitionersInfo.jrsId,
      title: practitionersInfo.jrs,
    },
    serviceArea: practitionersInfo.serviceArea,
    availabilityInfo: practitionersInfo.availabilityInfo || '',
    assignmentEndDate: dateInitValue(practitionersInfo.assignmentEndDate),
    availabilityDate: dateInitValue(practitionersInfo.availabilityDate),
    costRate: practitionersInfo.costRate,
    psAdder: { label: practitionersInfo.psAdder },
    terminationDate: dateInitValue(practitionersInfo.terminationDate),
    isCic: practitionersInfo.isCic,
    isPMO: practitionersInfo.isPMO,
    isPeM: practitionersInfo.isPeM,
    notes: practitionersInfo.practitionerPlan?.comment || '',
    isServiceLineLeader: practitionersInfo.isServiceLineLeader,
  };

  const methods = useForm<PractitionerDetailEditInterface>({
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, isSubmitSuccessful },
    reset,
    watch,
  } = methods;

  const watchBandInput = watch('band');
  const watchJrsInput = watch('jrs');
  const watchCountryInput = watch('country');
  const watchServiceLineLeader = watch('isServiceLineLeader');
  const watchEmfStatus = watch('emfStatus');
  const watchServiceLine = watch('serviceLine');
  const watchHiringDate = watch('hiringDate');
  const watchIsCic = useWatch({
    control,
    name: 'isCic',
  });

  useEffect(() => {
    methods.clearErrors('isServiceLineLeader');
    methods.clearErrors('serial');
  }, [watchEmfStatus, watchServiceLineLeader, watchServiceLine]);

  useEffect(() => {
    if (!watchHiringDate) return;

    const availabilityDate = methods.getValues('availabilityDate');
    const terminationDate = methods.getValues('terminationDate');

    if (availabilityDate && availabilityDate < watchHiringDate) {
      methods.setValue('availabilityDate', watchHiringDate);
    }

    if (!terminationDate || terminationDate <= watchHiringDate) {
      methods.setValue('terminationDate', watchHiringDate);
      setTimeout(() => {
        methods.setValue('terminationDate', undefined);
      }, 0);
    }
  }, [watchHiringDate]);

  const findServiceLine = () => {
    return staticDataObj?.serviceLineStaticData?.find(
      (serviceLine: {
        id: number;
        title: string;
        leader: { id: number; firstName: string; lastName: string } | null;
      }) => serviceLine.id === watchServiceLine?.id
    );
  };

  const checkIfOtherPersonIsServiceLineLeader = (): { validation: boolean; errorMessage: string } => {
    const foundServiceLine = findServiceLine();

    const validation = !foundServiceLine?.leader || foundServiceLine?.leader?.id === Number(practitionersInfo.id);

    const errorMessage = !validation
      ? t('createEditPractitionerForm.serviceLineLeader.otherIsLeader', {
          serviceLine: foundServiceLine?.title,
        })
      : '';

    setServiceLineLeaderError(foundServiceLine?.leader?.firstName + ' ' + foundServiceLine?.leader?.lastName);

    return {
      validation,
      errorMessage,
    };
  };

  const navigateToServiceLineLeader = () => {
    const foundServiceLine = findServiceLine();
    nav(`/practitioner/${foundServiceLine?.leader?.id}`);
  };

  const getPractitionerRate = (): string =>
    calculatePractitionerRate(
      staticDataObj?.rateStaticData,
      watchCountryInput?.label,
      watchBandInput?.title,
      getPractitionerPSA()
    );

  const getPractitionerPSA = (): string =>
    practitionerPSA({
      jrsStaticData: staticDataObj?.jrsStaticData,
      jrsId: watchJrsInput?.id,
      band: watchBandInput?.title,
    });

  const getServiceArea = (): string => {
    const jrs = staticDataObj.jrsStaticData?.find(
      (jrs: { id: number; title: string }) => jrs.title === watchJrsInput?.title
    );
    return !jrs?.serviceArea ? '' : jrs?.serviceArea;
  };

  const validatePractitionerSerial = (serial: string) =>
    validateSerialFE(serial, watchIsCic, watchCountryInput?.code, watchEmfStatus?.id, practitionersInfo?.emfStatusId);

  const addCountryCodeToSerial = (serial: string, countryCode: string, emfStatusId: number | undefined): string => {
    if (emfStatusId === EmfStatus.CandidateForHiring) return serial;
    if (serial.length === TalentID_LENGTH.SHORT) return serial + countryCode;
    return serial;
  };

  const isTerminationDateRequired = (value: Date | undefined): boolean => {
    return !(watchEmfStatus?.id === EmfStatus.Terminated && value?.toString() === DateValidation.InvalidDate);
  };

  const handleSubmitForm = (data: PractitionerDetailEditInterface) => {
    const oldPractitionerProfile: HookPractitionerObject = {
      id: Number(practitionersInfo?.id),
      firstName: practitionersInfo.firstName,
      lastName: practitionersInfo.lastName,
      serial: practitionersInfo.serial,
      availabilityInfo: practitionersInfo.availabilityInfo,
      country: practitionersInfo.countryId || 0,
      countryTitle: practitionersInfo.country || '',
      availabilityDate: practitionersInfo?.availabilityDate ? new Date(practitionersInfo.availabilityDate) : null,
      emfStatus: watchIsCic ? practitionersInfo.emfStatusId : null,
      emfStatusTitle: watchIsCic ? practitionersInfo.emfStatus : undefined,
      contractType: watchIsCic ? practitionersInfo.contractTypeId : null,
      contractTypeTitle: watchIsCic ? practitionersInfo.contractType : undefined,
      serviceLine: watchIsCic ? practitionersInfo.serviceLineId : null,
      serviceLineTitle: watchIsCic ? practitionersInfo.serviceLine : undefined,
      band: practitionersInfo.bandId,
      bandTitle: practitionersInfo.band,
      department: watchIsCic ? practitionersInfo.departmentId : null,
      departmentTitle: watchIsCic ? practitionersInfo.department : undefined,
      jrs: practitionersInfo.jrsId,
      jrsTitle: practitionersInfo.jrs,
      userRole: watchIsCic ? practitionersInfo.role : undefined,
      peopleManagerPractitionerId: watchIsCic ? practitionersInfo.peopleManagerPractitionerId : null,
      peopleManagerTitle: watchIsCic ? practitionersInfo?.peopleManager : undefined,
      hiringDate: watchIsCic ? (practitionersInfo?.hiringDate ? new Date(practitionersInfo.hiringDate) : null) : null,
      assignmentEndDate: watchIsCic
        ? practitionersInfo?.assignmentEndDate
          ? new Date(practitionersInfo.assignmentEndDate)
          : null
        : null,
      terminationDate: watchIsCic
        ? practitionersInfo?.terminationDate
          ? new Date(practitionersInfo.terminationDate)
          : null
        : null,
      isCic: practitionersInfo.isCic,
      isPMO: watchIsCic ? practitionersInfo.isPMO : false,
      isPeM: watchIsCic ? practitionersInfo.isPeM : false,
      sendEmail: false,
      ccEmail: [],
      emailTo: '',
      notes: practitionersInfo.practitionerPlan?.comment,
      availabilityDateManuallyEdited: practitionersInfo.availabilityDateManuallyEdited,
      isServiceLineLeader: practitionersInfo.isServiceLineLeader,
    };

    const updatedPractitionerProfile: HookPractitionerObject = {
      id: Number(practitionersInfo?.id),
      firstName: trim(data.firstName),
      lastName: trim(data.lastName),
      serial: addCountryCodeToSerial(data.serial, data.country.code, data.emfStatus?.id),
      availabilityInfo: trim(data.availabilityInfo),
      country: data.country?.id,
      countryTitle: data.country?.label,
      availabilityDate: data.availabilityDate ? new Date(data.availabilityDate) : null,
      emfStatus: watchIsCic ? data.emfStatus?.id : null,
      emfStatusTitle: watchIsCic ? data.emfStatus?.title : undefined,
      contractType: watchIsCic ? data.contractType?.id : null,
      contractTypeTitle: watchIsCic ? data.contractType?.title : undefined,
      serviceLine: watchIsCic ? data.serviceLine?.id : null,
      serviceLineTitle: watchIsCic ? data.serviceLine?.title : undefined,
      band: data.band?.id,
      bandTitle: data.band?.title,
      department: watchIsCic ? data.department?.id : null,
      departmentTitle: watchIsCic ? data.department?.title : undefined,
      jrs: data.jrs?.id,
      jrsTitle: data.jrs?.title,
      userRole: watchIsCic
        ? data.role?.map((role) => {
            return {
              id: role.id,
              title: role.title,
              description: role.description,
            };
          })
        : [],
      peopleManagerPractitionerId: watchIsCic ? data.peopleManager?.id : null,
      peopleManagerTitle: watchIsCic ? data.peopleManager?.name : undefined,
      hiringDate: watchIsCic ? (data.hiringDate ? new Date(data.hiringDate) : null) : null,
      assignmentEndDate: watchIsCic ? (data.assignmentEndDate ? new Date(data.assignmentEndDate) : null) : null,
      terminationDate: watchIsCic ? (data?.terminationDate ? new Date(data.terminationDate) : null) : null,
      isCic: data.isCic,
      isPMO: watchIsCic ? data.isPMO : false,
      isPeM: watchIsCic ? data.isPeM : false,
      sendEmail: false,
      ccEmail: [],
      emailTo: '',
      notes: trim(data.notes),
      availabilityDateManuallyEdited:
        new Date(String(oldPractitionerProfile?.availabilityDate)).getTime() !==
        new Date(
          typeof data?.availabilityDate === 'number' ? data?.availabilityDate : String(data?.availabilityDate)
        ).getTime()
          ? true
          : oldPractitionerProfile?.availabilityDateManuallyEdited,
      isServiceLineLeader: watchIsCic ? watchServiceLineLeader : false,
    };

    return editPractitioner(oldPractitionerProfile, updatedPractitionerProfile, true, false);
  };

  const psAdderComponent = (
    <Controller
      name="psAdder"
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextInput
          id="practitionerProfilePsAdder"
          value={getPractitionerPSA()}
          onChange={onChange}
          labelText={t(`createEditPractitionerForm.psAdder.label`)}
          placeholder={t(`createEditPractitionerForm.psAdder.placeholder`)}
          disabled={true}
        />
      )}
    />
  );

  const isTalentIdCFH = (): boolean => {
    return practitionersInfo.serial.slice(0, 3).toLowerCase() === 'cfh';
  };

  const isTerminationStatusAllowed = (status: number | undefined): boolean => {
    return (
      status === EmfStatus.Terminated &&
      practitionersInfo.assignments?.map((assignment) => assignment.status).includes(AssignmentStatus.Onboarded) ===
        true
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => handleSubmitForm(data))}>
        <div className="d-flex practitioner-detail-page-wrapper">
          <PractitionerPageSidebar />
          <div className="practitioner-detail-page-profile">
            <div>
              <h1 className="practitioner-detail-page-title">{t(`practitionerProfile.title`)}</h1>
            </div>
            <div className="d-flex practitioner-detail-page-img-input-container">
              <div>
                <img
                  className="practitioner-detail-page-img"
                  onError={() => {
                    setProfileImage(profilePicturePath);
                  }}
                  src={profileImage}
                  alt="Practitioner"
                />
              </div>
              <div className="d-flex practitioner-detail-page-inputs-button-container">
                <div className="practitioner-detail-page-input-grid">
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('fieldRequired', {
                          fieldLabel: t('createEditPractitionerForm.firstName.label'),
                        }),
                      },
                      maxLength: {
                        value: 64,
                        message: t('maxLengthMessage', { maxLength: 64 }),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        onChange={onChange}
                        id="practitionerProfileFirstName"
                        labelText={LabelWithAsterisk(t(`createEditPractitionerForm.firstName.label`))}
                        placeholder={t(`createEditPractitionerForm.firstName.placeholder`)}
                        value={value}
                        warn={!!errors['firstName']}
                        warnText={<ErrorBox>{errors.firstName?.message}</ErrorBox>}
                      />
                    )}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('fieldRequired', {
                          fieldLabel: t('createEditPractitionerForm.lastName.label'),
                        }),
                      },
                      maxLength: {
                        value: 64,
                        message: t('maxLengthMessage', { maxLength: 64 }),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        onChange={onChange}
                        id="practitionerProfileLastName"
                        labelText={LabelWithAsterisk(t(`createEditPractitionerForm.lastName.label`))}
                        placeholder={t(`createEditPractitionerForm.lastName.placeholder`)}
                        value={value}
                        warn={!!errors['lastName']}
                        warnText={<ErrorBox>{errors.lastName?.message}</ErrorBox>}
                      />
                    )}
                  />
                  <Controller
                    name="serial"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('fieldRequired', {
                          fieldLabel: t('createEditPractitionerForm.serial.label'),
                        }),
                      },
                      validate: {
                        validateUpdatedSerial: (value) => {
                          return (
                            !(watchIsCic && validatePractitionerSerial(value).error) ||
                            t(`createEditPractitionerForm.serial.${validatePractitionerSerial(value).errMessage}`) +
                              ` ${validatePractitionerSerial(value).code}`
                          );
                        },
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        onChange={onChange}
                        disabled={!watchIsCic || !isTalentIdCFH()}
                        id="practitionerProfileSerial"
                        labelText={LabelWithAsterisk(t(`createEditPractitionerForm.serial.label`))}
                        placeholder={t(`createEditPractitionerForm.serial.placeholder`)}
                        value={value}
                        warn={!!errors['serial']}
                        warnText={<ErrorBox>{errors.serial?.message}</ErrorBox>}
                      />
                    )}
                  />
                  <TextInput
                    defaultValue={practitionersInfo.email}
                    disabled
                    id="practitionerProfileEmail"
                    labelText={t(`createEditPractitionerForm.practitionerEmail.label`)}
                    placeholder={t(`createEditPractitionerForm.practitionerEmail.placeholder`)}
                  />
                  <Controller
                    name="availabilityInfo"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 512,
                        message: t('maxLengthMessage', { maxLength: 512 }),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        onChange={onChange}
                        id="practitionerProfileAvailabilityInfo"
                        labelText={t(`createEditPractitionerForm.availabilityInfo.label`)}
                        placeholder={t(`createEditPractitionerForm.availabilityInfo.placeholder`)}
                        value={value}
                        warn={!!errors['availabilityInfo']}
                        warnText={<ErrorBox>{errors.availabilityInfo?.message}</ErrorBox>}
                      />
                    )}
                  />
                  <Controller
                    name="availabilityDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="practitioner-detail-page-availability-date-input-wrapper">
                        <DatePicker
                          light
                          onChange={(_dates: Date[], _currentDateString: string) =>
                            onChange(new Date(_currentDateString).getTime())
                          }
                          datePickerType="single"
                          value={practitionersInfo?.availabilityDate ? value : ''}
                          disable={[(date) => date < toUTCDate(watchHiringDate || date)]}
                          dateFormat={t('format.date_4carbonDatePicker')}
                        >
                          <DatePickerInput
                            id="practitionerProfileAvailabilityDate"
                            labelText={t('createEditPractitionerForm.availabilityDate.label')}
                            placeholder={t('createEditPractitionerForm.availabilityDate.placeholder')}
                            autoComplete="off"
                          />
                        </DatePicker>
                        {practitionersInfo.availabilityDateManuallyEdited && (
                          <TooltipDefinition
                            onFocus={(e) => e.currentTarget.blur()}
                            tooltipText={t('availabilityDateUpdate.tooltipRecalculate')}
                          >
                            <Renew
                              className="practitioner-detail-page-recalculate-availability-icon"
                              onClick={() =>
                                triggerCalculatePractitionerAvailabilityDate({
                                  practitionerId: Number(practitionersInfo.id),
                                  recalculateOnAssignmentUpdate: false,
                                })
                              }
                            />
                          </TooltipDefinition>
                        )}
                      </div>
                    )}
                  />
                  <Controller
                    name="country"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ComboboxWithCustomFilter
                        customClassName="custom-filter-combobox-update-practitioner-country"
                        onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                        selectedItem={value}
                        items={staticDataObj?.countryStaticData || []}
                        itemKeyName="label"
                        titleText={LabelWithAsterisk(t(`createEditPractitionerForm.country.label`))}
                        placeholder={t(`createEditPractitionerForm.country.placeholder`)}
                        warn={!!errors['country']}
                        warnText={
                          <ErrorBox>
                            {t('fieldRequired', {
                              fieldLabel: t('createEditPractitionerForm.country.label'),
                            })}
                          </ErrorBox>
                        }
                      />
                    )}
                  />
                  <Controller
                    name="band"
                    control={control}
                    rules={{
                      required: watchIsCic,
                      validate: (value) => !!value?.id,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ComboboxWithCustomFilter
                        customClassName="custom-filter-combobox-update-practitioner-band"
                        onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                        selectedItem={value ?? { id: null, title: '' }}
                        items={staticDataObj.bandStaticData || []}
                        itemKeyName="title"
                        titleText={LabelWithAsterisk(t(`practitionerProfile.band`))}
                        placeholder={t(`createEditPractitionerForm.band.placeholder`)}
                        warn={!!errors['band']}
                        warnText={
                          <ErrorBox>
                            {t('fieldRequired', {
                              fieldLabel: t('createEditPractitionerForm.band.label'),
                            })}
                          </ErrorBox>
                        }
                      />
                    )}
                  />
                  <div className="practitioner-detail-page-jrs-input">
                    <Controller
                      name="jrs"
                      rules={{
                        required: watchIsCic,
                        validate: (value) => !!value?.id,
                      }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ComboboxWithCustomFilter
                          customClassName="custom-filter-combobox-update-practitioner-jrs"
                          onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                          selectedItem={value ?? { id: null, title: '' }}
                          items={
                            staticDataObj?.jrsStaticData
                              ? staticDataObj?.jrsStaticData
                                  .slice()
                                  .sort((a: JrsStaticDataInterface, b: JrsStaticDataInterface) =>
                                    a.title.localeCompare(b.title)
                                  )
                              : []
                          }
                          itemKeyName="title"
                          titleText={LabelWithAsterisk(t(`createEditPractitionerForm.jrs.label`))}
                          placeholder={t(`createEditPractitionerForm.jrs.placeholder`)}
                          warn={!!errors['jrs']}
                          warnText={
                            <ErrorBox>
                              {t('fieldRequired', {
                                fieldLabel: t('createEditPractitionerForm.jrs.label'),
                              })}
                            </ErrorBox>
                          }
                        />
                      )}
                    />
                  </div>
                  {watchIsCic && (
                    <>
                      <Controller
                        name="emfStatus"
                        rules={{
                          required: watchIsCic,
                          validate: {
                            validateTerminationStatus: (value) => {
                              return (
                                !isTerminationStatusAllowed(value?.id) ||
                                t('createEditPractitionerForm.emfStatus.terminationNotAllowed')
                              );
                            },
                            validateValue: (value) => {
                              return (
                                !!value?.id ||
                                t('fieldRequired', {
                                  fieldLabel: t('createEditPractitionerForm.emfStatus.label'),
                                })
                              );
                            },
                          },
                        }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Dropdown
                            selectedItem={value}
                            onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                            id="practitionerProfileEmfStatus"
                            label={t(`createEditPractitionerForm.emfStatus.label`)}
                            titleText={t(`createEditPractitionerForm.emfStatus.label`)}
                            warn={!!errors['emfStatus']}
                            warnText={<ErrorBox>{errors.emfStatus?.message}</ErrorBox>}
                            items={staticDataObj?.emfStatusStaticData || []}
                            itemToString={(item) =>
                              item.title ? item.title : t(`createEditPractitionerForm.emfStatus.label`)
                            }
                          />
                        )}
                      />
                      <Controller
                        name="contractType"
                        rules={{
                          required: watchIsCic,
                          validate: (value) => !!value?.id,
                        }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ComboboxWithCustomFilter
                            customClassName="custom-filter-combobox-update-practitioner-contract-type"
                            onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                            selectedItem={value ?? { id: null, title: '' }}
                            items={staticDataObj?.contractTypeStaticData || []}
                            itemKeyName="title"
                            titleText={LabelWithAsterisk(t(`createEditPractitionerForm.contractType.label`))}
                            placeholder={t(`createEditPractitionerForm.contractType.label`)}
                            warn={!!errors['contractType']}
                            warnText={
                              <ErrorBox>
                                {t('fieldRequired', {
                                  fieldLabel: t('createEditPractitionerForm.contractType.label'),
                                })}
                              </ErrorBox>
                            }
                          />
                        )}
                      />
                      <Controller
                        name="serviceLine"
                        rules={{
                          required: watchIsCic,
                          validate: (value) => !!value?.id,
                        }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ComboboxWithCustomFilter
                            customClassName="custom-filter-combobox-update-practitioner-service-line"
                            onChange={(onChangeData) => {
                              onChange(onChangeData.selectedItem);
                              methods.setValue(
                                'isServiceLineLeader',
                                onChangeData.selectedItem?.id === practitionersInfo.serviceLineId
                                  ? practitionersInfo.isServiceLineLeader
                                  : false
                              );
                            }}
                            selectedItem={value ?? { id: null, title: '' }}
                            items={staticDataObj?.serviceLineStaticData || []}
                            itemKeyName="title"
                            titleText={LabelWithAsterisk(t(`createEditPractitionerForm.serviceLine.label`))}
                            placeholder={t(`createEditPractitionerForm.serviceLine.label`)}
                            warn={!!errors['serviceLine']}
                            warnText={
                              <ErrorBox>
                                {t('fieldRequired', {
                                  fieldLabel: t('createEditPractitionerForm.serviceLine.label'),
                                })}
                              </ErrorBox>
                            }
                          />
                        )}
                      />
                      <Controller
                        name="department"
                        control={control}
                        rules={{
                          required: watchIsCic,
                          validate: (value) => !!value?.id,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <ComboboxWithCustomFilter
                            customClassName="custom-filter-combobox-update-practitioner-department"
                            onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                            selectedItem={value ?? { id: null, title: '' }}
                            items={staticDataObj.departmentStaticData || []}
                            itemKeyName="title"
                            titleText={LabelWithAsterisk(t(`createEditPractitionerForm.department.label`))}
                            placeholder={t(`practitionerProfile.department`)}
                            warn={!!errors['department']}
                            warnText={
                              <ErrorBox>
                                {t('fieldRequired', {
                                  fieldLabel: t('createEditPractitionerForm.department.label'),
                                })}
                              </ErrorBox>
                            }
                          />
                        )}
                      />
                      <PermissionsGate roles={[Role.ADMIN]}>
                        <Controller
                          name="role"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <MultiSelect
                              onChange={(onChangeData) => onChange(onChangeData.selectedItems)}
                              id="practitionerProfileRole"
                              items={
                                staticDataObj.roleStaticData?.map(
                                  (role: { id: number; title: string; description: string }) => {
                                    return {
                                      id: role.id,
                                      title: role.title,
                                      description: role.description,
                                    };
                                  }
                                ) || []
                              }
                              itemToString={(item) => (item.title ? item.title : '')}
                              selectedItems={value}
                              label={
                                value?.length !== 0
                                  ? value?.map((v) => ` ${v.title}`)
                                  : t(`createEditPractitionerForm.userRole.placeholder`)
                              }
                              titleText={t(`createEditPractitionerForm.userRole.label`)}
                            />
                          )}
                        />
                      </PermissionsGate>
                      <Controller
                        name="peopleManager"
                        rules={{
                          required: watchIsCic,
                          validate: (value) => !!value?.id,
                        }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ComboboxWithCustomFilter
                            customClassName="custom-filter-combobox-update-practitioner-pem"
                            onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                            selectedItem={value ?? { id: null, name: '' }}
                            items={
                              staticDataObj.pemInfoStaticData?.map(
                                (peopleManager: { id: number; firstName: string; lastName: string }) => {
                                  return {
                                    name: `${peopleManager.firstName} ${peopleManager.lastName}`,
                                    id: peopleManager.id,
                                  };
                                }
                              ) || []
                            }
                            itemKeyName="name"
                            titleText={LabelWithAsterisk(t(`createEditPractitionerForm.peopleManager.label`))}
                            placeholder={t(`createEditPractitionerForm.peopleManager.placeholder`)}
                            warn={!!errors['peopleManager']}
                            warnText={
                              <ErrorBox>
                                {t('fieldRequired', {
                                  fieldLabel: t('createEditPractitionerForm.peopleManager.label'),
                                })}
                              </ErrorBox>
                            }
                          />
                        )}
                      />
                      <Controller
                        name="hiringDate"
                        control={control}
                        rules={{
                          required: watchIsCic,
                          validate: (value) => value?.toString() !== DateValidation.InvalidDate,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            light
                            onChange={(_dates: Date[], _currentDateString: string) =>
                              onChange(new Date(_currentDateString).getTime())
                            }
                            datePickerType="single"
                            value={practitionersInfo?.hiringDate ? value : ''}
                            className="modalDateInput hiring-date"
                            dateFormat={t('format.date_4carbonDatePicker')}
                          >
                            <DatePickerInput
                              id="practitionerProfileHiringDate"
                              labelText={LabelWithAsterisk(t(`createEditPractitionerForm.hiringDate.label`))}
                              placeholder={t(`createEditPractitionerForm.hiringDate.placeholder`)}
                              autoComplete="off"
                              warn={!!errors['hiringDate']}
                              warnText={
                                <ErrorBox>
                                  {t('fieldRequired', {
                                    fieldLabel: t('createEditPractitionerForm.hiringDate.label'),
                                  })}
                                </ErrorBox>
                              }
                              pattern={t('format.date_4carbonDatePickerInput')}
                            />
                          </DatePicker>
                        )}
                      />
                      <Controller
                        name="assignmentEndDate"
                        control={control}
                        render={() =>
                          practitionersInfo?.assignmentEndDate ? (
                            <DatePicker
                              light
                              className="practitioner-detail-page-date-input"
                              datePickerType="single"
                              value={practitionersInfo?.assignmentEndDate}
                              dateFormat={t('format.date_4carbonDatePicker')}
                            >
                              <DatePickerInput
                                id="practitionerProfileAssignmentEndDate"
                                labelText={t(`createEditPractitionerForm.assignmentEndDate.label`)}
                                disabled
                                pattern={t('format.date_4carbonDatePickerInput')}
                              />
                            </DatePicker>
                          ) : (
                            <TextInput
                              id="practitionerProfileAssignmentEndDateInput"
                              labelText={t(`createEditPractitionerForm.assignmentEndDate.label`)}
                              placeholder={t(`createEditPractitionerForm.assignmentEndDate.noAssignmentMessage`)}
                              disabled
                            />
                          )
                        }
                      />
                      <Controller
                        name="terminationDate"
                        control={control}
                        rules={{
                          validate: (value) =>
                            isTerminationDateRequired(value) ||
                            t(`createEditPractitionerForm.terminationDate.emfStatusTerminated`),
                        }}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            light
                            className="modalDateInput"
                            id="practitionerProfileTerminationDate"
                            datePickerType="single"
                            onChange={(_dates: Date[], _currentDateString: string) =>
                              onChange(
                                _currentDateString ? new Date(_currentDateString).getTime() : DateValidation.InvalidDate
                              )
                            }
                            value={practitionersInfo?.terminationDate ? value : ''}
                            disable={[(date) => date <= (watchHiringDate || new Date())]}
                            dateFormat={t('format.date_4carbonDatePicker')}
                          >
                            <DatePickerInput
                              autoComplete="off"
                              id="createEditPractitionerTerminationDate"
                              labelText={
                                watchEmfStatus?.id === EmfStatus.Terminated
                                  ? LabelWithAsterisk(t(`createEditPractitionerForm.terminationDate.label`))
                                  : t(`createEditPractitionerForm.terminationDate.label`)
                              }
                              placeholder={t(`createEditPractitionerForm.terminationDate.placeholder`)}
                              pattern={t('format.date_4carbonDatePickerInput')}
                              warn={!!errors['terminationDate']}
                              warnText={<ErrorBox>{errors.terminationDate?.message}</ErrorBox>}
                            />
                          </DatePicker>
                        )}
                      />
                    </>
                  )}
                  <div className="practitioner-detail-page-checkboxes">
                    <Controller
                      name="isCic"
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Checkbox
                          onChange={onChange}
                          onBlur={onBlur}
                          checked={value}
                          id="practitionerProfileIsCic"
                          labelText={t(`practitionerProfile.isCic`)}
                        />
                      )}
                    />
                    {watchIsCic && (
                      <Controller
                        name="isPMO"
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onChange={onChange}
                            onBlur={onBlur}
                            checked={value}
                            id="practitionerProfileIsPMO"
                            labelText={t(`practitionerProfile.isPmo`)}
                          />
                        )}
                      />
                    )}
                    {watchIsCic && (
                      <Controller
                        name="isPeM"
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onChange={onChange}
                            onBlur={onBlur}
                            checked={value}
                            id="practitionerProfileIsPeM"
                            labelText={t(`practitionerProfile.isPem`)}
                          />
                        )}
                      />
                    )}
                    {watchIsCic && (
                      <Controller
                        name="isServiceLineLeader"
                        control={control}
                        rules={{
                          validate: {
                            leaderCantBeCFH: (value) =>
                              (value && watchEmfStatus?.id !== EmfStatus.CandidateForHiring) ||
                              !value ||
                              t('createEditPractitionerForm.serviceLineLeader.cfhError'),
                            checkIfOtherIsLeader: (value) => {
                              const validationAndErrorMsg = checkIfOtherPersonIsServiceLineLeader();
                              return (
                                (value && validationAndErrorMsg.validation) ||
                                !value ||
                                validationAndErrorMsg.errorMessage
                              );
                            },
                          },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <Checkbox
                              onChange={onChange}
                              onBlur={onBlur}
                              checked={value}
                              id="practitionerProfileIsServiceLineLeader"
                              labelText={t(`practitionerProfile.isServiceLineLeader`)}
                            />
                            {methods.formState.errors['isServiceLineLeader'] && (
                              <ErrorBox>
                                {methods.formState.errors['isServiceLineLeader']?.message + ' '}
                                <Link
                                  onClick={navigateToServiceLineLeader}
                                  className="delete-modal-error-message service-line-leader-nav"
                                >
                                  {serviceLineLeaderError}
                                </Link>
                              </ErrorBox>
                            )}
                          </>
                        )}
                      />
                    )}
                  </div>
                  {!watchIsCic && psAdderComponent}
                </div>
                {watchIsCic && (
                  <div className="practitioner-detail-page-additional-info-grid">
                    <Controller
                      name="costRate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          onChange={onChange}
                          id="practitionerProfileCostRate"
                          labelText={t(`createEditPractitionerForm.costRate.label`)}
                          placeholder={t(`createEditPractitionerForm.costRate.placeholder`)}
                          value={getPractitionerRate()}
                          disabled={true}
                        />
                      )}
                    />
                    {psAdderComponent}
                    <Controller
                      name="serviceArea"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          className="practitioner-profile-serviceArea"
                          value={getServiceArea()}
                          onChange={onChange}
                          id="practitionerProfileServiceArea"
                          labelText={t(`createEditPractitionerForm.serviceArea.label`)}
                          placeholder={t(`createEditPractitionerForm.serviceArea.placeholder`)}
                          disabled={true}
                          title={getServiceArea()}
                        />
                      )}
                    />
                  </div>
                )}
                <div className="practitioner-detail-page-notes">
                  <Controller
                    name="notes"
                    control={methods.control}
                    rules={{
                      maxLength: {
                        value: 5000,
                        message: t('maxLengthMessage', { maxLength: 5000 }),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextArea
                        light
                        onChange={onChange}
                        id="notes"
                        labelText={t('createEditPractitionerForm.notes.label')}
                        placeholder={t('createEditPractitionerForm.notes.placeholder')}
                        value={value}
                        invalid={!!errors?.['notes']}
                        invalidText={errors?.['notes']?.message}
                        rows={3}
                      />
                    )}
                  />
                </div>
                <div className="practitioner-detail-page-button-container">
                  <div className="practitioner-details-submit-section">
                    <div className="practitioner-details-submit-button-wrap">
                      <Button
                        kind="secondary"
                        onClick={() => {
                          isDirty ? reset(defaultValues) : nav('/practitioners');
                        }}
                      >
                        {isDirty ? t('editProject.reset') : t('editProject.cancel')}
                      </Button>
                    </div>
                    <div className="practitioner-details-submit-button-wrap ">
                      <Button
                        type="submit"
                        onClick={handleSubmit((data) => handleSubmitForm(data))}
                        kind="primary"
                        disabled={!isDirty || isSubmitting || isSubmitSuccessful}
                      >
                        {t('editProject.save')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default PractitionerDetailPageCompNotCic;
