import {
  addAllAssignmentsForExtension,
  addAssignmentForExtension,
  getProjectAssignments,
  openOrCloseExtensionModal,
  removeAllAssignmentsFromExtension,
  removeAssignmentFromExtension,
  resetFilterProjectAssignmentsString,
  setFilterProjectAssignmentsString,
  getLastAssignmentEndDate,
  getLastAssignmentEndDateMulti
} from '../data-access/project/slice';
import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';
import { SelectedAssignmentForExtension } from '../interfaces/project-interfaces';

const useProjectAssignments = () => {
  const dispatch = useAppDispatch();
  
  const projectAssignments = useAppSelector((state) => state.projects.projectAssignmentsData);
  const isAssignmentExtensionModalOpen = useAppSelector((state) => state.projects.assignmentExtensionModalIsOpen);
  const projectAssignmentsFilterString = useAppSelector((state) => state.projects.filterProjectAssignmentsString);
  const assignmentExtensionArray = useAppSelector((state) => state.projects.selectedAssignmentsForExtension);
  const lastAssignmentEndDate = useAppSelector((state) => state.projects.lastAssignmentEndDate);
  const lastAssignmentEndDateMulti = useAppSelector((state) => state.projects.lastAssignmentEndDateMulti);
  
  const isLoadingLastAssignmentEndDate = useAppSelector((state) => state.projects.isLoadingLastAssignmentEndDate);
  const isLoadingLastAssignmentEndDateMulti = useAppSelector((state) => state.projects.isLoadingLastAssignmentEndDateMulti);
  const isLoadingProjectAssignments = useAppSelector((state) => state.projects.isLoadingProjectAssignments);

  const getProjectAssignmentsData = (id: string) => {
    dispatch(getProjectAssignments(id));
  };

  const getAssignmentEndDate = (id: string) => {
    dispatch(getLastAssignmentEndDate(id))
  }

  const getMultiLastAssignmentEndDate = (ids: string[]) => {
    dispatch(getLastAssignmentEndDateMulti(ids))
  }

  const addAssignmentToExtensionArray = (data: SelectedAssignmentForExtension) => {
    dispatch(addAssignmentForExtension(data));
  };

  const addAllAssignmentsToExtensionArray = (data: SelectedAssignmentForExtension[]) => {
    dispatch(addAllAssignmentsForExtension(data));
  };

  const removeAssignmentFromExtensionArray = (data: string) => {
    dispatch(removeAssignmentFromExtension(data));
  };

  const removeAllAssignmentsFromExtensionArray = () => {
    dispatch(removeAllAssignmentsFromExtension());
  };

  const openCloseAssignmentExtensionModal = () => {
    dispatch(openOrCloseExtensionModal());
  };

  const setProjectAssignmentsFilterString = (filterString: string) => {
    dispatch(setFilterProjectAssignmentsString(filterString));
  };

  const resetProjectAssignmentsFilterString = () => {
    dispatch(resetFilterProjectAssignmentsString());
  };

  return {
    projectAssignments,
    isLoadingProjectAssignments,
    getProjectAssignmentsData,
    addAssignmentToExtensionArray,
    assignmentExtensionArray,
    removeAssignmentFromExtensionArray,
    addAllAssignmentsToExtensionArray,
    removeAllAssignmentsFromExtensionArray,
    openCloseAssignmentExtensionModal,
    isAssignmentExtensionModalOpen,
    projectAssignmentsFilterString,
    setProjectAssignmentsFilterString,
    resetProjectAssignmentsFilterString,
    getAssignmentEndDate,
    isLoadingLastAssignmentEndDate,
    lastAssignmentEndDate,
    isLoadingLastAssignmentEndDateMulti,
    lastAssignmentEndDateMulti,
    getMultiLastAssignmentEndDate,

  };
};

export default useProjectAssignments;
