import { remove as replaceSpecialCharacters } from 'remove-accents';

export function replaceLithuaniaLetters(s: string): string {
  const translate = {
    ą: 'a',
    Ą: 'A',
    č: 'c',
    Č: 'C',
    ė: 'e',
    Ė: 'E',
    Ę: 'E',
    ę: 'e',
    Į: 'I',
    į: 'i',
    Š: 'S',
    š: 's',
    Ų: 'U',
    ų: 'u',
    Ū: 'U',
    ū: 'u',
    Ž: 'Z',
    ž: 'z',
  };
  const translate_re = /[ąĄčČęĘėĖįĮšŠųŲūŪžŽ]/g;
  return s.replace(translate_re, function (match: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return translate[match];
  });
}

export const generatePractitionerTag = (nameFirstLast: {
  firstName: string;
  lastName: string;
}) => {
  const practitionerTag =
    nameFirstLast.firstName.trim().toLowerCase() +
    ' ' +
    nameFirstLast.lastName.trim().toLowerCase();

  return replaceSpecialCharacters(practitionerTag);
};

export const replaceSpecialCharactersCallback = (value: string) => {
  return replaceSpecialCharacters(value);
};

export const orderDataByASCII = <T>(value: T[], keys: (keyof T)[]) => {
  return value.sort((a, b) => {
    for (const key of keys) {
      const valueA =
        typeof a[key] === 'string' || typeof a[key] === 'number'
          ? replaceSpecialCharacters(String(a[key]).toLowerCase())
          : a[key];
      const valueB =
        typeof b[key] === 'string' || typeof b[key] === 'number'
          ? replaceSpecialCharacters(String(b[key]).toLowerCase())
          : b[key];
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
    }
    return 0;
  });
};
