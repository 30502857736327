import { useEffect, useState } from 'react';
import { PaginationNav } from 'carbon-components-react';
import { useParams } from 'react-router-dom';
import { useAuditLogs } from '../../custom-hooks';
import { AuditEntityType } from '@cic-boardlite/common';

interface Props {
  totalItems: number;
  pageViewType: AuditEntityType;
  itemsPerPage: number;
  filterString: string;
}

const Pagination = ({
  totalItems,
  pageViewType,
  itemsPerPage,
  filterString,
}: Props) => {
  const { id } = useParams<{ id: string }>();
  const [currentPage, setCurrentPage] = useState(0);
  const { getAuditLogs } = useAuditLogs();

  const handlePageChange = (page: number) => {
    getAuditLogs({
      id: String(id),
      auditableEntityType: pageViewType,
      page: page + 1,
      itemsPerPage: itemsPerPage,
      filterString: filterString,
    });
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [filterString]);

  return (
    <PaginationNav
      page={currentPage}
      totalItems={Math.ceil(totalItems / itemsPerPage)}
      itemsShown={itemsPerPage}
      onChange={handlePageChange}
    />
  );
};

export default Pagination;
