import { emptyBluePagesPractitioner } from '@cic-boardlite/common';
import { createSlice } from '@reduxjs/toolkit';
import { BluePagesStateInterface } from '../../interfaces/bluepages-interfaces';

export const initialState: BluePagesStateInterface = {
  bluePagesPractitionersData: [],
  isLoadingBluePagesPractitioners: false,
  pemBluePages: emptyBluePagesPractitioner,
  isPemBluePagesLoading: false,
  geoPmBluePages: emptyBluePagesPractitioner,
  isGeoPmBluePagesLoading: false,
  preferredIdentityEmailBluePages: undefined,
  isPreferredIdentityEmailBluePagesLoading: false,
  isBlueServicesOnline: false,
  isLoadingBlueServices: false,
  isCvBuilderOnline: false,
  isLoadingCvBuilder: false,
  externalServicesInterval: undefined,
  selectedBluePagesPractitioner: undefined,
  isSelectingBluePagesPractitioner: false,
};

const bluePagesSlice = createSlice({
  name: 'bluepages',
  initialState,
  reducers: {
    getBluePagesPractitionersBySerial(state, action) {
      state.isLoadingBluePagesPractitioners = true;
      state.bluePagesPractitionersData = [];
    },
    getBluePagesPractitionersByCountrySuccess(state, action) {
      state.bluePagesPractitionersData = action.payload;
      state.isLoadingBluePagesPractitioners = false;
    },
    getBluePagesPractitionersByCountryFailed(state) {
      state.isLoadingBluePagesPractitioners = false;
    },
    setSelectedBluePagesPractitioner(state, action) {
      state.isSelectingBluePagesPractitioner = true;
    },
    setSelectedBluePagesPractitionerSuccess(state, action) {
      state.selectedBluePagesPractitioner = action.payload;
      state.isSelectingBluePagesPractitioner = false;
    },
    setSelectedBluePagesPractitionerFailed(state) {
      state.isSelectingBluePagesPractitioner = false;
    },
    getPemBluePages(state, action) {
      state.isPemBluePagesLoading = true;
    },
    getPemBluePagesSuccess(state, action) {
      state.pemBluePages = action.payload;
      state.isPemBluePagesLoading = false;
    },
    getPemBluePagesFailed(state) {
      state.isPemBluePagesLoading = false;
    },
    getGeoPmBluePages(state, action) {
      state.isGeoPmBluePagesLoading = true;
    },
    getGeoPmBluePagesSuccess(state, action) {
      state.geoPmBluePages = action.payload;
      state.isGeoPmBluePagesLoading = false;
    },
    getGeoPmBluePagesFailed(state) {
      state.isGeoPmBluePagesLoading = false;
    },
    getPreferredIdentityEmailBluePages(state, action) {
      state.isPreferredIdentityEmailBluePagesLoading = true;
    },
    getPreferredIdentityEmailBluePagesSuccess(state, action) {
      state.preferredIdentityEmailBluePages = action.payload;
      state.isPreferredIdentityEmailBluePagesLoading = false;
    },
    getPreferredIdentityEmailBluePagesFailed(state) {
      state.isPreferredIdentityEmailBluePagesLoading = false;
    },

    pingBlueServices(state) {
      state.isLoadingBlueServices = true;
    },
    pingBlueServicesSuccess(state) {
      state.isBlueServicesOnline = true;
      state.isLoadingBlueServices = false;
    },
    pingBlueServicesFailed(state) {
      state.isBlueServicesOnline = false;
      state.isLoadingBlueServices = false;
    },
    pingCvBuilder(state) {
      state.isLoadingCvBuilder = true;
    },
    pingCvBuilderSuccess(state) {
      state.isCvBuilderOnline = true;
      state.isLoadingCvBuilder = false;
    },
    pingCvBuilderFailed(state) {
      state.isCvBuilderOnline = false;
      state.isLoadingCvBuilder = false;
    },
    setExternalServicesInterval(state, action) {
      state.externalServicesInterval = action.payload;
    },
  },
});

export const {
  getBluePagesPractitionersBySerial,
  getBluePagesPractitionersByCountrySuccess,
  getBluePagesPractitionersByCountryFailed,
  getPemBluePages,
  getPemBluePagesSuccess,
  getPemBluePagesFailed,
  getGeoPmBluePages,
  getGeoPmBluePagesSuccess,
  getGeoPmBluePagesFailed,
  getPreferredIdentityEmailBluePages,
  getPreferredIdentityEmailBluePagesSuccess,
  getPreferredIdentityEmailBluePagesFailed,
  pingBlueServices,
  pingBlueServicesSuccess,
  pingBlueServicesFailed,
  pingCvBuilder,
  pingCvBuilderSuccess,
  pingCvBuilderFailed,
  setExternalServicesInterval,
  setSelectedBluePagesPractitioner,
  setSelectedBluePagesPractitionerSuccess,
  setSelectedBluePagesPractitionerFailed,
} = bluePagesSlice.actions;
export default bluePagesSlice.reducer;
