import { useEffect } from 'react';
import { usePractitionerDetails } from '../../../custom-hooks';
import PractitionerDetailPageComp from './detail-page-comp';
import { useParams } from 'react-router-dom';
import UpdateAvailabilityDateModal from '../../update-availability-date-modal/update-availability-date-modal';

const PractitionerDetailPage = () => {
  const { id } = useParams<{ id: string }>();

  const {
    practitionerDetails,
    getSelectedPractitionerDetails,
    isLoadingPractitionerDetails,
  } = usePractitionerDetails();

  useEffect(() => {
    if (id) getSelectedPractitionerDetails(id);
  }, [id]);

  return !isLoadingPractitionerDetails ? (
    <>
      <UpdateAvailabilityDateModal
        editingFromProfilePage={true}
        editingFromPractitionersTable={false}
      />
      <PractitionerDetailPageComp practitionersInfo={practitionerDetails} />
    </>
  ) : (
    <div />
  );
};

export default PractitionerDetailPage;
