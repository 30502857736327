import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCommonState, usePractitionerAssignments, usePractitionerDetails } from '../../../custom-hooks';
import { DeleteModalType, EmfStatus } from '@cic-boardlite/common';
import { Button } from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import PractitionerAssignmentPageComp from './assignment-page-comp';
import AssignmentCreateEdit from '../../assignment-create-edit/create-edit-assignment-action';
import { DeleteAction } from '../../../ui/common/delete-action/deleteAction';
import { useTranslation } from 'react-i18next';
import SendEmailDialog from '../../send-email-modal-dialog/send-email-dialog';
import './assignment-page.scss';

const PractitionerAssignmentPage = () => {
  const { t } = useTranslation('common');
  const { id } = useParams<{ id: string }>();
  const { getSelectedPractitionerDetails, isLoadingPractitionerDetails, practitionerDetails } =
    usePractitionerDetails();
  const { isLoadingSelectedPractitionersAssignments } = usePractitionerAssignments();

  const { toggleAssignmentCreateEditModal } = useCommonState();

  useEffect(() => {
    if (id && !isLoadingPractitionerDetails) {
      getSelectedPractitionerDetails(id);
    }
  }, []);

  function toggleAssignmentCreateEdit() {
    toggleAssignmentCreateEditModal({
      isOpen: true,
    });
  }

  const isPractitionerTerminated = (): boolean => {
    return practitionerDetails.terminationDate?.length 
      && new Date(practitionerDetails.terminationDate).getTime() <= new Date().getTime() 
      || practitionerDetails.emfStatusId === EmfStatus.Terminated;
  }

  return (
    <>
      <DeleteAction type={DeleteModalType.assignment} />
      <PractitionerAssignmentPageComp />
      {!isLoadingSelectedPractitionersAssignments && <AssignmentCreateEdit />}
      <SendEmailDialog projectsPage={false} />
      {!isLoadingPractitionerDetails && (
        <Button
          className="create-assignment-button"
          hasIconOnly
          renderIcon={Add16}
          id="createAssignmentButton"
          onClick={toggleAssignmentCreateEdit}
          iconDescription={t('createEditAssignment.iconDescription')}
          disabled={isPractitionerTerminated()}
          title={
            isPractitionerTerminated()
              ? t('createEditAssignment.createButtonDisabled')
              : t('createEditAssignment.createButton')
          }
        />
      )}
    </>
  );
};

export default PractitionerAssignmentPage;
