import { ObjectLiteral } from 'typeorm';

export interface DataModifyLogValues {
  actionType: number;
  entityType: number;
  modifiedBy?: number;
  modifiedOn?: string;
  beforeRaw?: ObjectLiteral;
  afterRaw?: ObjectLiteral;
  beforeReadable?: ObjectLiteral;
  afterReadable?: ObjectLiteral;
  practitionerId?: number;
  projectId?: number;
  assignmentId?: number;
}

export enum AuditActionType {
  Delete = 1,
  Insert = 2,
  Update = 3,
  Email = 4,
}

export enum AuditEntityType {
  Practitioner = 1,
  Project = 2,
  Assignment = 3,
}
