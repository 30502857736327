import { request } from './request-TS';

const headerOptions = {
  Accept: 'application/json',
  'Access-Control-Allow-Headers': 'Content-Type',
};
const requestOptions = {
  headers: new Headers({
    ...headerOptions,
    'Content-Type': 'application/json',
  }),
  withCredentials: true,
};

export const get = <T>(path: string): Promise<T> => {
  return request(path, {
    method: 'GET',
    ...requestOptions,
  });
};

export const post = <T>(path: string, data: any): Promise<T> => {
  return request(path, {
    body: JSON.stringify(data),
    method: 'POST',
    ...requestOptions,
  });
};

export const put = <T>(path: string, data: any): Promise<T> => {
  return request(path, {
    body: JSON.stringify(data),
    method: 'PUT',
    ...requestOptions,
  });
};

export const deleteMethod = <T>(path: string, data = {}): Promise<T> => {
  return request(path, {
    body: JSON.stringify(data),
    method: 'DELETE',
    ...requestOptions,
  });
};

export const postFormData = <T>(path: string, data: object): Promise<T> => {
  return request(path, {
    body: data,
    method: 'POST',
    headers: new Headers({ ...headerOptions }),
  });
};
