import { HeadersInterface } from '@cic-boardlite/common';

export const headers: HeadersInterface[] = [
  {
    key: 'projectId',
    header: '',
  },
  {
    key: 'projectName',
    header: '',
  },
  {
    key: 'clientName',
    header: '',
  },
  {
    key: 'allocation',
    header: '',
  },
  {
    key: 'startDate',
    header: '',
  },
  {
    key: 'endDate',
    header: '',
  },
  {
    key: 'pmpSeat',
    header: '',
  },
  {
    key: 'pmo',
    header: '',
  },
  {
    key: 'assignmentStatus',
    header: '',
  },
];
