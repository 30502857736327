import {
  multiChangeFailed,
  multiChangeSuccess,
  triggerMultiChange,
} from './slice';
import {
  call,
  CallEffect,
  put as putEffect,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import { post } from '../state-configurations/base-api';

import {
  AuditActionType,
  AuditEntityType,
  MultiChangeAction,
  MultiChangeInterface,
  MultiChangeResponse,
  PractitionerTargetField,
} from '@cic-boardlite/common';
import {
  blueServicesConfig,
  domain,
  multiChangeConfig,
  practitionersFilterConfig,
} from '../../../config/config';
import {
  clearAllSelectedPractitioners,
  getActionRequiredPractitionersNumber,
  getFilteredPractitioners,
} from '../practitioner/slice';
import { addNotification } from '../notifications/slice';
import { setAuditLogData } from '../audit-logs/slice';
import i18next from 'i18next';
import common_en from '../../../assets/locales/en/translation.json';
import {
  clearSelectedProjectsArray,
  getFilteredProjects,
  getProjectAssignments,
} from '../project/slice';
export function* handleTriggerMultiChange(action: {
  type: string;
  payload: MultiChangeInterface;
}): Generator<
  CallEffect<MultiChangeResponse> | PutEffect,
  void,
  MultiChangeResponse
> {
  const { multiChange } = multiChangeConfig;
  const path = multiChange;

  try {
    const multiChangeResponse = yield call(() =>
      post<MultiChangeResponse>(path, action?.payload)
    );

    const decodedUrl = decodeURIComponent(window.location.href);

    if (multiChangeResponse) {
      if (
        action.payload.data.multiChangeAction === MultiChangeAction.practitioner
      ) {
        const practitionersFilter = decodedUrl.split('practitioners?')[1];
        yield putEffect({
          type: clearAllSelectedPractitioners.type,
        });
        yield putEffect({
          type: getFilteredPractitioners.type,
          payload: !practitionersFilter
            ? practitionersFilterConfig.getPractitionersFilter
            : `${domain}/practitioners/filter?${practitionersFilter}`,
        });
        if (action.payload.data.targetField === PractitionerTargetField.jrs)
          yield putEffect({
            type: getActionRequiredPractitionersNumber.type,
          });
      }

      if (action.payload.data.multiChangeAction === MultiChangeAction.project) {
        const projectsFilter = decodedUrl.split('projects')[1];
        yield putEffect({
          type: clearSelectedProjectsArray.type,
        });
        yield putEffect({
          type: getFilteredProjects.type,
          payload: projectsFilter,
        });
      }

      if (
        action.payload.data.multiChangeAction === MultiChangeAction.assignment
      ) {
        const projectId = decodedUrl.split('projects/')[1].split('/')[0];
        yield putEffect({
          type: getProjectAssignments.type,
          payload: projectId,
        });

        if (action.payload?.data.sendEmail && multiChangeResponse) {
          const { sendEmail } = blueServicesConfig;

          try {
            yield call(() =>
              post<MultiChangeResponse>(
                sendEmail,
                multiChangeResponse.emailData
              )
            );

            yield putEffect(
              addNotification({
                kind: 'success',
                title: i18next.t(
                  common_en.createEditAssignment.wmpEmailSentSuccess
                ),
              })
            );

            yield putEffect(
              setAuditLogData({
                actionType: AuditActionType.Email,
                entityType: AuditEntityType.Assignment,
                projectId: Number(projectId),
                afterReadable: {
                  emailTo: multiChangeResponse.emailData.emailContent.emailTo,
                  emailCc: multiChangeResponse.emailData.emailContent.emailCc,
                  emailType: i18next.t(
                    common_en.extraEmailTemplates.assignmentUpdate
                  ),
                },
              })
            );
          } catch (e) {
            yield putEffect(
              addNotification({
                kind: 'error',
                title: i18next.t(
                  common_en.createEditAssignment.wmpEmailSentFailed
                ),
              })
            );
          }
        }
      }

      yield putEffect(multiChangeSuccess());
      if (multiChangeResponse.availabilityManuallyEntered) {
        yield putEffect(
          addNotification({
            kind: 'warning',
            title: i18next.t(common_en.multiChange.availabilityAnnouncement, {
              handEntered: multiChangeResponse.availabilityManuallyEntered,
              total: action.payload.data.ids?.length,
              timeOut: 5000,
            }),
          })
        );
      } else if (!action.payload?.notShowSuccessNotification) {
        yield putEffect(
          addNotification({
            kind: 'success',
            title: i18next.t(common_en.multiChange.success),
          })
        );
      }
    }
  } catch (error: any) {
    yield putEffect(multiChangeFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error
          ? error.error
          : i18next.t(common_en.multiChange.error),
      })
    );
  }
}

export function* multiChangeSaga() {
  yield takeLatest(triggerMultiChange.type, handleTriggerMultiChange);
}
