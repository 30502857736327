export class EmailValidator {
  static isEmail(email: string): boolean {
    const regex = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(String(email).toLowerCase());
  }

  static toEmailsArray = (emailsString: string) => {
    const emailsArray: string[] = emailsString
      .split(/[\r?\n|\r| |;|,]+/g)
      .filter((email) => email.length);

    return emailsArray;
  };
}

export const formatCcEmailsArray = (ccEmails?: string[]) => {
  return ccEmails
    ? ccEmails.map((email) => email.split(' ').pop() || email)
    : [];
};
