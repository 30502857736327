import {
  createProject,
  createProjectReset,
} from '../data-access/project/slice';
import {
  useAppDispatch,
  useAppSelector,
} from '../data-access/state-configurations/hooks';
import { ProjectCreateEditPayloadInterface } from '@cic-boardlite/common';

const useCreateProject = () => {
  const dispatch = useAppDispatch();

  const buildProject = (details: ProjectCreateEditPayloadInterface) => {
    dispatch(createProject(details));
  };

  const isCreatingProject = useAppSelector(
    (state) => state.projects.isCreatingProject
  );

  const wasProjectCreated = useAppSelector(
    (state) => state.projects.wasProjectCreated
  );

  const resetProjectCreated = () => {
    dispatch(createProjectReset());
  };

  return {
    buildProject,
    isCreatingProject,
    wasProjectCreated,
    resetProjectCreated,
  };
};

export default useCreateProject;
