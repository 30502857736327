import React, { useEffect, useRef, useState, MouseEvent, ChangeEvent } from 'react';
import { Tabs, Tab, TabsSkeleton, Button, TextInput } from 'carbon-components-react';
import { Add, Close, Save } from '@carbon/icons-react/next';
import {
  useCommonState,
  useFilteredPractitioners,
  usePractitioners,
  useFilterTabs,
  useFilteredProjects,
} from '../../../custom-hooks';
import { useTranslation } from 'react-i18next';
import {
  filterObjectToUrlParams,
  PageType,
  FilterTabData,
  setUrlParamsString,
  LocalStorageName,
} from '@cic-boardlite/common';
import { togglePractitionersSideBarVisibility } from '../../../data-access/common-states/slice';
import { useAppDispatch } from '../../../data-access/state-configurations/hooks';
import { initialState } from '../../../data-access/filter-tabs/slice';
import { practitionersFilterConfig } from '../../../../config/config';
import './filter-tabs.scss';

const PractitionerFilterTabs = ({ pageType }: { pageType: number }) => {
  const {
    isLoadingFilterTabs,
    practitionerFilterTabsData,
    projectFilterTabsData,
    activePractitionerFilterTab,
    activeProjectFilterTab,
    setCurrentFilterTab,
    isCreatingFilterTab,
    isDeletingFilterTab,
    setIsCreatingFilterTab,
    setNewFilterTab,
    setDeleteFilterTab,
    getFilterTabs,
    setTabUnsavedContext,
    createTabForNewUser,
  } = useFilterTabs();
  const { filteredPractitionersData, isLoadingFilteredPractitioners, setPractitionerFilterCreatedUrl } =
    useFilteredPractitioners();
  const { filteredProjectsData, isLoadingFilteredProjects, actionRequiredProjectsNumber } = useFilteredProjects();
  const { actionRequiredPractitionersNumber } = usePractitioners();
  const isFilterCopyingEnabled = false;
  const { isPractitionersSideBarVisible } = useCommonState();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  let filterTitleRef = useRef<HTMLInputElement>(null);
  const [randomTabsKey, setRandomTabsKey] = useState<number>(0);

  useEffect(() => {
    getFilterTabs(pageType);
  }, [pageType]);

  const filterTabsData = pageType === PageType.Practitioner ? practitionerFilterTabsData : projectFilterTabsData;
  const localStorageName =
    pageType === PageType.Practitioner ? LocalStorageName.PractitionerFilterTab : LocalStorageName.ProjectFilterTab;
  const isLoadingItems =
    pageType === PageType.Practitioner ? isLoadingFilteredPractitioners : isLoadingFilteredProjects;
  const filteredItemsNumber =
    pageType === PageType.Practitioner
      ? filteredPractitionersData.numberOfFilteredPractitioners
      : filteredProjectsData.length;
  const isCreatingFilterState =
    isCreatingFilterTab[pageType === PageType.Practitioner ? 'practitionerTab' : 'projectTab'];
  const actionRequiredItemsNumber =
    pageType === PageType.Practitioner ? actionRequiredPractitionersNumber : actionRequiredProjectsNumber;
  const activeFilterTab = pageType === PageType.Practitioner ? activePractitionerFilterTab : activeProjectFilterTab;
  const activeFilterTabStateType =
    pageType === PageType.Practitioner ? 'activePractitionerFilterTab' : 'activeProjectFilterTab';
  const [previousActiveFilter, setPreviousActiveFilter] = useState(initialState[activeFilterTabStateType]);

  useEffect(() => {
    if (filterTabsData.length > 1) return;

    createTabForNewUser(pageType, filterTabsData.length);
  }, [pageType, filterTabsData.length]);

  useEffect(() => {
    if (isLoadingFilterTabs || isLoadingItems) return;

    if (
      !filterTabsData.length ||
      isDeletingFilterTab ||
      activeFilterTab.unsavedContext ||
      filteredItemsNumber === undefined ||
      filteredItemsNumber === null ||
      activeFilterTab.numberOfFilteredPractitioners === undefined
    )
      return;

    const filter = filterTabsData.find((filter) => filter.id === activeFilterTab.id);

    if (filter && filteredItemsNumber !== filter.numberOfFilteredPractitioners) {
      setNewFilterTab(
        {
          ...filter,
          numberOfFilteredPractitioners: filteredItemsNumber,
        },
        true,
        true
      );
    }
  }, [isLoadingFilterTabs, isLoadingItems]);

  useEffect(() => {
    if (!filterTabsData.length || isLoadingFilterTabs || isLoadingItems || isDeletingFilterTab) return;

    const activeFilterTabPosition = localStorage.getItem(localStorageName);

    if (isCreatingFilterState) {
      if (activeFilterTab.unsavedContext) {
        onAddFilterTabClick(activeFilterTab?.unsavedContext);
        setUrlParamsString(filterObjectToUrlParams(activeFilterTab?.unsavedContext));
      }
    } else if (activeFilterTabPosition && filterTabsData[+activeFilterTabPosition]) {
      setCurrentFilterTab({
        ...filterTabsData[+activeFilterTabPosition],
        numberOfFilteredPractitioners: filteredItemsNumber,
      });
    } else if (filterTabsData[1]) {
      setCurrentFilterTab(filterTabsData[1]);
    } else if (actionRequiredItemsNumber) {
      setCurrentFilterTab(filterTabsData[0]);
    } else {
      setCurrentFilterTab(initialState[activeFilterTabStateType]);
      setPractitionerFilterCreatedUrl(practitionersFilterConfig.getPractitionersFilter);
    }
  }, [isLoadingFilterTabs]);

  useEffect(() => {
    if (isCreatingFilterState || isLoadingFilterTabs) return;

    if (activeFilterTab.id === initialState[activeFilterTabStateType].id) {
      if (filterTabsData.length === 1) {
        if (actionRequiredItemsNumber) {
          setPreviousActiveFilter(filterTabsData[0]);
        }
      } else if (filterTabsData[1]) {
        setPreviousActiveFilter(filterTabsData[1]);
      }
    } else {
      setPreviousActiveFilter(activeFilterTab);
    }
  }, [activeFilterTab]);

  useEffect(() => {
    if (isCreatingFilterState || activeFilterTab.unsavedContext) return;

    setRandomTabsKey(Math.random());
  }, [isCreatingFilterState, activeFilterTab.unsavedContext]);

  const saveFilter = () => {
    const filterData: FilterTabData = {
      id: activeFilterTab.id,
      context: activeFilterTab.unsavedContext || activeFilterTab.context,
      title: '',
      tabPosition: filterTabsData.length + 1,
      numberOfFilteredPractitioners: 0,
      pageType: -1,
    };

    if (isCreatingFilterState) {
      filterData.title = filterTitleRef?.current?.value || `${t('filters.filter')} ${filterTabsData.length}`;
      filterData.tabPosition = filterTabsData.length;
      filterData.numberOfFilteredPractitioners = !filterTabsData.length ? filteredItemsNumber : 0;
      filterData.pageType = pageType;
    } else {
      filterData.title =
        filterTitleRef?.current?.value ||
        activeFilterTab.title ||
        `${t('filters.filter')} ${activeFilterTab.tabPosition}`;
      filterData.tabPosition = activeFilterTab.tabPosition;
      filterData.numberOfFilteredPractitioners = previousActiveFilter.numberOfFilteredPractitioners;
      filterData.pageType = pageType;
    }

    setUrlParamsString(filterObjectToUrlParams(filterData.context));
    resetFilterChangeStates();
    setNewFilterTab(filterData, !isCreatingFilterState, false);

    localStorage.setItem(localStorageName, filterData.tabPosition.toString());
  };

  const setFilterAsActive = (filter: FilterTabData) => {
    if (isDeletingFilterTab || filter.id === activeFilterTab.id) return;

    let filterData: FilterTabData = { ...filter };

    if (filter.id) {
      filterData = {
        ...filter,
        tabPosition: filter.tabPosition,
        title: filterTitleRef?.current?.value || filter.title,
        pageType: pageType,
      };
    }

    if (filterTitleRef.current) {
      filterTitleRef.current.value = filter.title;
    }

    setCurrentFilterTab({ ...filterData });
    setUrlParamsString(filterObjectToUrlParams(filter.unsavedContext || filter.context));

    localStorage.setItem(localStorageName, filterData.tabPosition.toString());
  };

  const resetFilterChangeStates = () => {
    setIsCreatingFilterTab({
      practitionerTab: pageType === PageType.Practitioner ? false : isCreatingFilterTab.practitionerTab,
      projectTab: pageType === PageType.Project ? false : isCreatingFilterTab.projectTab,
    });
  };

  const onAddFilterTabClick = (unsavedContext?: FilterTabData | { [key: string]: any }) => {
    if (!isPractitionersSideBarVisible) {
      dispatch(togglePractitionersSideBarVisibility());
    }

    setUrlParamsString('');
    setIsCreatingFilterTab({
      practitionerTab: pageType === PageType.Practitioner ? true : isCreatingFilterTab.practitionerTab,
      projectTab: pageType === PageType.Project ? true : isCreatingFilterTab.projectTab,
    });
    setTabUnsavedContext(-1, unsavedContext, pageType);

    if (filterTitleRef?.current?.value) filterTitleRef.current.value = '';
  };

  const onDeleteFilterTabClick = (filter: FilterTabData) => {
    if (activeFilterTab.id === filter.id) {
      localStorage.removeItem(localStorageName);
    }

    setDeleteFilterTab({ id: filter.id, pageType });

    if (filterTitleRef.current) filterTitleRef.current.value = '';
  };

  const getCorrectFilterDefaultTitle = (filterId: number) => {
    return isCreatingFilterState && filterId === initialState[activeFilterTabStateType].id
      ? ''
      : filterTabsData.find((filter) => filter.id === filterId)?.title;
  };

  const filterTitleInputKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') saveFilter();
    if (event.key === 'Escape') {
      if (isCreatingFilterState) {
        if (previousActiveFilter.id === initialState[activeFilterTabStateType].id && filterTabsData.length >= 1) {
          setFilterAsActive(filterTabsData[filterTabsData.length - 1]);
        } else {
          setFilterAsActive(previousActiveFilter);
        }
      } else if (activeFilterTab.tabPosition !== 0) {
        setTabUnsavedContext(activeFilterTab.id, undefined, pageType);
      } else if (actionRequiredItemsNumber) {
        setCurrentFilterTab({
          ...filterTabsData[0],
        });
      }

      resetFilterChangeStates();
    }
  };

  const handleTabSelection = (): number => {
    if (filterTabsData.length > 1) {
      return activeFilterTab.tabPosition;
    }

    return actionRequiredItemsNumber > 0 ? 0 : 1;
  };

  const isSelectedTabNotActionRequired = (filterId: number): boolean => {
    return activeFilterTab.tabPosition !== 0 && filterId === 0;
  };

  const isActionRequiredTabNotDisplayed = (
    filterId: number,
    numberOfFilteredPractitioners: number | undefined
  ): boolean => {
    return filterId === 0 && !numberOfFilteredPractitioners && actionRequiredItemsNumber === 0;
  };

  const FilterTitleInput = ({ filterId }: { filterId: number }) => {
    const isFilterTabBeingEditedIsActive = !!(filterId && filterId !== activeFilterTab.id);

    const isFilterTabBeingEdited = isCreatingFilterState
      ? false
      : filterTabsData.find((filter) => filter.id === filterId);

    const handleClick = (event: MouseEvent<HTMLInputElement>) => {
      event.stopPropagation(); // this enables tab name change

      if (!isFilterTabBeingEditedIsActive) return;

      setFilterAsActive(isFilterTabBeingEdited || initialState[activeFilterTabStateType]);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      filterTitleRef = { current: event.target };
    };

    const shouldDisableOnTabCreation = isCreatingFilterState && filterId !== initialState[activeFilterTabStateType].id;

    const textInputClassName = `filter-tab-input ${
      isFilterTabBeingEditedIsActive || shouldDisableOnTabCreation ? 'inactive-unsaved-filter-tab' : ''
    }`;

    return (
      <div className={!isCreatingFilterState ? 'filter-edit-container' : 'filter-create-container'}>
        <TextInput
          id={`filter-tab-input-${filterId}`}
          labelText=""
          size="md"
          maxLength={64}
          ref={filterTitleRef}
          className={textInputClassName}
          disabled={shouldDisableOnTabCreation}
          placeholder={`${t('filters.filter')} ${t('filters.filterTitle')}...`}
          defaultValue={getCorrectFilterDefaultTitle(filterId)}
          onClick={(e) => handleClick(e)}
          onChange={(e) => handleChange(e)}
          onKeyDown={filterTitleInputKeyDown}
          hideLabel
        />
        <div
          className={`save-btn-action-wrapper ${
            isFilterTabBeingEditedIsActive || shouldDisableOnTabCreation
              ? 'save-btn-action-wrapper-disabled'
              : 'save-btn-action-wrapper-enabled'
          }`}
          onClick={saveFilter}
        >
          <Save size={16} />
        </div>
      </div>
    );
  };

  const isCloseIconVisible = (filterId: number): boolean => {
    if (!isCreatingFilterTab) return false;

    //TODO: if projects have action required tab displayed change condition below
    if (PageType.Project && filterTabsData.length === 1) return false;
    if ((PageType.Practitioner && filterId === 0) || filterTabsData.length === 2) return false;

    return true;
  };

  return (
    <div id="filters-container">
      {isLoadingFilterTabs ? (
        <TabsSkeleton style={{ height: '3rem' }} />
      ) : (
        <Tabs
          key={randomTabsKey}
          id="filter-tabs"
          selected={handleTabSelection()}
          type="container"
          selectionMode="automatic"
          scrollIntoView
        >
          {filterTabsData.map((filter, index) => {
            let numberOfFilteredPractitioners = filter.numberOfFilteredPractitioners;

            if (isSelectedTabNotActionRequired(filter.id)) {
              numberOfFilteredPractitioners = actionRequiredItemsNumber;
            }

            filter = {
              ...filter,
              tabPosition: index,
            };

            return (
              
              <Tab
                key={index}
                id={`filter-tab-${filter.id}`}
                className={`${
                  isFilterCopyingEnabled &&
                  (activeFilterTab.unsavedContext || isCreatingFilterState) &&
                  'active-when-editing'
                }`}
                style={{
                  display: isActionRequiredTabNotDisplayed(filter.id, numberOfFilteredPractitioners) ? 'none' : 'block',
                }}
                disabled={isCreatingFilterState}
                label={
                  !filter.unsavedContext ? (
                    <div className="tab-label">
                      <p
                        title={filter.title}
                        className={
                          filter.id === 0 && numberOfFilteredPractitioners
                            ? 'action-required-filter-label'
                            : 'action-required-none-filter-label'
                        }
                      >
                        {filter.title}
                        {typeof filter.numberOfFilteredPractitioners === 'number' && (
                          <span className="number-of-filtered-practitioners">({numberOfFilteredPractitioners})</span>
                        )}
                      </p>
                      {isCloseIconVisible(filter.id) && (
                        <Close id={`filter-tab-delete-${filter.id}`} onClick={() => onDeleteFilterTabClick(filter)} />
                      )}
                    </div>
                  ) : (
                    <FilterTitleInput filterId={filter.id} />
                  )
                }
                onClick={(e) => {
                  if ((!isFilterCopyingEnabled && isCreatingFilterState) || e.target instanceof SVGElement) return;

                  setFilterAsActive(filter);
                }}
                onDoubleClick={() => {
                  if (filter.id === 0 || isCreatingFilterState || activeFilterTab.unsavedContext) return;

                  setTabUnsavedContext(
                    filter.id,
                    {
                      ...filter.context,
                    },
                    pageType
                  );
                }}
              />
            );
          })}
        </Tabs>
      )}
      {!isCreatingFilterState ? (
        <Button id="add-filter-button" kind="ghost" size="sm" onClick={() => onAddFilterTabClick()}>
          <Add size={20} />
        </Button>
      ) : (
        <FilterTitleInput filterId={initialState[activeFilterTabStateType].id} />
      )}
    </div>
  );
};

export default PractitionerFilterTabs;
