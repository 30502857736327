import { UNSAFE_NavigationContext } from 'react-router-dom';
import type { History, Transition } from 'history';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

function usePageLeaveBlock(isPageLeaveBlocked: boolean): [boolean, () => void, () => void] {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPageLeavePrompt, setShowPageLeavePrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<Transition>();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  const cancelNavigation = useCallback(() => {
    setShowPageLeavePrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation: Transition) => {
      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        setShowPageLeavePrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation]
  );

  const confirmNavigation = useCallback(() => {
    setShowPageLeavePrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useEffect(() => {
    if (!isPageLeaveBlocked) return;

    const unblock = navigator.block((tx: Transition) => {
      handleBlockedNavigation({
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      });
    });

    return unblock;
  }, [navigator, handleBlockedNavigation, isPageLeaveBlocked]);

  return [showPageLeavePrompt, confirmNavigation, cancelNavigation];
}

export default usePageLeaveBlock;
