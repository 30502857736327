import {
  getFilterTabsData,
  getFilterTabsDataSuccess,
  getFilterTabsDataFailed,
  setFilterTabData,
  setFilterTabDataSuccess,
  setFilterTabDataFailed,
  setDeleteFilterTabData,
  setDeleteFilterTabDataSuccess,
  setDeleteFilterTabDataFailed,
} from './slice';
import { call, CallEffect, put as putEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { deleteMethod, get, post, put } from '../state-configurations/base-api';
import { domain, practitionersFilterConfig } from '../../../config/config';
import { filterObjectToUrlParams, PageType, FilterTabData } from '@cic-boardlite/common';
import { AnyAction } from '@reduxjs/toolkit';
import { getFilteredPractitioners } from '../practitioner/slice';
import { getFilteredProjects } from '../project/slice';
import { practitionerActionRequiredFilter, projectActionRequiredFilter } from './default-tab-data';

export function* handleGetFilterTabsData(action: {
  type: string;
  payload: number;
}): Generator<CallEffect<FilterTabData[]> | PutEffect<AnyAction>, void, FilterTabData[]> {
  try {
    const filtersData: FilterTabData[] = (yield call(() =>
      get<FilterTabData[]>(`${domain}/dataFilter/${action.payload}`)
    )).map((filter: FilterTabData, index: number) => ({
      ...filter,
      tabPosition: index + 1,
    }));

    if (action.payload === PageType.Practitioner) {
      filtersData.unshift(practitionerActionRequiredFilter);
    }
    if (action.payload === PageType.Project) {
      filtersData.unshift(projectActionRequiredFilter);
    }

    yield putEffect(getFilterTabsDataSuccess({ filtersData, pageType: action.payload }));
  } catch (error) {
    console.error('Filters get saga error: ', error);
    yield putEffect(getFilterTabsDataFailed());
  }
}

export function* handleSetFilterTabData(action: {
  type: string;
  payload: FilterTabData & {
    isEdit?: boolean;
    isSilentUpdate?: boolean;
  };
}): Generator {
  try {
    const method: <T>(path: string, data: FilterTabData) => Promise<T> = action.payload.isEdit ? put : post;

    yield call(() => method(`${domain}/dataFilter`, action.payload));
    yield putEffect(setFilterTabDataSuccess(action.payload));

    if (!action.payload.isSilentUpdate) {
      yield putEffect({ type: getFilterTabsData.type, payload: action.payload.pageType });

      if (action.payload.isEdit) {
        if (action.payload.pageType === PageType.Practitioner) {
          yield putEffect(
            getFilteredPractitioners(
              `${practitionersFilterConfig.getPractitionersFilter.split('?')[0]}?` +
                filterObjectToUrlParams(action.payload.context)
            )
          );
        }
        if (action.payload.pageType === PageType.Project) {
          putEffect({
            type: getFilteredProjects.type,
            payload: filterObjectToUrlParams(action.payload.context),
          });
        }
      }
    }
  } catch (error) {
    console.error('Filter set saga error: ', error);
    yield putEffect(setFilterTabDataFailed());
  }
}

export function* handleSetDeleteFilterTabData(action: {
  type: string;
  payload: { id: FilterTabData['id']; pageType: number };
}): Generator {
  try {
    yield call(() => deleteMethod(`${domain}/dataFilter/${action.payload.id}`));
    yield putEffect(setDeleteFilterTabDataSuccess(action.payload.pageType));
    yield putEffect({
      type: getFilterTabsData.type,
      payload: action.payload.pageType,
    });
  } catch (error) {
    console.error('Filter delete saga error: ', error);
    yield putEffect(setDeleteFilterTabDataFailed());
  }
}

export function* filterTabsSaga() {
  yield takeLatest(getFilterTabsData.type, handleGetFilterTabsData);
  yield takeLatest(setFilterTabData.type, handleSetFilterTabData);
  yield takeLatest(setDeleteFilterTabData.type, handleSetDeleteFilterTabData);
}
