import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeletableObjectType, GetProjectSummary } from '@cic-boardlite/common';
import { useNavigate } from 'react-router-dom';
import { useCommonState } from '../../../custom-hooks';
import { TrashCan16 } from '@carbon/icons-react';
import './table-expanded-row.scss';
import { Edit } from '@carbon/icons-react/next';
import EditComment from './edit-comment/EditComment';

interface ProjectsInfo {
  projectsInfo: GetProjectSummary;
}

const ProjectsTableExpandedRow = (props: ProjectsInfo) => {
  const [isCommentEditVisible, setIsCommentEditVisible] = useState<boolean>(false);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { toggleDeleteModal } = useCommonState();

  const handleDeleteModal = (id: string) => {
    toggleDeleteModal({
      deleteConfirmationField: props.projectsInfo.name,
      deleteObject: {
        id: id,
      },
      deletableObjectType: DeletableObjectType.project,
    });
  };

  const handleNavigate = () => {
    return navigate(`/projects/${props.projectsInfo.id}/assignments`);
  };

  const handleOpenCommentEdit = () => {
    return setIsCommentEditVisible(true);
  };

  const handleCloseCommentEdit = () => {
    return setIsCommentEditVisible(false);
  };

  return (
    <div className="element-box">
      <div className="edit-and-delete"></div>
      <div className="cds--grid--full-width">
        <div className="cds--row" style={{ width: 'auto' }}>
          <div className="cds--col cds--col-xlg-4 cds--col-max-4">
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.projectId`)}
              <span className="bold blue-text" onClick={handleNavigate}>
                {props.projectsInfo.projectId}
              </span>
            </p>
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.name`)}
              <span className="bold">{props.projectsInfo.name}</span>
            </p>
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.clientName`)}
              <span className="bold">{props.projectsInfo.clientName}</span>
            </p>
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.created`)}
              <span className="bold">{props.projectsInfo.created}</span>
            </p>
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.startDate`)}
              <span className="bold">{props.projectsInfo.startDate}</span>
            </p>
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.endDate`)}
              <span className="bold">{props.projectsInfo.endDate}</span>
            </p>
          </div>
          <div className="cds--col cds--col-xlg-4 cds--col-max-4">
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.country`)}
              <span className="bold">{props.projectsInfo.country}</span>
            </p>
            <p className="expanded-projects-table-font-size expanded-project-accountId">
              {t(`expandedProjectsTable.projectAccountIds`)}
              <span className="bold">{props.projectsInfo.projectAccountIds}</span>
            </p>
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.projectType`)}
              <span className="bold">{props.projectsInfo.projectType}</span>
            </p>
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.projectStatus`)}
              <span className="bold">{props.projectsInfo.projectStatus}</span>
            </p>
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.serviceLine`)}
              <span className="bold">{props.projectsInfo.serviceLine}</span>
            </p>
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.createdBy`)}
              <span className="bold">{props.projectsInfo.createdBy}</span>
            </p>
          </div>
          <div className="cds--col cds--col-xlg-4 cds--col-max-4">
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.geoPm`)}
              <span className="bold">{props.projectsInfo.geoPm}</span>
            </p>
            <p className="expanded-projects-table-font-size">
              {t(`expandedProjectsTable.pmo`)}
              <span className="bold">{props.projectsInfo.pmo}</span>
            </p>
            <p className="expanded-projects-table-font-size expanded-projects-table__comment">
              {t(`expandedProjectsTable.comment`)}
              {!isCommentEditVisible && (
                <Edit className="expanded-projects-table__comment-edit" onClick={handleOpenCommentEdit} />
              )}
            </p>
            {!isCommentEditVisible && props.projectsInfo.comment.length > 0 && (
              <p className="expanded-projects-table-font-size">
                <span className="bold" onDoubleClick={handleOpenCommentEdit}>
                  {props.projectsInfo.comment}
                </span>
              </p>
            )}
            {isCommentEditVisible && (
              <EditComment
                comment={props.projectsInfo.comment}
                projectId={props.projectsInfo.projectId}
                id={props.projectsInfo.id}
                onCloseCommentEdit={handleCloseCommentEdit}
              />
            )}
          </div>
          <div className="cds--col delete-project">
            <div onClick={() => handleDeleteModal(props.projectsInfo.id)} className={'cursor-pointer'}>
              <TrashCan16 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsTableExpandedRow;
