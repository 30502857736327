import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSecondaryModal from '../../custom-hooks/useSecondaryModal';
import { Modal } from 'carbon-components-react';
import FeedbackModal from './feedback-modal/feedback-modal';

import './feedback.scss';

const Feedback = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { trackFormChanges, formChanged, secondaryModal, closeSecondaryModal, openSecondaryModal } =
    useSecondaryModal();
  const { t } = useTranslation('common');

  const handleOpenModal = () => {
    return setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    if (formChanged) {
      return openSecondaryModal(true);
    };

    return setIsModalOpen(false);
  };

  const closeAllModals = () => {
    openSecondaryModal(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={`feedback ${isModalOpen && 'feedback_open'}`} onClick={handleOpenModal}>
        <p className="feedback-title">{t('feedback.title')}</p>
      </div>
      {isModalOpen && (
        <FeedbackModal
          open={isModalOpen}
          onCloseModal={handleCloseModal}
          onSubmitClose={closeAllModals}
          trackFormChanges={trackFormChanges}
        />
      )}
      {secondaryModal && (
        <Modal
          open={secondaryModal}
          danger
          size="sm"
          modalHeading={t('formLeaveConfirmationModal.heading')}
          primaryButtonText={t('formLeaveConfirmationModal.yesButton')}
          secondaryButtonText={t('formLeaveConfirmationModal.noButton')}
          onRequestClose={closeSecondaryModal}
          onRequestSubmit={closeAllModals}
        >
          {t('formLeaveConfirmationModal.description')}
        </Modal>
      )}
    </>
  );
};

export default Feedback;
