import ProjectsTableExpandable from '../project-table-expandable/table-expandable';
import './page.scss';
import ProjectsFilter from '../filter/filter';
import ProjectCreate from '../create/create-action';
import { useEffect } from 'react';
import { useCommonState, useStaticData } from '../../../custom-hooks';
import { AccordionSkeleton } from 'carbon-components-react';
import MultiChangesDialog from '../../multi-changes-dialog/multi-changes-dialog';
import { DeleteModalType, MultiChangeAction, PageType } from '@cic-boardlite/common';
import { useTranslation } from 'react-i18next';
import { DeleteAction } from '../../../ui/common/delete-action/deleteAction';
import PractitionerFilterTabs from '../../practitioner/filter-tabs/filter-tabs';

const ProjectsPage = () => {
  const { isStaticDataSet, loadStaticData } = useStaticData();
  const { isProjectsSideBarVisible } = useCommonState();

  useEffect(() => {
    loadStaticData();
  }, []);

  const { t } = useTranslation('common');
  const projectMultiChangeKeys = [
    {
      key: 'pmo',
      title: t('multiChange.projectMultiChangeKeys.pmo'),
    },
    {
      key: 'projectStatus',
      title: t('multiChange.projectMultiChangeKeys.projectStatus'),
    },
    {
      key: 'endDate',
      title: t('multiChange.projectMultiChangeKeys.endDate'),
    },
  ];

  return !isStaticDataSet ? (
    <AccordionSkeleton open={false} count={3} />
  ) : (
    <>
      <DeleteAction type={DeleteModalType.project} />
      <MultiChangesDialog modalType={MultiChangeAction.project} multiChangeKeys={projectMultiChangeKeys} />
      <ProjectsFilter />
      <div
        className={`${isProjectsSideBarVisible ? 'projectTableWrapperSideBarWidth' : 'projectTableWrapper'}`}
      >
        <PractitionerFilterTabs pageType={PageType.Project} />
        <ProjectsTableExpandable />
      </div>
      <ProjectCreate />
    </>
  );
};

export default ProjectsPage;
