import { useTranslation } from 'react-i18next';
import {
  DeletableObjectType,
  getPractitionerPictureLink,
  GetPractitionerSummary,
  PRACTITIONER_DATA_RETENTION_PERION_IN_MS,
  PRACTITIONER_DATA_RETENTION_PERION_IN_YEARS,
  profilePicturePath,
  SingleFieldUpdateType,
  toFixedValue,
} from '@cic-boardlite/common';
import { useNavigate } from 'react-router-dom';
import { Link, Loading, TooltipDefinition } from 'carbon-components-react';
import { Edit, StarFilled, TrashCan } from '@carbon/icons-react/next';
import { useCommonState, usePractitionerAssignments, usePractitioners } from '../../../custom-hooks';
import { memo, useEffect, useState } from 'react';
import UpdateSingleFieldComponent from '../../single-field-update-practitioners-table/single-field-update-component';
import './table-expanded-row.scss';

interface PractitionersInfo {
  practitionersInfo: GetPractitionerSummary;
  isRowExpanded: boolean[];
}
const PractitionersTableExpandedRow = (props: PractitionersInfo) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [profilePictureLink, setProfilePictureLink] = useState('');

  const {
    getSelectedPractitionerAssignments,
    practitionerAssignmentsDetails,
    isLoadingSelectedPractitionersAssignments,
    getPractitionerAssignmentsCount,
    practitionerAssignmentsCount,
    isLoadingPractitionerAssignmentsCount,
  } = usePractitionerAssignments();

  useEffect(() => {
    if (props.practitionersInfo.serial.toLowerCase().includes('cfh'))
      getPractitionerAssignmentsCount(Number(props.practitionersInfo.id));
    setProfilePictureLink(
      getPractitionerPictureLink(props.practitionersInfo.serial, props.practitionersInfo.countryCode)
    );

    if (isLoadingSelectedPractitionersAssignments) return;

    getSelectedPractitionerAssignments({ id: props.practitionersInfo.id, reducedAssignments: true });
  }, []);

  const ProgressiveImg = ({ src, ...imageProps }: { src: string; alt?: string; width?: string; height?: string }) => {
    return (
      <img
        {...imageProps}
        alt={imageProps?.alt || ''}
        className="image"
        onError={() => {
          setProfilePictureLink(profilePicturePath);
        }}
        src={profilePictureLink || profilePicturePath}
      />
    );
  };

  const { toggleSingleFieldComponent, currentSingleFieldComponentType } = usePractitioners();
  const { toggleDeleteModal } = useCommonState();

  const handleDeleteModal = (serial: string, id: string) => {
    toggleDeleteModal({
      deleteConfirmationField: serial,
      deleteObject: { id: id },
      deletableObjectType: DeletableObjectType.practitioner,
    });
  };

  const showDeleteAction = () => {
    return (
      props.practitionersInfo.serial.toLowerCase().includes('cfh') &&
      !isLoadingPractitionerAssignmentsCount &&
      !practitionerAssignmentsCount ||
      canPractitionerDataBeDeleted()
    );
  };

  const findExpiringAssignment = () => {
    const today = new Date();
    const sixWeeksFromToday = new Date(Date.now() + 6 * 7 * 24 * 60 * 60 * 1000);
    return practitionerAssignmentsDetails.find(
      (assignment) => new Date(assignment.endDate) > today && new Date(assignment.endDate) < sixWeeksFromToday
    );
  };

  const canPractitionerDataBeDeleted = () => {
    return (props.practitionersInfo.terminationDate && 
            (new Date().getTime() - new Date(props.practitionersInfo.terminationDate).getTime()) >= PRACTITIONER_DATA_RETENTION_PERION_IN_MS);
  };

  const commonRowWithLabel = (label: string, value?: string) => (
    <p className="expanded-practitioners-table-font-size">
      {t(label)}
      <span className="bold">{value}</span>
    </p>
  );

  const cicPractitionerDataSections = () => {
    return [
      <>
        <p className="bold">
          {props.practitionersInfo.nameFirstLast} ({props.practitionersInfo.country})
        </p>
        {commonRowWithLabel('expandedTable.band', props.practitionersInfo.band)}
        {commonRowWithLabel('expandedTable.serviceLine', props.practitionersInfo.serviceLine)}
        {commonRowWithLabel('expandedTable.jrs', props.practitionersInfo.jrs)}
        <p className="expanded-practitioners-table-font-size">
          {t(`expandedTable.costRate`)}
          <span className="bold">
            {toFixedValue(props.practitionersInfo.costRate)}
          </span>
        </p>
        {commonRowWithLabel('expandedTable.contractType', props.practitionersInfo.contractType)}
        {commonRowWithLabel('expandedTable.psAdder', props.practitionersInfo.psAdder)}
        <p className="expanded-practitioners-table-font-size">
          {t(`expandedTable.hiringDate`)}
          <span className="bold">{props.practitionersInfo.hiringDate ? props.practitionersInfo.hiringDate : ''}</span>
        </p>
        <p>
          {t('practitionersFilter.isCic.options.isCic')}: <span className="bold">{t('misc.yes')}</span>
        </p>
      </>,
      <div className="cds--col cds--col-max-4">
        {commonRowWithLabel('expandedTable.department', props.practitionersInfo.department)}
        {commonRowWithLabel('expandedTable.serviceArea', props.practitionersInfo.serviceArea)}
        {commonRowWithLabel('expandedTable.emfStatus', props.practitionersInfo.emfStatus)}

        <p className="expanded-practitioners-table-font-size">
          {t(`expandedTable.terminationDate`)}
          <span className="bold">
            {props.practitionersInfo.terminationDate ? props.practitionersInfo.terminationDate : ''}
          </span>
        </p>

        {commonRowWithLabel('expandedTable.peopleManager', props.practitionersInfo.peopleManager)}
      </div>,
    ];
  };

  const nonCicPractitionerDataSection = () => {
    return (
      <>
        <p className="bold">
          {props.practitionersInfo.nameFirstLast} ({props.practitionersInfo.country})
        </p>
        {commonRowWithLabel('expandedTable.band', props.practitionersInfo.band)}
        {commonRowWithLabel('expandedTable.jrs', props.practitionersInfo.jrs)}
        <p className="expanded-practitioners-table-font-size">
          {t(`expandedTable.costRate`)}
          <span className="bold">
            {toFixedValue(props.practitionersInfo.costRate)}
          </span>
        </p>
        {commonRowWithLabel('expandedTable.psAdder', props.practitionersInfo.psAdder)}
        <p>
          {t('practitionersFilter.isCic.options.isCic')}: <span className="bold">{t('misc.no')}</span>
        </p>
      </>
    );
  };

  const isAssignmentExpiring = !isLoadingSelectedPractitionersAssignments && findExpiringAssignment();
  const isDataAvailableForDeletion = canPractitionerDataBeDeleted();

  return (
    <div className="element-box">
      <div className="cds--grid--full-width">
      
        {showDeleteAction() && (
          <div className="edit-and-delete">
            <div className={'cursor-pointer'}
              onClick={() => handleDeleteModal(props.practitionersInfo.serial, props.practitionersInfo.id)}>
              <TrashCan size="16"/>
            </div>
          </div>
        )}
        {props.practitionersInfo.deletion && (
          <div className="cds--row" style={{ width: 'auto' }}>
            <span className="update-jrs">{t('expandedTable.markedForDeletion')}</span>
          </div>
        )}
        {props.practitionersInfo.terminated && (
          <div className="cds--row" style={{ width: 'auto' }}>
            <span className="update-jrs">{t('expandedTable.terminated') + ' ' + t('expandedTable.terminatedSince', { date: props.practitionersInfo.terminationDate })}</span>
          </div>
        )}
        {isDataAvailableForDeletion && (
          <div className="cds--row" style={{ width: 'auto' }}>
            <span className="update-jrs">{t('filters.actionRequiredForOldData', {years: PRACTITIONER_DATA_RETENTION_PERION_IN_YEARS})}</span>
          </div>
        )}
        {!props.practitionersInfo.jrs && (
          <div className="cds--row" style={{ width: 'auto' }}>
            <span className="update-jrs">{t('filters.actionRequiredForJRS')}</span>
          </div>
        )}
        {isAssignmentExpiring && (
          <div className="cds--row" style={{ width: 'auto' }}>
            <span className="update-jrs">{t('filters.actionRequiredForExpiringAssignments')}</span>
          </div>
        )}
      </div>

      <div className="cds--grid--full-width">
        <div className="cds--row" style={{ width: 'auto' }}>
          <div>
            <ProgressiveImg src={profilePictureLink} width="100" height="100" />
          </div>
          <div className="cds--col-sm-1 cds--col-xlg-4 cds--col-max-4">
            <Link onClick={() => navigate(`/practitioner/${props.practitionersInfo.id}`)}>
              {props.practitionersInfo.serial}
            </Link>
            {props.practitionersInfo.isCic ? cicPractitionerDataSections()[0] : nonCicPractitionerDataSection()}
          </div>
          {props.practitionersInfo.isCic && cicPractitionerDataSections()[1]}
          <div className="cds--col cds--col-xlg-4 cds--col-max-4 active-assignments">
            <div className="practitioner-table-assignment">
              <p className="bold practitioner-assignment-title">{t(`expandedTable.activeAssignments`)}</p>
              {!isLoadingSelectedPractitionersAssignments ? (
                practitionerAssignmentsDetails.map((assignment) => (
                  <div key={assignment.id} className="expanded-practitioners-table-font-size">
                    <span>
                      <Link
                        onClick={() => navigate(`/projects/${assignment.project?.id}/assignments`)}
                        className="blue-text"
                      >
                        {assignment.project?.name}
                      </Link>
                      {' - '}
                      <span className="nowrap">
                          {assignment.project?.projectId}
                      </span>
                      {' - '}
                      <span className="nowrap">
                        {`${assignment.endDate}`}
                      </span>
                      {assignment.allocation ? ` (${assignment.allocation})` : ''}
                      {assignment.mainAssignment && (
                        <TooltipDefinition
                          align="end"
                          direction="bottom"
                          tooltipText={t('misc.mainAssignment')}
                          style={{ verticalAlign: 'text-top' }}
                        >
                          <StarFilled size="16" className="main-assignment-icon" />
                        </TooltipDefinition>
                      )}
                    </span>
                  </div>
                ))
              ) : (
                <div className="align-center">
                  <Loading withOverlay={false} small={true} />
                </div>
              )}
            </div>
            <div>
              <p className="center-bottom view-all-assignments">
                <Link onClick={() => navigate(`/practitioner/${props.practitionersInfo.id}/assignments`)}>
                  {t(`expandedTable.viewAllAssignments`)}
                </Link>
              </p>
            </div>
          </div>
          <div className="cds--col">
            <div className="center">
              <div className="editable-section-block">
                <div className="expanded-table-editable-section-header bold editable-section-title">
                  {t(`expandedTable.availabilityInfo`)}
                  {currentSingleFieldComponentType !== SingleFieldUpdateType.AvailabilityInfo && (
                    <Edit onClick={() => toggleSingleFieldComponent(SingleFieldUpdateType.AvailabilityInfo)} />
                  )}
                </div>
                {currentSingleFieldComponentType !== SingleFieldUpdateType.AvailabilityInfo ? (
                  <div className="cds--row expanded-table-editable-section">{props.practitionersInfo?.availabilityInfo}</div>
                ) : (
                  <UpdateSingleFieldComponent
                    type={SingleFieldUpdateType.AvailabilityInfo}
                    defaultValue={props.practitionersInfo?.availabilityInfo}
                    practitionerId={props.practitionersInfo.id}
                  />
                )}
              </div>

              <div className="editable-section-block">
                <div className="expanded-table-editable-section-header bold editable-section-title">
                  {t(`expandedTable.notes`)}
                  {currentSingleFieldComponentType !== SingleFieldUpdateType.Notes && (
                    <Edit onClick={() => toggleSingleFieldComponent(SingleFieldUpdateType.Notes)} />
                  )}
                </div>
                {currentSingleFieldComponentType !== SingleFieldUpdateType.Notes ? (
                  <div className="cds--row expanded-table-editable-section">{props.practitionersInfo?.notes}</div>
                ) : (
                  <UpdateSingleFieldComponent
                    type={SingleFieldUpdateType.Notes}
                    defaultValue={props.practitionersInfo?.notes}
                    practitionerId={props.practitionersInfo.id}
                  />
                )}
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PractitionersTableExpandedRow);
