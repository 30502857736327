import { EditOff, Save } from '@carbon/icons-react/next';
import { SingleFieldUpdateType, toUTCDate } from '@cic-boardlite/common';
import { DatePicker, DatePickerInput, TextArea } from 'carbon-components-react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePractitioners, useSavePractitioner } from '../../custom-hooks';
import { KeyboardEvent } from 'react';
import './single-field-update-component-style.scss';

interface PropsInterface {
  type: string;
  defaultValue?: string;
  practitionerId: string;
  oldAvailabilityDateManuallyEdited?: boolean;
  practitionerHiringDate?: Date;
}

const UpdateSingleFieldComponent = ({
  type,
  defaultValue,
  practitionerId,
  oldAvailabilityDateManuallyEdited,
  practitionerHiringDate,
}: PropsInterface) => {
  const { t } = useTranslation('common');
  const { toggleSingleFieldComponent } = usePractitioners();
  const { editPractitioner } = useSavePractitioner();

  const defaultValues = {
    [type]: defaultValue || '',
  };

  const methods = useForm<{ [x: string]: string }>({
    defaultValues: defaultValues,
  });

  const handleSubmitForm = (data: { [x: string]: string | null }) => {
    const oldPractitionerData = {
      id: Number(practitionerId),
      [type]: defaultValue,
    };

    const updatedPractitionerData = {
      id: Number(practitionerId),
      ...data,
    };

    if (type === SingleFieldUpdateType.AvailabilityDate) {
      Object.assign(oldPractitionerData, {
        availabilityDateManuallyEdited: oldAvailabilityDateManuallyEdited,
      });
      Object.assign(updatedPractitionerData, {
        availabilityDateManuallyEdited: !!data[type],
      });
    }

    return defaultValue !== data[type]
      ? editPractitioner(oldPractitionerData, updatedPractitionerData, false, true)
      : toggleSingleFieldComponent('');
  };

  const handleOnEnterSubmit = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className={
          type === SingleFieldUpdateType.AvailabilityDate
            ? 'single-field-update-comp-wrapper'
            : ''
        }
      >
        <Controller
          name={type}
          control={methods.control}
          rules={{
            validate: {
              validateLength: (value) => value.length < 512 || t('maxLengthMessage', { maxLength: 512 }),
            },
          }}
          render={({ field: { onChange, value } }) =>
            type === SingleFieldUpdateType.Notes || type === SingleFieldUpdateType.AvailabilityInfo? (
              <TextArea
                light
                onChange={onChange}
                id={type}
                labelText=""
                value={value}
                autoComplete="off"
                invalid={!!methods.formState.errors?.[`${type}`]}
                invalidText={methods.formState.errors?.[`${type}`]?.message}
                onKeyDown={(e) => handleOnEnterSubmit(e)}
              ></TextArea>
            ) : (
              <DatePicker
                className="single-field-update-comp-date-picker"
                light
                datePickerType="single"
                dateFormat={t('format.date_4carbonDatePicker')}
                onChange={(_dates: Date[], _currentDateString: string) => {
                  defaultValue !== _currentDateString
                    ? handleSubmitForm({
                        [type]: _currentDateString.length ? _currentDateString : null,
                      })
                    : toggleSingleFieldComponent('');
                }}
                disable={[(date) => date < toUTCDate(practitionerHiringDate || date)]}
              >
                <DatePickerInput
                  id={type}
                  defaultValue={defaultValue}
                  labelText=""
                  hideLabel
                  autoFocus
                  autoComplete="off"
                  size="sm"
                  invalid={!!methods.formState.errors?.[`${type}`]}
                  invalidText={methods.formState.errors?.[`${type}`]?.message}
                  pattern={t('format.date_4carbonDatePickerInput')}
                  onKeyDownCapture={(e) => handleOnEnterSubmit(e)}
                />
              </DatePicker>
            )
          }
        />
        <div className="single-field-update-comp-icons">
          {type !== SingleFieldUpdateType.AvailabilityDate && (
            <Save size={16} onClick={methods.handleSubmit((data) => handleSubmitForm(data))} />
          )}

          <EditOff size={16} onClick={() => toggleSingleFieldComponent('')} />
        </div>
      </form>
    </FormProvider>
  );
};

export default UpdateSingleFieldComponent;
