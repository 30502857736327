import { CloudDownload, Download, Edit, Email } from '@carbon/icons-react/next';
import { DownloadDataPages, MultiChangeAction } from '@cic-boardlite/common';
import {
  Loading,
  TableBatchAction,
  TableBatchActions,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import {
  useCommonState,
  useFileImportExport,
  useFilteredProjects,
  usePractitionerCv,
  useProjectAssignments,
} from '../../custom-hooks';
import DataDownloadMenuButton from './data-download-menu-button';
import DataDownloadToolbarSearch from './data-download-toolbar-search';
import './data-download-toolbar.scss';

interface PropsInterface {
  page: string;
}

const DataDownloadToolbar = ({ page }: PropsInterface) => {
  const { t } = useTranslation('common');

  const { selectSelectedPractitioners, removeAllSelectedPractitioners, openCloseCvModal } = usePractitionerCv();

  const { toggleMultiChangeModal } = useCommonState();

  const { assignmentExtensionArray, removeAllAssignmentsFromExtensionArray, openCloseAssignmentExtensionModal } =
    useProjectAssignments();

  const {
    exportSelectedPractitioners,
    exportSelectedPractitionersAssignments,
    exportSelectedProjectsAssignments,
    exportSelectedProjects,
    exportOpenedProjectSelectedAssignments,
    isExportingPractitionersAssignmentsSummary,
    isExportingProjectsAssignmentsSummary,
  } = useFileImportExport();

  const { selectedProjectsList, removeAllFromProjectsSelectedList } = useFilteredProjects();

  // TODO add action buttons for projects and practitioner assignments
  // when selection is available
  const practitionerPageActionButtons = [
    {
      title: t('fileImportExport.practitionersPage.selectedPractitioners'),
      action: () => exportSelectedPractitioners(),
    },
    {
      title: t('fileImportExport.practitionersPage.selectedAssignments'),
      action: () => exportSelectedPractitionersAssignments(),
    },
  ];

  const projectsPageActionButtons = [
    {
      title: t('fileImportExport.projectsPage.selectedProjects'),
      action: () => exportSelectedProjects(),
    },
    {
      title: t('fileImportExport.projectsPage.selectedAssignments'),
      action: () => exportSelectedProjectsAssignments(),
    },
  ];
  const projectAssignmentsActionButtons = [
    {
      title: t('fileImportExport.projectPageAssignments.selectedAssignments'),
      action: () => exportOpenedProjectSelectedAssignments(),
    },
  ];
  const selectedItemsNumber = (page: string) => {
    if (page === DownloadDataPages.Practitioners) return selectSelectedPractitioners.length;
    if (page === DownloadDataPages.ProjectAssignments) return assignmentExtensionArray.length;
    if (page === DownloadDataPages.Projects) return selectedProjectsList.length;
    return 0;
  };

  const showBatchActions = (page: string) => {
    if (page === DownloadDataPages.Practitioners) return !!selectSelectedPractitioners.length;
    if (page === DownloadDataPages.ProjectAssignments) return !!assignmentExtensionArray.length;
    if (page === DownloadDataPages.Projects) return !!selectedProjectsList.length;
    return false;
  };

  const handleOnCancel = (page: string) => {
    if (page === DownloadDataPages.Practitioners) removeAllSelectedPractitioners();
    if (page === DownloadDataPages.ProjectAssignments) removeAllAssignmentsFromExtensionArray();
    if (page === DownloadDataPages.Projects) return removeAllFromProjectsSelectedList();
    return;
  };

  return (
    <TableToolbar>
      {(isExportingPractitionersAssignmentsSummary || isExportingProjectsAssignmentsSummary) && <Loading />}
      <TableBatchActions
        className="toolbar-actions-container"
        shouldShowBatchActions={showBatchActions(page)}
        totalSelected={selectedItemsNumber(page)}
        onCancel={() => handleOnCancel(page)}
      >
        <TableBatchAction
          onClick={() => {
            page === DownloadDataPages.Practitioners && toggleMultiChangeModal(MultiChangeAction.practitioner);
            page === DownloadDataPages.ProjectAssignments && toggleMultiChangeModal(MultiChangeAction.assignment);
            page === DownloadDataPages.Projects && toggleMultiChangeModal(MultiChangeAction.project);
          }}
          renderIcon={Edit}
        >
          {t('dataDownloadToolbar.multiChanges')}
        </TableBatchAction>
        {page === DownloadDataPages.Practitioners && (
          <>
            {practitionerPageActionButtons.map((button, index) => (
              <TableBatchAction onClick={button.action} renderIcon={CloudDownload} key={index}>
                {button.title}
              </TableBatchAction>
            ))}
            <TableBatchAction renderIcon={Download} onClick={openCloseCvModal}>
              {t('dataDownloadToolbar.downloadCv')}
            </TableBatchAction>
          </>
        )}
        {page === DownloadDataPages.Projects && (
          <>
            {projectsPageActionButtons.map((button, index) => (
              <TableBatchAction onClick={button.action} renderIcon={CloudDownload} key={index}>
                {button.title}
              </TableBatchAction>
            ))}
          </>
        )}
        {page === DownloadDataPages.ProjectAssignments && (
          <>
            {projectAssignmentsActionButtons.map((button, index) => (
              <TableBatchAction renderIcon={CloudDownload} onClick={button.action} key={index}>
                {button.title}
              </TableBatchAction>
            ))}
            <TableBatchAction renderIcon={Email} onClick={openCloseAssignmentExtensionModal}>
              {t('dataDownloadToolbar.emailGeoPm')}
            </TableBatchAction>
          </>
        )}
      </TableBatchActions>
      <TableToolbarContent>
        <DataDownloadToolbarSearch page={page} />
        <DataDownloadMenuButton page={page} />
      </TableToolbarContent>
    </TableToolbar>
  );
};

export default DataDownloadToolbar;
