export const getUrlsParamsString = () => {
  return window.location.search.slice(1);
};

export const setUrlParamsString = (paramsString: string) => {
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${paramsString}`
  );
};

export const trim = (value?: string) => value ? value.trim() : value;

export const isFourByteInt = (value: number) => {
  return value >= -2147483648 && value <= 2147483647;
};
