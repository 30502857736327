import { createSlice } from '@reduxjs/toolkit';

export const initialState: {
  isLoadingMultiChange: boolean;
} = {
  isLoadingMultiChange: false,
};

const multiChangeSlice = createSlice({
  name: 'multiChange',
  initialState,
  reducers: {
    triggerMultiChange(state, action) {
      state.isLoadingMultiChange = true;
    },
    multiChangeSuccess(state) {
      state.isLoadingMultiChange = false;
    },
    multiChangeFailed(state) {
      state.isLoadingMultiChange = false;
    },
  },
});

export const { triggerMultiChange, multiChangeSuccess, multiChangeFailed } =
  multiChangeSlice.actions;
export default multiChangeSlice.reducer;
