import { call, CallEffect, put as putEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { get, post } from '../state-configurations/base-api';
import { EmailContentFeedbackResponse } from '@cic-boardlite/common';
import { AnyAction } from '@reduxjs/toolkit';
import { blueServicesConfig, practitionerConfig } from 'apps/frontend/src/config/config';
import i18next from 'i18next';
import common_en from '../../../assets/locales/en/translation.json';
import { addNotification } from '../notifications/slice';
import {
  sendFeedbackToMaintainerEmailSuccess,
  sendFeedbackToMaintainerEmailFailed,
  sendFeedbackToMaintainerEmail,
  getPractitionersMaintainerEmails,
  getPractitionersMaintainerEmailsSuccess,
  getPractitionersMaintainerEmailsFailed,
} from '../feedback/slice';

export function* handleGetMaintainerEmails(action: {
  type: string;
}): Generator<CallEffect<string[]> | PutEffect<AnyAction>, void, string[]> {
  try {
    const { getMaintainerEmails } = practitionerConfig;
    const response = yield call(() => get<string[]>(getMaintainerEmails));

    yield putEffect(getPractitionersMaintainerEmailsSuccess(response));
  } catch (error: any) {
    console.log(error);
    yield putEffect(getPractitionersMaintainerEmailsFailed());
  }
}

export function* handleSendFeedbackToMaintainerEmail(action: {
  type: string;
  payload: EmailContentFeedbackResponse;
}): Generator<CallEffect<boolean> | PutEffect<AnyAction>, void, boolean> {
  try {
    const { sendFeedbackEmail } = blueServicesConfig;
    const path = sendFeedbackEmail;
    const emailData = action.payload;

    if(emailData.emailContent.emailFrom.length === 0) {
      yield putEffect(
        addNotification({
          kind: 'error',
          title: i18next.t(common_en.feedback.noUserEmail),
        })
      );
      return;
    }

    if(emailData.emailContent.emailTo.length === 0) {
      yield putEffect(
        addNotification({
          kind: 'error',
          title: i18next.t(common_en.feedback.feedbackNoMaintainers),
        })
      );
      return;
    };

    const responseSendEmail = yield call(() => post<boolean>(path, emailData));

    yield putEffect(sendFeedbackToMaintainerEmailSuccess(responseSendEmail));
    yield putEffect(
      addNotification({
        kind: 'success',
        title: i18next.t(common_en.feedback.feedbackSendSuccess),
      })
    );
  } catch (error: any) {
    yield putEffect(sendFeedbackToMaintainerEmailFailed());
    yield putEffect(
      addNotification({
        kind: 'error',
        title: error?.error ? error.error : i18next.t(common_en.feedback.feedbackSendError),
      })
    );
  }
}

export function* feedbackSaga() {
  yield takeLatest(getPractitionersMaintainerEmails.type, handleGetMaintainerEmails);
  yield takeLatest(sendFeedbackToMaintainerEmail.type, handleSendFeedbackToMaintainerEmail);
}
