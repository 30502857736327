import { useState } from 'react';

const useSecondaryModal = () => {
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [secondaryModal, openSecondaryModal] = useState<boolean>(false);

  const trackFormChanges = (formChanged: boolean) => {
    setFormChanged(formChanged);
  };

  const closeSecondaryModal = () => {
    openSecondaryModal(false);
  };

  return { trackFormChanges, formChanged, secondaryModal, openSecondaryModal, closeSecondaryModal };
};

export default useSecondaryModal;
