import {
  BubbleIconInterface,
  ASSIGNMENT_ENDING_IN_4_WEEKS,
  ASSIGNMENT_ENDING_IN_6_WEEKS,
  ASSIGNMENT_NOT_ENDING_SOON,
} from '@cic-boardlite/common';
import './bubble-icon.scss';

const BubbleIcon = ({ assignmentEnd }: BubbleIconInterface) => {
  let backgroundColor = '';

  if (assignmentEnd === ASSIGNMENT_ENDING_IN_4_WEEKS) {
    backgroundColor = 'bubble-error';
  }

  if (assignmentEnd === ASSIGNMENT_ENDING_IN_6_WEEKS) {
    backgroundColor = 'bubble-warning';
  }

  if (assignmentEnd === ASSIGNMENT_NOT_ENDING_SOON) {
    backgroundColor = 'bubble-hidden';
  }

  return <span className={`${backgroundColor}`}>&#9679;</span>;
};

export default BubbleIcon;
