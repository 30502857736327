import {
  SideNav,
  SideNavItems,
  SideNavMenuItem,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const ProjectDetailPageSidebar = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const { t } = useTranslation('common');
  const location = useLocation();

  return (
    <SideNav
      className="project-detail-page-sidebar"
      aria-label="Side navigation"
    >
      <SideNavItems>
        <SideNavMenuItem
          onClick={() => nav(`/projects/${id}`)}
          className="project-page-button"
          isActive={location.pathname === `/projects/${id}`}
        >
          {t('projectSidebar.project')}
        </SideNavMenuItem>
        <SideNavMenuItem
          onClick={() => nav(`/projects/${id}/assignments`)}
          className="project-page-button"
          isActive={location.pathname === `/projects/${id}/assignments`}
        >
          {t('projectSidebar.assignments')}
        </SideNavMenuItem>
        <SideNavMenuItem
          onClick={() => nav(`/projects/${id}/logs`)}
          className="project-page-button"
          isActive={location.pathname === `/projects/${id}/logs`}
        >
          {t('projectSidebar.logs')}
        </SideNavMenuItem>
      </SideNavItems>
    </SideNav>
  );
};

export default ProjectDetailPageSidebar;
