import { Modal } from 'carbon-components-react';
import { CreateProjectForm } from './create-form';
import { Add16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { useState } from 'react';
import { useCreateProject, useProject, useSecondaryModal, useStaticData } from '../../../custom-hooks';
import CreateProjectFormSkeleton from './create-form-skeleton';
import { useTranslation } from 'react-i18next';
import './create-style.scss';

const ProjectCreate = () => {
  const { t } = useTranslation('common');
  const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false);

  const {
    loadProjectsIdNameData,
    isLoadingProjectsIdNameData,
    loadProjectsClientData,
    isLoadingProjectsClientData,
    isLoadingGeoPmNamesTalentIds,
    getGeoPmNamesTalentIdsData,
  } = useProject();
  const { loadStaticData, isStaticDataSet } = useStaticData();

  const { trackFormChanges, formChanged, secondaryModal, closeSecondaryModal, openSecondaryModal } =
    useSecondaryModal();

  const { wasProjectCreated } = useCreateProject();

  const createProject = () => {
    setOpenCreateProjectModal(true);
    loadProjectsIdNameData();
    loadStaticData();
    loadProjectsClientData();
    getGeoPmNamesTalentIdsData();
  };

  const closeModal = () => {
    if (formChanged && !wasProjectCreated) {
      return openSecondaryModal(true);
    }
    return setOpenCreateProjectModal(false);
  };

  const closeAllModals = () => {
    openSecondaryModal(false);
    setOpenCreateProjectModal(false);
  };

  return (
    <>
      <Button
        className="create-project-button"
        hasIconOnly
        renderIcon={Add16}
        iconDescription={t(`createProject.createIconDescription`)}
        onClick={createProject}
      />
      <Modal className="create-project-modal" passiveModal open={openCreateProjectModal} onRequestClose={closeModal}>
        {isLoadingProjectsIdNameData ||
        isLoadingProjectsClientData ||
        isLoadingGeoPmNamesTalentIds ||
        !isStaticDataSet ? (
          <CreateProjectFormSkeleton />
        ) : (
          <CreateProjectForm closeModal={closeModal} trackFormChanges={trackFormChanges} />
        )}
      </Modal>
      {secondaryModal && (
        <Modal
          open={secondaryModal}
          danger
          size="sm"
          modalHeading={t('formLeaveConfirmationModal.heading')}
          primaryButtonText={t('formLeaveConfirmationModal.yesButton')}
          secondaryButtonText={t('formLeaveConfirmationModal.noButton')}
          onRequestClose={closeSecondaryModal}
          onRequestSubmit={closeAllModals}
        >
          {t('formLeaveConfirmationModal.description')}
        </Modal>
      )}
    </>
  );
};

export default ProjectCreate;
