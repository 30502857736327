import { DataTableSortState } from "carbon-components-react/lib/components/DataTable/state/sorting";

export enum DataSortOrder {
  ASC  = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}

export function sortData(cellA: any, cellB: any, columnName: string, sortDirection: DataTableSortState, locale?: string) {
  if (sortDirection === DataSortOrder.NONE) {
    return 0;
  }

  const cellAValue = (cellA || '').toLowerCase();
  const cellBValue = (cellB || '').toLowerCase();

  const sortOrder = (sortDirection === DataSortOrder.ASC ? 1 : -1);

  const collator = new Intl.Collator(locale, {numeric: true, sensitivity: 'base'});
  if (columnName === 'band') {
    const cellABandDigit = cellAValue.replace(/\D+$/g, "");
    const cellBBandDigit = cellBValue.replace(/\D+$/g, "");

    if (!cellABandDigit.length || !cellBBandDigit.length) {
      return collator.compare(cellAValue, cellBValue) * sortOrder;
    } else {
      const digitComparison = collator.compare(cellABandDigit, cellBBandDigit);

      if (digitComparison === 0) {
        const cellABandText = cellAValue.replace(/^\d/g, "");
        const cellBBandText = cellBValue.replace(/^\d/g, "");

        return collator.compare(cellABandText, cellBBandText) * sortOrder;
      } else {
        return digitComparison * sortOrder;
      }
    }

  } else {
    return collator.compare(cellAValue, cellBValue) * sortOrder;
  }
}

export const actionSort = (valueA: string, valueB: string): number => {
  if (
    (valueA === 'low' && valueB === 'high') ||
    (valueA === 'low' && valueB === 'medium') ||
    (valueA === 'medium' && valueB === 'high')
  ) {
    return -1;
  }

  return 1;
};
