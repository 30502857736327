import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { config } from '../../send-email-modal-dialog/send-email-editor/send-email-editor.config';
import { Modal, TextInput, Checkbox } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import {
  useBluePages,
  usePractitioners,
  usePractitionerAssignments,
  useProjectAssignments,
  useUser,
  useProject,
} from '../../../custom-hooks';
import { formatCcEmailsArray, getBccEmail } from '@cic-boardlite/common';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { EmailsField } from '../../emails-field/emails-field';
import { useTranslation } from 'react-i18next';

const MultipleExtensionRequestModal = () => {
  const { assignmentExtensionArray, isAssignmentExtensionModalOpen, openCloseAssignmentExtensionModal } =
    useProjectAssignments();
  const { projectData } = useProject();
  const { sendSelectedPractitionerAssignmentEmail } = usePractitionerAssignments();
  const { userInfo } = useUser();
  const { pemWmpServiceLineLeadersEmails } = usePractitioners();
  const { getBluePagesPreferredIdentityEmail, bluePagesPreferredIdentityEmail } = useBluePages();
  const { t } = useTranslation('common');;
  const [geoPmEmail, setGeoPmEmail] = useState<string | undefined>();
  const [serviceLineLeadEmail, setServiceLineLeadEmail] = useState<string>('');
  const [isBccChecked, setIsBccChecked] = useState<boolean>(true);

  const defaultValues = {
    emailTo: [],
    subject: '',
    html: '',
    initialEmails: [],
  };

  const methods = useForm<{
    emailTo: string[];
    subject: string;
    html: string;
    initialEmails: string[];
  }>({ defaultValues: defaultValues });

  const assignmentArrayToHtml = () => {
    let html = '<p>';
    for (const assignment of assignmentExtensionArray) {
      html += `<strong>
    ${assignment?.firstLastName.split(' ')[0]} ${assignment?.firstLastName.split(' ')[1][0]}., TalentID ${
        assignment?.serial
      } - ${assignment?.endDate}</strong><br/>`;
    }
    return html + '</p>';
  };

  const setStateServiceLineLeadEmail = () => {
    if (projectData?.serviceLineId) {
      const serviceLineLeadInfo = pemWmpServiceLineLeadersEmails?.serviceLineLeadersEmails?.find(
        (serviceLine) => serviceLine?.serviceLineId === projectData?.serviceLineId
      );
      return serviceLineLeadInfo
        ? setServiceLineLeadEmail(
            t('sendEmailModal.serviceLineLeader', {
              serviceLine: serviceLineLeadInfo?.serviceLineTitle,
              leaderEmail: serviceLineLeadInfo?.serviceLineLeaderEmail,
            })
          )
        : setServiceLineLeadEmail('');
    }
  };

  useEffect(() => {
    if (isAssignmentExtensionModalOpen)
      setGeoPmEmail(bluePagesPreferredIdentityEmail && t('sendEmailModal.geoPM') + bluePagesPreferredIdentityEmail);
  }, [isAssignmentExtensionModalOpen, bluePagesPreferredIdentityEmail]);

  useEffect(() => {
    if (isAssignmentExtensionModalOpen) {
      if (projectData?.geoPmTalentId) getBluePagesPreferredIdentityEmail(projectData.geoPmTalentId);
      else setGeoPmEmail('');

      projectData?.serviceLineId && setStateServiceLineLeadEmail();

      const html = `
      <p>Dear <strong>${projectData?.geoPm?.split(' ')[0]}</strong>,<br/></p>
      <p>Please note below listed resource(s) assignment in <strong>${projectData?.name}</strong> ends on:<br/></p>
      ${assignmentArrayToHtml()}
      <p>Please confirm resource(s) extension in the project by specifying the new end date of assignment.<br/></p>
      <p>Thank you,</p>
      <p>${userInfo?.displayName}</p>
      `;

      methods.setValue('html', html);
      methods.setValue(
        'subject',
        t('sendEmailModal.multipleExtensionSubject', {
          projectId: projectData?.name,
        })
      );
    }
  }, [isAssignmentExtensionModalOpen]);

  const getInitialEmails = () => {
    const emails: string[] = [];

    if (geoPmEmail?.length) {
      emails.push(geoPmEmail);
    }
    for (const wmpEmail of pemWmpServiceLineLeadersEmails.wmpEmails) {
      emails.push(`${t('sendEmailModal.wmp')}${wmpEmail}`);
    }
    if (serviceLineLeadEmail.length) {
      emails.push(serviceLineLeadEmail);
    }

    return Array.from(new Set(emails.map((email) => email.split(':')[1]))).map((email) => {
      return emails.find((emailWithLabel) => emailWithLabel.includes(email)) || '';
    });
  };

  useEffect(() => {
    if (!isAssignmentExtensionModalOpen) return;
    const initialEmails = getInitialEmails();
    methods.setValue('initialEmails', initialEmails);
  }, [geoPmEmail, serviceLineLeadEmail, pemWmpServiceLineLeadersEmails, isAssignmentExtensionModalOpen]);

  const handleBccChecked = () => {
    setIsBccChecked((prevState) => !prevState);
  };

  const handleSubmitForm = (data: { emailTo: string[]; subject: string; html: string }) => {
    const emailDataObject = {
      subject: data.subject,
      html: data.html,
      emailCc: formatCcEmailsArray(data.emailTo).slice(1),
      emailBcc: getBccEmail({
        userEmail: userInfo.emailAddress,
        emails: formatCcEmailsArray(data.emailTo),
        isBccChecked: isBccChecked,
      }),
      emailFrom: userInfo.emailAddress,
      emailTo: formatCcEmailsArray(data.emailTo)[0],
      projectId: Number(projectData.id),
      extensionRequestEmailSent: assignmentExtensionArray.map((assignment) => ({
        id: assignment.id,
        extensionRequestEmailSent: assignment.extensionRequestEmailSent,
      })),
      projectsPage: true,
    };

    sendSelectedPractitionerAssignmentEmail(emailDataObject);
    openCloseAssignmentExtensionModal();
  };

  const closeModalAndResetErrors = () => {
    openCloseAssignmentExtensionModal();
    methods.clearErrors();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        modalHeading={t('sendEmailModal.multipleExtensionHeading')}
        open={isAssignmentExtensionModalOpen}
        onRequestClose={closeModalAndResetErrors}
        primaryButtonText={t('sendEmailModal.sendButtonText')}
        secondaryButtonText={t('sendEmailModal.cancelButtonText')}
        onRequestSubmit={methods.handleSubmit((data) => handleSubmitForm(data))}
        className="project-assignment-table-multiple-extension-modal"
        size="md"
      >
        <form>
          <Controller
            name="emailTo"
            control={methods.control}
            rules={{
              validate: (value) => !!value.length || t('sendEmailModal.emptyCcError'),
            }}
            render={() => (
              <EmailsField
                emailBoxName={'emailTo'}
                textAreaLabelLangKey={'ccEmail.label'}
                textAreaPlaceholderLangKey={'ccEmail.placeholder'}
                initialEmails={'initialEmails'}
              />
            )}
          />
          <div className="cds--row send-email-row send-email__AssignmentEmailBcc">
            <div className="cds--col-lg-16">
              <Checkbox
                id="AssignmentEmailBccCheckbox"
                labelText={t(`sendEmailModal.emailBcc`)}
                checked={isBccChecked}
                onChange={handleBccChecked}
              />
            </div>
          </div>
          <Controller
            name="subject"
            rules={{
              required: {
                value: true,
                message: t(`sendEmailModal.subjectTextInput.required`),
              },
            }}
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                id="subject"
                onChange={onChange}
                className="project-assignment-table-multiple-extension-subject"
                labelText={t('sendEmailModal.subjectTextInput.label')}
                placeholder={t('sendEmailModal.subjectTextInput.placeholder')}
                value={value}
                warn={!!methods.formState.errors['subject']}
                warnText={
                  <span className="send-email-modal-error-message">{methods.formState.errors['subject']?.message}</span>
                }
              />
            )}
          />
          <Controller
            name="html"
            rules={{
              required: {
                value: true,
                message: t(`sendEmailModal.textEditorInput.required`),
              },
            }}
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <div className="project-assignment-table-multiple-extension-editor">
                {isAssignmentExtensionModalOpen && (
                  <CKEditor
                    editor={ClassicEditor}
                    config={config}
                    data={value}
                    onChange={(event: any, editor: any) => {
                      onChange(editor.getData());
                    }}
                  />
                )}
              </div>
            )}
          />
        </form>
      </Modal>
    </FormProvider>
  );
};

export default MultipleExtensionRequestModal;
