import {
  convertDate,
  orderDataByASCII,
  ProjectClientData,
  ProjectCreateEditPayloadInterface,
  ProjectCreateProps,
  ProjectIdNameData,
  toUTCDate,
  trim,
} from '@cic-boardlite/common';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useEffect, useState, FocusEvent } from 'react';
import {
  Button,
  DatePicker,
  DatePickerInput,
  TextInput,
  TextArea,
  InlineLoading,
  Checkbox,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useBluePages, useCreateProject, useProject, useStaticData } from '../../../custom-hooks';
import { EmailsField } from '../../emails-field/emails-field';
import ErrorBox from '../../../ui/form-error-box/ErrorBox';
import { LabelWithAsterisk } from '../../../ui/label-with-asterisk/label-with-asterisk';
import ComboboxWithCustomFilter from '../../combobox-custom-filtering/combobox-custom-filtering';
import './create-style.scss';

enum DateValidation {
  NotANumber = 'NaN',
}

const filterDuplicateClientData = (arr: ProjectClientData[], key: 'clientName' | 'clientTag') => {
  const uniqueClientData = new Set();
  const filteredArray = arr.filter((el) => !uniqueClientData.has(el[key]) && uniqueClientData.add(el[key]));
  return orderDataByASCII(filteredArray, [key]);
};

export const CreateProjectForm = (props: ProjectCreateProps) => {
  const { t } = useTranslation('common');
  const { staticData } = useStaticData();
  const { buildProject, isCreatingProject, wasProjectCreated, resetProjectCreated } = useCreateProject();

  const [projectStartDate, setProjectStartDate] = useState(convertDate(new Date()));
  const [fieldsEdited, setFieldsEdited] = useState<boolean>(false);
  const [itemsForGeoPmList, setItemsForGeoPmList] = useState<{ name: string; talentId: string }[]>([]);

  const { projectsClientData, projectsIdNameData, geoPmNamesTalentIds } = useProject();
  const { bluePagesPractitioners } = useBluePages();

  const existingProjectsIDs: string[] = projectsIdNameData.map(
    (projectInfo: ProjectIdNameData) => projectInfo.projectId
  );

  const checkIfProjectIdExists = (array: string[], value: string) => {
    const arrayToLowerCase = array.map((item) => item.toLowerCase());
    const valueToLowerCase = value.toLowerCase().trim();
    return arrayToLowerCase.indexOf(valueToLowerCase) < 0;
  };

  useEffect(() => {
    const bluePagesGeoPmList = bluePagesPractitioners.map((practitioner) => ({
      name: practitioner?.nameFull,
      talentId: practitioner.id + ` [${practitioner?.nameFull}]`,
    }));

    const uniqueGeoPmListFromDB = geoPmNamesTalentIds
      .filter((geoPm) => geoPm.talentId)
      .map((item) => ({ ...item, talentId: item?.talentId + ` [${item?.name}]` }))
      .filter((geoPm) => !bluePagesGeoPmList.find((bpGeo) => bpGeo.talentId === geoPm.talentId));

    const geoPmSuggestionList = [...bluePagesGeoPmList, ...uniqueGeoPmListFromDB];

    setItemsForGeoPmList(geoPmSuggestionList);
  }, [bluePagesPractitioners]);

  useEffect(() => {
    if (wasProjectCreated) {
      resetProjectCreated();
      resetForm();
      props.closeModal();
    }
  }, [wasProjectCreated]);

  const methods = useForm();

  useEffect(() => {
    if (!projectStartDate) return;

    const endDate = convertDate(methods.getValues('endDate'));

    if (!endDate || endDate < projectStartDate) {
      methods.setValue('endDate', projectStartDate);
    }
  }, [projectStartDate]);

  function resetForm() {
    methods.reset({
      projectId: '',
      projectName: '',
      clientNameTag: {
        clientName: '',
        clientTag: '',
      },
      contractCountry: '',
      projectType: '',
      serviceLine: '',
      projectStatus: '',
      pmo: { name: '' },
      startDate: new Date().getTime(),
      endDate: projectStartDate ? setProjectStartDate(convertDate(new Date())) : new Date().getTime(),
      geoPmNameTalentId: {
        name: '',
        talentId: '',
      },
      accountIds: '',
      comment: '',
      ccEmail: [],
      sendEmailCheckbox: false,
    });
  }

  const onSubmit = (data: any) => {
    const newProject: ProjectCreateEditPayloadInterface = {
      projectId: trim(data.projectId),
      name: trim(data.projectName),
      clientName: trim(data.clientNameTag.clientName),
      projectAccountIds: data.accountIds,
      startDate: data.startDate,
      endDate: data.endDate,
      comment: trim(data.comment),
      country: data.contractCountry.id,
      countryTitle: data.contractCountry.label,
      projectType: data.projectType.id,
      projectTypeTitle: data.projectType.title,
      projectStatusTitle: data.projectStatus.title,
      projectStatus: data.projectStatus.id,
      serviceLineTitle: data.serviceLine.title,
      serviceLine: data.serviceLine.id,
      clientTag: trim(data.clientNameTag.clientTag),
      sendEmail: data.sendEmailCheckbox,
      emailTo: data.sendEmailCheckbox ? data.ccEmail[0] : '',
      ccEmail: data.sendEmailCheckbox ? data.ccEmail.slice(1) : [],
      pmo: data.pmo?.name ?? '',
      geoPm: data?.geoPmNameTalentId?.name,
      geoPmTalentId: data?.geoPmNameTalentId?.talentId,
    };

    buildProject(newProject);
  };

  useEffect(() => {
    setFieldsEdited(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (props.trackFormChanges) {
      props.trackFormChanges(fieldsEdited);
    }
  }, [fieldsEdited]);

  function updateTagWhenNameHandWritten(event: FocusEvent<HTMLInputElement>) {
    const clientName = methods.getValues('clientNameTag');
    if (clientName.clientTag === '') {
      methods.setValue('clientNameTag', {
        clientName: event?.target.value,
        clientTag: event?.target.value.replace(/\s/g, '').toLowerCase().slice(0, 10),
      });
    }
  }

  return (
    <FormProvider {...methods}>
      <form className="create-project" onSubmit={methods.handleSubmit((data) => onSubmit(data))}>
        <div className="create-project-form">
          <Controller
            name="projectId"
            control={methods.control}
            rules={{
              validate: {
                validateDuplicate: (value) =>
                  checkIfProjectIdExists(existingProjectsIDs, value) || t(`createProjectForm.projectId.exists`),
              },
              required: {
                value: true,
                message: t('fieldRequired', {
                  fieldLabel: t('createProjectForm.projectId.label'),
                }),
              },
              maxLength: {
                value: 128,
                message: t(`fieldMaxLength`, {
                  fieldLabel: t('createProjectForm.projectId.label'),
                  maxLength: 128,
                }),
              },
            }}
            render={({ field: { onChange, value = '' } }) => (
              <TextInput
                onChange={onChange}
                autoComplete="off"
                id="createProjectId"
                labelText={LabelWithAsterisk(t(`createProjectForm.projectId.label`))}
                placeholder={t(`createProjectForm.projectId.placeholder`)}
                value={value}
                warn={!!methods.formState.errors['projectId']?.message}
                warnText={<ErrorBox>{methods.formState.errors['projectId']?.message?.toString()}</ErrorBox>}
              />
            )}
          />
          <Controller
            name="projectName"
            control={methods.control}
            rules={{
              required: {
                value: true,
                message: t('fieldRequired', {
                  fieldLabel: t('createProjectForm.projectName.label'),
                }),
              },
              maxLength: {
                value: 128,
                message: t(`fieldMaxLength`, {
                  fieldLabel: t('createProjectForm.projectName.label'),
                  maxLength: 128,
                }),
              },
            }}
            render={({ field: { onChange, value = '' } }) => (
              <TextInput
                onChange={onChange}
                id="createProjectName"
                labelText={LabelWithAsterisk(t(`createProjectForm.projectName.label`))}
                placeholder={t(`createProjectForm.projectName.placeholder`)}
                value={value}
                warn={!!methods.formState.errors['projectName']?.message}
                warnText={<ErrorBox>{methods.formState.errors['projectName']?.message?.toString()}</ErrorBox>}
              />
            )}
          />
          <Controller
            name="clientNameTag"
            control={methods.control}
            defaultValue={{ clientName: '', clientTag: '' }}
            render={({ field: { onChange, value } }) => (
              <ComboboxWithCustomFilter
                customClassName="custom-filter-combobox-create-project-client-name"
                onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                onBlur={(event) => updateTagWhenNameHandWritten(event)}
                selectedItem={value ?? { clientName: '', clientTag: '' }}
                items={filterDuplicateClientData(projectsClientData, 'clientName')}
                itemKeyName="clientName"
                titleText={LabelWithAsterisk(t('createProjectForm.clientName.label'))}
                placeholder={t('createProjectForm.clientName.placeholder')}
                allowInputValue
                warn={!!methods.formState.errors['clientNameTag'] && !value?.clientName?.length}
                warnText={
                  <ErrorBox>
                    {t('fieldRequired', {
                      fieldLabel: t('createProjectForm.clientName.label'),
                    })}
                  </ErrorBox>
                }
              />
            )}
          />
          <Controller
            name="clientNameTag"
            control={methods.control}
            defaultValue={{ clientName: '', clientTag: '' }}
            rules={{
              validate: (value) => {
                if (value?.clientName?.length && value?.clientTag?.length) {
                  methods.clearErrors('clientNameTag');
                  return true;
                } else return false;
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ComboboxWithCustomFilter
                customClassName="custom-filter-combobox-create-project-client-tag"
                onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                selectedItem={value ?? { clientName: '', clientTag: '' }}
                items={filterDuplicateClientData(projectsClientData, 'clientTag') || []}
                itemKeyName="clientTag"
                titleText={LabelWithAsterisk(t('createProjectForm.clientTag.label'))}
                placeholder={t('createProjectForm.clientTag.placeholder')}
                allowInputValue
                warn={!!methods.formState.errors['clientNameTag'] && !value?.clientTag?.length}
                warnText={
                  <ErrorBox>
                    {t('fieldRequired', {
                      fieldLabel: t('createProjectForm.clientTag.label'),
                    })}
                  </ErrorBox>
                }
              />
            )}
          />
          <Controller
            name="geoPmNameTalentId"
            control={methods.control}
            rules={{
              required: true,
              validate: (value) => !!value?.talentId?.length,
            }}
            defaultValue={{ name: '', talentId: '' }}
            render={({ field: { onChange, value } }) => (
              <ComboboxWithCustomFilter
                customClassName="custom-filter-combobox-create-project-geo-pm-talent-id"
                onChange={(onChangeData) => {
                  onChange({
                    talentId: onChangeData.selectedItem?.talentId?.split(' ')[0] ?? '',
                    name: onChangeData.selectedItem?.name ?? '',
                  });
                }}
                selectedItem={value}
                items={itemsForGeoPmList}
                itemKeyName="talentId"
                titleText={LabelWithAsterisk(t('createProjectForm.geoPm.geoPmTalentIdLabel'))}
                placeholder={t('createProjectForm.geoPm.selectGeoPmTalentIdPlaceholder')}
                loadPractitionerSuggestionsW3
                warn={!!methods.formState.errors['geoPmNameTalentId']}
                warnText={
                  <ErrorBox>
                    {t('fieldRequired', {
                      fieldLabel: t('createProjectForm.geoPm.geoPmTalentIdLabel'),
                    })}
                  </ErrorBox>
                }
              />
            )}
          />
          <Controller
            name="geoPmNameTalentId"
            defaultValue={{ name: '', talentId: '' }}
            render={({ field: { value } }) => (
              <TextInput
                value={value?.name ?? ''}
                labelText={t('createProjectForm.geoPm.geoPmNameLabel')}
                id="edit-project-geo-pm-name"
                disabled
                placeholder={t('createProjectForm.geoPm.geoPmNameLabel')}
              />
            )}
          />
          <Controller
            name="contractCountry"
            control={methods.control}
            rules={{
              validate: (value) => !!value && !!value.label && !!value.id,
            }}
            render={({ field: { onChange, value } }) => (
              <ComboboxWithCustomFilter
                customClassName="custom-filter-combobox-create-project-country"
                onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                selectedItem={value ?? { id: null, label: '', code: '' }}
                items={staticData.staticData.countryStaticData ?? []}
                itemKeyName="label"
                titleText={LabelWithAsterisk(t(`createProjectForm.contractCountry.label`))}
                placeholder={t(`createProjectForm.contractCountry.select`)}
                warn={!!methods.formState.errors['contractCountry']}
                warnText={
                  <ErrorBox>
                    {t('fieldRequired', {
                      fieldLabel: t('createProjectForm.contractCountry.label'),
                    })}
                  </ErrorBox>
                }
              />
            )}
          />
          <Controller
            name="serviceLine"
            control={methods.control}
            rules={{
              validate: (value) => !!value && !!value?.id && !!value?.title?.length,
            }}
            render={({ field: { onChange, value = '' } }) => (
              <ComboboxWithCustomFilter
                customClassName="custom-filter-combobox-create-project-service-line"
                onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                selectedItem={value ?? { id: null, title: '' }}
                items={staticData.staticData.serviceLineStaticData ?? []}
                itemKeyName="title"
                titleText={LabelWithAsterisk(t(`createProjectForm.serviceLine.label`))}
                placeholder={t(`createProjectForm.serviceLine.select`)}
                warn={!!methods.formState.errors['serviceLine']}
                warnText={
                  <ErrorBox>
                    {t('fieldRequired', {
                      fieldLabel: t('createProjectForm.serviceLine.label'),
                    })}
                  </ErrorBox>
                }
              />
            )}
          />
          <Controller
            name="projectType"
            control={methods.control}
            rules={{
              validate: (value) => !!value && !!value?.id && !!value?.title?.length,
            }}
            render={({ field: { onChange, value } }) => (
              <ComboboxWithCustomFilter
                customClassName="custom-filter-combobox-create-project-project-type"
                onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                selectedItem={value ?? { id: null, title: '' }}
                items={staticData.staticData.projectTypeStaticData ?? []}
                itemKeyName="title"
                titleText={LabelWithAsterisk(t(`createProjectForm.projectType.label`))}
                placeholder={t(`createProjectForm.projectType.select`)}
                warn={!!methods.formState.errors['projectType']}
                warnText={
                  <ErrorBox>
                    {t('fieldRequired', {
                      fieldLabel: t('createProjectForm.projectType.label'),
                    })}
                  </ErrorBox>
                }
              />
            )}
          />
          <Controller
            name="pmo"
            control={methods.control}
            rules={{
              required: true,
              validate: (value) => !!value?.name?.length,
            }}
            render={({ field: { onChange, value } }) => (
              <ComboboxWithCustomFilter
                customClassName="custom-filter-combobox-create-project-pmo"
                onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                selectedItem={value ?? { name: '' }}
                items={
                  staticData?.staticData?.pmoStaticData?.map(
                    (pmo: { id: number; firstName: string; lastName: string }) => ({
                      name: (pmo?.firstName ?? '') + ' ' + (pmo?.lastName ?? ''),
                    })
                  ) || []
                }
                itemKeyName="name"
                titleText={LabelWithAsterisk(t(`createProjectForm.pmo.label`))}
                placeholder={t(`createProjectForm.pmo.select`)}
                warn={!!methods.formState.errors['pmo']}
                warnText={
                  <ErrorBox>
                    {t('fieldRequired', {
                      fieldLabel: t('createProjectForm.pmo.label'),
                    })}
                  </ErrorBox>
                }
              />
            )}
          />
          <div className="create-project-form-row-1-4 create-project-form-row-three-columns">
            <Controller
              name="startDate"
              control={methods.control}
              defaultValue={new Date().getTime()}
              rules={{
                validate: {
                  required: (value) =>
                    value.toString() !== DateValidation.NotANumber ||
                    t('fieldRequired', {
                      fieldLabel: t('createProjectForm.startDate.label'),
                    }),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  light
                  datePickerType="single"
                  onChange={(_dates: Date[], _currentDateString: string) => {
                    onChange(new Date(_currentDateString).getTime());
                    setProjectStartDate(_currentDateString);
                  }}
                  dateFormat={t('format.date_4carbonDatePicker')}
                  value={value}
                >
                  <DatePickerInput
                    id="createProjectStartDate"
                    autoComplete="off"
                    labelText={LabelWithAsterisk(t(`createProjectForm.startDate.label`))}
                    placeholder={t(`date.placeholder`)}
                    size="xl"
                    warn={!!methods.formState.errors['startDate']?.message}
                    warnText={<ErrorBox>{methods.formState.errors['startDate']?.message?.toString()}</ErrorBox>}
                    pattern={t('format.date_4carbonDatePickerInput')}
                  />
                </DatePicker>
              )}
            />
            <Controller
              name="endDate"
              control={methods.control}
              defaultValue={new Date().getTime()}
              rules={{
                validate: (value) =>
                  value.toString() !== DateValidation.NotANumber &&
                  methods.getValues('startDate') &&
                  new Date(value).getTime() >= new Date(methods.getValues('startDate')).getTime(),
              }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  light
                  datePickerType="single"
                  onChange={(_dates: Date[], _currentDateString: string) =>
                    onChange(new Date(_currentDateString).getTime())
                  }
                  dateFormat={t('format.date_4carbonDatePicker')}
                  value={value}
                  disable={[
                    (date: Date) => date < toUTCDate(projectStartDate || new Date()),
                  ]}
                >
                  <DatePickerInput
                    id="createProjectEndDate"
                    autoComplete="off"
                    labelText={LabelWithAsterisk(t(`createProjectForm.endDate.label`))}
                    placeholder={t(`date.placeholder`)}
                    size="xl"
                    warn={!!methods.formState.errors['endDate']}
                    warnText={
                      <ErrorBox>
                        {t('fieldRequired', {
                          fieldLabel: t('createProjectForm.endDate.label'),
                        })}
                      </ErrorBox>
                    }
                    pattern={t('format.date_4carbonDatePickerInput')}
                  />
                </DatePicker>
              )}
            />
            <Controller
              name="projectStatus"
              control={methods.control}
              rules={{
                validate: (value) => !!value && !!value?.id && !!value?.title?.length,
              }}
              render={({ field: { onChange, value } }) => (
                <ComboboxWithCustomFilter
                  customClassName="custom-filter-combobox-create-project-project-status"
                  onChange={(onChangeData) => onChange(onChangeData.selectedItem)}
                  selectedItem={value ?? { id: null, title: '' }}
                  items={staticData.staticData.projectStatusStaticData ?? []}
                  itemKeyName="title"
                  titleText={LabelWithAsterisk(t(`createProjectForm.projectStatus.label`))}
                  placeholder={t(`createProjectForm.projectStatus.select`)}
                  warn={!!methods.formState.errors['projectStatus']}
                  warnText={
                    <ErrorBox>
                      {t('fieldRequired', {
                        fieldLabel: t('createProjectForm.projectStatus.label'),
                      })}
                    </ErrorBox>
                  }
                />
              )}
            />
          </div>
          <div className="create-project-form-row-1-4 create-project-form-row-two-columns">
            <Controller
              name="accountIds"
              control={methods.control}
              rules={{
                required: {
                  value: true,
                  message: t(`createProjectForm.accountIds.required`),
                },
                maxLength: {
                  value: 5000,
                  message: t(`fieldMaxLength`, {
                    fieldLabel: t('createProjectForm.accountIds.label'),
                    maxLength: 5000,
                  }),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  onChange={onChange}
                  value={value}
                  id="createProjectAccountIds"
                  labelText={LabelWithAsterisk(t(`createProjectForm.accountIds.label`))}
                  placeholder={t(`createProjectForm.accountIds.placeholder`)}
                  rows={2}
                  invalid={!!methods.formState.errors['accountIds']?.message}
                  invalidText={<ErrorBox>{methods.formState.errors['accountIds']?.message?.toString()}</ErrorBox>}
                />
              )}
            />
            <Controller
              name="comment"
              control={methods.control}
              rules={{
                maxLength: {
                  value: 512,
                  message: t(`fieldMaxLength`, {
                    fieldLabel: t('createProjectForm.comment.label'),
                    maxLength: 512,
                  }),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  onChange={onChange}
                  value={value}
                  id="createProjectComment"
                  labelText={t(`createProjectForm.comment.label`)}
                  rows={2}
                  invalid={!!methods.formState.errors['comment']?.message}
                  invalidText={<ErrorBox>{methods.formState.errors['comment']?.message?.toString()}</ErrorBox>}
                />
              )}
            />
          </div>
        </div>
        <div className="create-project-email-checkbox">
          <Controller
            name="sendEmailCheckbox"
            defaultValue={false}
            control={methods.control}
            render={({ field: { onBlur, value } }) => (
              <Checkbox
                id="email-checkbox"
                className="field-cc-mail-checkbox"
                labelText={String(t('createProjectForm.send'))}
                onBlur={onBlur}
                checked={value}
                onChange={(value: boolean) => {
                  methods.setValue('sendEmailCheckbox', value, {
                    shouldDirty: true,
                  });
                  methods.clearErrors('sendEmailCheckbox');
                }}
              />
            )}
          />
        </div>
        {methods.getValues('sendEmailCheckbox') && (
          <div className="emailBox">
            <EmailsField
              checkBoxName={'sendEmailCheckbox'}
              emailBoxName={'ccEmail'}
              textAreaLabelLangKey={'ccEmail.label'}
              textAreaPlaceholderLangKey={'ccEmail.placeholder'}
            />
          </div>
        )}
        <div className="create-project-form-buttons">
          <Button
            id="createProjectReset"
            kind="secondary"
            className="create-project-form-button"
            size="lg"
            onClick={() => resetForm()}
          >
            {t(`createProjectForm.reset`)}
          </Button>
          <Button
            id="createProjectSubmit"
            type="submit"
            className="create-project-form-button"
            size="lg"
            onClick={() => {
              methods.clearErrors('ccEmail');
              methods.getValues('sendEmailCheckbox') &&
                (methods.getValues('ccEmail') === undefined || methods.getValues('ccEmail').length === 0) &&
                methods.setError('ccEmail', { message: t('ccEmail.required') });
            }}
            disabled={isCreatingProject}
          >
            {!isCreatingProject && t(`createProjectForm.save`)}
            {isCreatingProject && (
              <InlineLoading
                status="active"
                iconDescription={t(`createProjectForm.saving`)}
                description={t(`createProjectForm.savingProject`)}
              />
            )}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
