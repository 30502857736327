import { QueryByCountry } from '@cic-boardlite/common';
import {
  getBluePagesPractitionersBySerial,
  getGeoPmBluePages,
  getPemBluePages,
  getPreferredIdentityEmailBluePages,
  pingBlueServices,
  pingCvBuilder,
  setExternalServicesInterval,
  setSelectedBluePagesPractitioner,
} from '../data-access/bluepages/slice';
import { useAppDispatch, useAppSelector } from '../data-access/state-configurations/hooks';

const useBluePages = () => {
  const dispatch = useAppDispatch();

  const bluePagesPractitioners = useAppSelector((state) => state.bluePages.bluePagesPractitionersData);

  const isLoadingBluePagesPractitioners = useAppSelector((state) => state.bluePages.isLoadingBluePagesPractitioners);

  const getAllBluePagesPractitionersBySerial = (params: QueryByCountry) => {
    dispatch(getBluePagesPractitionersBySerial(params));
  };

  const selectedBluePagesPractitioner = useAppSelector((state) => state.bluePages.selectedBluePagesPractitioner);

  const selectBluePagesPractitioner = (data: string) => {
    dispatch(setSelectedBluePagesPractitioner(data));
  };

  const isSelectingBluePagesPractitioner = useAppSelector((state) => state.bluePages.isSelectingBluePagesPractitioner);

  // TODO: if getBluePagesPem and getBluePagesGeoPm functions will not be used in the future - delete them
  const bluePagesPem = useAppSelector((state) => state.bluePages.pemBluePages);

  const getBluePagesPem = (data: string) => {
    if (bluePagesPem?.nameFull !== data) dispatch(getPemBluePages(data));
  };

  const isLoadingPemBluePages = useAppSelector((state) => state.bluePages.isPemBluePagesLoading);

  const bluePagesGeoPm = useAppSelector((state) => state.bluePages.geoPmBluePages);

  const getBluePagesGeoPm = (data: string) => {
    if (bluePagesGeoPm?.nameFull !== data) dispatch(getGeoPmBluePages(data));
  };

  const isLoadingPreferredIdentityEmailBluePages = useAppSelector(
    (state) => state.bluePages.isPreferredIdentityEmailBluePagesLoading
  );

  const bluePagesPreferredIdentityEmail = useAppSelector((state) => state.bluePages.preferredIdentityEmailBluePages);

  const getBluePagesPreferredIdentityEmail = (data: string) => {
    dispatch(getPreferredIdentityEmailBluePages(data));
  };

  const isLoadingGeoPmBluePages = useAppSelector((state) => state.bluePages.isGeoPmBluePagesLoading);

  const isBlueServicesOnline = useAppSelector((state) => state.bluePages.isBlueServicesOnline);

  const isLoadingBlueServices = useAppSelector((state) => state.bluePages.isLoadingBlueServices);

  const checkBlueServices = () => {
    dispatch(pingBlueServices());
  };

  const isCvBuilderOnline = useAppSelector((state) => state.bluePages.isCvBuilderOnline);

  const isLoadingCvBuilder = useAppSelector((state) => state.bluePages.isLoadingCvBuilder);

  const checkCvBuilderStatus = () => {
    dispatch(pingCvBuilder());
  };

  const externalServicesInterval = useAppSelector((state) => state.bluePages.externalServicesInterval);

  const setExternalServicesIntervalId = (intervalId: NodeJS.Timer) => {
    dispatch(setExternalServicesInterval(intervalId));
  };

  return {
    getAllBluePagesPractitionersBySerial,
    isLoadingBluePagesPractitioners,
    bluePagesPractitioners,
    bluePagesPem,
    getBluePagesPem,
    isLoadingPemBluePages,
    bluePagesGeoPm,
    getBluePagesGeoPm,
    isLoadingGeoPmBluePages,
    bluePagesPreferredIdentityEmail,
    getBluePagesPreferredIdentityEmail,
    isLoadingPreferredIdentityEmailBluePages,
    isBlueServicesOnline,
    isLoadingBlueServices,
    checkBlueServices,
    isCvBuilderOnline,
    isLoadingCvBuilder,
    checkCvBuilderStatus,
    externalServicesInterval,
    setExternalServicesIntervalId,
    selectedBluePagesPractitioner,
    selectBluePagesPractitioner,
    isSelectingBluePagesPractitioner,
  };
};

export default useBluePages;
